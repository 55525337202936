export default class ClientsSelector {
  public static selectClient(state: ReduxState) {
    return state.clientEntry.model.data;
  }

  public static selectClients(state: ReduxState) {
    return state.clientEntry.list.data;
  }

  public static selectClientIsLoading(state: ReduxState) {
    return state.clientEntry.model.isLoading;
  }

  public static selectClientsIsLoading(state: ReduxState) {
    return state.clientEntry.list.isFetchLoading;
  }

  public static selectClientsCount(state: ReduxState) {
    return state.clientEntry.list.count;
  }
}
