import { reducerCreator } from 'redux-rest-helper-for-loopback';
import PackageCodeRegistriesAction from './PackageCodeRegistriesAction';

export default class PackageCodeRegistriesReducer {
  private static initValues = {
    orderNumber: 0,
    orderId: '',
    id: '',
    packageCode: '',
    clientId: '',
    clientName: '',
  };

  public static reducer = reducerCreator(
    PackageCodeRegistriesAction.ENTRY,
    PackageCodeRegistriesReducer.initValues
  ).getReducer();
}
