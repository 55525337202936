import React from 'react';
import { CoreuiCheckBox, Input, Select, TextArea } from 'releox-react';
import * as Yup from 'yup';
import { Language } from '../classes/Language';
import DatePicker from '../components/DatePicker';
import { countryOptions, languageOptions, teamOptions } from '../options';
import { Order } from '../store/order/Order';

export const OrderValidationSchema: any = (ignore: string[] = []) => {
  const rules: any = {
    welcomeCall: Yup.date().required(),
    dueDate: Yup.date().required(),
    office: Yup.string(),
    language: Yup.string().required(),
    country: Yup.string().required(),
  };
  ignore.forEach((key) => {
    delete rules[key];
  });
  return Yup.object().shape(rules);
};

export const orderFormInitValue: Order = {
  id: '',
  address: '',
  zip: '',
  city: '',
  welcomeCall: new Date(),
  dueDate: new Date(),
  products: [],
  language: Language.FINNISH,
  shipmentOffices: [],
  country: '',
  team: '',
  callerId: '',
  clientId: '',
  isWebOrder: false,
  generalMessage: '',
  packagerMessageFromBuyer: '',
  valuationMessageFromBuyer: '',
  tellerMessageFromBuyer: '',
  tellerMessageFromValuation: '',
  revaluationMessageFromTeller: '',
};

interface Props {
  minDate?: Date | null;
}

export default ({ minDate }: Props) => (
  <div>
    <DatePicker minDate={minDate} label="Welcome call" name="welcomeCall" />
    <DatePicker minDate={minDate} label="Due date" name="dueDate" />
    <Select label="Country" name="country" options={countryOptions} />
    <Select label="Language" name="language" options={languageOptions} />
    <Select label="Team" name="team" options={teamOptions} />
    <Input label="Close reason" name="closeReason" />
    <Input label="Bar code" name="barCode" />
    <Input type="number" label="Offer" name="offer" />
    <Input type="number" label="SEK offer" name="sekOffer" />
    <Input type="number" label="GBP offer" name="gbpOffer" />
    <TextArea label="General message" name="generalMessage" />
    <TextArea label="Packager message from buyer" name="packagerMessageFromBuyer" />
    <TextArea label="Valuation message from buyer" name="valuationMessageFromBuyer" />
    <TextArea label="Teller message from valuation" name="tellerMessageFromValuation" />
    <TextArea label="Teller message from buyer" name="tellerMessageFromBuyer" />
    <TextArea label="Revaluation message from teller" name="revaluationMessageFromTeller" />
    <TextArea label="Bursar comment" name="bursarComment" />
    <TextArea label="Teller comment" name="tellerComment" />
    <TextArea label="Customer service comment" name="customerServiceComment" />
    <Input name="tellerBreakdown" label="Teller breakdown" />

    <div className="row">
      <div className="col-6">
        <p>Teller</p>
        <CoreuiCheckBox label="returnToValuation" name="returnToValuation" />
        <CoreuiCheckBox label="callRequest" name="callRequest" />
        <CoreuiCheckBox label="termsAccepted" name="termsAccepted" />
        <CoreuiCheckBox label="offerAccepted" name="offerAccepted" />
        <CoreuiCheckBox label="ignobleReturn" name="ignobleReturn" />
        <CoreuiCheckBox label="offeredDeclined" name="offeredDeclined" />
        {/* <CoreuiCheckBox label="LEGACY: valuableReturn" name="valuableReturn" /> */}
        <CoreuiCheckBox label="insuranceReturn" name="insuranceReturn" />
        <CoreuiCheckBox label="isTellered" name="isTellered" />
        <CoreuiCheckBox label="arrivedSmsSent" name="arrivedSmsSent" />
        <CoreuiCheckBox label="isDonation" name="isDonation" />
        <hr />
        <p>System</p>
        <CoreuiCheckBox label="closed" name="closed" />
        <p>Bursar</p>
        <CoreuiCheckBox label="isBursarReturnMade" name="isBursarReturnMade" />
        <CoreuiCheckBox label="isComplete" name="isComplete" />
      </div>
      <div className="col-6">
        <p>Packager</p>
        <CoreuiCheckBox label="inPackager" name="inPackager" />
        <CoreuiCheckBox label="isPackaged" name="isPackaged" />
        <hr />
        <p>Valuation</p>
        <CoreuiCheckBox label="isBoomerang" name="isBoomerang" />
        <CoreuiCheckBox label="isRevaluated" name="isRevaluated" />
        <CoreuiCheckBox label="isValued" name="isValued" />
        <hr />
        <p>Payer</p>
        <CoreuiCheckBox label="isPaid" name="isPaid" />
        <hr />
        <p>Receiver</p>
        <CoreuiCheckBox label="isReceived" name="isReceived" />
        <hr />
        <p>Buyer</p>
        <CoreuiCheckBox label="welcomeCallDone" name="welcomeCallDone" />
        <CoreuiCheckBox label="isWebOrder" name="isWebOrder" />
        <CoreuiCheckBox label="afterCallDone" name="afterCallDone" />
      </div>
    </div>
  </div>
);
