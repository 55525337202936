import { QzsActionObject, QzsActionTypes } from './QzsAction';

export default class QzsReducer {
  private static initState = {
    connectionMessages: [] as string[],
  };

  public static reducer(state = QzsReducer.initState, action: QzsActionObject): typeof state {
    switch (action.type) {
      case QzsActionTypes.CONNECTION_MESSAGE:
        return {
          ...state,
          connectionMessages: [...state.connectionMessages, action.payload.message],
        };

      case QzsActionTypes.RESET:
        return QzsReducer.initState;

      default:
        return state;
    }
  }
}
