import { Action, ActionWPayload } from '../Action';

export enum QzsActionTypes {
  CONNECTION_MESSAGE = '@QZ:CONNECTION_MESSAGE',
  RESET = '@QZ:RESET',
}

type ConnectionMessage = ActionWPayload<QzsActionTypes.CONNECTION_MESSAGE, { message: string }>;
type Reset = Action<QzsActionTypes.RESET>;

export type QzsActionObject = ConnectionMessage | Reset;

export default class QzsAction {
  public static pushConnectionMessage(message: string): QzsActionObject {
    // eslint-disable-next-line no-console
    console.log(message);
    return {
      type: QzsActionTypes.CONNECTION_MESSAGE,
      payload: { message },
    };
  }

  public static reset(): QzsActionObject {
    return {
      type: QzsActionTypes.RESET,
    };
  }
}
