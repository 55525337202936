export default class ValuationBarCodePromptSelector {
  public static selectValuatedCount(state: ReduxState) {
    return state.valuationBarCodePrompt.valuatedCount;
  }

  public static selectWaitingValuationCount(state: ReduxState) {
    return state.valuationBarCodePrompt.waitingValuationCount;
  }

  public static selectWaitingRevaluationCount(state: ReduxState) {
    return state.valuationBarCodePrompt.waitingRevaluationCount;
  }
}
