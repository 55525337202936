import { Country } from './classes/Country';
import { Language } from './classes/Language';
import { Team } from './classes/Team';

export const languageOptions = [
  { value: '', label: 'Choose language' },
  { value: Language.FINNISH, label: 'Finnish' },
  { value: Language.SWEDISH, label: 'Swedish' },
  { value: Language.FINLAND_SWEDISH, label: 'Finland swedish' },
  { value: Language.UK_ENGLISH, label: 'English (UK)' },
];

export const countryOptions = [
  { value: '', label: 'Choose country' },
  { value: Country.FINLAND, label: 'Finland' },
  { value: Country.SWEDEN, label: 'Sweden' },
  { value: Country.UK, label: 'UK' },
];

export const teamOptions = [
  { value: '', label: 'Choose team' },
  { value: Team.TURKU, label: 'Turku' },
  { value: Team.HELSINKI, label: 'Helsinki' },
  { value: Team.GOTHENBURG, label: 'Göteborg' },
  { value: Team.MALAGA_FINLAND, label: 'Malaga Finland' },
  { value: Team.MALAGA_SWEDEN, label: 'Malaga Sweden' },
  { value: Team.UK, label: 'UK' },
];

export const getTeamName = (team: string) => {
  return teamOptions.find((t) => t.value === team)?.label;
};

export const closeMethodOptions = [
  { value: '', label: 'Choose Method' },
  { value: 'phone', label: 'Phone' },
  { value: 'boomerang', label: 'Boomerang' },
  { value: 'email', label: 'Email' },
];
