import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { Button, Card, CheckBox, GenericIndexScene, Input } from 'releox-react';
import { formatDateAndFrom } from '../../../config';
import routes from '../../../configs/routes';
import TeamsAction from '../../../store/team/TeamsAction';
import TeamsSelector from '../../../store/team/TeamsSelector';

const initValues = {
  where: {
    isActive: true,
    name: {
      like: '',
      options: 'i',
    },
  },
};

type Body = {
  where?: {
    isActive?: boolean;
    name?: {
      like: string;
      options: string;
    };
  };
};

export default () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<LoopbackFilter>({ where: { isActive: true } });

  const redirect = (url: string) => () => {
    dispatch(push(url));
  };

  const handleSubmit = (form: typeof initValues) => {
    const body: Body = {};
    _.set(body, 'where.isActive', form.where.isActive);
    if (form.where.name.like) _.set(body, 'where.name', form.where.name);
    setQuery(body);
  };

  const columns = [
    { text: 'Name', dataField: 'name', sort: true },
    { text: 'Active', dataField: 'isActive', formatter: (bool: boolean) => (bool ? 'Yes' : 'No') },
    { text: 'Username', dataField: 'username', sort: true },
    { text: 'Created', dataField: 'createdAt', sort: true, formatter: formatDateAndFrom },
    { text: 'Updated', dataField: 'updatedAt', sort: true, formatter: formatDateAndFrom },
    {
      text: 'Actions',
      dataField: 'id',
      formatter: (id: string) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm mr-3"
              onClick={redirect(routes.ADMIN_USERS.EDIT.replace(':id', id))}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={redirect(routes.ADMIN_USERS.PASSWORD.replace(':id', id))}
            >
              Change password
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Formik initialValues={initValues} onSubmit={handleSubmit}>
          <Form>
            <div className="row">
              <div className="col-md-4">
                <Input name="where.name.like" label="Name" />
                <div className="row">
                  <div className="col-md-6">
                    <CheckBox label="Show active users" name="where.isActive" />
                  </div>
                  <div className="col-md-6">
                    <Button id="submit" className="float-right" type="submit">
                      Find
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Card>
      <GenericIndexScene
        key={JSON.stringify(query)}
        tNamespace="TeamIndexScene"
        loadingSelector={TeamsSelector.selectMembersIsLoading}
        dataSelector={TeamsSelector.selectMembers}
        dataSizeSelector={TeamsSelector.selectMembersCount}
        onChangeLoopback={TeamsAction.list}
        columns={columns}
        createLink={routes.ADMIN_USERS.CREATE}
        query={query}
      />
    </>
  );
};
