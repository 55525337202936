import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import { Card, CardTitle } from 'releox-react';
import BuyerStatsAction from '../../store/buyer-stat/BuyerStatsAction';
import BuyerStatsSelector from '../../store/buyer-stat/BuyerStatsSelector';
import UsersSelector from '../../store/user/UsersSelector';

export default () => {
  const dispatch = useDispatch();
  const outCounts = useSelector(BuyerStatsSelector.selectOutCount);
  const inCounts = useSelector(BuyerStatsSelector.selectInCount);
  const cancelCount = useSelector(BuyerStatsSelector.selectCancelCount);
  const user = useSelector(UsersSelector.selectUser);

  useEffect(() => {
    dispatch(BuyerStatsAction.fetchData());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-lg-6">
        <Card>
          <CardTitle>Your stats</CardTitle>
          <table className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Out</th>
                <th>In (au)</th>
                <th>Cancelled</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Today</td>
                <td>{outCounts.yourToday}</td>
                <td>{inCounts.yourToday}</td>
                <td>{cancelCount.yourToday}</td>
              </tr>
              <tr>
                <td>Month</td>
                <td>{outCounts.yourMonth}</td>
                <td>{inCounts.yourMonth}</td>
                <td>{cancelCount.yourMonth}</td>
              </tr>
            </tbody>
          </table>
          <Progress value={inCounts.yourMonth} max={user.buyerCommission}>
            Commission {inCounts.yourMonth} / {user.buyerCommission}
          </Progress>
        </Card>
      </div>
      <div className="col-lg-6">
        <Card>
          <CardTitle>Team stats</CardTitle>
          <table className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Out</th>
                <th>In (au)</th>
                <th>Cancelled</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Today</td>
                <td>{outCounts.teamToday}</td>
                <td>{inCounts.teamToday}</td>
                <td>{cancelCount.teamToday}</td>
              </tr>
              <tr>
                <td>Month</td>
                <td>{outCounts.teamMonth}</td>
                <td>{inCounts.teamMonth}</td>
                <td>{cancelCount.teamMonth}</td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};
