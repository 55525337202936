import { rednotReducer } from 'rednot';
import { combineReducers } from 'redux';
import AfterCallsReducer from './after-call/AfterCallsReducer';
import BursarsReducer from './bursar/BursarsReducer';
import BuyerStatsReducer from './buyer-stat/BuyerStatsReducer';
import CallCountsReducer from './call-count/CallCountsReducer';
import CallLogsReducer from './call-log/CallLogsReducer';
import CallTransfersReducer from './call-transfer/CallTransfersReducer';
import CancelCallTransfersReducer from './cancel-call-transfer/CancelCallTransfersReducer';
import CcManagerReportsReducer from './cc-manager-report/CcManagerReportsReducer';
import ClientsReducer from './client/ClientsReducer';
import ColumnsReducer from './column/ColumnsReducer';
import GenerateOrderNumbersReducer from './generate-order-number/GenerateOrderNumbersReducer';
import MassBoomerangsReducer from './mass-boomerang/MassBoomerangsReducer';
import MaterialsReducer from './material/MaterialsReducer';
import NewOrdersReducer from './new-order/NewOrdersReducer';
import OrdersReducer from './order/OrdersReducer';
import PackageCodeRegistriesReducer from './package-code-registry/PackageCodeRegistriesReducer';
import PackagerStatsReducer from './packager-stats/PackagerStatsReducer';
import PackagerSystemsReducer from './PackagerSystem/PackagerSystemsReducer';
import PayersReducer from './payer/PayersReducer';
import PaymentXmlsReducer from './payment-xml/PaymentXmlsReducer';
import QzsReducer from './qz/QzsReducer';
import ReceiverReducer from './receiver/ReceiverReducer';
import RegisterPackagesReducer from './register-package/RegisterPackagesReducer';
import ReportFiltersReducer from './report-filter/ReportFiltersReducer';
import ReportsReducer from './report/ReportsReducer';
import SearchReducer from './search/SearchReducer';
import TeamsReducer from './team/TeamsReducer';
import TellerSmsReducer from './teller-sms/TellerSmsReducer';
import TellersReducer from './teller/TellersReducer';
import UsersReducer from './user/UsersReducer';
import ValuatesReducer from './valuate/ValuatesReducer';
import ValuationBarCodePromptReducer from './valuation-bar-code-prompt/ValuationBarCodePromptReducer';

const rootReducer = combineReducers({
  user: UsersReducer.reducer,
  teamEntry: TeamsReducer.reducer,
  clientEntry: ClientsReducer,
  orderEntry: OrdersReducer,
  materialEntry: MaterialsReducer.reducer,
  rednot: rednotReducer,
  // buyerEntry: buyerReducer,
  bursar: BursarsReducer.reducer,
  newOrder: NewOrdersReducer.reducer,
  teller: TellersReducer,
  payer: PayersReducer.reducer,
  paymentXml: PaymentXmlsReducer,
  search: SearchReducer.reducer,
  column: ColumnsReducer.reducer,
  callLog: CallLogsReducer.reducer,
  receiver: ReceiverReducer.reducer,
  buyerStats: BuyerStatsReducer.reducer,
  valuationBarCodePrompt: ValuationBarCodePromptReducer.reducer,
  afterCall: AfterCallsReducer.reducer,
  report: ReportsReducer.reducer,
  reportFilter: ReportFiltersReducer.reducer,
  valuate: ValuatesReducer.reducer,
  qz: QzsReducer.reducer,
  callTransfer: CallTransfersReducer.reducer,
  cancelCallTransfer: CancelCallTransfersReducer.reducer,
  tellerSms: TellerSmsReducer.reducer,
  packagerSystem: PackagerSystemsReducer.reducer,
  packagerStats: PackagerStatsReducer.reducer,
  massBoomerang: MassBoomerangsReducer.reducer,
  registerPackage: RegisterPackagesReducer.reducer,
  generateOrderNumber: GenerateOrderNumbersReducer.reducer,
  callCount: CallCountsReducer.reducer,
  ccManagerReport: CcManagerReportsReducer.reducer,
  packageCodeRegistry: PackageCodeRegistriesReducer.reducer,
});

export type ReducerReturn = ReturnType<typeof rootReducer>;

export default rootReducer;
