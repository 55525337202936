export default class SearchSelector {
  public static selectOrderQ(state: ReduxState) {
    return state.search.orderQ;
  }

  public static selectClientQ(state: ReduxState) {
    return state.search.clientQ;
  }

  public static selectOrderQuery(state: ReduxState) {
    return state.search.orderSearch;
  }

  public static selectClientQuery(state: ReduxState) {
    return state.search.clientSearch;
  }
}
