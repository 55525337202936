import React from 'react';
import { GenericEditForm } from 'releox-react';
import routes from '../../../configs/routes';
import ClientForm, { clientFormInitValue, ClientValidationSchema } from '../../../forms/ClientForm';
import { ReduxDispatch } from '../../../store/Action';
import { Client } from '../../../store/client/Client';
import ClientsAction from '../../../store/client/ClientsAction';
import ClientsSelector from '../../../store/client/ClientsSelector';

export default () => {
  const onSubmit = (id: string | number, body: Partial<Client>) => (dispatch: ReduxDispatch) => {
    dispatch(ClientsAction.update(id, body, { redirect: routes.ADMIN_CLIENT.INDEX }));
  };

  return (
    <GenericEditForm
      dataSelector={ClientsSelector.selectClient}
      loadingSelector={ClientsSelector.selectClientIsLoading}
      tNamespace="ClientEditScene"
      EmbedForm={() => <ClientForm />}
      initialValues={clientFormInitValue}
      onSubmit={onSubmit}
      validationSchema={ClientValidationSchema}
      onFetch={ClientsAction.fetch}
    />
  );
};
