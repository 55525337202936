import moment from 'moment';
import { fixFloat, uploadFile } from '../config';
import { Order } from '../store/order/Order';
import OrdersEffect from '../store/order/OrdersEffect';
import { MaterialObject } from '../store/payer/Payer';
import PaymentXml from '../store/payment-xml/PaymentXml';
import { Country } from './Country';

export interface SelectedOrder extends Order {
  isSelected: boolean;
}

export enum PaymentMode {
  DONATION = 'donation',
}

export default abstract class AbstractPaymentGenerator {
  protected DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  protected materialObject: MaterialObject;

  protected orders: SelectedOrder[];

  protected team?: string;

  private cachedFileName?: string;

  protected memberId: string;

  private date: string;

  protected isDonation: boolean;

  protected category: string;

  protected abstract FILE_EXTENSION: string;

  protected abstract MIME_TYPE: string;

  protected abstract DIRECTORY: string;

  constructor(
    orders: SelectedOrder[],
    materialObject: MaterialObject,
    memberId: string,
    category: Country | PaymentMode,
    isDonation: boolean,
    team?: string
  ) {
    this.materialObject = materialObject;
    this.orders = orders;
    this.isDonation = isDonation;
    this.team = team;
    this.date = moment.utc().format(this.DATE_FORMAT);
    this.memberId = memberId;
    this.category = category;
    // this.fileName = `payment${team ? `-${team}` : ''}-${category}-${date}.${fileExt}`;
  }

  protected getFileName() {
    this.cachedFileName = `payment${this.team ? `-${this.team}` : ''}-${this.category}-${
      this.date
    }.${this.FILE_EXTENSION}`;
    return this.cachedFileName;
  }

  protected abstract getBody(): Promise<string>;

  protected abstract createRecord(location: string): Promise<PaymentXml>;

  /**
   * Upload file to S3
   *
   * @protected
   * @param {string} content
   * @returns {Promise<string>}
   * @memberof AbstractPaymentGenerator
   */
  protected async uploadFile(content: string): Promise<string> {
    const file = new File([content], this.getFileName(), {
      type: this.MIME_TYPE,
    });
    const path = `${this.DIRECTORY}/${this.getFileName()}`;
    const url = await uploadFile(file, path);
    return url;
  }

  /**
   * Calculate order's total
   *
   * @protected
   * @returns {number}
   * @memberof AbstractPaymentGenerator
   */
  protected countTotal(): number {
    let total = 0;
    this.orders.forEach((o) => {
      if (o.offer === undefined) throw new Error('Missing offer');
      total += o.offer;
    });
    return fixFloat(total);
  }

  /**
   * Execute class method. The main method
   *
   * @returns {Promise<any>}
   * @memberof AbstractPaymentGenerator
   */
  public execute(): Promise<any> {
    return this.getBody()
      .then(this.uploadFile.bind(this))
      .then(this.createRecord.bind(this))
      .then(this.markOrdersAsPaid.bind(this));
  }

  private markOrdersAsPaid() {
    const body = {
      isPaid: true,
      payerId: this.memberId,
      payed: new Date(),
    };
    const requests = this.orders.map((o) => OrdersEffect.update(o.id, body));
    return Promise.all(requests);
  }
}
