export default class ColumnsSelector {
  public static selectAdminOrderColumns(state: ReduxState) {
    return state.column.adminOrderColumns;
  }

  public static selectAdminOrderCheckboxes(state: ReduxState) {
    return state.column.adminOrderCheckboxes;
  }

  public static selectCustomerServiceOrderColumns(state: ReduxState) {
    return state.column.customerServiceOrderColumns;
  }
}
