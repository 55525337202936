import { FieldProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { uploadFile } from '../config';
import ErrorsAction from '../store/error/ErrorsAction';
import { Order } from '../store/order/Order';
import RednotAction from '../store/rednot/RednotAction';

interface Props extends FieldProps<any> {
  label: string;
  order: Order;
}

const FileInput = (props: Props) => {
  const { label, form, field, order } = props;
  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (files: File[]) => {
      if (!order) return dispatch(RednotAction.error('Missing order'));
      const file = files[0];
      const date = moment.utc().format('YYYY-MM-DDTH-mm');
      const valuationFileName = `valuation-images/${date}-O${order.id}-${
        field.name
      }.${file.name.split('.').pop()}`;
      return uploadFile(file, valuationFileName)
        .then((valuationImage) => form.setFieldValue(field.name, valuationImage))
        .catch(dispatch(ErrorsAction.onCatch));
    },
  });

  return (
    <div className="form-group FileInput">
      <label htmlFor={field.name}>{label}</label>
      <div>
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div className="btn btn-primary" {...getRootProps()}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input name={field.name} id={field.name} {...getInputProps()} />
            Valitse tiedosto
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileInput;
