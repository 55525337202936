import Axios from 'axios';
import React, { useEffect } from 'react';
import { Card, CardTitle } from 'releox-react';
import apis from '../../configs/apis';

type ValuationStats = {
  name: string;
  count: string;
}[];

const getData = async (): Promise<ValuationStats> => {
  // Fetch the data from the api by using axios
  const { data } = await Axios.get(apis.VALUATION_STATS);
  return data;
};

export const ValuationStatsScene = () => {
  const [data, setData] = React.useState<ValuationStats | null>(null);

  useEffect(() => {
    (async () => {
      const lData = await getData();
      // eslint-disable-next-line no-console
      console.log('ValuationStatsScene', lData);
      setData(lData);
    })();
  }, []);

  // if loading, show loading
  if (data === null) {
    return <div>Loading...</div>;
  }

  // Show data in table
  return (
    <Card>
      <CardTitle>Today&apos;s valuation stats</CardTitle>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.name}>
              <td>{item.name}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};
