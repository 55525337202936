import { parseAsync } from 'json2csv';
import PaymentXmlsEffect from '../store/payment-xml/PaymentXmlsEffect';
import AbstractPaymentGenerator from './AbstractPaymentGenerator';
import { Country } from './Country';

export default class PaymentCSVGenerator extends AbstractPaymentGenerator {
  protected MIME_TYPE = 'text/csv';

  protected FILE_EXTENSION = 'csv';

  protected DIRECTORY = `payment-csv`;

  /**
   * Get file extension
   * @returns string
   */
  getFileExt() {
    return this.FILE_EXTENSION;
  }

  protected getBody(): Promise<string> {
    let fields = [
      'id',
      'orderNumber',
      'offer',
      'country',
      'client.lastName',
      'client.firstName',
      'client.iban',
    ];

    if (this.category === Country.UK) {
      // name,recipientEmail	paymentReference	receiverType	amountCurrency	amount	sourceCurrency	targetCurrency	sortCode	accountNumber
      fields = [
        // name
        'name',
        // recipientEmail
        'email',
        // paymentReference
        'paymentReference',
        // receiverType
        'receiverType',
        // amountCurrency
        'amountCurrency',
        // amount
        'amount',
        // sourceCurrency
        'sourceCurrency',
        // targetCurrency
        'targetCurrency',
        // sortCode
        'sortCode',
        // accountNumber
        'accountNumber',
      ];

      this.orders = this.orders.map((order) => {
        return {
          ...order,
          name: `${order.client?.lastName} ${order.client?.firstName}`,
          paymentReference: `Order ${order.orderNumber}`,
          receiverType: 'PERSON',
          amountCurrency: 'source',
          sourceCurrency: 'GBP',
          targetCurrency: 'GBP',
          amount: order.gbpOffer,
          sortCode: order.client?.ukSortCode,
          accountNumber: order.client?.ukAccountNumber,
        };
      });
    }

    const opts = { fields };

    return parseAsync(this.orders, opts);
  }

  protected createRecord(location: string) {
    const body = {
      id: '',
      country: this.category,
      location,
      name: this.getFileName(),
      total: this.countTotal(),
      isDonation: this.isDonation,
    };
    return PaymentXmlsEffect.create(body);
  }
}
