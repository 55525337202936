import { getErrorMessage } from 'releox-react';
import { clientFormInitValue } from '../../forms/ClientForm';
import { Action, ActionWPayload, ReduxDispatch } from '../Action';
import { Client } from '../client/Client';
import ClientsEffect from '../client/ClientsEffect';
import { Order } from '../order/Order';
import OrdersEffect from '../order/OrdersEffect';
import RednotAction from '../rednot/RednotAction';
import TeamsEffect from '../team/TeamsEffect';
import { User } from '../user/User';

export interface BuyerOrderBody {
  googleAddress: string;
  order: Order;
  client: Client;
}

export enum NewOrdersActionsType {
  SET_LOADING = '@NEW_ORDER:SET_LOADING',
  SET_CLIENT = '@NEW_ORDER:SET_CLIENT',
  RESET = '@NEW_ORDER:RESET',
  PHONE_SEARCHED = '@NEW_ORDER:PHONE_SEARCHED',
  SET_WEB_ORDER = '@NEW_ORDER:SET_WEB_ORDER',
}

type SetLoading = ActionWPayload<NewOrdersActionsType.SET_LOADING, { isLoading: boolean }>;
type SetClient = ActionWPayload<NewOrdersActionsType.SET_CLIENT, { client: Client }>;
type SetWebOrder = ActionWPayload<NewOrdersActionsType.SET_WEB_ORDER, { isWebOrder: boolean }>;
type PhoneSearched = Action<NewOrdersActionsType.PHONE_SEARCHED>;
type Reset = Action<NewOrdersActionsType.RESET>;

export type NewOrdersActions = SetLoading | SetClient | PhoneSearched | Reset | SetWebOrder;

export default class NewOrdersAction {
  private static setLoading(isLoading: boolean): SetLoading {
    return {
      type: NewOrdersActionsType.SET_LOADING,
      payload: { isLoading },
    };
  }

  private static setClient(client: Client): SetClient {
    return {
      type: NewOrdersActionsType.SET_CLIENT,
      payload: { client },
    };
  }

  private static phoneSearched(): PhoneSearched {
    return {
      type: NewOrdersActionsType.PHONE_SEARCHED,
    };
  }

  public static reset(): Reset {
    return {
      type: NewOrdersActionsType.RESET,
    };
  }

  public static setWebOrder(isWebOrder: boolean): SetWebOrder {
    return {
      type: NewOrdersActionsType.SET_WEB_ORDER,
      payload: {
        isWebOrder,
      },
    };
  }

  public static searchByPhoneNumber(phone: string) {
    return (dispatch: ReduxDispatch) => {
      dispatch(this.setLoading(true));

      const isValidCountryCode = /^358/.test(phone) || /^46/.test(phone) || /^44/.test(phone);

      if (!/^\d+$/.test(phone)) {
        return dispatch(RednotAction.error('Phone number must contain only numbers'));
      }

      if (!isValidCountryCode) {
        return dispatch(RednotAction.error('Missing country code'));
      }

      return ClientsEffect.find({ where: { phone } }).then((client) => {
        if (client.length) dispatch(this.setClient(client[0]));
        else dispatch(this.setClient({ ...clientFormInitValue, phone }));
        dispatch(this.phoneSearched());
        dispatch(this.setLoading(false));
      });
    };
  }

  public static createOrder(body: BuyerOrderBody) {
    return (dispatch: ReduxDispatch) => {
      dispatch(this.setLoading(true));
      const requestBody = {
        ...body.order,
        clientBody: body.client,
        language: body.client.language,
        country: body.client.country,
      };

      /* Change data if order is web order */
      let request: Promise<User | void> = Promise.resolve();
      if (body.order.isWebOrder) {
        request = TeamsEffect.findById(body.order.callerId);
      }

      request
        .then((user) => {
          if (user) {
            requestBody.team = user.team;
          }
          return OrdersEffect.create(requestBody);
        })
        .then(() => dispatch(this.reset()))
        .catch((e) => {
          dispatch(RednotAction.error(getErrorMessage(e)));
          dispatch(NewOrdersAction.setLoading(false));
        });
    };
  }
}
