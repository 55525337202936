import moment from 'moment';
import { ReportTypes } from '../report/ReportsAction';
import { ReportFiltersActionObject, ReportFiltersActionTypes } from './ReportFiltersAction';

export default class ReportFiltersReducer {
  private static initState = {
    query: {
      date: {
        gt: moment()
          .subtract(1, 'week')
          .startOf('day')
          .toDate(),
        lt: moment()
          .endOf('day')
          .toDate(),
      },
      params: {
        team: '',
      },
      reportType: ReportTypes.ACCEPTED_WEIGHTS,
    },
  };

  public static reducer(
    state = ReportFiltersReducer.initState,
    action: ReportFiltersActionObject
  ): typeof state {
    switch (action.type) {
      case ReportFiltersActionTypes.SAVE_QUERY:
        return {
          ...state,
          query: action.payload.query,
        };

      default:
        return state;
    }
  }
}
