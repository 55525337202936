import IBAN from 'iban';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardTitle, CheckBox } from 'releox-react';
import { Country } from '../classes/Country';
import { getTeamName } from '../options';
import { Order } from '../store/order/Order';
import OrdersSelector from '../store/order/OrdersSelector';

export default (): JSX.Element => {
  const orders = useSelector(OrdersSelector.selectOrders);

  const formatPrice = useCallback((order: Order) => {
    let price = `${order.price}`;

    if (order.country === Country.SWEDEN) {
      price += ` (${order.sekOffer} SEK)`;
    }
    if (order.country === Country.UK) {
      price += ` (${order.gbpOffer} GBP)`;
    }

    return price;
  }, []);

  return (
    <Card>
      <>
        <CardTitle>Payer Information</CardTitle>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Select</th>
              <th>#</th>
              <th>Name</th>
              <th>Team</th>
              <th>IBAN</th>
              <th>Valid IBAN</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, i) => {
              if (order.client)
                return (
                  <tr key={order.id}>
                    <td>
                      <CheckBox name={`${i}.isSelected`} label="" />
                    </td>
                    <td>{order.orderNumber}</td>
                    <td>{`${order.client.firstName} ${order.client.lastName}`}</td>
                    <td>{getTeamName(order.team)}</td>
                    <td>{order.client.iban}</td>
                    <td>{IBAN.isValid(order.client.iban) ? 'Yes' : 'No'}</td>
                    <td>{formatPrice(order)}</td>
                  </tr>
                );
              return undefined;
            })}
          </tbody>
        </table>
        <Button id="create-xml" type="submit">
          Create payment xml
        </Button>
      </>
    </Card>
  );
};
