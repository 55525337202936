import { fixFloat } from '../config';
import { Product } from '../store/order/Order';

export default (product: Product): number => {
  let factory: string;
  if (product.density === 'customGold') {
    factory = `${product.factor}`;
  } else {
    const postFix = product.densityName.split('/ ')[1];
    factory = `0.${postFix}`;
  }
  return fixFloat(parseFloat(factory) * product.weight);
};
