import { ActionWPayload } from '../Action';
import { ReportSearchBody } from '../report/ReportsAction';

export enum ReportFiltersActionTypes {
  SAVE_QUERY = '@REPORT_FILTER:SAVE_QUERY',
}

type SaveQueryReturn = ActionWPayload<
  ReportFiltersActionTypes.SAVE_QUERY,
  { query: ReportSearchBody }
>;

export type ReportFiltersActionObject = SaveQueryReturn;

export default class ReportFiltersAction {
  public static saveQuery(query: ReportSearchBody) {
    return {
      type: ReportFiltersActionTypes.SAVE_QUERY,
      payload: { query },
    };
  }
}
