import React from 'react';
import SearchGuide from './SearchGuide';

export default () => {
  const dateFields = ['createdAt', 'updatedAt'];

  const valueFields = [
    'firstName',
    'lastName',
    'clientNumber',
    'address',
    'iban',
    'phone',
    'birthYear',
    'city',
    'country',
    'zip',
    'language',
    'createdAt',
    'updatedAt',
    'email',
  ];

  const examples = [
    {
      query: 'clientNumber:30012',
      description: 'find client with client number 30012',
    },
    {
      query: 'city:Turku',
      description: 'list all orders from turku',
    },
  ];

  return <SearchGuide examples={examples} dateFields={dateFields} valueFields={valueFields} />;
};
