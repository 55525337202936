import Axios from 'axios';
import moment from 'moment';
import { LoopbackFilter, loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import { Order } from './Order';

export interface ValuatedPerDay {
  user: number;
}

interface FindByBarcodeArgs {
  barCode: string;
}

export default class OrdersEffect {
  public static cleanBody(order: Partial<Order>) {
    /* eslint-disable no-param-reassign */
    delete order.id;
    delete order.valuator;
    delete order.client;
    delete order.packager;
    delete order.logs;
    delete order.caller;
    delete order.receiver;
    delete order.bursar;
    delete order.teller;
    delete order.payer;
    delete order.closer;
    delete order.afterCallLogs;
    delete order.welcomeCallLogs;
    delete order.originalCaller;
    delete order.assignedCaller;
    delete order.coldCallLogs;
    delete order.coldCaseCaller;
    /* eslint-enable no-param-reassign */
    return order;
  }

  public static restRequests = loopbackRequestCreator<Order>(apis.ORDER, OrdersEffect.cleanBody);

  public static findById = OrdersEffect.restRequests.getById;

  public static find = OrdersEffect.restRequests.getAll;

  public static create = OrdersEffect.restRequests.create;

  public static update = OrdersEffect.restRequests.update;

  public static delete = OrdersEffect.restRequests.delete;

  public static count = OrdersEffect.restRequests.count;

  public static async findByBarcode({ barCode }: FindByBarcodeArgs): Promise<Order | null> {
    const orders = await OrdersEffect.find({ where: { barCode }, order: 'createdAt desc' });
    if (!orders.length) return null;
    return orders[0];
  }

  public static registerPackage(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Axios.get(`${apis.ORDER}/${id}/register-package`)
        .then(() => resolve())
        .catch(reject);
    });
  }

  public static checkAddressBase64(id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Axios.get(`${apis.ORDER}/${id}/check-address-base64`)
        .then((r) => resolve(r.data.isValid))
        .catch(reject);
    });
  }

  public static async valuatedTodayReport(userId: string): Promise<ValuatedPerDay> {
    const query: LoopbackFilter = {
      where: {
        valuationDate: {
          gt: moment().startOf('day'),
        },
        valuatorId: userId,
      },
    };

    const user = await OrdersEffect.count(query);

    return {
      user: user.count,
    };
  }
}
