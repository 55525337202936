import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'releox-react';
import ReportsSelector from '../store/report/ReportsSelector';
import ReportWeight from './ReportWeight';

export default () => {
  const result = useSelector(ReportsSelector.selectAcceptedOffersReport);
  return (
    <div className="col-lg-10">
      <Card>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              {result.materials.map((m) => (
                <th key={m.id}>{m.name}</th>
              ))}
              <th>Custom Gold gross</th>
              <th>Custom Gold net</th>
              <th>Gold gross</th>
              <th>Gold net</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {result.users.map((u) => (
              <tr key={u.name}>
                <td>{u.name}</td>
                {result.materials.map((m) => (
                  <ReportWeight
                    key={`${m.id}-${u.name}`}
                    weights={u.gross}
                    materialId={m.id}
                    productCounts={u.counts}
                  />
                ))}
                <ReportWeight weights={u.gross} materialId="customGold" productCounts={u.counts} />
                <ReportWeight weights={u.net} materialId="customGold" productCounts={u.counts} />
                <ReportWeight weights={u.gross} materialId="gold" productCounts={u.counts} />
                <ReportWeight weights={u.net} materialId="gold" productCounts={u.counts} />
                <td>{u.offer} €</td>
              </tr>
            ))}
            <tr>
              <td>Total: Gross</td>
              {result.materials.map((m) => (
                <ReportWeight
                  key={`${m.id}`}
                  weights={result.gross}
                  materialId={m.id}
                  productCounts={result.productCounts}
                />
              ))}
              <ReportWeight
                weights={result.gross}
                materialId="customGold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.net}
                materialId="customGold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.gross}
                materialId="gold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.net}
                materialId="gold"
                productCounts={result.productCounts}
              />
              <td>{result.totalOffer} €</td>
            </tr>
            <tr>
              <td>Total: Net</td>
              {result.materials.map((m) => (
                <ReportWeight
                  key={`${m.id}`}
                  weights={result.net}
                  materialId={m.id}
                  productCounts={result.productCounts}
                />
              ))}
              <ReportWeight
                weights={result.net}
                materialId="customGold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.net}
                materialId="customGold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.net}
                materialId="gold"
                productCounts={result.productCounts}
              />
              <ReportWeight
                weights={result.net}
                materialId="gold"
                productCounts={result.productCounts}
              />
              <td>{result.totalOffer} €</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
  );
};
