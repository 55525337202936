import { DataTableColumn } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import { OrderColumnInitialValueType } from '../../forms/order-columns/OrderColumnInitialValues';
import { ActionWPayload, ReduxDispatch } from '../Action';

export enum ColumnsActionType {
  SET_ADMIN_ORDER_COLUMNS = '@COLUMN:SET_ADMIN_ORDER_COLUMNS',
  SET_CUSTOMER_SERVICE_ORDER_COLUMNS = '@COLUMN:SET_CUSTOMER_SERVICE_ORDER_COLUMNS',
}

interface ColumnPayload {
  columns: DataTableColumn[];
  checkboxValues: OrderColumnInitialValueType;
}

type SetAdminOrderColumns = ActionWPayload<
  ColumnsActionType.SET_ADMIN_ORDER_COLUMNS,
  ColumnPayload
>;

export type ColumnsActions = SetAdminOrderColumns;

export default class ColumnsAction {
  public static setAdminOrderColumns(
    columns: DataTableColumn[],
    checkboxValues: OrderColumnInitialValueType
  ): ColumnsActions {
    return {
      type: ColumnsActionType.SET_ADMIN_ORDER_COLUMNS,
      payload: { columns, checkboxValues },
    };
  }

  public static onOrderSubmit(body: OrderColumnInitialValueType) {
    return (dispatch: ReduxDispatch) => {
      const cols: DataTableColumn[] = [OrderColumn.orderNumberAdminLink];
      if (body.status) cols.push(OrderColumn.status);
      if (body.clientName) cols.push(OrderColumn.clientName);
      if (body.barCode) cols.push(OrderColumn.barCode);
      if (body.country) cols.push(OrderColumn.country);
      if (body.language) cols.push(OrderColumn.language);
      if (body.createdAt) cols.push(OrderColumn.createdAt);
      if (body.updatedAt) cols.push(OrderColumn.updatedAt);
      if (body.phone) cols.push(OrderColumn.phone);
      if (body.afterCallLatest) cols.push(OrderColumn.afterCallLatest);
      if (body.welcomeCall) cols.push(OrderColumn.welcomeCall);
      if (body.afterCallTries) cols.push(OrderColumn.afterCallTries);
      if (body.address) cols.push(OrderColumn.address);
      if (body.welcomeCallLatest) cols.push(OrderColumn.welcomeCallLatest);
      if (body.invoice) cols.push(OrderColumn.invoice);
      if (body.letter) cols.push(OrderColumn.letter);
      if (body.barCodePrint) cols.push(OrderColumn.barCodePrintForDetails);
      if (body.offer) cols.push(OrderColumn.offer);
      if (body.tellerCallLatest) cols.push(OrderColumn.tellerCallLatest);
      if (body.closerName) cols.push(OrderColumn.closerName);
      if (body.callerName) cols.push(OrderColumn.callerName);
      if (body.packagerName) cols.push(OrderColumn.packagedName);
      if (body.packagedDate) cols.push(OrderColumn.packagedDate);
      if (body.receiverName) cols.push(OrderColumn.receiverName);
      if (body.receivedDate) cols.push(OrderColumn.receivedDate);
      if (body.valuatorName) cols.push(OrderColumn.valuatorName);
      if (body.valuationDate) cols.push(OrderColumn.valuationDate);
      if (body.tellerName) cols.push(OrderColumn.tellerName);
      if (body.tellerDate) cols.push(OrderColumn.tellerDate);
      if (body.payerName) cols.push(OrderColumn.payerName);
      if (body.payedDate) cols.push(OrderColumn.payedDate);
      if (body.bursarName) cols.push(OrderColumn.bursarName);
      if (body.bursarHandleDate) cols.push(OrderColumn.bursarHandleDate);
      if (body.team) cols.push(OrderColumn.team);
      if (body.closeReason) cols.push(OrderColumn.closeReason);
      if (body.closeMethod) cols.push(OrderColumn.closeMethod);
      if (body.closeDate) cols.push(OrderColumn.closeDate);
      if (body.dueDate) cols.push(OrderColumn.dueDate);

      if (body.materialWeight) cols.push(OrderColumn.materialWeight);
      if (body.packageWeight) cols.push(OrderColumn.packageWeight);
      if (body.goldWeight) cols.push(OrderColumn.goldWeight);
      if (body.silverWeight) cols.push(OrderColumn.silverWeight);
      dispatch(ColumnsAction.setAdminOrderColumns(cols, body));
    };
  }
}
