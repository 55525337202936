import React from 'react';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { DataTableColumn, DataTableDefaultSort, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

const columns: DataTableColumn[] = [OrderColumn.orderNumber, OrderColumn.clientName];
const defaultSorted: DataTableDefaultSort = { dataField: 'orderNumber', order: 'ASC' };
const query: LoopbackFilter = OrderQuery.getWaitingValuationQuery();

export default () => (
  <GenericIndexScene
    tNamespace="ValuationWaitingIndexScene"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={columns}
    defaultSorted={defaultSorted}
    query={query}
  />
);
