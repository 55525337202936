import { useCallback, useState } from 'react';
import PackagerPipe from '../../classes/PackagerPipe';
import { usePackagerMarkAsPackagedMutation } from '../../react-query-hooks/packager/packager-query-hooks';
import { UsePrintPackage } from './use-print-package.types';

export const usePrintPackage: UsePrintPackage = ({ logger, print, onComplete }) => {
  const [isPrintLoading, setIsLoading] = useState(false);

  /**
   * This is a react-query mutation hook that marks an order as packaged.
   */
  const { mutate } = usePackagerMarkAsPackagedMutation({
    onSuccess: () => {
      onComplete();
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  /**
   * This function is used to print a package.
   */
  const printPackage = useCallback(
    (order) => {
      (async () => {
        setIsLoading(true);
        try {
          const pipe = new PackagerPipe(order, logger, print);
          await pipe.printAll();
          mutate(order.id);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      })();
    },
    [logger, mutate, print]
  );

  return { printPackage, isPrintLoading };
};
