import {
  CallTransferSearchBody,
  CallTransferSearchType,
} from '../../forms/call-transfer-search/CallTransferSearchInitialValues';
import { CallTransferFormBody } from '../../forms/call-transfer/CallTransferInitialValues';
import { Action, ActionWPayload, ReduxDispatch } from '../Action';
import ErrorsAction from '../error/ErrorsAction';
import { Order } from '../order/Order';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';
import {
  CallTransferFormValues,
  CallTransferPermanentRequestBody,
  CallTransferTemporaryRequestBody,
} from './CallTransferModel';

export enum CallTransfersActionTypes {
  SEARCH_REQUEST = '@CALL_TRANSFER:SEARCH_REQUEST',
  SEARCH_SUCCESS = '@CALL_TRANSFER:SEARCH_SUCCESS',
  SEARCH_FAIL = '@CALL_TRANSFER:SEARCH_FAIL',
  RESET = '@CALL_TRANSFER:RESET',
}

type SearchRequest = ActionWPayload<
  CallTransfersActionTypes.SEARCH_REQUEST,
  { type: CallTransferSearchType; searchUserId: string }
>;
type SearchSuccess = ActionWPayload<
  CallTransfersActionTypes.SEARCH_SUCCESS,
  { callTransferField: CallTransferFormValues[] }
>;
type SearchFail = Action<CallTransfersActionTypes.SEARCH_FAIL>;
type ResetFail = Action<CallTransfersActionTypes.RESET>;

export type CallTransfersActionObject = SearchRequest | SearchFail | SearchSuccess | ResetFail;

export default class CallTransfersAction {
  private static searchRequest(
    type: CallTransferSearchType,
    searchUserId: string
  ): CallTransfersActionObject {
    return {
      type: CallTransfersActionTypes.SEARCH_REQUEST,
      payload: { type, searchUserId },
    };
  }

  private static searchSuccess(
    callTransferField: CallTransferFormValues[]
  ): CallTransfersActionObject {
    return {
      type: CallTransfersActionTypes.SEARCH_SUCCESS,
      payload: { callTransferField },
    };
  }

  public static reset(): CallTransfersActionObject {
    return {
      type: CallTransfersActionTypes.RESET,
    };
  }

  private static searchFail(): CallTransfersActionObject {
    return {
      type: CallTransfersActionTypes.SEARCH_FAIL,
    };
  }

  public static remapOrderToTransferFields(orders: Order[]) {
    return orders.map((o) => ({
      ...o,
      form: {
        isSelected: true,
      },
    }));
  }

  public static searchUndoneOrders(
    body: CallTransferSearchBody
  ): (dispatch: ReduxDispatch) => void {
    return (dispatch: ReduxDispatch) => {
      const query = OrderQuery.getCallTransferSearchQuery(body);

      dispatch(this.searchRequest(body.type, body.callerId));
      OrdersEffect.find(query)
        .then((orders) => {
          const callTransferFields = this.remapOrderToTransferFields(orders);
          dispatch(this.searchSuccess(callTransferFields));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.searchFail)));
    };
  }

  public static transferOrders(body: CallTransferFormBody) {
    return (dispatch: ReduxDispatch) => {
      const filterSelected = (o: CallTransferFormValues) => o.form.isSelected;
      let mapOrders: (
        o: CallTransferFormValues
      ) => CallTransferTemporaryRequestBody | CallTransferPermanentRequestBody;

      mapOrders = (o: CallTransferFormValues): CallTransferTemporaryRequestBody => ({
        id: o.id,
        assignedCallerId: body.newCallerId,
        isTemporarilyTransferred: true,
      });

      if (body.isPermanent) {
        mapOrders = (o: CallTransferFormValues): CallTransferPermanentRequestBody => ({
          id: o.id,
          callerId: body.newCallerId,
          originalCallerId: o.callerId,
          isPermanentlyTransferred: true,
        });
      }

      const transferOrderList = body.callTransferField.filter(filterSelected).map(mapOrders);
      const requests = transferOrderList.map((to) => OrdersEffect.update(to.id, to));

      Promise.all(requests)
        .then(() => {
          dispatch(RednotAction.success(`${transferOrderList.length} order has been transferred`));
          dispatch(this.reset());
        })
        .catch(dispatch(ErrorsAction.onCatch()));
    };
  }
}
