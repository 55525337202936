import { GetState, ReduxDispatch } from '../Action';
import RednotAction from '../rednot/RednotAction';
import { CallCountsActionReturnType } from './CallCountsActionReturnType';
import CallCountsActionType from './CallCountsActionType';
import CallCountsEffect from './CallCountsEffect';

export default class CallCountsAction {
  public static fetchCounts() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;
      dispatch(CallCountsAction.welcomeCallRequest());

      CallCountsEffect.fetchWelcomeCallCount(user)
        .then((count) => dispatch(CallCountsAction.welcomeCallSuccess(count.count)))
        .catch((e) => {
          dispatch(CallCountsAction.welcomeCallFail());
          dispatch(RednotAction.onError(e, dispatch));
        });

      dispatch(CallCountsAction.afterCallRequest());

      CallCountsEffect.fetchAfterCallCount(user)
        .then((count) => dispatch(CallCountsAction.afterCallSuccess(count.count)))
        .catch((e) => {
          dispatch(CallCountsAction.afterCallFail());
          dispatch(RednotAction.onError(e, dispatch));
        });
    };
  }

  private static welcomeCallRequest(): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.WELCOME_CALL_REQUEST,
    };
  }

  private static afterCallRequest(): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.AFTER_CALL_REQUEST,
    };
  }

  private static welcomeCallFail(): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.WELCOME_CALL_FAIL,
    };
  }

  private static afterCallFail(): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.AFTER_CALL_FAIL,
    };
  }

  private static afterCallSuccess(count: number): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.AFTER_CALL_SUCCESS,
      payload: { count },
    };
  }

  private static welcomeCallSuccess(count: number): CallCountsActionReturnType {
    return {
      type: CallCountsActionType.WELCOME_CALL_SUCCESS,
      payload: { count },
    };
  }
}
