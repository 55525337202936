import { Field, FieldProps } from 'formik';
import React from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { Button, Card, CardTitle, CheckBox, Input, Select, TextArea } from 'releox-react';
import DatePicker from '../components/DatePicker';
import GoogleAutocomplete from '../components/GoogleAutocomplete';
import { countryOptions, languageOptions } from '../options';
import NewOrdersSelector from '../store/new-order/NewOrdersSelector';
import TeamsSelector from '../store/team/TeamsSelector';
import { User } from '../store/user/User';
import UsersSelector from '../store/user/UsersSelector';

const mapUsersToOptions = (member: User) => ({
  value: member.id,
  label: member.name,
});

export default () => {
  const member = useSelector(UsersSelector.selectUser);
  const isWebOrder = useSelector(NewOrdersSelector.selectIsWebOrder);
  const members = useSelector(TeamsSelector.selectMembers);
  const isLoading = useSelector(NewOrdersSelector.selectIsLoading);

  return (
    <div className="row">
      <div className="col-md-6">
        <Card>
          <>
            <CardTitle>Add order</CardTitle>
            <Input inline inlineLabelWidth={2} label="First name" name="client.firstName" />
            <Input inline inlineLabelWidth={2} label="Last name" name="client.lastName" />
            <Input inline inlineLabelWidth={2} label="Phone" name="client.phone" />
            <Input
              inline
              inlineLabelWidth={2}
              label="Birth year"
              type="number"
              name="client.birthYear"
            />
            <Field name="googleAddress">
              {(props: FieldProps) => (
                <GoogleAutocomplete
                  label="Address finder"
                  value={props.field.value}
                  onSelect={(address) => {
                    props.form.setFieldValue('googleAddress', address);
                    geocodeByAddress(address)
                      .then((results) => {
                        const addr = results[0].address_components;
                        const addrArr: string[] = [];
                        let zip = '';
                        let city = '';
                        addr.map((field) => {
                          if (field.types.includes('street_number'))
                            addrArr.unshift(field.long_name);
                          if (field.types.includes('route')) addrArr.unshift(field.long_name);
                          if (field.types.includes('postal_code')) zip = field.long_name;
                          if (field.types.includes('locality')) city = field.long_name;
                          if (field.types.includes('postal_town')) city = field.long_name;
                          if (field.types.includes('administrative_area_level_3'))
                            city = field.long_name;
                          return field;
                        });

                        const addressValue = addrArr.join(' ');

                        props.form.setFieldValue('client.address', addressValue);
                        props.form.setFieldValue('client.city', city);
                        props.form.setFieldValue('client.zip', zip);

                        props.form.setFieldValue('order.address', addressValue);
                        props.form.setFieldValue('order.city', city);
                        props.form.setFieldValue('order.zip', zip);
                      })
                      .catch((error) => console.error('Error', error)); // eslint-disable-line no-console
                  }}
                  onChange={(value) => props.form.setFieldValue(props.field.name, value)}
                />
              )}
            </Field>
            <hr />
            <Input inline inlineLabelWidth={2} label="Address" name="client.address" />
            <Input inline inlineLabelWidth={2} label="Zip" name="client.zip" />
            <Input inline inlineLabelWidth={2} label="City" name="client.city" />
            <Select
              inline
              inlineLabelWidth={2}
              name="client.country"
              label="Country"
              options={countryOptions}
            />
            <Select
              inline
              inlineLabelWidth={2}
              name="client.language"
              label="Language"
              options={languageOptions}
            />
            <hr />
            {member.buyerWebOrder ? (
              <>
                <div className="form-group">
                  <CheckBox label="Web order" name="order.isWebOrder" />
                </div>
                {isWebOrder ? (
                  <Select
                    name="order.callerId"
                    label="Choose caller"
                    options={members.map(mapUsersToOptions)}
                  />
                ) : (
                  <span />
                )}
              </>
            ) : (
              ''
            )}
            <DatePicker inline inlineLabelWidth={2} label="Welcome call" name="order.welcomeCall" />
            <DatePicker inline inlineLabelWidth={2} label="Due date" name="order.dueDate" />
            <Button id="send-button" type="submit" disabled={isLoading} disabledText="Loading...">
              Send
            </Button>
          </>
        </Card>
      </div>
      <div className="col-md-6">
        <Card>
          <CardTitle>Messages</CardTitle>
          <TextArea label="General message" name="order.generalMessage" />
          <TextArea label="Message to packager" name="order.packagerMessageFromBuyer" />
          <TextArea label="Message to valuation" name="order.valuationMessageFromBuyer" />
          <TextArea label="Message to teller" name="order.tellerMessageFromBuyer" />
        </Card>
      </div>
    </div>
  );
};
