import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'releox-react';
import AcceptedOfferReportTable from '../../components/AcceptedOfferReportTable';
import ValuatedReportTable from '../../components/ValuatedReportTable';
import ReportForm from '../../forms/report/ReportForm';
import MaterialsAction from '../../store/material/MaterialsAction';
import ReportFiltersAction from '../../store/report-filter/ReportFiltersAction';
import ReportFiltersSelector from '../../store/report-filter/ReportFiltersSelector';
import ReportsAction, { ReportSearchBody, ReportTypes } from '../../store/report/ReportsAction';
import ReportsSelector from '../../store/report/ReportsSelector';

export default () => {
  const dispatch = useDispatch();
  const searchQuery = useSelector(ReportFiltersSelector.selectQuery);
  const isSearched = useSelector(ReportsSelector.selectIsSearched);
  const isLoading = useSelector(ReportsSelector.selectIsLoading);

  useEffect(() => {
    dispatch(MaterialsAction.list());
  }, [dispatch]);

  const onSubmit = useCallback(
    (body: ReportSearchBody) => {
      dispatch(ReportFiltersAction.saveQuery(body));
      dispatch(ReportsAction.onSubmit(body));
    },
    [dispatch]
  );

  let report = <AcceptedOfferReportTable />;

  if (searchQuery.reportType === ReportTypes.VALUATED_ORDERS) report = <ValuatedReportTable />;

  return (
    <div className="row" id="ReportIndexScene">
      <div className="col-lg-2">
        <Card>
          <Formik onSubmit={onSubmit} initialValues={searchQuery}>
            <Form>
              <ReportForm />
              <Button
                id="submit"
                type="submit"
                className="btn-block"
                disabled={isLoading}
                disabledText="Loading..."
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      {isSearched && !isLoading ? report : ''}
    </div>
  );
};
