import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { Button, Card, DataTableColumn, GenericIndexScene, Input } from 'releox-react';
import { formatTime } from '../../config';
import PackageCodeRegistriesAction from '../../store/package-code-registry/PackageCodeRegistriesAction';
import PackageCodeRegistriesSelector from '../../store/package-code-registry/PackageCodeRegistriesSelector';

export default () => {
  const [query, setQuery] = useState<LoopbackFilter | undefined>();
  const columns: DataTableColumn[] = [
    {
      text: 'Order id',
      dataField: 'orderId',
    },
    {
      text: 'Order number',
      dataField: 'orderNumber',
    },
    {
      text: 'Client id',
      dataField: 'clientId',
    },
    {
      text: 'Client name',
      dataField: 'clientName',
    },
    {
      text: 'Package code',
      dataField: 'packageCode',
    },
    {
      text: 'Created',
      dataField: 'createdAt',
      sort: true,
      formatter: formatTime,
    },
  ];

  const handleSubmit = ({ query: q }: { query: string }) => {
    if (!q) {
      setQuery(undefined);
    } else {
      const localQuery: LoopbackFilter = {
        where: {
          $or: [{ orderId: q }, { packageCode: q }],
        },
      };

      const int = parseInt(q, 10);
      if (Number.isInteger(int)) {
        localQuery.where.$or.push({ orderNumber: int });
      }
      setQuery(localQuery);
    }
  };

  return (
    <>
      <Card>
        <Formik initialValues={{ query: '' }} onSubmit={handleSubmit}>
          <Form>
            <div className="row">
              <div className="col-md-3">
                <Input name="query" label="Query" />
                <Button id="submit" className="float-right" type="submit">
                  Find
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </Card>
      <GenericIndexScene
        key={JSON.stringify(query)}
        tNamespace="PackageCodeRegistryIndexScene"
        loadingSelector={PackageCodeRegistriesSelector.selectPackageCodeRegistriesIsLoading}
        dataSelector={PackageCodeRegistriesSelector.selectPackageCodeRegistries}
        dataSizeSelector={PackageCodeRegistriesSelector.selectPackageCodeRegistriesCount}
        onChangeLoopback={PackageCodeRegistriesAction.list}
        defaultSorted={{ dataField: 'createdAt', order: 'DESC' }}
        columns={columns}
        query={query}
      />
    </>
  );
};
