import React from 'react';
import OrderIndex from '../../components/order-index/OrderIndex';
import routes from '../../configs/routes';
import ColumnsSelector from '../../store/column/ColumnsSelector';

export default () => {
  return (
    <OrderIndex
      columnSelector={ColumnsSelector.selectCustomerServiceOrderColumns}
      redirect={routes.CUSTOMER_SERVICE.ORDER_SHOW}
    />
  );
};
