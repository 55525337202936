import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import RednotAction from '../rednot/RednotAction';
import PackageCodeRegistriesEffect from './PackageCodeRegistriesEffect';

export default class PackageCodeRegistriesAction {
  static ENTRY = 'PACKAGE_CODE_REGISTRY';

  static actionCreator = actionCreator(
    PackageCodeRegistriesAction.ENTRY,
    PackageCodeRegistriesEffect.restRequests,
    push,
    RednotAction.onError
  );

  static fetch = PackageCodeRegistriesAction.actionCreator.getFindByIdAction();

  static list = PackageCodeRegistriesAction.actionCreator.getFindAndCountAction();

  static clean = PackageCodeRegistriesAction.actionCreator.getCleanAction();
}
