import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardTitle, Loading, TextArea } from 'releox-react';
import FormikEffect from '../../components/FormikEffect';
import { SignedMagnifyImg } from '../../components/SignedComponents';
import ClientForm from '../../forms/ClientForm';
import { usePreviousOrdersCount } from '../../react-query-hooks/teller/teller-query-hooks';
import { Order } from '../../store/order/Order';
import TellersAction from '../../store/teller/TellersAction';
import TellersSelector from '../../store/teller/TellersSelector';
import TellerPreview from './TellerPreview';
import BankInputs from './components/BankInputs';
import InOfferTable from './components/InOfferTable';
import MaterialWeightCard from './components/MaterialWeightCard';
import OfferInput from './components/OfferInput';
import TellerButtons from './components/TellerButtons';
import TellerCheckBoxes from './components/TellerCheckBoxes';
import TellerMessages from './components/TellerMessages';

const TellerShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const previousOrders = usePreviousOrdersCount(id);

  const order = useSelector(TellersSelector.selectOrder);
  const isLoading = useSelector(TellersSelector.selectOrderIsLoading);
  const isSMSLoading = useSelector(TellersSelector.selectIsSMSLoading);
  const isInPreviewMode = useSelector(TellersSelector.selectIsInPreviewMode);

  useEffect(() => {
    dispatch(TellersAction.onOrderUpdate(order));
  }, [order, dispatch]);

  useEffect(() => {
    dispatch(TellersAction.onViewMount(id));
    return () => {
      dispatch(TellersAction.reset());
    };
  }, [id, dispatch]);

  const onSubmit = useCallback(
    (body: Order) => {
      dispatch(TellersAction.onSubmit(body));
    },
    [dispatch]
  );

  const onFormChange = useCallback(
    (formikProps) => {
      dispatch(TellersAction.formOnChange(formikProps));
    },
    [dispatch]
  );

  if (isLoading || isSMSLoading) return <Loading centeredVertical />;
  let content = (
    <div className="row">
      <div className="col-lg-3">
        {previousOrders.data === 3 ? (
          <div className="alert alert-danger" role="alert">
            This is the 3rd order of this client
          </div>
        ) : (
          ''
        )}
        <FormikEffect onChange={onFormChange} />
        <TellerCheckBoxes />
        <TellerMessages />
        <Card>
          <CardTitle>Client</CardTitle>
          <ClientForm prefix="client" hideCountry hideLanguage hideIban inline={false} />
        </Card>
      </div>
      <div className="col-lg-3">
        <MaterialWeightCard />
        <InOfferTable isEditable />
        <Card>
          <OfferInput />
          <TellerButtons />
        </Card>
      </div>
      <div className="col-lg-6">
        {order.valuationImage ? <SignedMagnifyImg src={order.valuationImage} /> : ''}
        <Card>
          <BankInputs country={order.country} />
          <div className="row">
            <div className="col-6">
              <TextArea name="tellerComment" label="Teller's comment" />
            </div>
            <div className="col-6">
              <TextArea name="revaluationMessageFromTeller" label="Message to revaluation" />
            </div>
          </div>
        </Card>
        {order.extraImages && order.extraImages.length
          ? order.extraImages.map((img) => (
              <div className="mt-4">
                <SignedMagnifyImg src={img} />
              </div>
            ))
          : ''}
        <div className="mb-3" />
      </div>
    </div>
  );

  if (isInPreviewMode) {
    content = <TellerPreview />;
  }

  return (
    <Formik initialValues={order} onSubmit={onSubmit}>
      <Form>{content}</Form>
    </Formik>
  );
};

export default TellerShow;
