import { AcceptedOffersReport } from '../../utils/report-methods/accepted-offers-report/get-accepted-offers-report';
import { ValuatedOrdersReport } from '../../utils/report-methods/valuated-offers-report/get-valuated-offers-report';
import { ReportsActionObject, ReportsActionTypes } from './ReportsAction';

const valuatedOffersReport: ValuatedOrdersReport = {
  users: [],
  auCount: 0,
  totalCount: 0,
  outCount: 0,
  out2WCount: 0,
  cancelledCount: 0,
};

const acceptedOffersReport: AcceptedOffersReport = {
  materials: [],
  gross: {},
  net: {},
  productCounts: {},
  users: [],
  totalOffer: 0,
};

export default class ReportsReducer {
  private static initState = {
    isSearched: false,
    isLoading: false,
    acceptedOffersReport,
    valuatedOffersReport,
  };

  public static reducer(
    state = ReportsReducer.initState,
    action: ReportsActionObject
  ): typeof state {
    switch (action.type) {
      case ReportsActionTypes.MATERIAL_WEIGHT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          acceptedOffersReport: action.payload.acceptedOffersReport,
        };

      case ReportsActionTypes.SEARCH_STARTED:
        return {
          ...state,
          isSearched: true,
          isLoading: true,
        };

      case ReportsActionTypes.VALUATED_OFFERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          valuatedOffersReport: action.payload.valuatedOffersReport,
        };

      case ReportsActionTypes.RESET:
        return ReportsReducer.initState;

      default:
        return state;
    }
  }
}
