import { Order } from '../order/Order';
import { MassBoomerangsActionObject, MassBoomerangsActionTypes } from './MassBoomerangsAction';

export default class MassBoomerangsReducer {
  private static initState = {
    orders: [] as Order[],
    isLoading: false,
  };

  public static reducer(
    state = MassBoomerangsReducer.initState,
    action: MassBoomerangsActionObject
  ): typeof state {
    switch (action.type) {
      case MassBoomerangsActionTypes.CLEAN:
        return MassBoomerangsReducer.initState;

      case MassBoomerangsActionTypes.ADD_TO_LIST:
        return {
          ...state,
          orders: [action.payload.order, ...state.orders],
        };

      case MassBoomerangsActionTypes.SET_IS_LOADING:
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };

      default:
        return state;
    }
  }
}
