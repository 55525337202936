import { CCManagerReportBody } from '../../forms/ccmanager-report-search/CcmanagerReportSearchInitialValues';
import { ReduxDispatch } from '../Action';
import { CCManagerReportsData } from './CCManagerReportData';
import { CcManagerReportsActionReturnType } from './CcManagerReportsActionReturnType';
import CcManagerReportsActionType from './CcManagerReportsActionType';
import CcManagerReportsEffect from './CcManagerReportsEffect';

export default class CcManagerReportsAction {
  private static request(): CcManagerReportsActionReturnType {
    return {
      type: CcManagerReportsActionType.REQUEST,
    };
  }

  private static fail(): CcManagerReportsActionReturnType {
    return {
      type: CcManagerReportsActionType.FAIL,
    };
  }

  public static clean(): CcManagerReportsActionReturnType {
    return {
      type: CcManagerReportsActionType.CLEAN,
    };
  }

  private static success(data: CCManagerReportsData): CcManagerReportsActionReturnType {
    return {
      type: CcManagerReportsActionType.SUCCESS,
      payload: { data },
    };
  }

  public static fetch(body: CCManagerReportBody) {
    return (dispatch: ReduxDispatch) => {
      dispatch(CcManagerReportsAction.request());
      CcManagerReportsEffect.getData(body)
        .then((data) => dispatch(CcManagerReportsAction.success(data)))
        .catch(() => {
          dispatch(CcManagerReportsAction.fail());
        });
    };
  }
}
