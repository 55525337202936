import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from 'releox-react';
import OrdersAction from '../store/order/OrdersAction';
import OrderQuery from '../store/order/OrdersQuery';
import OrdersSelector from '../store/order/OrdersSelector';

export default (Component: any) => (props: any) => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const isLoading = useSelector(OrdersSelector.selectIsModelLoading);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(OrdersAction.fetch(id, OrderQuery.onlyInclude));
    setIsReady(true);
    return () => {
      dispatch(OrdersAction.clean());
    };
  }, [id, dispatch]);

  if (isLoading || !isReady) return <Loading />;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />;
};
