import { TellerRedux } from './Teller';

const TellersReducerInitState: TellerRedux = {
  inDealTotal: 0,
  isManualCalculation: false,
  offer: 0,
  sekOffer: 0,
  gbpOffer: 0,
  isSMSLoading: false,
  showPreview: false,
  iban: '',
  bban: '',
  isIBANValid: false,
  isBBANValid: false,
  formValues: {},
  ukAccountNumber: '',
  ukSortCode: '',
};

export default TellersReducerInitState;
