import React from 'react';
import { GenericEditForm } from 'releox-react';
import * as Yup from 'yup';
import PasswordForm from '../../../forms/PasswordForm';
import TeamsAction from '../../../store/team/TeamsAction';
import TeamsSelector from '../../../store/team/TeamsSelector';

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .required(),
});

export default () => (
  <GenericEditForm
    loadingSelector={TeamsSelector.selectMemberIsLoading}
    dataSelector={() => ({})}
    tNamespace="TeamPasswordScene"
    EmbedForm={PasswordForm}
    initialValues={{ password: '' }}
    onFetch={TeamsAction.fetch}
    onSubmit={TeamsAction.update}
    validationSchema={Schema}
  />
);
