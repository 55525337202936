import moment from 'moment';
import React from 'react';
import { AsyncSelect, Select } from 'releox-react';
import DatePicker from '../../components/DatePicker';
import apis from '../../configs/apis';

const minDate = moment()
  .subtract(2, 'year')
  .startOf('day')
  .toDate();

export default () => (
  <>
    <Select
      name="type"
      label="Type"
      options={[
        { label: 'Welcome call', value: 'welcomeCall' },
        { label: 'After call', value: 'dueDate' },
      ]}
    />
    <DatePicker minDate={minDate} label="Start" name="startDate" />
    <DatePicker minDate={minDate} label="End" name="endDate" />
    <AsyncSelect name="callerId" searchFields={['name']} label="Buyer" getUrl={apis.MEMBER} />
  </>
);
