export default class OrdersSelector {
  public static selectOrder(state: ReduxState) {
    return state.orderEntry.model.data;
  }

  public static selectIsModelLoading(state: ReduxState) {
    return state.orderEntry.model.isLoading;
  }

  public static selectIsListLoading(state: ReduxState) {
    return state.orderEntry.list.isFetchLoading;
  }

  public static selectOrders(state: ReduxState) {
    return state.orderEntry.list.data;
  }

  public static selectAfterCallLog(state: ReduxState) {
    return state.orderEntry.model.data.afterCallLogs || [];
  }

  public static selectWelcomeCallLog(state: ReduxState) {
    return state.orderEntry.model.data.welcomeCallLogs || [];
  }

  public static selectLogs(state: ReduxState) {
    return state.orderEntry.model.data.logs || [];
  }

  public static selectOrdersCount(state: ReduxState) {
    return state.orderEntry.list.count;
  }
}
