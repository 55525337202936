import React from 'react';
import { Input } from 'releox-react';

export default () => {
  return (
    <>
      <Input name="fi" label="fi" />
      <Input name="sv" label="sv" />
      <Input name="svFI" label="svFI" />
    </>
  );
};
