import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { Country } from '../../classes/Country';
import apis from '../../configs/apis';
import OrderQuery from '../../store/order/OrdersQuery';

const generateHeader = (filter: LoopbackFilter): AxiosRequestConfig => ({
  params: {
    where: filter.where,
  },
});

export const useUnvaluatedCount = (country: Country) => {
  return useQuery(`tellerUnvaluatedCount:${country}`, async () => {
    const { data } = await axios.get(
      `${apis.ORDER}/count`,
      generateHeader(OrderQuery.getUnvaluatedQuery(country))
    );
    return data;
  });
};

export const usePreviousOrdersCount = (id: string) => {
  return useQuery(
    `previousOrdersCount:${id}`,
    async (): Promise<number> => {
      try {
        const { data: order } = await axios.get(`${apis.ORDER}/${id}`, {
          params: { filter: { fields: 'clientId' } },
        });

        const { data } = await axios.get(`${apis.ORDER}/count`, {
          params: { where: { clientId: order.clientId } },
        });
        return data.count;
      } catch {
        return 0;
      }
    }
  );
};
