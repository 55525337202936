import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Details, Loading, Select, TextArea } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import CallLogList from '../../components/CallLogList';
import ClientLink from '../../components/ClientLink';
import DatePicker from '../../components/DatePicker';
import { SignedImg, SignedMagnifyImg } from '../../components/SignedComponents';
import StepStamps from '../../components/StepStamps';
import routes from '../../configs/routes';
import { languageOptions } from '../../options';
import { ReduxDispatch } from '../../store/Action';
import { Order } from '../../store/order/Order';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';
import RednotAction from '../../store/rednot/RednotAction';
import RegisterPackagesAction from '../../store/register-package/RegisterPackagesAction';
import RegisterPackagesSelector from '../../store/register-package/RegisterPackagesSelector';
import InOfferTable from '../teller/components/InOfferTable';

export default () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { id } = useParams<{ id: string }>();
  const order = useSelector((state: ReduxState) => state.orderEntry.model.data);
  const isPackageRegisterLoading = useSelector(RegisterPackagesSelector.selectIsLoading);

  useEffect(() => {
    dispatch(OrdersAction.fetch(id, { include: OrderQuery.include }));
  }, [dispatch, id]);

  const onSubmit = useCallback(
    (body: Order) => {
      dispatch(OrdersAction.update(id, body)).then(() => dispatch(RednotAction.success('Saved!')));
    },
    [id, dispatch]
  );

  const registerPackage = useCallback(() => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (confirm("Are you sure you want to change order's bar code?")) {
      dispatch(RegisterPackagesAction.registerPackage(id));
    }
  }, [dispatch, id]);

  if (!order) return <Loading />;

  return (
    <div className="row">
      <div className="col-sm-4">
        <Card>
          {order.isPackaged ? (
            <span />
          ) : (
            <Button
              id="register-package"
              onClick={registerPackage}
              disabled={isPackageRegisterLoading}
              disabledText="Loading..."
              className="btn-sm float-right"
            >
              Register package
            </Button>
          )}
          <CardTitle>
            <>Order # {order.orderNumber}</>
          </CardTitle>
          <p>
            <ClientLink url={routes.CUSTOMER_SERVICE.CLIENT_SHOW} />
          </p>
          {order.closed ? (
            <span />
          ) : (
            <p>
              <Link to={routes.CUSTOMER_SERVICE.CLOSE.replace(':id', id)}>Close order</Link>
            </p>
          )}
          <hr />
          <Details
            object={order}
            properties={[
              {
                text: 'Close reason',
                dataField: 'closeReason',
              },
              {
                text: 'Closed',
                dataField: 'closed',
                formatter: (closed: boolean) => (closed ? 'Yes' : 'No'),
              },
              OrderColumn.phone,
              OrderColumn.status,
              OrderColumn.clientName,
              OrderColumn.address,
              OrderColumn.callTransfer,
              OrderColumn.barCode,
              OrderColumn.team,
              OrderColumn.language,
              OrderColumn.welcomeCall,
              OrderColumn.offer,
              OrderColumn.isPaid,
            ]}
          />
        </Card>
        <Card>
          <CardTitle>Edit</CardTitle>
          <Formik initialValues={order} enableReinitialize onSubmit={onSubmit}>
            <Form>
              <DatePicker name="dueDate" label="Due date" />
              <Select
                inlineLabelWidth={2}
                name="language"
                label="Language"
                options={languageOptions}
              />
              <TextArea name="customerServiceComment" label="Customer service comment" />
              <TextArea label="General message" name="generalMessage" />
              <Button id="submit" className="float-right" type="submit">
                Save
              </Button>
            </Form>
          </Formik>
        </Card>
        {order.extraImages?.map((ex) => (
          <SignedImg src={ex} key={ex} className="w-100 my-2" />
        ))}
      </div>
      <div className="col-sm-5">
        {order.valuationImage ? <SignedMagnifyImg src={order.valuationImage} /> : ''}
        <InOfferTable />
        <StepStamps />
      </div>
      <div className="col-sm-3">
        <Card>
          <CardTitle>Call log</CardTitle>
          <CallLogList selector={OrdersSelector.selectLogs} />
        </Card>
        {order.isPackaged ? (
          <Card>
            <CardTitle>Prints</CardTitle>
            <Details
              object={order}
              properties={[
                OrderColumn.invoice,
                OrderColumn.letter,
                OrderColumn.barCodePrintForDetails,
                OrderColumn.valuationLabel,
                OrderColumn.addressLabel,
              ]}
            />
          </Card>
        ) : (
          ''
        )}
        {order.isTellered ? (
          <Card>
            <CardTitle>Teller information</CardTitle>
            <Details
              object={order}
              properties={[
                OrderColumn.termsAccepted,
                OrderColumn.offerAccepted,
                OrderColumn.insuranceReturn,
                OrderColumn.ignobleReturn,
                OrderColumn.offeredDeclined,
                OrderColumn.isDonation,
              ]}
            />
          </Card>
        ) : (
          ''
        )}
        <Card>
          <CardTitle>Messages</CardTitle>
          <Details
            object={order}
            properties={[
              OrderColumn.generalMessage,
              OrderColumn.packagerMessageFromBuyer,
              OrderColumn.valuationMessageFromBuyer,
              OrderColumn.tellerMessageFromBuyer,
              OrderColumn.tellerMessageFromValuation,
              OrderColumn.revaluationMessageFromTeller,
              OrderColumn.bursarComment,
              OrderColumn.tellerComment,
            ]}
          />
        </Card>
      </div>
    </div>
  );
};
