export default class ValuatesSelector {
  public static selectView(state: ReduxState) {
    return state.valuate.view;
  }

  public static selectFormValues(state: ReduxState) {
    return state.valuate.form;
  }

  public static selectGoldWeight(state: ReduxState) {
    return state.valuate.goldWeight;
  }

  public static selectSilverWeight(state: ReduxState) {
    return state.valuate.silverWeight;
  }

  public static selectOtherWeight(state: ReduxState): number {
    return state.valuate.otherWeight;
  }

  public static selectCustomGoldWeight(state: ReduxState): number {
    return state.valuate.customGoldWeight;
  }

  public static selectPrice(state: ReduxState) {
    return state.valuate.price;
  }
}
