import PaymentXml from './PaymentXml';

const PaymentXmlInitialValues: PaymentXml = {
  id: '',
  name: '',
  location: '',
  country: '',
  total: 0,
};

export default PaymentXmlInitialValues;
