import React from 'react';
import { GenericIndexScene } from 'releox-react';
import routes from '../../../configs/routes';
import MaterialsAction from '../../../store/material/MaterialsAction';
import MaterialsSelector from '../../../store/material/MaterialsSelector';

const columns = [
  { text: 'Name', dataField: 'name', sort: true },
  { text: 'Price', dataField: 'price', sort: true },
  { text: 'Category', dataField: 'category', sort: true },
];

export default () => (
  <GenericIndexScene
    tNamespace="MaterialIndexScene"
    loadingSelector={MaterialsSelector.selectMaterialsIsLoading}
    dataSelector={MaterialsSelector.selectMaterials}
    dataSizeSelector={MaterialsSelector.selectMaterialsCount}
    onChangeLoopback={MaterialsAction.list}
    columns={columns}
    defaultSorted={{ dataField: 'createdAt', order: 'desc' }}
    redirectUrl={routes.ADMIN_MATERIAL.EDIT}
    createLink={routes.ADMIN_MATERIAL.CREATE}
  />
);
