import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardTitle } from 'releox-react';
import ValuationOrderInfo from '../../../components/ValuationOrderInfo';
import ValuatesAction, { ValuateScenes } from '../../../store/valuate/ValuatesAction';

interface Props {
  hideNextButton?: boolean;
}

export default ({ hideNextButton = false }: Props) => {
  const dispatch = useDispatch();

  const nextStep = useCallback(() => {
    dispatch(ValuatesAction.nextStep(ValuateScenes.VALUATE));
  }, [dispatch]);

  return (
    <Card>
      <>
        <CardTitle>Info</CardTitle>
        <ValuationOrderInfo />
        {!hideNextButton ? (
          <Button id="next-button" className="float-right" onClick={nextStep}>
            Next
          </Button>
        ) : (
          ''
        )}
      </>
    </Card>
  );
};
