import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import routes from '../../configs/routes';
import RednotAction from '../rednot/RednotAction';
import TeamsEffect from './TeamsEffect';

export default class TeamsAction {
  static ENTRY = 'TEAM';

  static actionCreator = actionCreator(
    TeamsAction.ENTRY,
    TeamsEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = TeamsAction.actionCreator.getCreateAction(routes.ADMIN_USERS.INDEX);

  static update = TeamsAction.actionCreator.getUpdateAction(routes.ADMIN_USERS.INDEX);

  static fetch = TeamsAction.actionCreator.getFindByIdAction();

  static delete = TeamsAction.actionCreator.getDeleteAction(routes.ADMIN_USERS.INDEX);

  static list = TeamsAction.actionCreator.getFindAndCountAction();

  static clean = TeamsAction.actionCreator.getCleanAction();
}
