import { CancelCallTransferSearchFormBody } from '../../forms/cancel-call-transfer-search/CancelCallTransferSearchInitialValues';
import { CancelTransferFormBody } from '../../forms/cancel-call-transfer/CancelCallTransferInitialValues';
import { Action, ActionWPayload, ReduxDispatch } from '../Action';
import CallTransfersAction from '../call-transfer/CallTransfersAction';
import ErrorsAction from '../error/ErrorsAction';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';
import { CancelTransferFormValues } from './CancelCallTransfer';

export enum CancelCallTransfersActionTypes {
  SEARCH_SUCCESS = '@CANCEL_CALL_TRANSFER:SEARCH_SUCCESS',
  RESET = '@CANCEL_CALL_TRANSFER:RESET',
}

type SearchSuccess = ActionWPayload<
  CancelCallTransfersActionTypes.SEARCH_SUCCESS,
  { cancelTransferField: CancelTransferFormValues[] }
>;

type Reset = Action<CancelCallTransfersActionTypes.RESET>;

export type CancelCallTransfersActionObject = SearchSuccess | Reset;

export default class CancelCallTransfersAction {
  private static searchSuccess(
    cancelTransferField: CancelTransferFormValues[]
  ): CancelCallTransfersActionObject {
    return {
      type: CancelCallTransfersActionTypes.SEARCH_SUCCESS,
      payload: { cancelTransferField },
    };
  }

  public static reset(): CancelCallTransfersActionObject {
    return {
      type: CancelCallTransfersActionTypes.RESET,
    };
  }

  public static searchOrders(body: CancelCallTransferSearchFormBody) {
    return (dispatch: ReduxDispatch) => {
      const query = OrderQuery.getCancelCallTransferSearchQuery(body);
      OrdersEffect.find(query)
        .then((orders) => {
          const callTransferFields = CallTransfersAction.remapOrderToTransferFields(orders);
          dispatch(this.searchSuccess(callTransferFields));
        })
        .catch(dispatch(ErrorsAction.onCatch()));
    };
  }

  public static cancelTransfers(body: CancelTransferFormBody) {
    return (dispatch: ReduxDispatch) => {
      const requestBodies: any = body.callTransferField
        .filter((o) => o.form.isSelected)
        .map((o) => {
          if (o.originalCallerId) {
            return {
              id: o.id,
              callerId: o.originalCallerId,
              originalCallerId: '',
              isPermanentlyTransferred: false,
            };
          }

          return {
            id: o.id,
            assignedCallerId: '',
            isTemporarilyTransferred: false,
          };
        });

      const requests = requestBodies.map((o: any) => OrdersEffect.update(o.id, o));
      Promise.all(requests)
        .then(() => {
          dispatch(RednotAction.success(`${requestBodies.length} order has been transferred`));
          dispatch(this.reset());
        })
        .catch(dispatch(ErrorsAction.onCatch()));
    };
  }
}
