import React from 'react';
import { GenericEditForm } from 'releox-react';
import MaterialForm, {
  materialFormInitValue,
  MaterialValidationSchema,
} from '../../../forms/MaterialForm';
import MaterialsAction from '../../../store/material/MaterialsAction';
import MaterialsSelector from '../../../store/material/MaterialsSelector';

export default () => (
  <GenericEditForm
    tNamespace="MaterialEditScene"
    loadingSelector={MaterialsSelector.selectMaterialIsLoading}
    dataSelector={MaterialsSelector.selectMaterial}
    EmbedForm={MaterialForm}
    initialValues={materialFormInitValue}
    onSubmit={MaterialsAction.update}
    validationSchema={MaterialValidationSchema}
    onFetch={MaterialsAction.fetch}
    onDelete={MaterialsAction.delete}
  />
);
