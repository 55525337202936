import { fixFloat } from '../../../config';
import { Product } from '../../../store/order/Order';
import addOrCalculate from '../../add-or-calculate';
import getNetWeight from '../../get-net-weight';

interface WeightReportWeight {
  [key: string]: number;
}

interface ReportUserData {
  name: string;
  grosses: WeightReportWeight;
  nets: WeightReportWeight;
  counts: WeightReportWeight;
  offer: number;
}

export default class AcceptedReportUser {
  private user: ReportUserData;

  constructor(name: string) {
    this.user = {
      name,
      offer: 0,
      grosses: {},
      nets: {},
      counts: {},
    };
  }

  public calculateGrossWeight(density: string, weight: number): void {
    this.user.grosses[density] = fixFloat(addOrCalculate(this.user.grosses, density, weight));
  }

  public calculateNetWeight(product: Product): void {
    const { density } = product;
    const netWeight = getNetWeight(product);
    this.user.nets[density] = fixFloat(addOrCalculate(this.user.nets, density, netWeight));
    if (density !== 'customGold') {
      this.user.nets.gold = fixFloat(addOrCalculate(this.user.nets, 'gold', netWeight));
    }
  }

  public calculateCount(density: string): void {
    this.user.counts[density] = addOrCalculate(this.user.counts, density, 1);
  }

  public incrementGoldCount(): void {
    this.calculateCount('gold');
  }

  public calculateOffer(offer: number): void {
    this.user.offer += offer;
  }

  public getGrossWeights(): WeightReportWeight {
    return this.user.grosses;
  }

  public getNetWeight(): WeightReportWeight {
    return this.user.nets;
  }

  public getCounts(): WeightReportWeight {
    return this.user.counts;
  }

  public getName(): string {
    return this.user.name;
  }

  public getOffer(): number {
    return fixFloat(this.user.offer);
  }
}
