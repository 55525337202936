import { RednotAction as RAction } from 'rednot';
import { getErrorMessage } from 'releox-react';

const dismissAfter = 3500;

const notif = (className: string) => (message: string) => (dispatch: Function) => {
  dispatch(RAction.notifSend({ message, dismissAfter, className }));
};

export default class RednotAction {
  static error = notif('alert alert-danger');

  static success = notif('alert alert-primary');

  static onError = (e: Error, dispatch: Function) =>
    dispatch(RednotAction.error(getErrorMessage(e)));
}
