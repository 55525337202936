import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'releox-react';
import CallTransferSearchForm from '../../forms/call-transfer-search/CallTransferSearchForm';
import getCallTransferSearchInitialValues, {
  CallTransferSearchBody,
} from '../../forms/call-transfer-search/CallTransferSearchInitialValues';
import CallTransferSearchValidationSchema from '../../forms/call-transfer-search/CallTransferSearchValidationSchema';
import CallTransferForm from '../../forms/call-transfer/CallTransferForm';
import {
  CallTransferFormBody,
  getTransferInitialValues,
} from '../../forms/call-transfer/CallTransferInitialValues';
import CallTransferValidationSchema from '../../forms/call-transfer/CallTransferValidationSchema';
import CallTransfersAction from '../../store/call-transfer/CallTransfersAction';
import CallTransfersSelector from '../../store/call-transfer/CallTransfersSelector';

export default () => {
  const dispatch = useDispatch();
  const callTransferField = useSelector(CallTransfersSelector.selectCallTransferField);

  useEffect(
    () => () => {
      dispatch(CallTransfersAction.reset());
    },
    [dispatch]
  );

  const onSearchSubmit = useCallback(
    (body: CallTransferSearchBody) => {
      dispatch(CallTransfersAction.searchUndoneOrders(body));
    },
    [dispatch]
  );

  const onTransferSubmit = useCallback(
    (body: CallTransferFormBody) => {
      dispatch(CallTransfersAction.transferOrders(body));
    },
    [dispatch]
  );

  return (
    <div className="row">
      <div className="col-lg-2">
        <Card>
          <Formik
            onSubmit={onSearchSubmit}
            validationSchema={CallTransferSearchValidationSchema}
            initialValues={getCallTransferSearchInitialValues()}
          >
            <Form>
              <CallTransferSearchForm />
              <Button id="submit" type="submit" className="btn-block">
                Search
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      <div className="col-lg-10">
        <Card key={JSON.stringify(callTransferField)}>
          <Formik
            onSubmit={onTransferSubmit}
            initialValues={getTransferInitialValues(callTransferField)}
            validationSchema={CallTransferValidationSchema}
          >
            <Form>
              <CallTransferForm />
              <Button id="submit-transfer" className="float-right" type="submit">
                Transfer
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  );
};
