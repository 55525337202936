import moment from 'moment';
import React from 'react';
import { AsyncSelect, Select } from 'releox-react';
import DatePicker from '../../components/DatePicker';
import apis from '../../configs/apis';
import { teamOptions } from '../../options';

export enum CCManagerCallListSearchBy {
  AFTER_CALL = 'dueDate',
  WELCOME_CALL = 'welcomeCall',
}

const minDate = moment()
  .subtract(2, 'year')
  .startOf('day')
  .toDate();

export default () => (
  <div className="row">
    <div className="col-lg-2">
      <Select
        label="Search by"
        name="by"
        options={[
          {
            label: 'Welcome Call',
            value: CCManagerCallListSearchBy.WELCOME_CALL,
          },
          {
            label: 'After Call',
            value: CCManagerCallListSearchBy.AFTER_CALL,
          },
        ]}
      />
    </div>
    <div className="col-lg-2">
      <DatePicker minDate={minDate} label="From" name="startDate" />
    </div>
    <div className="col-lg-2">
      <DatePicker minDate={minDate} label="To" name="endDate" />
    </div>
    <div className="col-lg-2">
      <AsyncSelect label="Caller" name="callerId" getUrl={apis.MEMBER} searchFields={['name']} />
    </div>
    <div className="col-lg-2">
      <Select name="team" label="Team" options={teamOptions} />
    </div>
  </div>
);
