import {
  GenerateOrderNumbersActionObject,
  GenerateOrderNumbersActionTypes,
} from './GenerateOrderNumbersAction';

export default class GenerateOrderNumbersReducer {
  private static initState = {
    isLoading: false,
  };

  public static reducer(
    state = GenerateOrderNumbersReducer.initState,
    action: GenerateOrderNumbersActionObject
  ): typeof state {
    switch (action.type) {
      case GenerateOrderNumbersActionTypes.REQUEST:
        return {
          ...state,
          isLoading: true,
        };

      case GenerateOrderNumbersActionTypes.REQUEST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case GenerateOrderNumbersActionTypes.REQUEST_FAIL:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  }
}
