import deepmerge from 'deepmerge';
import { FieldProps } from 'formik';
import IBAN from 'iban';
import merge from 'lodash/merge';
import { goBack } from 'react-router-redux';
import { getErrorMessage } from 'releox-react';
import * as Yup from 'yup';
import { Country } from '../../classes/Country';
import { fixFloat, IBANRegex } from '../../config';
import { sendSingleSMS } from '../../send-sms';
import { getNotAnswerSMSMessage } from '../../sms-messages';
import getFirstFormikError from '../../utils/get-first-formik-error';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import { Client } from '../client/Client';
import ClientsAction from '../client/ClientsAction';
import ClientsEffect from '../client/ClientsEffect';
import ErrorsAction from '../error/ErrorsAction';
import { Order, Product } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

interface UpdateInformation {
  orderValues: Partial<Order>;
  orderBaseValues?: Partial<Order>;
  clientValues?: Partial<Client>;
  clientBaseValues?: Partial<Client>;
}

export enum TellersActionType {
  SET_IBAN = `@$TELLER:SET_IBAN`,
  RESET = `@$TELLER:RESET`,
  SET_BBAN = `@$TELLER:SET_BBAN`,
  SET_IN_DEAL_TOTAL = `@$TELLER:SET_IN_DEAL_TOTAL`,
  SET_FORM_VALUES = `@$TELLER:SET_FORM_VALUES`,
  SET_OFFER = `@$TELLER:SET_OFFER`,
  SET_SEK_OFFER = `@$TELLER:SET_SEK_OFFER`,
  SET_GBP_OFFER = `@$TELLER:SET_BGP_OFFER`,
  SET_MANUAL_MODE = `@$TELLER:SET_MANUAL_MODE`,
  SET_SMS_LOADING = `@$TELLER:SET_SMS_LOADING`,
  SHOW_PREVIEW = `@$TELLER:SHOW_PREVIEW`,

  SET_UK_ACCOUNT_NUMBER = `@$TELLER:SET_UK_ACCOUNT_NUMBER`,
  SET_UK_SORT_CODE = `@$TELLER:SET_UK_SORT_CODE`,
}

type TellerBody = {
  isTellered: boolean;
  tellerDate: Date;
  tellerId: string;
  offer: number;
  sekOffer?: number;
  gbpOffer?: number;
  ukAccountNumber?: string;
  ukSortCode?: string;
};

export default class TellersAction {
  static onSubmit(body: Order) {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();

      if (!body.offerAccepted && !body.offeredDeclined) {
        return dispatch(RednotAction.error('Check offer accept or decline'));
      }
      const { isIBANValid, iban, offer, sekOffer, gbpOffer } = state.teller;
      const { country } = state.orderEntry.model.data;
      const memberId = state.user.data.id;
      let clientBody: Partial<Client> = {};
      const requestBody: Partial<TellerBody> = {
        isTellered: true,
        tellerDate: new Date(),
        tellerId: memberId,
        offer,
      };

      const shape: any = {};

      if (country === Country.SWEDEN) {
        shape.offer = Yup.number().required();
        shape.sekOffer = Yup.number().required();

        requestBody.sekOffer = sekOffer;
      }

      if (country === Country.UK) {
        shape.offer = Yup.number().required();
        shape.gbpOffer = Yup.number().required();

        requestBody.gbpOffer = gbpOffer;
      }

      if (country === Country.FINLAND) {
        shape.offer = Yup.number().required();
      }

      const validationSchema = Yup.object().shape(shape);

      return validationSchema
        .validate(requestBody)
        .then((validatedRequestBody) => {
          if (!body.isDonation && !body.offeredDeclined) {
            if (offer < 0.01) {
              return dispatch(RednotAction.error('Deal is accepted, but offer is empty or 0'));
            }

            if (country === 'sweden') {
              if (sekOffer < 0.01) {
                return dispatch(
                  RednotAction.error('Deal is accepted, but SEK offer is empty or 0')
                );
              }
            }

            if (!isIBANValid && country !== 'uk') {
              return dispatch(RednotAction.error('Invalid IBAN'));
            }

            if (country === 'uk') {
              clientBody = {
                ukSortCode: state.teller.ukSortCode.replace(/ /g, ''),
                ukAccountNumber: state.teller.ukAccountNumber.replace(/ /g, ''),
              };
              // Vlaidate UK sort code and account number
              if (state.teller.ukSortCode.length !== 6) {
                return dispatch(
                  RednotAction.error(`Invalid UK sort code ${state.teller.ukSortCode}`)
                );
              }

              if (state.teller.ukAccountNumber.length !== 8) {
                return dispatch(RednotAction.error('Invalid UK account number'));
              }
            } else {
              clientBody = { iban: iban.replace(/ /g, '') };
            }
          }
          return dispatch(
            this.updateInformation({ orderValues: validatedRequestBody, clientValues: clientBody })
          );
        })
        .catch((e) => {
          dispatch(RednotAction.error(getFirstFormikError(e.errors)));
        });
    };
  }

  static goBack() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const { isIBANValid, iban } = state.teller;
      const { clientId, client } = state.orderEntry.model.data;

      if (client?.iban !== iban && isIBANValid && iban) {
        ClientsEffect.update(clientId, { iban: iban.replace(/ /g, '') })
          .then(() => dispatch(goBack()))
          .catch((e) => {
            dispatch(RednotAction.error(getErrorMessage(e)));
            dispatch(goBack());
          });
      } else {
        dispatch(goBack());
      }
    };
  }

  static bbanOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    return (dispatch: Function, getState: GetState) => {
      const { value } = e.target;
      const state = getState();
      const { country } = state.orderEntry.model.data;

      let countryCode = 'FI';

      if (country === 'sweden') countryCode = 'SE';

      let iban = '';
      const isValidBBAN = IBAN.isValidBBAN(countryCode, value);
      if (isValidBBAN) {
        iban = IBAN.fromBBAN(countryCode, value);
      }
      dispatch(this.validateIBAN(iban));
      dispatch(this.setBBAN(value, isValidBBAN));
    };
  }

  static setBBAN(bban: string, isBBANValid: boolean): TellersActions {
    return { type: TellersActionType.SET_BBAN, payload: { bban, isBBANValid } };
  }

  static setIBAN(iban: string, isIBANValid: boolean): TellersActions {
    return { type: TellersActionType.SET_IBAN, payload: { iban, isIBANValid } };
  }

  static setInDealTotal(inDealTotal: number): TellersActions {
    return { type: TellersActionType.SET_IN_DEAL_TOTAL, payload: { inDealTotal } };
  }

  static showPreview(showPreview: boolean): TellersActions {
    return { type: TellersActionType.SHOW_PREVIEW, payload: { showPreview } };
  }

  static validateIBAN(iban: string) {
    return (dispatch: Function) => {
      const isIBANValid = IBAN.isValid(iban);
      const isRegexValid = IBANRegex.test(iban);
      dispatch(this.setIBAN(iban, isIBANValid && isRegexValid));
    };
  }

  static ibanOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    return (dispatch: Function) => {
      const { value } = e.target;
      dispatch(this.validateIBAN(value));
    };
  }

  static onOrderUpdate(order: Order) {
    return (dispatch: Function) => {
      if (order.client) {
        dispatch(this.countTotal(order.products));
        dispatch(this.validateIBAN(order.client.iban));
        dispatch(this.setBBAN('', false));
        dispatch(this.ukSortCode(order.client.ukSortCode || ''));
        dispatch(this.ukAccountNumber(order.client.ukAccountNumber || ''));
        if (order.offer && order.offer > 0.01) {
          dispatch(this.setManualMode());
          dispatch(this.setOffer(fixFloat(order.offer)));
        }
      }
    };
  }

  static ukSortCode(ukSortCode: string): TellersActions {
    return {
      type: TellersActionType.SET_UK_SORT_CODE,
      payload: { ukSortCode },
    };
  }

  static ukAccountNumber(ukAccountNumber: string): TellersActions {
    return {
      type: TellersActionType.SET_UK_ACCOUNT_NUMBER,
      payload: { ukAccountNumber },
    };
  }

  static setOffer(offer: number): TellersActions {
    return {
      type: TellersActionType.SET_OFFER,
      payload: { offer },
    };
  }

  static setsekOffer(sekOffer: number): TellersActions {
    return {
      type: TellersActionType.SET_SEK_OFFER,
      payload: { sekOffer },
    };
  }

  static setgbpOffer(gbpOffer: number): TellersActions {
    return {
      type: TellersActionType.SET_GBP_OFFER,
      payload: { gbpOffer },
    };
  }

  static reset(): TellersActions {
    return {
      type: TellersActionType.RESET,
    };
  }

  static setManualMode(): TellersActions {
    return {
      type: TellersActionType.SET_MANUAL_MODE,
    };
  }

  static countTotal(products: Product[]) {
    return (dispatch: Function, getState: GetState) => {
      const previousOldValues = getState().teller.inDealTotal;
      const { isManualCalculation } = getState().teller;
      let total = 0;
      products.forEach((p) => {
        if (p.inDeal) total += p.price;
      });
      if (previousOldValues !== total) {
        dispatch(this.setInDealTotal(fixFloat(total)));
        if (!isManualCalculation) {
          dispatch(this.setOffer(fixFloat(total)));
        }
      }
    };
  }

  static setFormValues(formValues: Order): TellersActions {
    return {
      type: TellersActionType.SET_FORM_VALUES,
      payload: { formValues },
    };
  }

  static formOnChange(formProps: FieldProps<any, Order>) {
    return (dispatch: Function) => {
      dispatch(this.countTotal(formProps.form.values.products));
      dispatch(this.setFormValues(formProps.form.values));
    };
  }

  static onViewMount(id: string) {
    return (dispatch: Function) => {
      dispatch(TellersAction.reset());
      dispatch(OrdersAction.fetch(id, OrderQuery.onlyInclude));
      dispatch(this.showPreview(false));
    };
  }

  static setSMSLoading(isSMSLoading: boolean): TellersActions {
    return {
      type: TellersActionType.SET_SMS_LOADING,
      payload: { isSMSLoading },
    };
  }

  static didNotAnswerAndSendSms() {
    return (dispatch: Function, getState: GetState) => {
      const order = getState().orderEntry.model.data;
      if (!order.client) throw new Error('Missing client');
      dispatch(this.setSMSLoading(true));
      sendSingleSMS(order.client.phone, getNotAnswerSMSMessage(order.language))
        .then(() => {
          dispatch(this.didNotAnswer());
          dispatch(this.setSMSLoading(false));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.setSMSLoading, false)));
    };
  }

  static didNotAnswer() {
    return (dispatch: Function, getState: GetState) => {
      const order = getState().orderEntry.model.data;
      const latestTellerCall = new Date();
      const body: any = {
        tellerCallTries: [...(order.tellerCallTries || []), latestTellerCall],
        latestTellerCall,
      };
      dispatch(this.updateInformation({ orderValues: body }));
    };
  }

  static returnToValuation() {
    return (dispatch: Function) => {
      const orderValues = { returnToValuation: true };
      const infoBody = { orderValues };
      dispatch(this.updateInformation(infoBody));
    };
  }

  static updateInformation(updateInformation: UpdateInformation) {
    return (dispatch: Function, getState: GetState) => {
      const orderBase = updateInformation.orderBaseValues || getState().teller.formValues;
      const orderBody: any = merge(orderBase, updateInformation.orderValues);

      let returnCost = 0;

      orderBody.products.forEach((p: Product) => {
        if (!p.inDeal) {
          returnCost += p.price;
        }
      });

      // Force insurance return if return value is over 100e
      if (returnCost > 100) orderBody.insuranceReturn = true;

      const promises = [];

      let clientBase: any =
        updateInformation.clientBaseValues || getState().teller.formValues.client;

      if (updateInformation.clientValues) {
        clientBase = deepmerge(clientBase, updateInformation.clientValues);
      }

      promises.push(dispatch(OrdersAction.update(orderBody.id, orderBody)));
      promises.push(dispatch(ClientsAction.update(clientBase.id, clientBase)));

      Promise.all(promises)
        .then(() => dispatch(goBack()))
        .catch((e) => dispatch(RednotAction.error(getErrorMessage(e))));
    };
  }
}

export type TellerSetIBAN = ActionWPayload<
  TellersActionType.SET_IBAN,
  {
    isIBANValid: boolean;
    iban: string;
  }
>;

export type TellerSetBBAN = ActionWPayload<
  TellersActionType.SET_BBAN,
  {
    bban: string;
    isBBANValid: boolean;
  }
>;

export type TellerSetInDealTotal = ActionWPayload<
  TellersActionType.SET_IN_DEAL_TOTAL,
  {
    inDealTotal: number;
  }
>;

export type TellerSetFormValues = ActionWPayload<
  TellersActionType.SET_FORM_VALUES,
  {
    formValues: Order;
  }
>;

export type TellerSetSMSLoading = ActionWPayload<
  TellersActionType.SET_SMS_LOADING,
  {
    isSMSLoading: boolean;
  }
>;

export type TellerSetOffer = ActionWPayload<
  TellersActionType.SET_OFFER,
  {
    offer: number;
  }
>;

export type TellerSetsekOffer = ActionWPayload<
  TellersActionType.SET_SEK_OFFER,
  {
    sekOffer: number;
  }
>;

export type TellerSetgbpOffer = ActionWPayload<
  TellersActionType.SET_GBP_OFFER,
  {
    gbpOffer: number;
  }
>;

export type TellerShowPreview = ActionWPayload<
  TellersActionType.SHOW_PREVIEW,
  {
    showPreview: boolean;
  }
>;

export type TellerSetSortCode = ActionWPayload<
  TellersActionType.SET_UK_SORT_CODE,
  {
    ukSortCode: string;
  }
>;

export type TellerSetAccountNumber = ActionWPayload<
  TellersActionType.SET_UK_ACCOUNT_NUMBER,
  {
    ukAccountNumber: string;
  }
>;

export type TellersActions =
  | TellerSetIBAN
  | TellerSetBBAN
  | TellerShowPreview
  | TellerSetInDealTotal
  | TellerSetOffer
  | TellerSetsekOffer
  | TellerSetgbpOffer
  | TellerSetSMSLoading
  | TellerSetFormValues
  | TellerSetSortCode
  | TellerSetAccountNumber
  | Action<TellersActionType.RESET>
  | Action<TellersActionType.SET_MANUAL_MODE>;
