import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Details } from 'releox-react';
import withOrder from '../../HOC/withOrder';
import OrderColumn from '../../columns/OrderColumn';
import ClientLink from '../../components/ClientLink';
import { SignedImg, SignedMagnifyImg } from '../../components/SignedComponents';
import StepStamps from '../../components/StepStamps';
import routes from '../../configs/routes';
import OrderForm from '../../forms/OrderForm';
import { Order } from '../../store/order/Order';
import OrdersAction from '../../store/order/OrdersAction';
import OrdersEffect from '../../store/order/OrdersEffect';
import OrdersSelector from '../../store/order/OrdersSelector';
import RegisterPackagesAction from '../../store/register-package/RegisterPackagesAction';
import RegisterPackagesSelector from '../../store/register-package/RegisterPackagesSelector';
import InOfferTable from '../teller/components/InOfferTable';

const minDate = moment()
  .subtract(2, 'year')
  .startOf('day')
  .toDate();

const AdminOrderEdit = () => {
  const dispatch = useDispatch();
  const [isAddressValid, setIsAddressValid] = useState(false);
  const isPackageRegisterLoading = useSelector(RegisterPackagesSelector.selectIsLoading);
  const { id } = useParams<{ id: string }>();
  const order = useSelector(OrdersSelector.selectOrder);

  const onSubmit = useCallback(
    (body: Order) => {
      dispatch(OrdersAction.update(id, body, { redirect: routes.ADMIN_ORDER.INDEX }));
    },
    [dispatch, id]
  );

  const registerPackage = useCallback(() => {
    dispatch(RegisterPackagesAction.registerPackage(id));
  }, [dispatch, id]);

  useEffect(() => {
    OrdersEffect.checkAddressBase64(id).then((isValid) => {
      setIsAddressValid(isValid);
    });
  }, [id]);

  return (
    <div className="row">
      <div className="col-lg-6">
        <Card>
          <Button
            id="register-package"
            onClick={registerPackage}
            disabled={isPackageRegisterLoading}
            disabledText="Loading..."
            className="btn-sm float-right"
          >
            Register package
          </Button>
          <CardTitle>
            <>Order #{order.orderNumber}</>
          </CardTitle>
          <ClientLink url={routes.ADMIN_CLIENT.EDIT} />
          <p>System address: {`${order.address}, ${order.zip}, ${order.city}`}</p>
          {isAddressValid ? <p>Address check: OK!</p> : <p>Address check: FAIL!</p>}
          <Formik initialValues={order} onSubmit={onSubmit}>
            <Form>
              <OrderForm minDate={minDate} />
              <Button id="submit" type="submit" className="float-right">
                Save
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      <div className="col-lg-6">
        {order.valuationImage ? <SignedMagnifyImg src={order.valuationImage} /> : ''}
        {order.isValued ? <InOfferTable /> : ''}
        {order.shipmentOffices && order.shipmentOffices.length ? (
          <Card>
            <CardTitle>Pickup locations</CardTitle>
            <ul>
              {order.shipmentOffices.map((shipmentOffice) => (
                <li
                  key={shipmentOffice.name}
                >{`${shipmentOffice.name}, ${shipmentOffice.address}, ${shipmentOffice.zip} ${shipmentOffice.city}`}</li>
              ))}
            </ul>
          </Card>
        ) : (
          ''
        )}
        {order.isPackaged ? (
          <Card>
            <CardTitle>Prints</CardTitle>
            <Details
              object={order}
              properties={[
                OrderColumn.phone,
                OrderColumn.invoice,
                OrderColumn.letter,
                OrderColumn.barCodePrintForDetails,
                OrderColumn.valuationLabel,
                OrderColumn.addressLabel,
              ]}
            />
          </Card>
        ) : (
          ''
        )}
        <StepStamps />
        <Card>
          <CardTitle>Links</CardTitle>
          <ul>
            <li>
              <Link to={routes.BUYER.WELCOME_CALL.replace(':id', id)}>Welcome call</Link>
            </li>
            <li>
              <Link to={routes.BUYER.AFTER_CALL.replace(':id', id)}>After call</Link>
            </li>
          </ul>
        </Card>
        {order.extraImages?.map((ex) => (
          <SignedImg src={ex} key={ex} className="w-100 my-2" />
        ))}
      </div>
    </div>
  );
};

export default withOrder(AdminOrderEdit);
