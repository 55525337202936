import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, SelectOption } from 'releox-react';
import { ProductInitialValue } from '../../forms/valuation/ValuateInitialValues';
import MaterialsSelector from '../../store/material/MaterialsSelector';
import { Product } from '../../store/order/Order';
import RednotAction from '../../store/rednot/RednotAction';
import ProductCalculateInput from './ProductCalculateInput';
import ProductDensitySelect from './ProductDensitySelect';

export default () => {
  const dispatch = useDispatch();
  const materials = useSelector(MaterialsSelector.selectMaterials);
  const headers = ['Name', 'Density', 'Weight', 'Factor', 'Price'];
  const options: SelectOption[] = [
    { value: '', label: 'Choose material' },
    { value: 'other', label: 'Other' },
    { value: 'customGold', label: 'Custom Gold' },
    ...materials.map((m) => ({ value: m.id, label: m.name })),
  ];

  const addNewProduct = useCallback(
    (props: FieldArrayRenderProps) => () => {
      const silver = materials.find((m) => m.category === 'silver');
      if (!silver) return dispatch(RednotAction.error('Missing silver material'));
      return props.push(ProductInitialValue(silver));
    },
    [materials, dispatch]
  );

  return (
    <Card>
      <FieldArray
        name="products"
        render={(props: FieldArrayRenderProps) => {
          const products = props.form.values.products as Product[];
          return (
            <div>
              <div className="row">
                <div className="col">
                  <Button id="add-new" onClick={addNewProduct(props)}>
                    Add new product
                  </Button>
                </div>
              </div>
              <hr />
              <div className="row">
                {headers.map((title) => (
                  <div key={title} className="col-2">
                    <p>{title}</p>
                  </div>
                ))}
              </div>
              {products.map((p, i) => {
                const calculatePrice = p.density !== 'customGold';
                return (
                  <div key={p.key} className="row mb-3">
                    <div className="col-2">
                      <Input name={`products.${i}.name`} label="Name" hideLabel />
                    </div>
                    <div className="col-2">
                      <ProductDensitySelect
                        name={`products.${i}.density`}
                        label="Density"
                        options={options}
                        materials={materials}
                        hideLabel
                      />
                    </div>
                    <div className="col-2">
                      <ProductCalculateInput
                        name={`products.${i}.weight`}
                        label="Weight"
                        calculatePrice={calculatePrice}
                        hideLabel
                        materials={materials}
                      />
                    </div>
                    <div className="col-2">
                      {(() => {
                        if (['other', 'customGold'].includes(p.density)) {
                          return (
                            <ProductCalculateInput
                              name={`products.${i}.factor`}
                              label="Factor"
                              hideLabel
                              calculatePrice={calculatePrice}
                              materials={materials}
                            />
                          );
                        }
                        const material = materials.find((m) => m.id === p.density);
                        if (material) return <>{material.price}</>;
                        return '';
                      })()}
                    </div>
                    <div className="col-2">
                      {p.density === 'customGold' ? (
                        <Input name={`products.${i}.price`} label="Price" hideLabel />
                      ) : (
                        <p>{p.price}</p>
                      )}
                    </div>
                    <div className="col-2">
                      <Button id={`del-${p.key}`} onClick={() => props.remove(i)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </Card>
  );
};
