import React from 'react';
import { GenericCreateForm } from 'releox-react';
import MaterialForm, {
  materialFormInitValue,
  MaterialValidationSchema,
} from '../../../forms/MaterialForm';
import MaterialsAction from '../../../store/material/MaterialsAction';
import MaterialsSelector from '../../../store/material/MaterialsSelector';

export default () => (
  <GenericCreateForm
    tNamespace="MaterialCreateScene"
    loadingSelector={MaterialsSelector.selectMaterialIsLoading}
    EmbedForm={MaterialForm}
    initialValues={materialFormInitValue}
    onSubmit={MaterialsAction.create}
    validationSchema={MaterialValidationSchema}
  />
);
