import { Order } from '../../../store/order/Order';
import AcceptedReportProduct from './AcceptedReportProduct';

export default class AcceptedReportOrder {
  private order: Order;

  private products: AcceptedReportProduct[];

  constructor(order: Order) {
    this.order = order;
    this.products = order.products
      .filter((product) => product.inDeal)
      .map((product) => new AcceptedReportProduct(product));
  }

  calculateOffer(previousOffer: number) {
    const offer = this.order.offer || 0;
    return previousOffer + offer;
  }

  getCallerId(): string {
    return this.order.callerId;
  }

  getCallerName(): string {
    return this.order.caller?.name || '';
  }

  getProducts(): AcceptedReportProduct[] {
    return this.products;
  }

  hasGold(): boolean {
    let hasGold = false;
    this.products.forEach((product) => {
      if (product.isGold()) {
        hasGold = true;
      }
    });
    return hasGold;
  }

  getOffer(): number {
    return this.order.offer || 0;
  }
}
