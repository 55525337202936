import {
  ValuationBarCodePromptActions,
  ValuationBarCodePropmtActionTypes,
} from './ValuationBarCodePromptAction';

export default class ValuationBarCodePromptReducer {
  public static initialState = {
    valuatedCount: 0,
    waitingValuationCount: 0,
    waitingRevaluationCount: 0,
  };

  public static reducer(
    state = ValuationBarCodePromptReducer.initialState,
    action: ValuationBarCodePromptActions
  ): typeof state {
    switch (action.type) {
      case ValuationBarCodePropmtActionTypes.VALUATED_COUNT_SUCCESS:
        return {
          ...state,
          valuatedCount: action.payload.count,
        };

      case ValuationBarCodePropmtActionTypes.WAITING_VALUATION_COUNT_SUCCESS:
        return {
          ...state,
          waitingValuationCount: action.payload.count,
        };

      case ValuationBarCodePropmtActionTypes.WAITING_REVALUATION_COUNT_SUCCESS:
        return {
          ...state,
          waitingRevaluationCount: action.payload.count,
        };

      default:
        return state;
    }
  }
}
