export interface ValuatedReportUserData {
  name: string;
  auCount: number;
  totalCount: number;
  outCount: number;
  out2WCount: number;
  cancelledCount: number;
}

export default class ValuatedReportUser {
  private user: ValuatedReportUserData;

  constructor(name: string) {
    this.user = {
      name,
      auCount: 0,
      totalCount: 0,
      outCount: 0,
      out2WCount: 0,
      cancelledCount: 0,
    };
  }

  public getUser() {
    return this.user;
  }

  public incrementGoldCount() {
    this.user.auCount += 1;
  }

  public incrementTotalCount() {
    this.user.totalCount += 1;
  }

  public incrementOutCount() {
    this.user.outCount += 1;
  }

  public incrementOut2WCount() {
    this.user.out2WCount += 1;
  }

  public incrementCancelledCount() {
    this.user.cancelledCount += 1;
  }
}
