import PackageCodeRegistry from './PackageCodeRegistry';

export default class PackageCodeRegistriesSelector {
  public static selectPackageCodeRegistry(state: ReduxState): PackageCodeRegistry {
    return state.packageCodeRegistry.model.data;
  }

  public static selectPackageCodeRegistries(state: ReduxState): PackageCodeRegistry[] {
    return state.packageCodeRegistry.list.data;
  }

  public static selectPackageCodeRegistryIsLoading(state: ReduxState): boolean {
    return state.packageCodeRegistry.model.isLoading;
  }

  public static selectPackageCodeRegistriesIsLoading(state: ReduxState): boolean {
    return state.packageCodeRegistry.list.isFetchLoading;
  }

  public static selectPackageCodeRegistriesCount(state: ReduxState): number {
    return state.packageCodeRegistry.list.count;
  }
}
