import { reducerCreator } from 'redux-rest-helper-for-loopback';
import CallLogsAction from './CallLogsAction';

export default class CallLogsReducer {
  public static initialModel = {
    id: '',
    type: 'WELCOME_CALL',
    memberId: '',
    orderId: '',
    message: '',
  };

  public static reducer = reducerCreator(
    CallLogsAction.ENTRY,
    CallLogsReducer.initialModel
  ).getReducer();
}
