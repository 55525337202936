import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import PackageCodeRegistry from './PackageCodeRegistry';

export default class PackageCodeRegistriesEffect {
  public static restRequests = loopbackRequestCreator<PackageCodeRegistry>(
    apis.PACKAGE_CODE_REGISTRY
  );

  public static findById = PackageCodeRegistriesEffect.restRequests.getById;

  public static find = PackageCodeRegistriesEffect.restRequests.getAll;

  public static count = PackageCodeRegistriesEffect.restRequests.count;
}
