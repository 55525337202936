import { clientFormInitValue } from '../../forms/ClientForm';
import { NewOrdersActions, NewOrdersActionsType } from './NewOrdersActions';

export default class NewOrdersReducer {
  public static initialState = {
    client: clientFormInitValue,
    isLoading: false,
    isPhoneSearched: false,
    isWebOrder: false,
  };

  public static reducer(
    state = NewOrdersReducer.initialState,
    action: NewOrdersActions
  ): typeof state {
    switch (action.type) {
      case NewOrdersActionsType.SET_LOADING:
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };

      case NewOrdersActionsType.SET_CLIENT:
        return {
          ...state,
          client: action.payload.client,
        };

      case NewOrdersActionsType.RESET:
        return NewOrdersReducer.initialState;

      case NewOrdersActionsType.PHONE_SEARCHED:
        return {
          ...state,
          isPhoneSearched: true,
        };

      case NewOrdersActionsType.SET_WEB_ORDER:
        return {
          ...state,
          isWebOrder: action.payload.isWebOrder,
        };

      default:
        return state;
    }
  }
}
