import _ from 'lodash';
import moment from 'moment';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { Country } from '../../classes/Country';
import { Language } from '../../classes/Language';
import { Team } from '../../classes/Team';
import {
  CallTransferSearchBody,
  CallTransferSearchType,
} from '../../forms/call-transfer-search/CallTransferSearchInitialValues';
import { CancelCallTransferSearchFormBody } from '../../forms/cancel-call-transfer-search/CancelCallTransferSearchInitialValues';
import { User } from '../user/User';
import { Order } from './Order';

export default class OrderQuery {
  public static include: any = [
    'client',
    'valuator',
    'caller',
    'bursar',
    'teller',
    'packager',
    'closer',
    'receiver',
    'payer',
    'originalCaller',
    'assignedCaller',
    'coldCaseCaller',
    {
      relation: 'logs',
      scope: {
        include: ['member'],
        order: 'createdAt DESC',
      },
    },
    {
      relation: 'welcomeCallLogs',
      scope: {
        where: { type: 'WELCOME_CALL' },
        include: ['member'],
        order: 'createdAt DESC',
      },
    },
    {
      relation: 'afterCallLogs',
      scope: {
        where: { type: 'AFTER_CALL' },
        include: ['member'],
        order: 'createdAt DESC',
      },
    },
    {
      relation: 'coldCallLogs',
      scope: {
        where: { type: 'COLD_CALL' },
        include: ['member'],
        order: 'createdAt DESC',
      },
    },
  ];

  public static onlyInclude = {
    include: OrderQuery.include,
  };

  public static closeFilter = {
    closed: false,
  };

  public static getBuyerWelcomeCallIndex(user: User) {
    const basicQuery = {
      ...this.closeFilter,
      isReceived: false,
      welcomeCallDone: false,
      welcomeCall: { lte: moment().endOf('day') },
      createdAt: {
        gt: moment()
          .subtract(45, 'day')
          .startOf('day'),
      },
    };
    return {
      include: this.include,
      where: {
        or: [
          {
            ...basicQuery,
            callerId: user.id,
          },
          {
            ...basicQuery,
            assignedCallerId: user.id,
          },
        ],
      },
    };
  }

  public static getColdCaseIndex(language: string, team?: string) {
    const basicQuery = {
      ...this.closeFilter,
      isReceived: false,
      language,
      createdAt: {
        lt: moment()
          .subtract(45, 'day')
          .startOf('day'),
      },
    };
    if (team) _.set(basicQuery, 'team', team);
    return {
      include: this.include,
      where: basicQuery,
    };
  }

  public static getPayerQuery(country: string) {
    return {
      where: {
        ...this.closeFilter,
        isTellered: true,
        isPaid: false,
        offerAccepted: true,
        isDonation: false,
        isBoomerang: false,
        country,
        offeredDeclined: false,
      },
      include: this.include,
    };
  }

  public static getDonationQuery(team: string) {
    return {
      where: {
        team,
        ...this.closeFilter,
        isTellered: true,
        isPaid: false,
        isDonation: true,
        isBoomerang: false,
      },
      include: this.include,
    };
  }

  public static getBursarHandledQuery() {
    return {
      where: {
        and: [{ bursarHandleDate: { gt: moment().startOf('day') } }, { isComplete: true }],
      },
      include: this.include,
    };
  }

  public static getBursarUnhandledQuery() {
    return {
      where: {
        or: [
          {
            isComplete: false,
            isPaid: true,
            isTellered: true,
          },
          {
            isComplete: false,
            isPaid: false,
            offeredDeclined: true,
            isTellered: true,
          },
          {
            isComplete: false,
            isPaid: false,
            isDonation: true,
            isTellered: true,
          },
        ],
      },
      include: this.include,
    };
  }

  public static getBuyerAfterCallIndexQuery(user: User) {
    const basicQuery = {
      ...this.closeFilter,
      isReceived: false,
      // TODO: Change this to false after 15.4.2020
      afterCallDone: { neq: true },
      dueDate: {
        lt: moment()
          .subtract(7, 'days')
          .endOf('day'),
      },
      createdAt: {
        gt: moment()
          .subtract(45, 'day')
          .startOf('day'),
      },
    };

    return {
      include: this.include,
      where: {
        or: [
          {
            ...basicQuery,
            callerId: user.id,
          },
          {
            ...basicQuery,
            assignedCallerId: user.id,
          },
        ],
      },
    };
  }

  public static getBuyerModifyIndex(user: User) {
    return {
      where: {
        ...this.closeFilter,
        callerId: user.id,
      },
      include: this.include,
    };
  }

  public static getBuyerMyOrderIndex(user: User) {
    return {
      include: this.include,
      where: {
        and: [{ ...this.closeFilter }, { callerId: user.id }],
      },
    };
  }

  public static getPackagerWaitingList() {
    return {
      include: this.include,
      where: {
        ...this.closeFilter,
        isPackaged: false,
      },
    };
  }

  private static getPackagerParams(language: string) {
    return {
      ...this.closeFilter,
      language,
      isPackaged: false,
      isShipmentRegistered: true,
      inPackager: false,
    };
  }

  private static getPackagerLockedParams(user: User, language: string) {
    return {
      ...this.getPackagerParams(language),
      packagerId: user.id,
      inPackager: true,
    };
  }

  public static getPackagerQuery(language: string) {
    return {
      where: this.getPackagerParams(language),
      include: this.include,
      order: 'orderNumber ASC',
    };
  }

  public static getPackagerLockedQuery(user: User, language: string) {
    return {
      where: this.getPackagerLockedParams(user, language),
      include: this.include,
      order: 'orderNumber ASC',
    };
  }

  public static getPackagerAllQuery(user: User, language: string, limit?: number) {
    return {
      limit,
      include: this.include,
      where: {
        or: [this.getPackagerLockedParams(user, language), this.getPackagerParams(language)],
      },
    };
  }

  public static getPackagerHistoryQuery() {
    return {
      include: this.include,
      where: {
        isPackaged: true,
      },
    };
  }

  public static getPackagedTodayQuery(language: Language) {
    return {
      include: this.include,
      where: {
        and: [
          {
            language,
          },
          {
            isPackaged: true,
          },
          {
            packagedDate: {
              lt: moment().endOf('day'),
            },
          },
          {
            packagedDate: {
              gt: moment().startOf('day'),
            },
          },
        ],
      },
    };
  }

  public static getReceiverTodayReceivedQuery() {
    return {
      where: {
        receivedDate: { gt: moment().startOf('day') },
      },
    };
  }

  public static getReceiverTodayReceivedByTeamQuery(team: Team) {
    return {
      where: {
        receivedDate: { gt: moment().startOf('day') },
        team,
      },
    };
  }

  public static getTellerSMSQuery(language: string) {
    return {
      include: this.include,
      where: {
        ...this.closeFilter,
        arrivedSmsSent: false,
        language,
        isReceived: true,
        returnToValuation: false,
        isTellered: false,
      },
    };
  }

  public static getTellerSMSLogQuery() {
    return {
      include: this.include,
      where: {
        arrivedSmsSent: true,
      },
    };
  }

  public static getValuationWaitingReValuationQuery() {
    return {
      where: {
        ...this.closeFilter,
        returnToValuation: true,
      },
      include: this.include,
    };
  }

  public static getValuationWaitingReValuationFinnishQuery() {
    const query: LoopbackFilter = this.getValuationWaitingReValuationQuery();
    query.where.country = Country.FINLAND;
    return query;
  }

  public static getValuationWaitingReValuationSwedishQuery() {
    const query = this.getValuationWaitingReValuationFinnishQuery();
    query.where.country = Country.SWEDEN;
    return query;
  }

  public static getValuationWaitingReValuationUKQuery() {
    const query = this.getValuationWaitingReValuationFinnishQuery();
    query.where.country = Country.UK;
    return query;
  }

  public static getValuationWaitingValuationQuery() {
    return {
      where: {
        ...this.closeFilter,
        isReceived: true,
        isValued: false,
      },
    };
  }

  public static getValuationTodayValuatedQuery() {
    return {
      where: {
        ...this.closeFilter,
        valuationDate: { gt: moment().startOf('day') },
      },
    };
  }

  public static getUnvaluatedQuery(country: Country) {
    return {
      where: {
        ...this.closeFilter,
        isReceived: true,
        isValued: false,
        country,
      },
    };
  }

  // public static getCallTransferCancelSearchQuery(body: CallTransferSearchBody) {
  //   const extraField: Partial<Order> = {};
  //   if (body.type === CallTransferSearchType.WELCOME_CALL) extraField.welcomeCallDone = false;
  //   return {
  //     include: this.include,
  //     where: {
  //       and: [
  //         {
  //           ...this.closeFilter,
  //           ...extraField,
  //           originalCallerId: body.callerId,
  //           isReceived: false,
  //         },
  //         {
  //           [body.type]: { gt: body.startDate },
  //         },
  //         {
  //           [body.type]: { lt: body.endDate },
  //         },
  //       ],
  //     },
  //   };
  // }

  public static getCallTransferSearchQuery(body: CallTransferSearchBody) {
    const extraField: Partial<Order> = {};
    let { startDate, endDate } = body;

    if (body.type === CallTransferSearchType.WELCOME_CALL) extraField.welcomeCallDone = false;

    if (body.type === CallTransferSearchType.AFTER_CALL) {
      startDate = moment(startDate)
        .subtract(7, 'days')
        .toDate();

      endDate = moment(endDate)
        .subtract(7, 'days')
        .toDate();

      extraField.afterCallDone = false;
    }

    return {
      include: this.include,
      where: {
        and: [
          {
            ...this.closeFilter,
            ...extraField,
            isPermanentlyTransferred: false,
            isTemporarilyTransferred: false,
            isReceived: false,
            callerId: body.callerId,
          },

          {
            [body.type]: { gt: startDate },
          },
          {
            [body.type]: { lt: endDate },
          },
        ],
      },
    };
  }

  public static getCancelCallTransferSearchQuery(body: CancelCallTransferSearchFormBody) {
    const base = {
      ...this.closeFilter,
      isReceived: false,
    };
    return {
      include: this.include,
      where: {
        or: [
          {
            ...base,
            callerId: body.callerId,
            isTemporarilyTransferred: true,
          },
          {
            ...base,
            isPermanentlyTransferred: true,
            originalCallerId: body.callerId,
          },
        ],
      },
    };
  }

  public static getWaitingValuationQuery() {
    return {
      include: this.include,
      where: {
        ...this.closeFilter,
        ...this.getValuationWaitingValuationQuery().where,
      },
    };
  }
}
