import { BursarsActionObject, BursarsActionTypes } from './BursarsAction';

export default class BursarsReducer {
  private static initState = {
    requireReturn: false,
  };

  public static reducer(
    state = BursarsReducer.initState,
    action: BursarsActionObject
  ): typeof state {
    switch (action.type) {
      case BursarsActionTypes.VIEW_DID_MOUNT:
        return {
          ...state,
          requireReturn: action.payload.requireReturn,
        };

      default:
        return state;
    }
  }
}
