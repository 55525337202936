import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardTitle, Details } from 'releox-react';
import withQZ, { Print } from '../../HOC/withQZ';
import { Language } from '../../classes/Language';
import Printer from '../../classes/Printer';
import OrderColumn from '../../columns/OrderColumn';
import BarCodePrompt, { BarCodePromptBody } from '../../components/BarCodePrompt';
import routes from '../../configs/routes';
import ClientForm, { ClientValidationSchema } from '../../forms/ClientForm';
import { usePrintPackage } from '../../hooks/packager/use-print-package';
import { useSendButtonState } from '../../hooks/packager/use-send-button-state';
import {
  usePackagerAddBarcodeMutation,
  usePackagerOrderQuery,
  usePackagerUpdateClientMutation,
} from '../../react-query-hooks/packager/packager-query-hooks';
import { Client } from '../../store/client/Client';
import RednotAction from '../../store/rednot/RednotAction';

const NoMore = () => (
  <Card>
    <CardTitle>No more orders</CardTitle>
    <Link to={routes.PACKAGER.PACKAGER_LANGUAGE_SELECT}>Back to package page</Link>
  </Card>
);

interface Props {
  print: Print;
}

const PackagerSystemUK = ({ print }: Props) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<string[]>([]);
  const orderQuery = usePackagerOrderQuery(Language.UK_ENGLISH);
  const { data, isLoading: isPackagerQueryLoading, refetch } = orderQuery;
  const { mutate: updateBarcode } = usePackagerAddBarcodeMutation({
    onSuccess: () => {
      refetch();
    },
    onError: (e) => {
      dispatch(RednotAction.error(e));
    },
  });

  const { isLoading: isClientUpdateLoading, mutate: updateUser } = usePackagerUpdateClientMutation({
    onComplete: () => {
      refetch();
    },
  });

  const { isPrintLoading, printPackage } = usePrintPackage({
    logger: (msg) => setMessages((msgs) => [...msgs, msg]),
    print,
    onComplete: () => {
      setMessages([]);
      refetch();
    },
  });

  const send = useCallback(() => {
    if (!data) return;
    printPackage(data.order);
  }, [printPackage, data]);

  const updateClient = useCallback(
    (body: Client) => {
      updateUser({
        id: body.id,
        form: body,
      });
    },
    [updateUser]
  );

  const handleBarcode = (body: BarCodePromptBody) => {
    if (!body.barCode) return;
    updateBarcode({
      barCode: body.barCode,
      orderId: data?.order.id ?? 'BAR_UNKNOWN_ID',
    });
  };

  const buttonState = useSendButtonState({
    barCode: _.get(data, 'order.barCode', undefined),
    isSending: isPrintLoading,
    isClientUpdating: isClientUpdateLoading,
    isOrderInvalid: false,
  });

  if (isPackagerQueryLoading) return <span />;

  if (data?.isLast) return <NoMore />;

  if (!data) {
    return (
      <Card>
        <CardTitle>
          There was an error fetching the order. Please try again or contact support if the problem
          persists.
        </CardTitle>
      </Card>
    );
  }

  const { order } = data;

  if (!order.barCode) {
    return (
      <BarCodePrompt
        title="Add a barcode to the order before sending it"
        onSubmit={handleBarcode}
      />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-5 offset-sm-2">
        <Card>
          <>
            <CardTitle>Package order</CardTitle>
            <Details
              properties={[
                OrderColumn.orderNumber,
                OrderColumn.dueDate,
                OrderColumn.welcomeCall,
                OrderColumn.phone,
                OrderColumn.language,
                OrderColumn.clientName,
                OrderColumn.address,
                OrderColumn.generalMessage,
              ]}
              object={order}
            />

            {order.packagerMessageFromBuyer ? (
              <div className="alert alert-danger my-3">
                <b>{order.packagerMessageFromBuyer}</b>
              </div>
            ) : (
              ''
            )}

            <hr />
            <Link to={routes.PACKAGER.PACKAGER_LANGUAGE_SELECT} className="btn btn-primary">
              Back
            </Link>
            <Button
              id="button"
              className="float-right"
              onClick={send}
              disabledText={buttonState.text}
              disabled={buttonState.isDisabled}
            >
              Send
            </Button>
          </>
        </Card>
        <Card key={JSON.stringify(order.client)}>
          <details>
            <summary>Edit client information</summary>
            {order.client ? (
              <Formik
                initialValues={data?.order.client}
                validateSchema={ClientValidationSchema}
                onSubmit={updateClient}
              >
                <Form>
                  <ClientForm />
                  <Button id="update-button" className="float-right" type="submit">
                    Update
                  </Button>
                </Form>
              </Formik>
            ) : (
              'Missing client population'
            )}
          </details>
        </Card>
      </div>
      <div className="col-sm-3">
        <Card>
          <>
            <CardTitle>Log</CardTitle>
            {messages.map((m) => (
              <span key={m} style={{ display: 'block', marginBottom: '5px' }}>
                {m}
              </span>
            ))}
          </>
        </Card>
      </div>
    </div>
  );
};

export default withQZ(PackagerSystemUK, Printer.PACKAGER);
