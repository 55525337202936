import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import { getErrorMessage } from 'releox-react';
import { Language } from '../../classes/Language';
import { ActionWPayload, GetState, ReduxDispatch } from '../Action';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

export enum PackagerStatsActionTypes {
  WAITING_FI = '@PACKAGER_STATS:WAITING_FI',
  WAITING_SE = '@PACKAGER_STATS:WAITING_SE',
  WAITING_FIN_SE = '@PACKAGER_STATS:WAITING_FIN_SE',
  WAITING_UK = '@PACKAGER_STATS:WAITING_UK',
  DONE_FI = '@PACKAGER_STATS:DONE_FI',
  DONE_SE = '@PACKAGER_STATS:DONE_SE',
  DONE_FIN_SE = '@PACKAGER_STATS:DONE_FIN_SE',
  DONE_UK = '@PACKAGER_STATS:DONE_UK',
}

type CountBody = { count: number };

type WaitingFiReturn = ActionWPayload<PackagerStatsActionTypes.WAITING_FI, CountBody>;
type WaitingSeReturn = ActionWPayload<PackagerStatsActionTypes.WAITING_SE, CountBody>;
type WaitingFinSeReturn = ActionWPayload<PackagerStatsActionTypes.WAITING_FIN_SE, CountBody>;
type WaitingUkReturn = ActionWPayload<PackagerStatsActionTypes.WAITING_UK, CountBody>;
type DoneFiReturn = ActionWPayload<PackagerStatsActionTypes.DONE_FI, CountBody>;
type DoneSeReturn = ActionWPayload<PackagerStatsActionTypes.DONE_SE, CountBody>;
type DoneFinSeReturn = ActionWPayload<PackagerStatsActionTypes.DONE_FIN_SE, CountBody>;
type DoneUkReturn = ActionWPayload<PackagerStatsActionTypes.DONE_UK, CountBody>;

export type PackagerStatsActionObject =
  | WaitingFiReturn
  | WaitingSeReturn
  | WaitingFinSeReturn
  | WaitingUkReturn
  | DoneFiReturn
  | DoneSeReturn
  | DoneFinSeReturn
  | DoneUkReturn;

export default class PackagerStatsAction {
  private static requestSuccess(actionType: PackagerStatsActionTypes, count: number) {
    return {
      type: actionType,
      payload: { count },
    };
  }

  private static makeRequest(query: LoopbackFilter, actionType: PackagerStatsActionTypes) {
    return (dispatch: ReduxDispatch) => {
      OrdersEffect.count(query)
        .then((r) => {
          dispatch(PackagerStatsAction.requestSuccess(actionType, r.count));
        })
        .catch((e) => {
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }

  public static fetchStats() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;

      const wFiQuery = OrderQuery.getPackagerAllQuery(user, Language.FINNISH);
      const wSeQuery = OrderQuery.getPackagerAllQuery(user, Language.SWEDISH);
      const wFSeQuery = OrderQuery.getPackagerAllQuery(user, Language.FINLAND_SWEDISH);
      const wUkQuery = OrderQuery.getPackagerAllQuery(user, Language.UK_ENGLISH);

      const dFiQuery = OrderQuery.getPackagedTodayQuery(Language.FINNISH);
      const dSeQuery = OrderQuery.getPackagedTodayQuery(Language.SWEDISH);
      const dFSeQuery = OrderQuery.getPackagedTodayQuery(Language.FINLAND_SWEDISH);
      const dUkQuery = OrderQuery.getPackagedTodayQuery(Language.UK_ENGLISH);

      dispatch(PackagerStatsAction.makeRequest(wFiQuery, PackagerStatsActionTypes.WAITING_FI));
      dispatch(PackagerStatsAction.makeRequest(wFSeQuery, PackagerStatsActionTypes.WAITING_FIN_SE));
      dispatch(PackagerStatsAction.makeRequest(wSeQuery, PackagerStatsActionTypes.WAITING_SE));
      dispatch(PackagerStatsAction.makeRequest(wUkQuery, PackagerStatsActionTypes.WAITING_UK));

      dispatch(PackagerStatsAction.makeRequest(dFiQuery, PackagerStatsActionTypes.DONE_FI));
      dispatch(PackagerStatsAction.makeRequest(dFSeQuery, PackagerStatsActionTypes.DONE_FIN_SE));
      dispatch(PackagerStatsAction.makeRequest(dSeQuery, PackagerStatsActionTypes.DONE_SE));
      dispatch(PackagerStatsAction.makeRequest(dUkQuery, PackagerStatsActionTypes.DONE_UK));
    };
  }
}
