import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CheckBox } from 'releox-react';
import { OrderColumnInitialValueType } from '../forms/order-columns/OrderColumnInitialValues';
import ColumnsAction from '../store/column/ColumnsAction';
import ColumnsSelector from '../store/column/ColumnsSelector';

export default () => {
  const dispatch = useDispatch();
  const initValues = useSelector(ColumnsSelector.selectAdminOrderCheckboxes);
  const onSubmit = useCallback(
    (body: OrderColumnInitialValueType) => {
      dispatch(ColumnsAction.onOrderSubmit(body));
    },
    [dispatch]
  );

  return (
    <Card>
      <details>
        <summary>
          <strong>Edit columns</strong>
        </summary>
        <Formik initialValues={initValues} onSubmit={onSubmit}>
          <Form>
            <div className="row">
              <div className="col-4">
                <CheckBox name="status" label="status" />
                <CheckBox name="clientName" label="clientName" />
                <CheckBox name="barCode" label="barCode" />
                <CheckBox name="country" label="country" />
                <CheckBox name="language" label="language" />
                <CheckBox name="createdAt" label="createdAt" />
                <CheckBox name="updatedAt" label="updatedAt" />
                <CheckBox name="phone" label="phone" />
                <CheckBox name="afterCallLatest" label="afterCallLatest" />
                <CheckBox name="welcomeCallLatest" label="welcomeCallLatest" />
                <CheckBox name="team" label="team" />
                <CheckBox name="letter" label="letter" />
              </div>
              <div className="col-4">
                <CheckBox name="dueDate" label="dueDate" />
                <CheckBox name="callerName" label="callerName" />
                <CheckBox name="packagerName" label="packagerName" />
                <CheckBox name="packagedDate" label="packagedDate" />
                <CheckBox name="receiverName" label="receiverName" />
                <CheckBox name="receivedDate" label="receivedDate" />
                <CheckBox name="valuatorName" label="valuatorName" />
                <CheckBox name="valuationDate" label="valuatorName" />
                <CheckBox name="tellerName" label="tellerName" />
                <CheckBox name="tellerDate" label="tellerDate" />
                <CheckBox name="payerName" label="payerName" />
                <CheckBox name="payedDate" label="payedDate" />
                <CheckBox name="bursarName" label="bursarName" />
                <CheckBox name="bursarHandleDate" label="bursarHandleDate" />
                <CheckBox name="invoice" label="invoice" />
              </div>
              <div className="col-4">
                <CheckBox name="barCodePrint" label="barCodePrint" />
                <CheckBox name="offer" label="offer" />
                <CheckBox name="tellerCallLatest" label="tellerCallLatest" />
                <CheckBox name="closerName" label="closerName" />
                <CheckBox name="welcomeCall" label="welcomeCall" />
                <CheckBox name="afterCallTries" label="afterCallTries" />
                <CheckBox name="address" label="address" />
                <CheckBox name="packageWeight" label="packageWeight" />
                <CheckBox name="materialWeight" label="materialWeight" />
                <CheckBox name="silverWeight" label="silverWeight" />
                <CheckBox name="goldWeight" label="goldWeight" />
                <CheckBox name="closeReason" label="closeReason" />
                <CheckBox name="closeDate" label="closeDate" />
                <CheckBox name="closeMethod" label="closeMethod" />
              </div>
            </div>
            <Button className="btn-sm" id="set" type="submit">
              Change columns
            </Button>
          </Form>
        </Formik>
      </details>
    </Card>
  );
};
