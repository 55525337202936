import { Product } from '../../../store/order/Order';

export default class AcceptedReportProduct {
  private product: Product;

  constructor(product: Product) {
    this.product = product;
  }

  public getProduct(): Product {
    return this.product;
  }

  public getWeight(): number {
    if (typeof this.product.weight === 'string') {
      return parseFloat(this.product.weight);
    }
    return this.product.weight;
  }

  public getDensity(): string {
    return this.product.density;
  }

  public getDensityName(): string {
    return this.product.densityName;
  }

  public isGold(): boolean {
    return this.product.category === 'gold';
  }

  public isCustomGold(): boolean {
    return this.product.density === 'customGold';
  }

  public getGoldKey(): 'gold' | 'customGold' {
    return this.product.category === 'gold' ? 'gold' : 'customGold';
  }
}
