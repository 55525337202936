import Axios from 'axios';
import apis from '../../configs/apis';
import { CCManagerReportBody } from '../../forms/ccmanager-report-search/CcmanagerReportSearchInitialValues';
import { CCManagerReportsData } from './CCManagerReportData';

export default class CcManagerReportsEffect {
  static getData(body: CCManagerReportBody): Promise<CCManagerReportsData> {
    const start = body.startDate.toString();
    const end = body.endDate.toString();
    const userId = body.callerId;

    let url = apis.CC_MANAGER_REPORT;
    url = url.replace(':start', start);
    url = url.replace(':end', end);
    url = url.replace(':userId', userId);

    return new Promise((resolve, reject) => {
      Axios.get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  }
}
