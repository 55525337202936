import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'releox-react';
import CancelCallTransferSearchForm from '../../forms/cancel-call-transfer-search/CancelCallTransferSearchForm';
import CancelCallTransferSearchInitialValues, {
  CancelCallTransferSearchFormBody,
} from '../../forms/cancel-call-transfer-search/CancelCallTransferSearchInitialValues';
import CancelCallTransferSearchValidationSchema from '../../forms/cancel-call-transfer-search/CancelCallTransferSearchValidationSchema';
import CancelCallTransferForm from '../../forms/cancel-call-transfer/CancelCallTransferForm';
import {
  CancelTransferFormBody,
  getCancelTransferInitialValues,
} from '../../forms/cancel-call-transfer/CancelCallTransferInitialValues';
import CancelCallTransfersAction from '../../store/cancel-call-transfer/CancelCallTransfersAction';
import CancelCallTransfersSelector from '../../store/cancel-call-transfer/CancelCallTransfersSelector';

export default () => {
  const dispatch = useDispatch();
  const cancelTransferField = useSelector(CancelCallTransfersSelector.selectCancelTransferField);

  const onSearchSubmit = useCallback(
    (body: CancelCallTransferSearchFormBody) => {
      dispatch(CancelCallTransfersAction.searchOrders(body));
    },
    [dispatch]
  );

  const onCancelSubmit = useCallback(
    (body: CancelTransferFormBody) => {
      dispatch(CancelCallTransfersAction.cancelTransfers(body));
    },
    [dispatch]
  );

  return (
    <div className="row">
      <div className="col-lg-2">
        <Card>
          <Formik
            onSubmit={onSearchSubmit}
            validationSchema={CancelCallTransferSearchValidationSchema}
            initialValues={CancelCallTransferSearchInitialValues}
          >
            <Form>
              <CancelCallTransferSearchForm />
              <Button id="submit" type="submit" className="btn-block">
                Search
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      <div className="col-lg-10">
        <Card key={JSON.stringify(cancelTransferField)}>
          <Formik
            onSubmit={onCancelSubmit}
            initialValues={getCancelTransferInitialValues(cancelTransferField)}
          >
            <Form>
              <CancelCallTransferForm />
              <Button id="submit-transfer" className="float-right" type="submit">
                Cancel transfer
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  );
};
