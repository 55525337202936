import React from 'react';
import { GenericEditForm } from 'releox-react';
import TeamForm, { teamFormInitValues, TeamValidationSchema } from '../../../forms/TeamForm';
import TeamsAction from '../../../store/team/TeamsAction';
import TeamsSelector from '../../../store/team/TeamsSelector';

export default () => (
  <GenericEditForm
    dataSelector={TeamsSelector.selectMember}
    loadingSelector={TeamsSelector.selectMemberIsLoading}
    tNamespace="TeamEditScene"
    EmbedForm={TeamForm(false)}
    initialValues={teamFormInitValues}
    onSubmit={TeamsAction.update}
    validationSchema={TeamValidationSchema(['password'])}
    onFetch={TeamsAction.fetch}
  />
);
