import React from 'react';
import { useSelector } from 'react-redux';
import { Details } from 'releox-react';
import OrderColumn from '../columns/OrderColumn';
import OrdersSelector from '../store/order/OrdersSelector';

export default () => {
  const order = useSelector(OrdersSelector.selectOrder);
  return (
    <Details
      object={order}
      properties={[
        OrderColumn.orderNumber,
        OrderColumn.clientName,
        OrderColumn.phone,
        OrderColumn.address,
        OrderColumn.createdAt,
        OrderColumn.receivedDate,
        { dataField: 'generalMessage', text: 'General message' },
        { dataField: 'valuationMessageFromBuyer', text: 'Valuation message from buyer' },
        { dataField: 'revaluationMessageFromTeller', text: 'Revaluation message from teller' },
      ]}
    />
  );
};
