import { MaterialObject } from './Payer';

export default class PayersSelector {
  public static selectMaterialsObject(state: ReduxState): MaterialObject {
    return state.payer.materialsObject;
  }

  static selectTeam(state: ReduxState): string {
    return state.payer.team;
  }
}
