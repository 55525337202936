interface ReportUserContainerData<T> {
  [id: string]: T;
}

export default abstract class ReportUserContainer<T> {
  protected users: ReportUserContainerData<T> = {};

  public hasUser(userId: string) {
    return this.users[userId] !== undefined;
  }

  public addUser(callerId: string, userInstance: T) {
    if (!this.users[callerId]) {
      this.users[callerId] = userInstance;
    }
  }

  public getUser(id: string): T {
    return this.users[id];
  }
}
