import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import { User } from './User';

export enum UsersActionType {
  SET_USER = '@USER:SET_USER',
}

export default class UsersAction {
  private static setUserToSentry(user: User) {
    return (scope: Sentry.Scope) => scope.setUser(user as any);
  }

  public static setUserAction(user: User) {
    Sentry.configureScope(this.setUserToSentry(user));
    ReactGA.set(user);

    return {
      type: UsersActionType.SET_USER,
      payload: user,
    };
  }
}
