import merge from 'deepmerge';
import moment from 'moment';
import { CountResponse } from 'redux-rest-helper-for-loopback';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import OrdersEffect from '../order/OrdersEffect';
import { User } from '../user/User';

export enum BuyerStatsActionTypes {
  FETCH_OUT_REQUEST = '@BUYER_STATS:FETCH_OUT_REQUEST',
  FETCH_OUT_SUCCESS = '@BUYER_STATS:FETCH_OUT_SUCCESS',
  FETCH_IN_REQUEST = '@BUYER_STATS:FETCH_IN_REQUEST',
  FETCH_IN_SUCCESS = '@BUYER_STATS:FETCH_IN_SUCCESS',
  FETCH_CANCEL_REQUEST = '@BUYER_STATS:FETCH_CANCEL_REQUEST',
  FETCH_CANCEL_SUCCESS = '@BUYER_STATS:FETCH_CANCEL_SUCCESS',
}

type OutRequest = Action<BuyerStatsActionTypes.FETCH_OUT_REQUEST>;
type OutSuccess = ActionWPayload<BuyerStatsActionTypes.FETCH_OUT_SUCCESS, Counts>;
type InRequest = Action<BuyerStatsActionTypes.FETCH_IN_REQUEST>;
type InSuccess = ActionWPayload<BuyerStatsActionTypes.FETCH_IN_SUCCESS, Counts>;
type CancelRequest = Action<BuyerStatsActionTypes.FETCH_CANCEL_REQUEST>;
type CancelSuccess = ActionWPayload<BuyerStatsActionTypes.FETCH_CANCEL_SUCCESS, Counts>;

export type BuyerStatsActions =
  | OutRequest
  | OutSuccess
  | InRequest
  | InSuccess
  | CancelRequest
  | CancelSuccess;

interface Counts {
  teamToday: number;
  teamMonth: number;
  yourToday: number;
  yourMonth: number;
}

export default class BuyerStatsAction {
  private static getYourQuery(user: User) {
    return { callerId: user.id };
  }

  private static getTeamQuery(user: User) {
    return { team: user.team };
  }

  private static getMonth() {
    return moment()
      .startOf('month')
      .toString();
  }

  private static getDay() {
    return moment()
      .startOf('day')
      .toString();
  }

  private static getWhere(query: any) {
    return { where: query };
  }

  public static getOutQueries(user: User) {
    const yourQuery = this.getYourQuery(user);
    const teamQuery = this.getTeamQuery(user);
    const todayQuery = { createdAt: { gt: this.getDay() } };
    const monthQuery = { createdAt: { gt: this.getMonth() } };
    return {
      yourToday: this.getWhere({ ...yourQuery, ...todayQuery }),
      teamToday: this.getWhere({ ...teamQuery, ...todayQuery }),
      yourMonth: this.getWhere({ ...yourQuery, ...monthQuery }),
      teamMonth: this.getWhere({ ...teamQuery, ...monthQuery }),
    };
  }

  private static getInQuery(user: User) {
    const baseQuery = { isValued: true, goldWeight: { gt: 0 } };
    const yourQuery = this.getYourQuery(user);
    const teamQuery = this.getTeamQuery(user);
    const todayQuery = { valuationDate: { gt: this.getDay() } };
    const monthQuery = { valuationDate: { gt: this.getMonth() } };

    return {
      yourToday: this.getWhere(merge.all([yourQuery, todayQuery, baseQuery])),
      teamToday: this.getWhere(merge.all([teamQuery, todayQuery, baseQuery])),
      yourMonth: this.getWhere(merge.all([yourQuery, monthQuery, baseQuery])),
      teamMonth: this.getWhere(merge.all([teamQuery, monthQuery, baseQuery])),
    };
  }

  private static getCancelQuery(user: User) {
    const baseQuery = { closed: true };
    const yourQuery = this.getYourQuery(user);
    const teamQuery = this.getTeamQuery(user);
    const todayQuery = { closeDate: { gt: this.getDay() } };
    const monthQuery = { closeDate: { gt: this.getMonth() } };
    return {
      yourToday: this.getWhere(merge.all([yourQuery, todayQuery, baseQuery])),
      teamToday: this.getWhere(merge.all([teamQuery, todayQuery, baseQuery])),
      yourMonth: this.getWhere(merge.all([yourQuery, monthQuery, baseQuery])),
      teamMonth: this.getWhere(merge.all([teamQuery, monthQuery, baseQuery])),
    };
  }

  private static fetchOutSuccess(outCounts: Counts): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_OUT_SUCCESS,
      payload: outCounts,
    };
  }

  private static fetchOutRequest(): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_OUT_REQUEST,
    };
  }

  private static fetchInSuccess(outCounts: Counts): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_IN_SUCCESS,
      payload: outCounts,
    };
  }

  private static fetchInRequest(): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_IN_REQUEST,
    };
  }

  private static promiseArrayBuilder(object: Test) {
    return [
      OrdersEffect.count(object.teamToday),
      OrdersEffect.count(object.teamMonth),
      OrdersEffect.count(object.yourToday),
      OrdersEffect.count(object.yourMonth),
    ];
  }

  private static promiseHandler(
    promises: Promise<CountResponse>[],
    requestAction: () => BuyerStatsActions,
    successAction: (out: Counts) => BuyerStatsActions
  ) {
    return (dispatch: ReduxDispatch) => {
      dispatch(requestAction());
      Promise.all(promises).then((outResponses) => {
        const out = {
          teamToday: outResponses[0].count,
          teamMonth: outResponses[1].count,
          yourToday: outResponses[2].count,
          yourMonth: outResponses[3].count,
        };
        dispatch(successAction(out));
      });
    };
  }

  public static fetchOutData() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;
      const outQueries = this.getOutQueries(user);
      const outRequests = this.promiseArrayBuilder(outQueries);
      dispatch(this.promiseHandler(outRequests, this.fetchOutRequest, this.fetchOutSuccess));
    };
  }

  public static fetchInAuData() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;
      const inQueries = this.getInQuery(user);
      const inRequests = this.promiseArrayBuilder(inQueries);
      dispatch(this.promiseHandler(inRequests, this.fetchInRequest, this.fetchInSuccess));
    };
  }

  private static fetchCancelRequest(): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_CANCEL_REQUEST,
    };
  }

  private static fetchCancelSuccess(outCounts: Counts): BuyerStatsActions {
    return {
      type: BuyerStatsActionTypes.FETCH_CANCEL_SUCCESS,
      payload: outCounts,
    };
  }

  public static fetchCancelData() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;
      const inQueries = this.getCancelQuery(user);
      const inRequests = this.promiseArrayBuilder(inQueries);
      dispatch(this.promiseHandler(inRequests, this.fetchCancelRequest, this.fetchCancelSuccess));
    };
  }

  public static fetchData() {
    return (dispatch: ReduxDispatch) => {
      dispatch(this.fetchOutData());
      dispatch(this.fetchInAuData());
      dispatch(this.fetchCancelData());
    };
  }
}

type Test = ReturnType<typeof BuyerStatsAction.getOutQueries>;
