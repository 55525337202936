import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import { Material } from './Material';

export default class MaterialsEffect {
  public static restRequests = loopbackRequestCreator<Material>(apis.MATERIAL);

  public static findById = MaterialsEffect.restRequests.getById;

  public static find = MaterialsEffect.restRequests.getAll;

  public static create = MaterialsEffect.restRequests.create;

  public static update = MaterialsEffect.restRequests.update;

  public static delete = MaterialsEffect.restRequests.delete;

  public static count = MaterialsEffect.restRequests.count;
}
