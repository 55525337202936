export default class PackagerStatsSelector {
  public static selectWaitingFinnishPackageCount(state: ReduxState) {
    return state.packagerStats.waitingFinnishPackageCount;
  }

  public static selectWaitingFinlandSwedishPackageCount(state: ReduxState) {
    return state.packagerStats.waitingFinlandSwedishPackageCount;
  }

  public static selectWaitingSwedishPackageCount(state: ReduxState) {
    return state.packagerStats.waitingSwedishPackageCount;
  }

  public static selectDoneFinnishPackageCount(state: ReduxState) {
    return state.packagerStats.doneFinnishPackageCount;
  }

  public static selectDoneFinlandSwedishPackageCount(state: ReduxState) {
    return state.packagerStats.doneFinlandSwedishPackageCount;
  }

  public static selectDoneSwedishPackageCount(state: ReduxState) {
    return state.packagerStats.doneSwedishPackageCount;
  }

  public static selectWaitingUKPackageCount(state: ReduxState) {
    return state.packagerStats.waitingUKPackageCount;
  }

  public static selectDoneUKPackageCount(state: ReduxState) {
    return state.packagerStats.doneUKPackageCount;
  }
}
