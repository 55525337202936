import React, { useMemo } from 'react';
import { Select } from 'releox-react';
import DatePicker from '../../components/DatePicker';
import { teamOptions } from '../../options';
import { ReportTypes } from '../../store/report/ReportsAction';
import { getMinDate } from '../../utils/report-methods/report-min-date';

export default () => {
  const minDate = useMemo(() => getMinDate(), []);
  return (
    <>
      <DatePicker minDate={minDate} label="Start" name="date.gt" />
      <DatePicker minDate={minDate} label="End" name="date.lt" />
      <Select label="Team" name="params.team" options={teamOptions} />
      <Select
        label="Report type"
        name="reportType"
        options={[
          { value: ReportTypes.ACCEPTED_WEIGHTS, label: 'Buyer: Accepted offers' },
          { value: ReportTypes.VALUATED_ORDERS, label: 'Buyer: Valuated order' },
        ]}
      />
    </>
  );
};
