import React from 'react';
import { useSelector } from 'react-redux';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';
import SearchSelector from '../../store/search/SearchSelector';
import ChangeOrderColumns from '../ChangeOrderColumns';
import OrderSearchGuide from '../OrderSearchGuide';
import Search from '../Search';

// const defaultColumns = [
//   OrderColumn.orderNumberAdminLink,
//   OrderColumn.status,
//   OrderColumn.clientName,
//   OrderColumn.barCode,
//   OrderColumn.country,
//   OrderColumn.language,
//   OrderColumn.createdAt,
//   OrderColumn.updatedAt,
// ];

interface Props {
  columnSelector(state: ReduxState): DataTableColumn[];
  redirect?: string;
  showColumnEdit?: boolean;
}

export default ({ columnSelector, redirect, showColumnEdit = false }: Props) => {
  const where = useSelector(SearchSelector.selectOrderQuery);
  const columns = useSelector(columnSelector);

  return (
    <>
      <div className="row">
        <div className="col-7">
          <Search
            qSelector={SearchSelector.selectOrderQ}
            type="order"
            searchFields={['orderNumber', 'barCode', 'referenceNumber']}
            SearchGuide={OrderSearchGuide}
          />
        </div>
        {showColumnEdit ? (
          <div className="col-5">
            <ChangeOrderColumns />
          </div>
        ) : (
          ''
        )}
      </div>
      <GenericIndexScene
        key={JSON.stringify(where)}
        tNamespace="OrderIndex"
        loadingSelector={OrdersSelector.selectIsListLoading}
        dataSelector={OrdersSelector.selectOrders}
        dataSizeSelector={OrdersSelector.selectOrdersCount}
        onChangeLoopback={OrdersAction.list}
        columns={columns}
        query={{ include: OrderQuery.include, where }}
        defaultSorted={{ dataField: 'createdAt', order: 'desc' }}
        redirectUrl={redirect}
      />
    </>
  );
};
