import moment from 'moment';
import routes from '../../configs/routes';
import { GetState, ReduxDispatch } from '../Action';
import CallLog from '../call-log/CallLog';
import CallLogsAction from '../call-log/CallLogsAction';
import { Order } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import RednotAction from '../rednot/RednotAction';

export enum WelcomeCallOption {
  DONE = 'done',
  DOES_NOT_ANSWER = 'doesNotAnswer',
  CALL_AGAIN = 'callAgain',
  EMPTY = '',
}

export default class WelcomeCallAction {
  public static getInitialValues(order: Order) {
    return {
      welcomeCall: moment(order.welcomeCall).toDate(),
      dueDate: order.dueDate,
      event: WelcomeCallOption.EMPTY,
      generalMessage: order.generalMessage,
      valuationMessageFromBuyer: order.valuationMessageFromBuyer,
      tellerMessageFromBuyer: order.tellerMessageFromBuyer,
      logMessage: '',
    };
  }

  public static onSubmit(id: string, order: Order, body: WelcomeCallBody) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;

      const callLogBody: CallLog = {
        id: '',
        type: 'WELCOME_CALL',
        event: body.event,
        message: body.logMessage,
        memberId: user.id,
        orderId: order.id,
      };

      const reqBody: Partial<Order> = {
        latestWelcomeCall: new Date().toString(),
        welcomeCall: body.welcomeCall,
        welcomeCallDone: order.welcomeCallDone,
        dueDate: body.dueDate,
        generalMessage: body.generalMessage,
        valuationMessageFromBuyer: body.valuationMessageFromBuyer,
        tellerMessageFromBuyer: body.tellerMessageFromBuyer,
      };

      const createOpts = { redirect: routes.BUYER.WELCOME_CALLS };

      switch (body.event) {
        case WelcomeCallOption.CALL_AGAIN:
        case WelcomeCallOption.DOES_NOT_ANSWER:
          // TODO: Remove this line after 1.3.2020
          if (!reqBody.welcomeCallTried) reqBody.welcomeCallTried = order.welcomeCallTried || [];
          reqBody.welcomeCallTried.push(new Date());
          // END OF REMOVE
          dispatch(CallLogsAction.create(callLogBody));
          return dispatch(OrdersAction.update(id, reqBody, createOpts));

        case WelcomeCallOption.DONE:
          reqBody.welcomeCallDone = true;
          dispatch(CallLogsAction.create(callLogBody));
          return dispatch(OrdersAction.update(id, reqBody, createOpts));

        default:
          return dispatch(RednotAction.error('Please choose event'));
      }
    };
  }
}

export type WelcomeCallBody = ReturnType<typeof WelcomeCallAction.getInitialValues>;
