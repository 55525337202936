import { getErrorMessage } from 'releox-react';
import { Action, ReduxDispatch } from '../Action';
import OrdersAction from '../order/OrdersAction';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';
import GenerateOrderNumbersEffect from './GenerateOrderNumbersEffect';

export enum GenerateOrderNumbersActionTypes {
  REQUEST = '@GENERATE_ORDER_NUMBER:REQUEST',
  REQUEST_SUCCESS = '@GENERATE_ORDER_NUMBER:REQUEST_SUCCESS',
  REQUEST_FAIL = '@GENERATE_ORDER_NUMBER:REQUEST_FAIL',
}

type RequestReturn = Action<GenerateOrderNumbersActionTypes.REQUEST>;
type RequestSuccessReturn = Action<GenerateOrderNumbersActionTypes.REQUEST_SUCCESS>;
type RequestFailReturn = Action<GenerateOrderNumbersActionTypes.REQUEST_FAIL>;

export type GenerateOrderNumbersActionObject =
  | RequestReturn
  | RequestSuccessReturn
  | RequestFailReturn;

export default class GenerateOrderNumbersAction {
  private static request(): GenerateOrderNumbersActionObject {
    return {
      type: GenerateOrderNumbersActionTypes.REQUEST,
    };
  }

  private static requestSuccess(): GenerateOrderNumbersActionObject {
    return {
      type: GenerateOrderNumbersActionTypes.REQUEST_SUCCESS,
    };
  }

  private static requestFail(): GenerateOrderNumbersActionObject {
    return {
      type: GenerateOrderNumbersActionTypes.REQUEST_FAIL,
    };
  }

  public static generate(id: string) {
    return (dispatch: ReduxDispatch): void => {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (!confirm('Are you sure you want to change Order number?')) return;
      dispatch(GenerateOrderNumbersAction.request());
      GenerateOrderNumbersEffect.generate(id)
        .then(() => {
          dispatch(GenerateOrderNumbersAction.requestSuccess());
          dispatch(OrdersAction.fetch(id, { include: OrderQuery.include }));
        })
        .catch((e) => {
          dispatch(GenerateOrderNumbersAction.requestFail());
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }
}
