export default class ReportsSelector {
  public static selectAcceptedOffersReport(state: ReduxState) {
    return state.report.acceptedOffersReport;
  }

  public static selectValuatedOffersReport(state: ReduxState) {
    return state.report.valuatedOffersReport;
  }

  public static selectIsSearched(state: ReduxState) {
    return state.report.isSearched;
  }

  public static selectIsLoading(state: ReduxState) {
    return state.report.isLoading;
  }
}
