import { Material } from '../store/material/Material';
import { Product } from '../store/order/Order';

export default (product: Product, materials: Material[]) => {
  const material = materials.find((m) => m.id === product.density);
  if (material) {
    return product.name ? `${material.name} (${product.name})` : material.name;
  }
  return `${product.name} (${product.densityName})`;
};
