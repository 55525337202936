import { AfterCallsActionObject, AfterCallsActionTypes } from './AfterCallsAction';

export default class AfterCallsReducer {
  private static initState = {
    isLoading: false,
  };

  public static reducer(
    state = AfterCallsReducer.initState,
    action: AfterCallsActionObject
  ): typeof state {
    switch (action.type) {
      case AfterCallsActionTypes.SMS_SUCCESS:
      case AfterCallsActionTypes.SMS_FAIL:
        return {
          ...state,
          isLoading: false,
        };

      case AfterCallsActionTypes.SMS_REQUEST:
        return {
          ...state,
          isLoading: true,
        };

      default:
        return state;
    }
  }
}
