export default class MaterialsSelector {
  public static selectMaterial(state: ReduxState) {
    return state.materialEntry.model.data;
  }

  public static selectMaterials(state: ReduxState) {
    return state.materialEntry.list.data;
  }

  public static selectMaterialIsLoading(state: ReduxState) {
    return state.materialEntry.model.isLoading;
  }

  public static selectMaterialsIsLoading(state: ReduxState) {
    return state.materialEntry.list.isFetchLoading;
  }

  public static selectMaterialsCount(state: ReduxState) {
    return state.materialEntry.list.count;
  }
}
