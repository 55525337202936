import '@coreui/coreui/dist/css/coreui.min.css';
import * as Sentry from '@sentry/browser';
import Axios, { AxiosError } from 'axios';
import { createBrowserHistory } from 'history';
import i18next from 'i18next';
import merge from 'lodash/merge';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import { initReactI18next } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import { Rednot } from 'rednot';
import 'rednot/src/styles/index.css';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { getErrorMessage, releoxTranslations } from 'releox-react';
import 'releox-react/lib/releox.css';
import App from './App';
import translation from './configs/translation';
import './index.css';
import * as serviceWorker from './serviceWorker';
import rootReducer, { ReducerReturn } from './store/rootReducer';
import isProduction from './utils/is-production';

declare global {
  interface Window {
    API_ENDPOINT: string;
    SERVER_URL: string;
    S3_BUCKET: string;
    CI_BUILD: string;
    CI_ENV: string;
    TOKEN_LOCAL_STORAGE_KEY: string;
  }

  type ReduxState = ReducerReturn;
}

let sentryDns;
if (isProduction()) {
  // eslint-disable-next-line no-console
  console.log('GA and Sentry initialized');
  ReactGA.initialize('UA-106023287-3');
  sentryDns = 'https://b114d65753b24d2a8ca418f8dbb29e5d@sentry.io/1249186';
}

const history = createBrowserHistory();
history.listen((location) => {
  if (isProduction()) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk, routerMiddleware(history)))
);

Sentry.init({
  dsn: sentryDns,
  ignoreErrors: [
    "Cannot read properties of null (reading 'send')",
    'Connection closed before response received',
  ],
});

Sentry.configureScope((scope) => {
  scope.setTag('CI_BUILD', window.CI_BUILD);
  scope.setTag('CI_ENV', window.CI_ENV);
});

Axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<any>) => {
    Sentry.withScope((scope) => {
      scope.setTag('Error Source', 'Axios middleware');
      if (error.response) {
        scope.setExtra('response-object', error.response.data.error);
      }
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(new Error(getErrorMessage(error)));
    });
    return Promise.reject(error);
  }
);

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: merge(releoxTranslations, translation),
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <Helmet titleTemplate="%s | SJMK" defaultTitle="SJMK" />

    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
    <Rednot />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
