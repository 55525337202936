import React from 'react';

interface Example {
  query: string;
  description: string;
}

interface Props {
  examples: Example[];
  dateFields: string[];
  booleanFields?: string[];
  valueFields: string[];
}

export default ({ examples, booleanFields, valueFields, dateFields }: Props) => {
  return (
    <>
      <p>
        <strong>Advance search guide</strong>
      </p>
      <ul>
        {examples.map((e) => (
          <li key={e.query}>
            &quot;<b>{e.query}</b>&quot; {e.description}
          </li>
        ))}
      </ul>
      <details>
        <summary>
          <strong>All data keys</strong>
        </summary>
        <ul>
          <li>
            <b>Date fields (Format (UTC) &quot;2020-01-22T15:15:30&quot;):</b>{' '}
            {dateFields.join(', ')}
          </li>
          {booleanFields ? (
            <li>
              <b>Boolean fields (Format &quot;true&quot; OR &quot;false&quot;):</b>{' '}
              {booleanFields.join(', ')}
            </li>
          ) : (
            ''
          )}
          <li>
            <b>Text/Number fields:</b> {valueFields.join(', ')}
          </li>
          <li>
            <b>Operators (Format &lt;key&gt;:lt:&lt;value&gt; ):</b> lt (less than), gt (greater
            than)
          </li>
        </ul>
      </details>
    </>
  );
};
