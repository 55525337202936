import React, { useMemo } from 'react';
import { Select } from 'releox-react';
import DatePicker from '../../components/DatePicker';
import { teamOptions } from '../../options';
import { getMinDate } from '../../utils/report-methods/report-min-date';

export default () => {
  const minDate = useMemo(() => getMinDate(), []);
  return (
    <>
      <div className="col-md-2">
        <DatePicker minDate={minDate} label="Start" name="start" />
      </div>
      <div className="col-md-2">
        <DatePicker minDate={minDate} label="End" name="end" />
      </div>
      <div className="col-md-2">
        <Select label="Team" name="team" options={teamOptions} />
      </div>
    </>
  );
};
