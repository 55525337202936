import React from 'react';
import { AsyncSelect, CoreuiCheckBox, Input } from 'releox-react';
import apis from '../configs/apis';

export default () => {
  return (
    <>
      <AsyncSelect label="User" name="userId" searchFields={['name']} getUrl={apis.MEMBER} />
      <Input label="Hours" name="hours" type="number" inlineLabelWidth={2} />
      <CoreuiCheckBox label="Sick leave absence" name="sickLeave" />
      <CoreuiCheckBox label="Paid day off" name="paidDayOff" />
    </>
  );
};
