import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, Loading } from 'releox-react';
import { PaymentMode, SelectedOrder } from '../../classes/AbstractPaymentGenerator';
import PayerPaymentTable from '../../components/PayerPaymentTable';
import PayerPreviousPaymentsTable from '../../components/PayerPreviousPaymentsTable';
import PayerTeamSearchForm from '../../forms/payer-team-search/PayerTeamSearchForm';
import PayerTeamSearchValidationSchema from '../../forms/payer-team-search/PayerTeamSearchValidationSchema';
import MaterialsSelector from '../../store/material/MaterialsSelector';
import { Order } from '../../store/order/Order';
import OrdersSelector from '../../store/order/OrdersSelector';
import PayersAction from '../../store/payer/PayersAction';
import PayersSelector from '../../store/payer/PayersSelector';

const addIsSelectedField = (orders: Order[]): SelectedOrder[] => {
  return orders.map((order) => {
    return {
      ...order,
      isSelected: true,
    };
  });
};

export default () => {
  const dispatch = useDispatch();
  const orders = useSelector(OrdersSelector.selectOrders);
  const isLoading = useSelector(OrdersSelector.selectIsListLoading);
  const materials = useSelector(MaterialsSelector.selectMaterials);
  const team = useSelector(PayersSelector.selectTeam);

  useEffect(() => {
    dispatch(PayersAction.remapMaterials(materials));
  }, [dispatch, materials]);

  useEffect(() => {
    dispatch(PayersAction.fetchMaterial());
    dispatch(PayersAction.getPaymentFiles({ isDonation: true }));
  }, [dispatch]);

  useEffect(() => {
    if (team) dispatch(PayersAction.fetchDonationOrdersByTeam(team));
  }, [dispatch, team]);

  const downloadXML = useCallback(
    (formBody) => {
      dispatch(PayersAction.downloadDocument(formBody, PaymentMode.DONATION, team));
    },
    [dispatch, team]
  );

  const search = useCallback(
    (body: { team: string }) => {
      dispatch(PayersAction.setTeam(body.team));
    },
    [dispatch]
  );

  if (isLoading) return <Loading />;

  return (
    <div className="row">
      <div className="col-lg-2">
        <Formik
          initialValues={{ team }}
          validationSchema={PayerTeamSearchValidationSchema}
          onSubmit={search}
        >
          <Form>
            <Card>
              <CardTitle>Select team</CardTitle>
              <PayerTeamSearchForm />
              <Button id="search" type="submit">
                Search
              </Button>
            </Card>
          </Form>
        </Formik>
      </div>
      <div className="col-lg-6">
        {!team ? (
          <p>Choose team</p>
        ) : (
          <Formik
            enableReinitialize
            initialValues={addIsSelectedField(orders)}
            onSubmit={downloadXML}
          >
            <Form>
              <PayerPaymentTable />
            </Form>
          </Formik>
        )}
      </div>
      <div className="col-lg-4">
        <PayerPreviousPaymentsTable />
      </div>
    </div>
  );
};
