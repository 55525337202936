export default class CallCountsSelector {
  public static selectAfterCallCount(state: ReduxState): number {
    return state.callCount.afterCallCount;
  }

  public static selectWelcomeCallCount(state: ReduxState): number {
    return state.callCount.welcomeCallCount;
  }

  public static selectIsWelcomeCallCountLoading(state: ReduxState): boolean {
    return state.callCount.isWelcomeCallCountLoading;
  }

  public static selectIsAfterCallCountLoading(state: ReduxState): boolean {
    return state.callCount.isAfterCallCountLoading;
  }
}
