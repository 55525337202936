import React from 'react';
import { useSelector } from 'react-redux';
import { Card, DetailField } from 'releox-react';
import TellersSelector from '../../../store/teller/TellersSelector';

interface Props {
  children: string;
  label: string;
  highlight?: boolean;
}

const Message = ({ children, label, highlight }: Props) => (
  <>
    {children ? (
      <p className={highlight ? 'text-danger lead' : ''}>
        <strong>{label}</strong>
        <br />
        {children || '-'}
      </p>
    ) : (
      ''
    )}
  </>
);

export default () => {
  const order = useSelector(TellersSelector.selectOrder);

  return (
    <Card>
      <table>
        <tbody>
          <DetailField label="Order #">{order.orderNumber}</DetailField>
          <DetailField label="Client #">{order.client?.clientNumber}</DetailField>
          <DetailField label="Caller">{order.caller?.name}</DetailField>
          <DetailField label="Valuator">{order.valuator?.name}</DetailField>
          <DetailField label="Country">{order.country}</DetailField>
        </tbody>
      </table>
      <Message label="General message">{order.generalMessage}</Message>
      <Message label="Message to valuation from Buyer">{order.valuationMessageFromBuyer}</Message>
      <Message highlight label="Message from Buyer">
        {order.tellerMessageFromBuyer}
      </Message>
      <Message highlight label="Message from Valuation">
        {order.tellerMessageFromValuation}
      </Message>
    </Card>
  );
};
