import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TellersAction from '../../../store/teller/TellersAction';
import TellersSelector from '../../../store/teller/TellersSelector';

const IBAN: FC = () => {
  const dispatch = useDispatch();
  const iban = useSelector(TellersSelector.selectIBAN);

  const isBBANValid = useSelector(TellersSelector.selectIsBBANValid);
  const isIBANValid = useSelector(TellersSelector.selectIsIBANValid);
  const onBBANChange = useCallback((e) => dispatch(TellersAction.bbanOnChange(e)), [dispatch]);
  const onIBANChange = useCallback((e) => dispatch(TellersAction.ibanOnChange(e)), [dispatch]);

  return (
    <>
      <div className="form-group">
        <label>BBAN</label>
        <input
          type="text"
          placeholder="BBAN"
          onChange={onBBANChange}
          className={`form-control ${isBBANValid ? 'is-valid' : 'is-invalid'}`}
        />
        <p className="text-muted">This field is optional. It&apos;s only for convert purposes</p>
      </div>
      <div className="form-group">
        <label>IBAN</label>
        <input
          placeholder="IBAN"
          value={iban}
          onChange={onIBANChange}
          className={`form-control ${isIBANValid ? 'is-valid' : 'is-invalid'}`}
        />
      </div>
    </>
  );
};

const UKBankAccount: FC = () => {
  const dispatch = useDispatch();

  const accountNumber = useSelector(TellersSelector.selectUKAccountNumber);
  const sortCode = useSelector(TellersSelector.selectUKSortCode);

  const onChangeAccountNumber = useCallback(
    (e) => {
      dispatch(TellersAction.ukAccountNumber(e.target.value));
    },
    [dispatch]
  );

  const onChangeSortCode = useCallback(
    (e) => {
      dispatch(TellersAction.ukSortCode(e.target.value));
    },
    [dispatch]
  );

  return (
    <>
      <div className="form-group">
        <label>Account number</label>
        <input
          type="text"
          placeholder="Account number"
          onChange={onChangeAccountNumber}
          value={accountNumber}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Sort Code</label>
        <input
          placeholder="Sort Code"
          value={sortCode}
          onChange={onChangeSortCode}
          className="form-control"
        />
      </div>
    </>
  );
};

type Props = {
  country: string;
};

export default ({ country }: Props) => {
  if (country === 'uk') {
    return <UKBankAccount />;
  }
  return <IBAN />;
};
