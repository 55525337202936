import { CallTransferSearchType } from '../../forms/call-transfer-search/CallTransferSearchInitialValues';
import { CallTransferFormValues } from './CallTransferModel';
import { CallTransfersActionObject, CallTransfersActionTypes } from './CallTransfersAction';

export default class CallTransfersReducer {
  private static initState = {
    callTransferField: [] as CallTransferFormValues[],
    type: CallTransferSearchType.WELCOME_CALL,
    searchUserId: '',
  };

  public static reducer(
    state = CallTransfersReducer.initState,
    action: CallTransfersActionObject
  ): typeof state {
    switch (action.type) {
      case CallTransfersActionTypes.SEARCH_REQUEST:
        return {
          ...state,
          type: action.payload.type,
          searchUserId: action.payload.searchUserId,
        };

      case CallTransfersActionTypes.RESET:
        return CallTransfersReducer.initState;

      case CallTransfersActionTypes.SEARCH_SUCCESS:
        return {
          ...state,
          callTransferField: action.payload.callTransferField,
        };

      default:
        return state;
    }
  }
}
