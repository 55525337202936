import { getErrorMessage } from 'releox-react';
import { Action, ReduxDispatch } from '../Action';
import OrdersAction from '../order/OrdersAction';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

export enum RegisterPackagesActionTypes {
  REQUEST = '@REGISTER_PACKAGE:REQUEST',
  REQUEST_SUCCESS = '@REGISTER_PACKAGE:REQUEST_SUCCESS',
  REQUEST_FAIL = '@REGISTER_PACKAGE:REQUEST_FAIL',
}

type RequestReturn = Action<RegisterPackagesActionTypes.REQUEST>;
type RequestSuccessReturn = Action<RegisterPackagesActionTypes.REQUEST_SUCCESS>;
type RequestFailReturn = Action<RegisterPackagesActionTypes.REQUEST_FAIL>;

export type RegisterPackagesActionObject = RequestReturn | RequestSuccessReturn | RequestFailReturn;

export default class RegisterPackagesAction {
  private static request(): RequestReturn {
    return {
      type: RegisterPackagesActionTypes.REQUEST,
    };
  }

  private static requestSuccess(): RequestSuccessReturn {
    return {
      type: RegisterPackagesActionTypes.REQUEST_SUCCESS,
    };
  }

  private static requestFail(): RequestFailReturn {
    return {
      type: RegisterPackagesActionTypes.REQUEST_FAIL,
    };
  }

  public static registerPackage(id: string) {
    return (dispatch: ReduxDispatch) => {
      dispatch(RegisterPackagesAction.request());
      OrdersEffect.registerPackage(id)
        .then(() => {
          dispatch(RegisterPackagesAction.requestSuccess());
          dispatch(OrdersAction.fetch(id, { include: OrderQuery.include }));
        })
        .catch((e) => {
          dispatch(RegisterPackagesAction.requestFail());
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }
}
