import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import { ReduxDispatch } from '../Action';
import RednotAction from '../rednot/RednotAction';
import { Order } from './Order';
import OrdersEffect from './OrdersEffect';

export type CloseMethod = 'boomerang' | 'phone' | 'email';

export default class OrdersAction {
  public static ENTRY = 'ORDER';

  public static actionCreator = actionCreator<Order>(
    OrdersAction.ENTRY,
    OrdersEffect.restRequests,
    push,
    RednotAction.onError
  );

  public static update = OrdersAction.actionCreator.getUpdateAction();

  public static fetch = OrdersAction.actionCreator.getFindByIdAction();

  public static list = OrdersAction.actionCreator.getFindAndCountAction();

  public static clean = OrdersAction.actionCreator.getCleanAction();

  public static closeOrder(
    id: string,
    closerId: string,
    closeMethod: CloseMethod,
    closeReason: string,
    isBoomerang: boolean = false
  ) {
    return (dispatch: ReduxDispatch) => {
      const body: any = {
        closeReason,
        closeMethod,
        isBoomerang,
        closerId,
        closeDate: new Date(),
        closed: true,
      };
      return dispatch(OrdersAction.update(id, body));
    };
  }

  public static markAsBoomerang(id: string, closerId: string, redirect?: string) {
    return (dispatch: ReduxDispatch) => {
      const body = {
        closeReason: 'boomerang',
        closeMethod: 'boomerang',
        closerId,
        isBoomerang: true,
        closeDate: new Date(),
        closed: true,
      };
      return dispatch(OrdersAction.update(id, body, { redirect }));
    };
  }
}
