import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import { User } from '../user/User';

export default class CallCountsEffect {
  public static fetchAfterCallCount(user: User) {
    return OrdersEffect.count(OrderQuery.getBuyerAfterCallIndexQuery(user));
  }

  public static fetchWelcomeCallCount(user: User) {
    return OrdersEffect.count(OrderQuery.getBuyerWelcomeCallIndex(user));
  }
}
