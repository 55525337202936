import React from 'react';
import { Details } from 'releox-react';
import OrderColumn from '../columns/OrderColumn';
import { Order } from '../store/order/Order';

interface Props {
  order: Order;
}

export default ({ order }: Props) => {
  return (
    <Details
      object={order}
      properties={[
        OrderColumn.orderNumber,
        OrderColumn.clientName,
        OrderColumn.phone,
        OrderColumn.birthYearAndAge,
        OrderColumn.callTransfer,
        OrderColumn.address,
        OrderColumn.createdAt,
        OrderColumn.dueDate,
        OrderColumn.welcomeCall,
        { dataField: 'generalMessage', text: 'General message' },
      ]}
    />
  );
};
