const OrderColumnInitialValues = {
  status: true,
  clientName: true,
  barCode: true,
  country: true,
  language: true,
  createdAt: true,
  updatedAt: true,
  bursarName: false,
  bursarHandleDate: false,
  tellerName: false,
  tellerDate: false,
  phone: false,
  afterCallLatest: false,
  welcomeCall: false,
  afterCallTries: false,
  address: false,
  welcomeCallLatest: false,
  invoice: false,
  letter: false,
  barCodePrint: false,
  packagedDate: false,
  offer: false,
  tellerCallLatest: false,
  closerName: false,
  callerName: false,
  packagerName: false,
  valuatorName: false,
  valuationDate: false,
  payerName: false,
  payedDate: false,
  receiverName: false,
  receivedDate: false,
  materialWeight: false,
  packageWeight: false,
  goldWeight: false,
  silverWeight: false,
  team: false,

  closeReason: false,
  closeMethod: false,
  closeDate: false,
  dueDate: false,
};

export type OrderColumnInitialValueType = typeof OrderColumnInitialValues;

export default OrderColumnInitialValues;
