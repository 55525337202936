import routes from '../../configs/routes';
import { BursarFormBody } from '../../forms/bursar/BursarInitialValues';
import { ActionWPayload, GetState, ReduxDispatch } from '../Action';
import OrdersAction from '../order/OrdersAction';
import RednotAction from '../rednot/RednotAction';

export enum BursarsActionTypes {
  VIEW_DID_MOUNT = '@BURSAR:VIEW_DID_MOUNT',
}

type ViewDidMountReturn = ActionWPayload<
  BursarsActionTypes.VIEW_DID_MOUNT,
  { requireReturn: boolean }
>;

export type BursarsActionObject = ViewDidMountReturn;

export default class BursarsAction {
  private static viewMountComplete(requireReturn: boolean): BursarsActionObject {
    return {
      type: BursarsActionTypes.VIEW_DID_MOUNT,
      payload: { requireReturn },
    };
  }

  public static viewDidMount() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const order = state.orderEntry.model.data;
      const noDealProducts = order.products.filter((product) => !product.inDeal);
      let requireReturn = false;
      if (order.ignobleReturn || noDealProducts.length) {
        requireReturn = true;
      }
      dispatch(this.viewMountComplete(requireReturn));
    };
  }

  public static onSubmit(bursarFormBody: BursarFormBody) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const order = state.orderEntry.model.data;
      const user = state.user.data;
      const { requireReturn } = state.bursar;

      const requestBody = {
        bursarComment: bursarFormBody.bursarComment,
        isBursarReturnMade: bursarFormBody.isBursarReturnMade,
        isComplete: true,
        bursarHandleDate: new Date(),
        bursarId: user.id,
      };

      if (requireReturn && !bursarFormBody.isBursarReturnMade) {
        return dispatch(RednotAction.error('You should make return'));
      }

      const options = {
        redirect: routes.BURSAR.BAR_CODE_PROMPT.replace(':country', order.country),
      };
      return dispatch(OrdersAction.update(order.id, requestBody, options));
    };
  }
}
