import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Loading, Select, TextArea } from 'releox-react';
import OrderInfo from '../../../components/BuyerOrderInfo';
import CallLogList from '../../../components/CallLogList';
import DatePicker from '../../../components/DatePicker';
import routes from '../../../configs/routes';
import OrdersAction from '../../../store/order/OrdersAction';
import OrderQuery from '../../../store/order/OrdersQuery';
import OrdersSelector from '../../../store/order/OrdersSelector';
import WelcomeCallAction, {
  WelcomeCallBody,
  WelcomeCallOption,
} from '../../../store/wellcome-call/WelcomeCallAction';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const order = useSelector(OrdersSelector.selectOrder);
  const isLoading = useSelector(OrdersSelector.selectIsModelLoading);

  const eventOptions = [
    { value: WelcomeCallOption.EMPTY, label: 'Choose event' },
    { value: WelcomeCallOption.DOES_NOT_ANSWER, label: 'Does not answer' },
    { value: WelcomeCallOption.CALL_AGAIN, label: 'Call again' },
    { value: WelcomeCallOption.DONE, label: 'Done' },
  ];

  useEffect(() => {
    dispatch(OrdersAction.fetch(id, OrderQuery.onlyInclude));
  }, [dispatch, id]);

  const onSubmit = useCallback(
    (body: WelcomeCallBody) => {
      dispatch(WelcomeCallAction.onSubmit(id, order, body));
    },
    [dispatch, id, order]
  );

  if (isLoading || !order.client) return <Loading centeredVertical />;
  return (
    <Formik initialValues={WelcomeCallAction.getInitialValues(order)} onSubmit={onSubmit}>
      <Form>
        <div className="row">
          <div className="col-lg-4">
            <Card>
              <CardTitle>Welcome call</CardTitle>
              <OrderInfo order={order} />
              <hr />
              <DatePicker name="welcomeCall" label="Next welcome call" inline={false} />
              <DatePicker name="dueDate" label="Due date" inline={false} />
              <Select name="event" label="Choose one" options={eventOptions} />
              <TextArea name="logMessage" label="Log message" />
              <Link to={routes.BUYER.WELCOME_CALLS} className="btn btn-light">
                Back
              </Link>
              <Button id="submit" type="submit" className="float-right">
                Save
              </Button>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <TextArea label="General message" name="generalMessage" />
              <TextArea label="Message to valuation" name="valuationMessageFromBuyer" />
              <TextArea label="Message to teller" name="tellerMessageFromBuyer" />
            </Card>
            <Card>
              <CardTitle>Pickup locations</CardTitle>
              <ul>
                {order.shipmentOffices.map((shipmentOffice) => (
                  <li
                    key={shipmentOffice.name}
                  >{`${shipmentOffice.name}, ${shipmentOffice.address}, ${shipmentOffice.zip} ${shipmentOffice.city}`}</li>
                ))}
              </ul>
            </Card>
          </div>
          <div className="col-lg-4">
            <Card>
              <CardTitle>Welcome call Log</CardTitle>
              <CallLogList selector={OrdersSelector.selectWelcomeCallLog} />
            </Card>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
