import React from 'react';
import { useSelector } from 'react-redux';
import { CheckBox, TextArea } from 'releox-react';
import BursarsSelector from '../../store/bursar/BursarsSelector';

export default () => {
  const requireReturn = useSelector(BursarsSelector.selectRequireReturn);

  return (
    <>
      <div>
        {requireReturn ? (
          <>
            <p>Did you make a return? </p>
            <CheckBox name="isBursarReturnMade" label="I made return" />
          </>
        ) : (
          ''
        )}
        <div className="mt-3">
          <TextArea name="bursarComment" label="Your own comments:" />
        </div>
      </div>
    </>
  );
};
