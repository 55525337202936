import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle } from 'releox-react';
import PaymentXmlsSelector from '../store/payment-xml/PaymentXmlsSelector';
import { LazySignedLink } from './SignedComponents';

export default (): JSX.Element => {
  const paymentXmls = useSelector(PaymentXmlsSelector.selectPaymentXmls);

  return (
    <Card>
      <CardTitle>Previous Payments</CardTitle>
      <ul>
        {paymentXmls.map((paymentXML) => (
          <li key={paymentXML.id}>
            <LazySignedLink href={paymentXML.location}>{paymentXML.name}</LazySignedLink>
            <>{` - Total: ${paymentXML.total}`}</>
          </li>
        ))}
      </ul>
    </Card>
  );
};
