import Axios from 'axios';
import { Form, Formik } from 'formik';
import { getErrorMessage } from 'loopback-error-parser';
import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { AuthLayout, Button, Input, saveAccessInformation } from 'releox-react';
import apis from '../configs/apis';
import routes from '../configs/routes';

const init = { username: '', password: '' };

type Body = typeof init;

const getTimingVariables = (value: number) => ({
  category: 'Login',
  variable: 'load',
  value,
  label: 'Login request',
});

export const LoginScene = () => {
  const [message, setMessage] = useState<undefined | string>();
  const [redirect, setRedirect] = useState(false);

  const onSubmit = useCallback(async (body: Body) => {
    ReactGA.event({
      category: 'Auth',
      action: 'Login submit',
    });

    const start = Date.now();

    try {
      const { data } = await Axios.post(`${apis.MEMBER}/login`, body);
      ReactGA.timing(getTimingVariables(Date.now() - start));
      saveAccessInformation(data.id, data.userId);
      setRedirect(true);
    } catch (e) {
      ReactGA.timing(getTimingVariables(Date.now() - start));
      setMessage(getErrorMessage(e));
    }
  }, []);

  if (redirect) return <Redirect to={routes.HOME} />;

  return (
    <AuthLayout title="Login" subTitle="Fill form and login" context="LoginScene" links={[]}>
      <Formik initialValues={init} onSubmit={onSubmit}>
        <Form>
          <Input name="username" label="Username" />
          <Input name="password" type="password" label="Password" />
          <Button className="float-right" type="submit" id="login-button">
            Login
          </Button>
          <div className="text-center">{message}</div>
        </Form>
      </Formik>
    </AuthLayout>
  );
};
