import * as Sentry from '@sentry/browser';
import { getErrorMessage } from 'releox-react';
import PackagerPipe from '../../classes/PackagerPipe';
import { Print } from '../../HOC/withQZ';
import isProduction from '../../utils/is-production';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import { Client } from '../client/Client';
import ClientsEffect from '../client/ClientsEffect';
import { Order } from '../order/Order';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';
import { User } from '../user/User';

export enum PackagerSystemsActionTypes {
  RESET = '@PACKAGER_SYSTEM:RESET',
  NO_MORE_ORDERS = '@PACKAGER_SYSTEM:NO_MORE_ORDERS',
  FOUND_ORDER = '@PACKAGER_SYSTEM:FOUND_ORDER',
  SEND_REQUEST = '@PACKAGER_SYSTEM:SEND_REQUEST',
  SEND_SUCCESS = '@PACKAGER_SYSTEM:SEND_SUCCESS',
  SEND_FAIL = '@PACKAGER_SYSTEM:SEND_FAIL',
  RESET_MESSAGES = '@PACKAGER_SYSTEM:RESET_MESSAGES',
  ADD_MESSAGE = '@PACKAGER_SYSTEM:ADD_MESSAGE',
  FIND_ORDER_REQUEST = '@PACKAGER_SYSTEM:FIND_ORDER_REQUEST',
  FIND_ORDER_SUCCESS = '@PACKAGER_SYSTEM:FIND_ORDER_SUCCESS',
  FIND_ORDER_FAIL = '@PACKAGER_SYSTEM:FIND_ORDER_FAIL',
}

type ResetReturn = Action<PackagerSystemsActionTypes.RESET>;
type ResetMessagesReturn = Action<PackagerSystemsActionTypes.RESET_MESSAGES>;

type SendRequestReturn = Action<PackagerSystemsActionTypes.SEND_REQUEST>;
type SendSuccessReturn = Action<PackagerSystemsActionTypes.SEND_SUCCESS>;
type SendFailReturn = Action<PackagerSystemsActionTypes.SEND_FAIL>;

type FindRequestReturn = Action<PackagerSystemsActionTypes.FIND_ORDER_REQUEST>;
type FindSuccessReturn = Action<PackagerSystemsActionTypes.FIND_ORDER_SUCCESS>;
type FindFailReturn = Action<PackagerSystemsActionTypes.FIND_ORDER_FAIL>;

type HasNoMoreOrdersReturn = Action<PackagerSystemsActionTypes.NO_MORE_ORDERS>;
type FoundOrderReturn = ActionWPayload<
  PackagerSystemsActionTypes.FOUND_ORDER,
  { order: Order; isValid: boolean }
>;
type AddMessageReturn = ActionWPayload<PackagerSystemsActionTypes.ADD_MESSAGE, { message: string }>;

type ValidatedOrderBody = { order: Order | null; isValid: boolean };
type ValidatedOrderClientUpdate = { order: Order; isValid: boolean };

export type PackagerSystemsActionObject =
  | SendRequestReturn
  | SendSuccessReturn
  | FindRequestReturn
  | FindSuccessReturn
  | FindFailReturn
  | AddMessageReturn
  | SendFailReturn
  | ResetMessagesReturn
  | ResetReturn
  | HasNoMoreOrdersReturn
  | FoundOrderReturn;

export default class PackagerSystemsAction {
  public static reset(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.RESET,
    };
  }

  public static findPackage(user: User, language: string) {
    return (dispatch: ReduxDispatch) => {
      const query = OrderQuery.getPackagerAllQuery(user, language, 1);
      if (!user.id) throw new Error('Find package missing user id');
      dispatch(PackagerSystemsAction.findRequest());
      OrdersEffect.find(query)
        .then(
          (orders): Promise<ValidatedOrderBody> => {
            return new Promise((resolve, reject) => {
              if (!orders.length) return resolve({ order: null, isValid: false });
              return OrdersEffect.checkAddressBase64(orders[0].id)
                .then((isValid) => resolve({ order: orders[0], isValid }))
                .catch(reject);
            });
          }
        )
        .then(({ order, isValid }) => {
          if (!order) return dispatch(PackagerSystemsAction.hasNoMoreOrders());
          OrdersEffect.update(order.id, { inPackager: true, packagerId: user.id });
          dispatch(PackagerSystemsAction.findSuccess());
          return dispatch(PackagerSystemsAction.foundOrder(order, isValid));
        })
        .catch((e) => {
          dispatch(RednotAction.error(getErrorMessage(e)));
          dispatch(PackagerSystemsAction.findFail());
        });
    };
  }

  public static updateClient(body: Client) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const { order } = getState().packagerSystem;
      dispatch(PackagerSystemsAction.findRequest());
      ClientsEffect.update(body.id, body)
        .then(() => OrdersEffect.findById(order.id, { include: OrderQuery.include }))
        .then(
          (o): Promise<ValidatedOrderClientUpdate> =>
            new Promise((resolve, reject) => {
              OrdersEffect.checkAddressBase64(o.id)
                .then((isValid) => resolve({ order: o, isValid }))
                .catch(reject);
            })
        )
        .then(({ order: o, isValid }) => {
          dispatch(PackagerSystemsAction.findSuccess());
          dispatch(PackagerSystemsAction.foundOrder(o, isValid));
        })
        .catch((e) => {
          dispatch(PackagerSystemsAction.findFail());
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }

  public static send(print: Print, user: User, language: string) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const { order } = state.packagerSystem;

      dispatch(PackagerSystemsAction.sendRequest());
      dispatch(PackagerSystemsAction.resetMessages());
      dispatch(PackagerSystemsAction.addMessage('Operation started'));

      const logMessage = (message: string) => {
        // eslint-disable-next-line no-console
        console.log(message);
        dispatch(PackagerSystemsAction.addMessage(message));
      };

      const packagePipe = new PackagerPipe(order, logMessage, print);

      Promise.resolve()
        .then(() => packagePipe.printCarrierLabel())
        .then(() => packagePipe.printInvoice())
        .then(() => {
          dispatch(PackagerSystemsAction.addMessage('Updating package information...'));
          return OrdersEffect.update(order.id, {
            packagedDate: new Date().toString(),
            isPackaged: true,
          });
        })
        .then(() => {
          dispatch(PackagerSystemsAction.addMessage('Updating: OK!'));
          dispatch(PackagerSystemsAction.addMessage('Finding new package...'));
          dispatch(PackagerSystemsAction.findPackage(user, language));
          dispatch(PackagerSystemsAction.sendSuccess());
          dispatch(PackagerSystemsAction.resetMessages());
        })
        .catch((e) => {
          if (isProduction()) {
            Sentry.withScope((scope) => {
              scope.setExtra('error-object', e);
              scope.setLevel(Sentry.Severity.Error);
              Sentry.captureException(new Error(getErrorMessage(e)));
            });
          }
          dispatch(PackagerSystemsAction.sendFail());
          dispatch(PackagerSystemsAction.addMessage(getErrorMessage(e)));
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }

  private static addMessage(message: string): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.ADD_MESSAGE,
      payload: { message },
    };
  }

  private static sendRequest(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.SEND_REQUEST,
    };
  }

  private static sendSuccess(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.SEND_SUCCESS,
    };
  }

  private static sendFail(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.SEND_FAIL,
    };
  }

  private static findRequest(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.FIND_ORDER_REQUEST,
    };
  }

  private static findSuccess(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.FIND_ORDER_SUCCESS,
    };
  }

  private static findFail(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.FIND_ORDER_FAIL,
    };
  }

  private static resetMessages(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.RESET_MESSAGES,
    };
  }

  private static hasNoMoreOrders(): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.NO_MORE_ORDERS,
    };
  }

  private static foundOrder(order: Order, isValid: boolean): PackagerSystemsActionObject {
    return {
      type: PackagerSystemsActionTypes.FOUND_ORDER,
      payload: { order, isValid },
    };
  }
}
