import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from 'releox-react';
import QZPrint from '../classes/QZPrint';
import QZConnectionLog from '../components/QZConnectionLog';
import qzTray from '../configs/qz-tray';
import QzsAction from '../store/qz/QzsAction';

const a4Size = {
  width: 8.26771654,
  height: 11.6929134,
};

export type Print = (url: string, printerName?: string, options?: any) => Promise<any>;

export interface QZTrayProps {
  print: Print;
}

export default (Component: any, zebraPrinterName: string) => (props: any) => {
  const dispatch = useDispatch();
  const [qz, setQZ] = useState(null as any);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let localQZ: any;
    dispatch(QzsAction.pushConnectionMessage('Init QZTray connection...'));
    new QZPrint(qzTray.CERT, qzTray.PRIVATE_KEY)
      .getConnectionInstance()
      .then((q) => {
        dispatch(QzsAction.pushConnectionMessage('Connection complete.'));
        localQZ = q;
        dispatch(QzsAction.pushConnectionMessage(`Searching ${zebraPrinterName}`));
        return localQZ.printers.find(zebraPrinterName);
      })
      .then(() => {
        dispatch(QzsAction.pushConnectionMessage(`${zebraPrinterName} found.`));
        setQZ(localQZ);
        setIsReady(true);
      })
      .catch((e) => dispatch(QzsAction.pushConnectionMessage(`ERROR: ${getErrorMessage(e)}`)));
  }, [setQZ, dispatch]);

  const print = useCallback(
    (url: string, printerName = zebraPrinterName, options?: any) => {
      return qz.printers.find(printerName).then((p: any) => {
        const defaultOptions = {
          scaleContent: false,
          size: a4Size,
        };
        const config = qz.configs.create(p, options || defaultOptions);
        const data = [
          {
            type: 'pdf',
            format: 'file',
            data: url,
          },
        ];
        // eslint-disable-next-line no-console
        console.log('print:', { data, printerName, options: defaultOptions });
        return qz.print(config, data);
      });
    },
    [qz]
  );

  useEffect(() => {
    return () => {
      dispatch(QzsAction.reset());
      if (qz) qz.websocket.disconnect();
    };
  }, [dispatch, qz]);

  if (!isReady) return <QZConnectionLog />;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component print={print} {...props} />;
};
