import moment from 'moment';
import { CCManagerCallListSearchBy } from './CCManagerCallListSearchForm';

const CCManagerCallListSearchInitialValues = {
  by: CCManagerCallListSearchBy.WELCOME_CALL,
  startDate: moment()
    .startOf('day')
    .toDate(),
  endDate: moment()
    .endOf('week')
    .toDate(),
  callerId: '',
  team: '',
};

export default CCManagerCallListSearchInitialValues;

export type CCCManagerCallListSearch = typeof CCManagerCallListSearchInitialValues;
