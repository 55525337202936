export default class PackagerSystemsSelector {
  public static selectHasNoMoreOrders(state: ReduxState) {
    return state.packagerSystem.hasNoMoreOrders;
  }

  public static selectOrder(state: ReduxState) {
    return state.packagerSystem.order;
  }

  public static selectMessages(state: ReduxState) {
    return state.packagerSystem.messages;
  }

  public static selectIsSending(state: ReduxState) {
    return state.packagerSystem.isSending;
  }

  public static selectIsLoading(state: ReduxState) {
    return state.packagerSystem.isLoading;
  }

  public static selectIsValidPackage(state: ReduxState) {
    return state.packagerSystem.isValidPackage;
  }
}
