import { ReceiverActions, ReceiverActionTypes } from './ReceiverAction';

export default class ReceiverReducer {
  public static initialState = {
    receivedCount: 0,
    byTeamCount: {
      turku: 0,
      helsinki: 0,
      gothenburg: 0,
      malagaFinland: 0,
      malagaSweden: 0,
    },
    isReceivedCountLoading: false,
  };

  public static reducer(
    state = ReceiverReducer.initialState,
    action: ReceiverActions
  ): typeof state {
    switch (action.type) {
      case ReceiverActionTypes.FETCH_RECEIVED_COUNT_REQUEST:
        return {
          ...state,
          isReceivedCountLoading: true,
        };

      case ReceiverActionTypes.FETCH_RECEIVED_COUNT_SUCCESS:
        return {
          ...state,
          receivedCount: action.payload.count,
          isReceivedCountLoading: false,
        };

      case ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_SUCCESS:
        return {
          ...state,
          byTeamCount: action.payload,
        };

      case ReceiverActionTypes.FETCH_RECEIVED_COUNT_FAIL:
        return {
          ...state,
          isReceivedCountLoading: false,
        };

      default:
        return state;
    }
  }
}
