import React from 'react';
import { useSelector } from 'react-redux';
import { formatTimeAndFrom } from '../config';
import CallLog from '../store/call-log/CallLog';

interface Props {
  selector(state: ReduxState): CallLog[];
}

export default ({ selector }: Props) => {
  const callLog = useSelector(selector);
  return (
    <>
      {callLog.map((cl) => (
        <p key={cl.id}>
          <b>Date:</b> {formatTimeAndFrom(cl.createdAt || '')}
          <br />
          <b>Caller:</b> {cl.member?.name}
          <br />
          <b>Type:</b> {cl.type}
          <br />
          <b> Event:</b> {cl.event}
          <br />
          <b> Message:</b> {cl.message}
        </p>
      ))}
    </>
  );
};
