import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'releox-react';
import { fixFloat } from '../config';
import ReportsSelector from '../store/report/ReportsSelector';

export default () => {
  const result = useSelector(ReportsSelector.selectValuatedOffersReport);

  return (
    <div className="col-lg-10">
      <Card>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>AU in count</th>
              <th>Total in count</th>
              <th>AU in %</th>
              <th>Total out count</th>
              <th>Total out count (2w)</th>
              <th>AU in % (2w)</th>
              <th>Cancelled</th>
            </tr>
          </thead>
          <tbody>
            {result.users.map((u) => (
              <tr key={u.name}>
                <td>{u.name}</td>
                <td>{u.auCount}</td>
                <td>{u.totalCount}</td>
                <td>{fixFloat((u.auCount / u.totalCount) * 100)}</td>
                <td>{u.outCount}</td>
                <td>{u.out2WCount}</td>
                <td>{fixFloat((u.auCount / u.out2WCount) * 100)}</td>
                <td>{u.cancelledCount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td>{result.auCount}</td>
              <td>{result.totalCount}</td>
              <td>{fixFloat((result.auCount / result.totalCount) * 100)}</td>
              <td>{result.outCount}</td>
              <td>{result.out2WCount}</td>
              <td>{fixFloat((result.auCount / result.out2WCount) * 100)}</td>
              <td>{result.cancelledCount}</td>
            </tr>
          </tfoot>
        </table>
      </Card>
    </div>
  );
};
