import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Loading, Select, TextArea } from 'releox-react';
import OrderInfo from '../../../components/BuyerOrderInfo';
import CallLogList from '../../../components/CallLogList';
import routes from '../../../configs/routes';
import ColdCasesAction, {
  ColdCallBody,
  ColdCasesEventOption,
} from '../../../store/cold-cases/ColdCasesAction';
import OrdersAction from '../../../store/order/OrdersAction';
import OrderQuery from '../../../store/order/OrdersQuery';
import OrdersSelector from '../../../store/order/OrdersSelector';
import UsersSelector from '../../../store/user/UsersSelector';

const eventOptions = [
  { value: ColdCasesEventOption.EMPTY_EVENT, label: 'Choose event' },
  { value: ColdCasesEventOption.DOES_NOT_ANSWER, label: 'Does not answer' },
  { value: ColdCasesEventOption.CLOSE, label: 'Close / Done' },
];

export default () => {
  const { id: orderId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const order = useSelector(OrdersSelector.selectOrder);
  const user = useSelector(UsersSelector.selectUser);
  const isLoading = useSelector(OrdersSelector.selectIsModelLoading);

  useEffect(() => {
    dispatch(OrdersAction.fetch(orderId, OrderQuery.onlyInclude));
  }, [dispatch, orderId]);

  const onSubmit = useCallback(
    (body: ColdCallBody) => {
      dispatch(ColdCasesAction.onSubmit(order, user, body));
    },
    [dispatch, order, user]
  );

  const sendSMS = useCallback(() => {
    if (!order?.client?.phone) return;
    dispatch(ColdCasesAction.sendMHSMS(order, order.client.phone));
  }, [dispatch, order]);

  if (isLoading) return <Loading centeredVertical />;

  return (
    <Formik initialValues={ColdCasesAction.getInitialValues(order)} onSubmit={onSubmit}>
      <div className="row">
        <div className="col-lg-4">
          <Card>
            <CardTitle>Cold call</CardTitle>
            <OrderInfo order={order} />
            <hr />
            <Form>
              <Select name="event" label="Choose one" options={eventOptions} />
              <TextArea name="logMessage" label="Log message" />
              <Link to={routes.BUYER.COLD_CASES_INDEX_ROOT.replace(':language', order.language)}>
                Back
              </Link>
              <Button id="submit" type="submit" className="float-right">
                Save
              </Button>
              {order.country === 'finland' && order.language === 'finnish' ? (
                <>
                  <hr />
                  <Button
                    id="send-mh-return-code"
                    className="float-right"
                    type="button"
                    onClick={sendSMS}
                    disabledText="Loading...."
                  >
                    Send Matkahuolto return code
                  </Button>
                </>
              ) : (
                ''
              )}
            </Form>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card>
            <TextArea label="General message" name="generalMessage" />
            <TextArea label="Message to valuation" name="valuationMessageFromBuyer" />
            <TextArea label="Message to teller" name="tellerMessageFromBuyer" />
          </Card>
          <Card>
            <CardTitle>Pickup locations</CardTitle>
            <ul>
              {order.shipmentOffices.map((shipmentOffice) => (
                <li
                  key={shipmentOffice.name}
                >{`${shipmentOffice.name}, ${shipmentOffice.address}, ${shipmentOffice.zip} ${shipmentOffice.city}`}</li>
              ))}
            </ul>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card>
            <CardTitle>Call Log</CardTitle>
            <CallLogList selector={OrdersSelector.selectLogs} />
          </Card>
        </div>
      </div>
    </Formik>
  );
};
