export default {
  en: {
    BursarHandledScene: {
      title: 'Today handled',
    },
    BursarUnhandledScene: {
      title: 'Unhandled',
    },
  },
};
