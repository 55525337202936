import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getAuthRoutes, routeMapper } from 'releox-react';
import DashboardRouter from './DashboardRouter';
import { LoginScene } from './scenes/LoginScene';

const App: React.FC = () => (
  <Switch>
    <Route exact path="/login" component={LoginScene} />
    {getAuthRoutes().map(routeMapper)}
    <Route path="/" component={DashboardRouter} />
  </Switch>
);

export default App;
