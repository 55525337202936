import React from 'react';
import { DataTableColumn, DataTableDefaultSort, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

const reValuationColumns: DataTableColumn[] = [OrderColumn.orderNumber, OrderColumn.clientName];
const reValuationDefaultSorted: DataTableDefaultSort = {
  dataField: 'orderNumber',
  order: 'ASC',
};

export const ReValuationFinnish = () => (
  <GenericIndexScene
    tNamespace="ReValuation:finnish"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={reValuationColumns}
    defaultSorted={reValuationDefaultSorted}
    query={OrderQuery.getValuationWaitingReValuationFinnishQuery()}
  />
);

export const ReValuationSwedish = () => (
  <GenericIndexScene
    tNamespace="ReValuation:sweden"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={reValuationColumns}
    defaultSorted={reValuationDefaultSorted}
    query={OrderQuery.getValuationWaitingReValuationSwedishQuery()}
  />
);

export const ReValuationUK = () => (
  <GenericIndexScene
    tNamespace="ReValuation:uk"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={reValuationColumns}
    defaultSorted={reValuationDefaultSorted}
    query={OrderQuery.getValuationWaitingReValuationUKQuery()}
  />
);
