import { reducerCreator } from 'redux-rest-helper-for-loopback';
import { materialFormInitValue } from '../../forms/MaterialForm';
import MaterialsAction from './MaterialsAction';

export default class MaterialsReducer {
  private static initValues = materialFormInitValue;

  public static reducer = reducerCreator(
    MaterialsAction.ENTRY,
    MaterialsReducer.initValues
  ).getReducer();
}
