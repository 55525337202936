import IBAN from 'iban';
import { getErrorMessage } from 'releox-react';
import * as Yup from 'yup';
import { PaymentMode, SelectedOrder } from '../../classes/AbstractPaymentGenerator';
import { Country } from '../../classes/Country';
import PaymentCSVGenerator from '../../classes/PaymentCSVGenerator';
import PaymentXMLGenerator from '../../classes/PaymentXMLGenerator';
import { GetState, ReduxDispatch } from '../Action';
import { Material } from '../material/Material';
import MaterialsAction from '../material/MaterialsAction';
import OrdersAction from '../order/OrdersAction';
import OrderQuery from '../order/OrdersQuery';
import PaymentXmlsAction from '../payment-xml/PaymentXmlsAction';
import RednotAction from '../rednot/RednotAction';
import { MaterialObject } from './Payer';
import PayersActionType, { PayersActionObject } from './PayerActionType';

interface CountryWhere {
  country: string;
}

interface TeamWhere {
  team: string;
}

interface DonationWhere {
  isDonation: true;
}

type Where = CountryWhere | TeamWhere | DonationWhere;

export default class PayersAction {
  private static getPaymentXmlFilter(where?: Where) {
    return { order: 'createdAt DESC', where };
  }

  public static fetchMaterial() {
    return (dispatch: ReduxDispatch) => {
      dispatch(MaterialsAction.list());
    };
  }

  public static setTeam(team: string): PayersActionObject {
    return {
      type: PayersActionType.SET_TEAM,
      payload: { team },
    };
  }

  public static fetchOrdersByCountry(country: string) {
    return (dispatch: ReduxDispatch) => {
      const query = OrderQuery.getPayerQuery(country);
      const countryWhere: CountryWhere = { country };
      dispatch(PayersAction.fetchOrders(query, countryWhere));
    };
  }

  public static fetchDonationOrdersByTeam(team: string) {
    return (dispatch: ReduxDispatch) => {
      const query = OrderQuery.getDonationQuery(team);
      dispatch(PayersAction.setTeam(team));
      dispatch(PayersAction.fetchOrders(query, { isDonation: true }));
    };
  }

  public static getPaymentFiles(xmlQuery?: Where) {
    return (dispatch: ReduxDispatch) => {
      dispatch(PaymentXmlsAction.list(this.getPaymentXmlFilter(xmlQuery)));
    };
  }

  private static fetchOrders(query: any, xmlQuery?: Where) {
    return (dispatch: ReduxDispatch) => {
      dispatch(PayersAction.getPaymentFiles(xmlQuery));
      dispatch(OrdersAction.list(query));
    };
  }

  private static setMaterialObject(materialsObject: MaterialObject): PayersActionObject {
    return {
      type: PayersActionType.SET_MATERIAL,
      payload: { materialsObject },
    };
  }

  public static remapMaterials(materials: Material[]) {
    return (dispatch: ReduxDispatch) => {
      const remappedMaterials: MaterialObject = {};
      materials.forEach((material) => {
        remappedMaterials[material.id] = material.name;
      });
      dispatch(this.setMaterialObject(remappedMaterials));
    };
  }

  private static getValidationMessage(orders: SelectedOrder[], validateIban: boolean = true) {
    const filteredOrders: SelectedOrder[] = orders.filter((o) => o.isSelected);
    let iban: any;

    if (validateIban) {
      iban = Yup.string()
        .test('iban', '[PayerAction] IBAN is invalid', (value) => IBAN.isValid(value))
        .required();
    }

    const clientSchema = Yup.object().shape({
      iban,
    });

    let orderShape: any = {
      client: clientSchema,
      offer: Yup.number()
        .moreThan(0)
        .required(),
    };

    if (orders[0].isDonation) {
      orderShape = {
        client: clientSchema,
      };
    }

    const orderSchema = Yup.object().shape(orderShape);

    const schema = Yup.array()
      .of(orderSchema)
      .min(1);

    try {
      schema.validateSync(filteredOrders, { strict: true });
      return '';
    } catch (e) {
      return getErrorMessage(e);
    }
  }

  public static downloadDocument(
    orders: SelectedOrder[],
    mode: PaymentMode | Country,
    team?: string
  ) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      let generator: PaymentXMLGenerator | PaymentCSVGenerator | undefined;
      const { materialsObject } = getState().payer;
      const memberId = getState().user.data.id;
      const filteredOrders: SelectedOrder[] = orders.filter((o) => o.isSelected);

      // Get firsts order country to check if it is a uk order set undefined if orders are empty
      const firstOrder = filteredOrders[0];
      const country = firstOrder ? firstOrder.country : undefined;

      const validationMessage = this.getValidationMessage(
        filteredOrders,
        mode !== PaymentMode.DONATION && country !== Country.UK
      );

      const isDonation = mode === PaymentMode.DONATION;

      if (validationMessage) throw dispatch(RednotAction.error(validationMessage));

      if (mode === Country.FINLAND) {
        generator = new PaymentXMLGenerator(
          filteredOrders,
          materialsObject,
          memberId,
          mode,
          isDonation
        );
      }

      if (mode === Country.SWEDEN) {
        generator = new PaymentXMLGenerator(
          filteredOrders,
          materialsObject,
          memberId,
          mode,
          isDonation
        );
      }

      if (mode === PaymentMode.DONATION) {
        generator = new PaymentCSVGenerator(
          filteredOrders,
          materialsObject,
          memberId,
          mode,
          isDonation,
          team
        );
      }

      if (mode === Country.UK) {
        generator = new PaymentCSVGenerator(
          filteredOrders,
          materialsObject,
          memberId,
          mode,
          isDonation,
          team
        );
      }

      if (generator === undefined) throw dispatch(RednotAction.error('Missing generator'));

      let action = () => dispatch(PayersAction.fetchOrdersByCountry(mode));
      if (team) action = () => dispatch(PayersAction.fetchDonationOrdersByTeam(team));

      generator
        .execute()
        .then(() => action())
        .catch((e) => dispatch(RednotAction.error(getErrorMessage(e))));
    };
  }
}
