import React from 'react';
import SearchGuide from './SearchGuide';

export default () => {
  const dateFields = [
    'dueDate',
    'latestTellerCall',
    'welcomeCall',
    'welcomeCallDone',
    'closeDate',
    'packagedDate',
    'receivedDate',
    'bursarHandleDate',
    'valuationDate',
    'payed',
    'tellerDate',
  ];

  const booleanFields = [
    'isWebOrder',
    'closed',
    'termsAccepted',
    'offerAccepted',
    'ignobleReturn',
    'offeredDeclined',
    'insuranceReturn',
    'returnToValuation',
    'arrivedSmsSent',
    'inPackager',
    'isDonation',
    'isPackaged',
    'isReceived',
    'isValued',
    'isRevaluated',
    'isBoomerang',
    'isTellered',
    'isPaid',
    'isComplete',
    'isBursarReturnMade',
  ];

  const valueFields = [
    'language',
    'country',
    'closeReason',
    'closeMethod',
    'orderNumber',
    'referenceNumber',
    'silverWeight',
    'goldWeight',
    'price',
    'materialWeight',
    'team',
    'packageWeight',
    'barCode',
    'offer',
  ];

  const examples = [
    {
      query: 'orderNumber:30012',
      description: 'find order with order number 30012',
    },
    {
      query: 'isComplete:true country:sweden',
      description: 'list all complete swedish orders',
    },
    {
      query: 'offer:gt:5 offer:lt:15',
      description: 'list all orders where offer is greater than 5 euros and less than 15 euros',
    },
  ];

  return (
    <SearchGuide
      examples={examples}
      dateFields={dateFields}
      booleanFields={booleanFields}
      valueFields={valueFields}
    />
  );
};
