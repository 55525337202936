export default class TellersSelector {
  static selectIBAN = (state: ReduxState) => state.teller.iban;

  static selectBBAN = (state: ReduxState) => state.teller.bban;

  static selectUKAccountNumber = (state: ReduxState) => state.teller.ukAccountNumber;

  static selectUKSortCode = (state: ReduxState) => state.teller.ukSortCode;

  static selectIsIBANValid = (state: ReduxState) => state.teller.isIBANValid;

  static selectIsBBANValid = (state: ReduxState) => state.teller.isBBANValid;

  static selectInDealTotal = (state: ReduxState) => state.teller.inDealTotal;

  static selectOrder = (state: ReduxState) => state.orderEntry.model.data;

  static selectOrderIsLoading = (state: ReduxState) => state.orderEntry.model.isLoading;

  static selectOffer = (state: ReduxState) => state.teller.offer;

  static selectIsSMSLoading = (state: ReduxState) => state.teller.isSMSLoading;

  static selectIsInPreviewMode = (state: ReduxState) => state.teller.showPreview;

  static selectFormValues = (state: ReduxState) => state.teller.formValues;

  static selectsekOffer = (state: ReduxState) => state.teller.sekOffer;

  static selectgbpOffer = (state: ReduxState) => state.teller.gbpOffer;
}
