import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import RednotAction from '../rednot/RednotAction';
import CallLogsEffect from './CallLogsEffect';

export default class CallLogsAction {
  static ENTRY = 'CALL_LOG';

  static actionCreator = actionCreator(
    CallLogsAction.ENTRY,
    CallLogsEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = CallLogsAction.actionCreator.getCreateAction();

  static update = CallLogsAction.actionCreator.getUpdateAction();

  static fetch = CallLogsAction.actionCreator.getFindByIdAction();

  static list = CallLogsAction.actionCreator.getFindAndCountAction();

  static clean = CallLogsAction.actionCreator.getCleanAction();
}
