import * as Yup from 'yup';

export const ProductValidationSchema = Yup.object().shape({
  name: Yup.string(),
  density: Yup.string().required(),
  weight: Yup.number().required(),
  factor: Yup.number().required(),
  price: Yup.number().required(),
  category: Yup.string(),
  densityName: Yup.string(),
  inDeal: Yup.boolean(),
});

const formSchema = {
  materialWeight: Yup.number().required(),
  packageWeight: Yup.number().required(),
  valuationImage: Yup.string().required(),
  extraImages: Yup.array().of(Yup.string().required()),
  tellerMessageFromValuation: Yup.string(),
  products: Yup.array().of(ProductValidationSchema),
};

const valuationRequestSchema = {
  ...formSchema,
  price: Yup.number().required(),
  goldWeight: Yup.number().required(),
  silverWeight: Yup.number().required(),
};

export const ValuationRequestSchema = Yup.object().shape({
  ...valuationRequestSchema,
  valuatorId: Yup.string().required(),
  valuationDate: Yup.string().required(),
  isValued: Yup.string().required(),
});

export const ReValuationRequestSchema = Yup.object().shape({
  ...valuationRequestSchema,
  returnToValuation: Yup.bool().required(),
  reValuationDate: Yup.date().required(),
  reValuatorId: Yup.string().required(),
});

export default Yup.object().shape(formSchema);
