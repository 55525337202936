import React from 'react';
import { GenericCreateForm } from 'releox-react';
import TeamForm, { teamFormInitValues, TeamValidationSchema } from '../../../forms/TeamForm';
import TeamsAction from '../../../store/team/TeamsAction';
import TeamsSelector from '../../../store/team/TeamsSelector';

export default () => (
  <GenericCreateForm
    loadingSelector={TeamsSelector.selectMemberIsLoading}
    tNamespace="TeamCreateScene"
    EmbedForm={TeamForm(true)}
    initialValues={teamFormInitValues}
    onSubmit={TeamsAction.create}
    validationSchema={TeamValidationSchema}
  />
);
