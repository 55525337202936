import ReportUserContainer from '../ReportUserContainer';
import AcceptedReportUser from './AcceptedReportUser';

export default class AcceptedReportUserContainer extends ReportUserContainer<AcceptedReportUser> {
  public getReport() {
    return Object.keys(this.users).map((userId) => ({
      gross: this.users[userId].getGrossWeights(),
      net: this.users[userId].getNetWeight(),
      counts: this.users[userId].getCounts(),
      name: this.users[userId].getName(),
      offer: this.users[userId].getOffer(),
    }));
  }
}
