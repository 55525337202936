import { push } from 'react-router-redux';
import routes from '../../configs/routes';
import { ReduxDispatch } from '../Action';
import { Client } from '../client/Client';
import ClientsAction from '../client/ClientsAction';
import { Order } from '../order/Order';
import OrdersAction from '../order/OrdersAction';

export default class BuyerModifyAction {
  public static onSubmit(body: Order) {
    return (dispatch: ReduxDispatch) => {
      if (!body.client) throw new Error('Missing client');
      const orderRequestBody = body;
      const clientRequestBody = body.client;
      delete orderRequestBody.client;
      const requests: Promise<Client | Order>[] = [];
      requests.push(dispatch(OrdersAction.update(body.id, orderRequestBody)));
      requests.push(dispatch(ClientsAction.update(clientRequestBody.id, clientRequestBody)));
      Promise.all(requests).then(() => {
        dispatch(push(routes.BUYER.MODIFY_ORDERS_INDEX));
      });
    };
  }
}
