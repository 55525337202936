import React from 'react';
import { CoreuiCheckBox, Input, Select } from 'releox-react';
import * as Yup from 'yup';
import { Country } from '../classes/Country';
import { Language } from '../classes/Language';
import { countryOptions, languageOptions, teamOptions } from '../options';
import { User } from '../store/user/User';

export const TeamValidationSchema: any = (ignore: string[] = []) => {
  const rules: any = {
    name: Yup.string().required(),
    buyerCommission: Yup.number().required(),
    password: Yup.string()
      .min(8)
      .required(),
    username: Yup.string()
      .min(8)
      .required(),
    preferLanguage: Yup.string().required(),
    preferCountry: Yup.string().required(),
    location: Yup.string(),
  };
  ignore.forEach((key) => {
    delete rules[key];
  });
  return Yup.object().shape(rules);
};

export const teamFormInitValues: User = {
  id: '',
  name: '',
  username: '',
  phone: '',
  buyerCommission: 0,
  preferLanguage: Language.FINNISH,
  preferCountry: Country.FINLAND,
  team: 'turku',
  isActive: true,
  admin: false,
  isColdCaller: false,
  buyer: false,
  buyerWebOrder: false,
  packager: false,
  receiver: false,
  valuation: false,
  teller: false,
  bursar: false,
  customer: false,
  payer: false,
  ccManager: false,
  teamLeader: false,
  messageCenter: false,
  officeAdmin: false,
};

export default (showPassword: boolean = false) => () => {
  const passwordField = (
    <Input inline inlineLabelWidth={2} label="Password" type="password" name="password" />
  );
  return (
    <div>
      <Input inline inlineLabelWidth={2} label="Name" name="name" />
      <Input inline inlineLabelWidth={2} label="Username" name="username" />
      {showPassword ? passwordField : ''}
      <Input inline inlineLabelWidth={2} label="Phone" name="phone" />
      <Input
        inline
        inlineLabelWidth={2}
        type="number"
        label="Buyer commission"
        name="buyerCommission"
      />
      <Select
        inline
        inlineLabelWidth={2}
        name="preferLanguage"
        label="Language"
        options={languageOptions}
      />
      <Select
        inline
        inlineLabelWidth={2}
        name="preferCountry"
        label="Country"
        options={countryOptions}
      />
      <Select inline inlineLabelWidth={2} name="team" label="Team" options={teamOptions} />
      <div className="row">
        <div className="col-6">
          <CoreuiCheckBox name="isActive" label="Active" />
          <CoreuiCheckBox name="admin" label="Admin" />
          <CoreuiCheckBox name="buyer" label="Buyer" />
          <CoreuiCheckBox name="isColdCaller" label="Cold caller" />
          <CoreuiCheckBox name="buyerWebOrder" label="Buyer (Web order)" />
          <CoreuiCheckBox name="packager" label="Packager" />
          <CoreuiCheckBox name="receiver" label="Receiver" />
          <CoreuiCheckBox name="valuation" label="Valuation" />
          <CoreuiCheckBox name="teller" label="Teller" />
        </div>
        <div className="col-6">
          <CoreuiCheckBox name="bursar" label="Bursar" />
          <CoreuiCheckBox name="customer" label="Customer" />
          <CoreuiCheckBox name="payer" label="Payer" />
          <CoreuiCheckBox name="ccManager" label="CC Manager" />
          <CoreuiCheckBox name="teamLeader" label="Team Leader" />
          <CoreuiCheckBox name="messageCenter" label="Message Center" />
          <CoreuiCheckBox name="officeAdmin" label="Office Admin" />
        </div>
      </div>
    </div>
  );
};
