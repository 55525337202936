import React from 'react';
import { Input, Select } from 'releox-react';
import * as Yup from 'yup';
import { Language } from '../classes/Language';
import { addressRegex, cityRegex, nameRegex, zipRegex } from '../config';
import { countryOptions, languageOptions } from '../options';
import { Client } from '../store/client/Client';

export const ClientValidationSchema: any = (ignore: string[] = []) => {
  const rules: any = {
    firstName: Yup.string()
      .matches(nameRegex)
      .required(),
    lastName: Yup.string()
      .matches(nameRegex)
      .required(),
    address: Yup.string()
      .matches(addressRegex)
      .required(),
    iban: Yup.string(),
    phone: Yup.string().required(),
    birthYear: Yup.number()
      .moreThan(1900)
      .lessThan(2100)
      .required(),
    city: Yup.string()
      .matches(cityRegex)
      .required(),
    country: Yup.string().required(),
    zip: Yup.string()
      .matches(zipRegex)
      .required(),
    language: Yup.string().required(),
    email: Yup.string().email(),
  };
  ignore.forEach((key) => {
    delete rules[key];
  });
  return Yup.object().shape(rules);
};

export const clientFormInitValue: Client = {
  id: '',
  firstName: '',
  lastName: '',
  address: '',
  iban: '',
  phone: '',
  birthYear: '',
  city: '',
  country: '',
  zip: '',
  language: Language.FINNISH,
  email: '',
  ukSortCode: '',
  ukAccountNumber: '',
};

interface Props {
  prefix?: string;
  hideLanguage?: boolean;
  hideIban?: boolean;
  hideCountry?: boolean;
  inline?: boolean;
}

export default ({ prefix, hideLanguage, hideCountry, hideIban, inline = true }: Props) => {
  let pre = '';
  if (prefix) pre = `${prefix}.`;
  return (
    <div>
      <Input inline={inline} inlineLabelWidth={2} label="First name" name={`${pre}firstName`} />
      <Input inline={inline} inlineLabelWidth={2} label="Last name" name={`${pre}lastName`} />
      <Input inline={inline} inlineLabelWidth={2} label="Address" name={`${pre}address`} />
      {hideIban ? (
        undefined
      ) : (
        <Input inline={inline} inlineLabelWidth={2} label="IBAN" name={`${pre}iban`} />
      )}
      {hideIban ? (
        undefined
      ) : (
        <Input inline={inline} inlineLabelWidth={2} label="UK Sort Code" name="ukSortCode" />
      )}
      {hideIban ? (
        undefined
      ) : (
        <Input
          inline={inline}
          inlineLabelWidth={2}
          label="UK Account number"
          name="ukAccountNumber"
        />
      )}
      <Input inline={inline} inlineLabelWidth={2} label="Phone" name={`${pre}phone`} />
      <Input
        inline={inline}
        inlineLabelWidth={2}
        label="Birth year"
        type="number"
        name={`${pre}birthYear`}
      />
      <Input inline={inline} inlineLabelWidth={2} label="City" name={`${pre}city`} />
      {hideCountry ? (
        undefined
      ) : (
        <Select
          inline={inline}
          inlineLabelWidth={2}
          name={`${pre}country`}
          label="Country"
          options={countryOptions}
        />
      )}
      <Input inline={inline} inlineLabelWidth={2} label="Zip" name={`${pre}zip`} />

      {hideLanguage ? (
        undefined
      ) : (
        <Select
          inline={inline}
          inlineLabelWidth={2}
          name="language"
          label="Language"
          options={languageOptions}
        />
      )}
      <Input inline={inline} inlineLabelWidth={2} label="Email" name={`${pre}email`} />
    </div>
  );
};
