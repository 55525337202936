import routes from '../../configs/routes';
import { sendSingleSMS } from '../../send-sms';
import { Action, ReduxDispatch } from '../Action';
import AfterCallsAction from '../after-call/AfterCallsAction';
import CallLog from '../call-log/CallLog';
import CallLogsAction from '../call-log/CallLogsAction';
import ErrorsAction from '../error/ErrorsAction';
import { Order } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import RednotAction from '../rednot/RednotAction';
import { User } from '../user/User';

export enum ColdCasesEventOption {
  CLOSE = 'close',
  DOES_NOT_ANSWER = 'doesNotAnswer',
  EMPTY_EVENT = '',
}

export enum ColdCallsActionTypes {
  SMS_REQUEST = '@COLD_CALL:SMS_REQUEST',
  SMS_FAIL = '@COLD_CALL:SMS_FAIL',
  SMS_SUCCESS = '@COLD_CALL:SMS_SUCCESS',
}

type SMSRequest = Action<ColdCallsActionTypes.SMS_REQUEST>;
type SMSFail = Action<ColdCallsActionTypes.SMS_FAIL>;
type SMSSuccess = Action<ColdCallsActionTypes.SMS_SUCCESS>;

export type ColdCallsActionObject = SMSRequest | SMSFail | SMSSuccess;

export default class ColdCasesAction {
  public static getInitialValues(order: Order) {
    return {
      generalMessage: order.generalMessage,
      valuationMessageFromBuyer: order.valuationMessageFromBuyer,
      tellerMessageFromBuyer: order.tellerMessageFromBuyer,
      event: ColdCasesEventOption.EMPTY_EVENT,
      logMessage: '',
    };
  }

  public static onSubmit(order: Order, user: User, body: ColdCallBody) {
    return (dispatch: ReduxDispatch) => {
      if (!body.event) return dispatch(RednotAction.error('Please choose event'));

      const callLogBody: CallLog = {
        id: '',
        type: 'COLD_CALL',
        event: body.event,
        message: body.logMessage,
        memberId: user.id,
        orderId: order.id,
      };

      const updateRequestBody: Partial<Order> = {
        latestColdCall: new Date().toString(),
      };

      const updateData: Partial<Order> = {
        generalMessage: body.generalMessage,
        valuationMessageFromBuyer: body.valuationMessageFromBuyer,
        tellerMessageFromBuyer: body.tellerMessageFromBuyer,
        isColdCase: true,
        coldCaseCallerId: user.id,
      };

      const redirect = routes.BUYER.COLD_CASES_INDEX_ROOT.replace(':language', order.language);
      const actionOptions = { redirect };

      dispatch(OrdersAction.update(order.id, updateData));

      switch (body.event) {
        case ColdCasesEventOption.DOES_NOT_ANSWER:
          dispatch(CallLogsAction.create(callLogBody));
          return dispatch(OrdersAction.update(order.id, updateRequestBody, actionOptions));

        case ColdCasesEventOption.CLOSE:
          dispatch(CallLogsAction.create(callLogBody));
          dispatch(OrdersAction.closeOrder(order.id, user.id, 'phone', 'Closed by cold call'));
          return dispatch(OrdersAction.update(order.id, updateRequestBody, actionOptions));

        default:
          return dispatch(RednotAction.error('Please choose event'));
      }
    };
  }

  public static sendMHSMS(order: Order, phone: string) {
    return (dispatch: ReduxDispatch) => {
      if (order.language === 'swedish') {
        return dispatch(RednotAction.error('Only Finnish orders can send SMS'));
      }

      if (!phone) return dispatch(RednotAction.error('Missing phone number'));

      dispatch(this.sendSMSRequest());
      return sendSingleSMS(phone, AfterCallsAction.MHSMSMessage)
        .then(() => {
          dispatch(this.sendSMSSuccess());
          dispatch(RednotAction.success('Message sent'));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.sendSMSFail)));
    };
  }

  private static sendSMSRequest(): ColdCallsActionObject {
    return {
      type: ColdCallsActionTypes.SMS_REQUEST,
    };
  }

  private static sendSMSSuccess(): ColdCallsActionObject {
    return {
      type: ColdCallsActionTypes.SMS_SUCCESS,
    };
  }

  private static sendSMSFail(): ColdCallsActionObject {
    return {
      type: ColdCallsActionTypes.SMS_FAIL,
    };
  }
}

export type ColdCallBody = ReturnType<typeof ColdCasesAction.getInitialValues>;
