import React from 'react';
import { Field, FieldProps } from 'formik';

interface Props {
  onChange(values: FieldProps): any;
}

export default <T extends {}>({ onChange }: Props) => (
  <Field>
    {(fieldProps: FieldProps) => {
      onChange(fieldProps);
      return '';
    }}
  </Field>
);
