import { useMemo } from 'react';
import { UseSendButtonStateHook } from './use-send-button-state.types';

export const useSendButtonState: UseSendButtonStateHook = (opt) => {
  return useMemo(() => {
    if (!opt.barCode) {
      return {
        isDisabled: true,
        text: 'No barcode',
      };
    }

    if (opt.isClientUpdating) {
      return {
        isDisabled: true,
        text: 'Updating client...',
      };
    }

    if (opt.isSending) {
      return {
        isDisabled: true,
        text: 'Sending...',
      };
    }
    return {
      isDisabled: false,
      text: 'Send',
    };
  }, [opt.barCode, opt.isClientUpdating, opt.isSending]);
};
