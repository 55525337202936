import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, DetailField, Input, TextArea } from 'releox-react';
import FileInput from '../../components/FileUploadInput';
import FormikEffect from '../../components/FormikEffect';
import { SignedImg } from '../../components/SignedComponents';
import ValuationOrderInfo from '../../components/ValuationOrderInfo';
import ProductsArrayForm from '../../components/valuation/ProductsArrayForm';
import routes from '../../configs/routes';
import OrdersAction from '../../store/order/OrdersAction';
import OrdersSelector from '../../store/order/OrdersSelector';
import UsersSelector from '../../store/user/UsersSelector';
import ValuatesAction, { ValuateScenes } from '../../store/valuate/ValuatesAction';
import ValuatesSelector from '../../store/valuate/ValuatesSelector';
import getFirstFormikError from '../../utils/get-first-formik-error';
import { ValuationFormBody } from './ValuateInitialValues';
import ValuateValidationSchema from './ValuateValidationSchema';

export default () => {
  const dispatch = useDispatch();
  const initForm = useSelector(ValuatesSelector.selectFormValues);
  const order = useSelector(OrdersSelector.selectOrder);
  const user = useSelector(UsersSelector.selectUser);
  const goldWeight = useSelector(ValuatesSelector.selectGoldWeight);
  const silverWeight = useSelector(ValuatesSelector.selectSilverWeight);
  const customGoldWeight = useSelector(ValuatesSelector.selectCustomGoldWeight);
  const otherWeight = useSelector(ValuatesSelector.selectOtherWeight);
  const totalPrice = useSelector(ValuatesSelector.selectPrice);

  const onSubmit = useCallback(
    (body: ValuationFormBody) => {
      dispatch(ValuatesAction.onSubmit(body));
    },
    [dispatch]
  );

  const remapOrder = useCallback((): ValuationFormBody => {
    return {
      materialWeight: order.materialWeight ? `${order.materialWeight}` : initForm.materialWeight,
      packageWeight: order.packageWeight ? `${order.packageWeight}` : initForm.packageWeight,
      valuationImage: order.valuationImage || initForm.valuationImage,
      tellerMessageFromValuation: order.tellerMessageFromValuation
        ? order.tellerMessageFromValuation
        : initForm.tellerMessageFromValuation,
      products: initForm.products.length ? initForm.products : order.products,
      extraImages: order.extraImages || [],
    };
  }, [order, initForm]);

  const markAsBoomerang = useCallback(() => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (confirm('Are you sure you want to close order?')) {
      dispatch(OrdersAction.markAsBoomerang(order.id, user.id, routes.VALUATION.BAR_CODE_PROMPT));
    }
  }, [dispatch, order, user]);

  const previousState = useCallback(() => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (confirm('Are you sure you want to go back? You lose all unsaved changes')) {
      dispatch(ValuatesAction.nextStep(ValuateScenes.MESSAGES));
    }
  }, [dispatch]);

  const calculate = useCallback(
    (fieldProps: FieldProps<ValuationFormBody>) => {
      dispatch(ValuatesAction.calculate(fieldProps));
    },
    [dispatch]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={remapOrder()}
      validationSchema={ValuateValidationSchema}
    >
      {({ isValid, errors, values }) => (
        <Form>
          <FormikEffect onChange={calculate} />
          <div className="row">
            <div className="col-lg-6">
              <Card>
                <CardTitle>Basic information</CardTitle>
                <Input name="packageWeight" label="Package weight" />
                <Input name="materialWeight" label="Material Weight" />
                <TextArea name="tellerMessageFromValuation" label="Message to teller" />

                <Button id="back-button" onClick={previousState}>
                  Back
                </Button>
                <Button id="markAsBoomerang-button" className="ml-3" onClick={markAsBoomerang}>
                  Marks as boomerang
                </Button>
              </Card>
            </div>
            <div className="col-lg-6">
              <Card>
                <ValuationOrderInfo />
                <hr />
                <CardTitle>Weights</CardTitle>
                <table>
                  <tbody>
                    <DetailField label="Gold weight">{goldWeight}</DetailField>
                    <DetailField label="Silver weight">{silverWeight}</DetailField>
                    <DetailField label="Custom gold weight">{customGoldWeight}</DetailField>
                    <DetailField label="Other weight">{otherWeight}</DetailField>
                    <DetailField label="Total price">{totalPrice}</DetailField>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
          <ProductsArrayForm />
          <div className="row">
            <div className="col-lg-6">
              <Card>
                <Button
                  id="preview-button"
                  className="float-right"
                  type="submit"
                  disabled={!isValid}
                  disabledText={getFirstFormikError(errors)}
                >
                  Preview
                </Button>
                <Field
                  name="valuationImage"
                  label="Valuation image"
                  component={FileInput}
                  order={order}
                />
                <FieldArray
                  name="extraImages"
                  render={(arrayHelpers) => (
                    <>
                      {arrayHelpers.form.values.extraImages &&
                      arrayHelpers.form.values.extraImages.length > 0
                        ? arrayHelpers.form.values.extraImages.map(
                            (extraImage: string, index: number) => (
                              <>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <Field
                                      name={`extraImages.${index}`}
                                      label={`Extra valuation image ${index + 1}`}
                                      component={FileInput}
                                      order={order}
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      style={{ marginTop: '30px' }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                </div>
                              </>
                            )
                          )
                        : ''}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => arrayHelpers.push('')}
                      >
                        Add extra image
                      </button>
                    </>
                  )}
                />
              </Card>
            </div>
            <div className="col-lg-6">
              <div className="mb-4">
                {values.valuationImage ? (
                  <SignedImg className="valuation-img" src={values.valuationImage} />
                ) : (
                  ''
                )}
              </div>
              <div className="row">
                {values.extraImages && values.extraImages.length
                  ? values.extraImages.map((img) =>
                      img ? (
                        <div className="col-lg-6">
                          <SignedImg className="valuation-img" src={img} />
                        </div>
                      ) : (
                        undefined
                      )
                    )
                  : ''}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
