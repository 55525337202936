import moment from 'moment';

const CcmanagerReportSearchInitialValues = {
  startDate: moment()
    .startOf('week')
    .toDate(),
  endDate: moment()
    .endOf('week')
    .toDate(),
  callerId: '',
};

export default CcmanagerReportSearchInitialValues;
export type CCManagerReportBody = typeof CcmanagerReportSearchInitialValues;
