import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'releox-react';
import { Country } from '../../../classes/Country';
import OrdersSelector from '../../../store/order/OrdersSelector';
import TellersAction from '../../../store/teller/TellersAction';
import TellersSelector from '../../../store/teller/TellersSelector';

export default () => {
  const dispatch = useDispatch();

  const offer = useSelector(TellersSelector.selectOffer);
  const sekOffer = useSelector(TellersSelector.selectsekOffer);
  const gbpOffer = useSelector(TellersSelector.selectgbpOffer);
  const order = useSelector(OrdersSelector.selectOrder);

  const onOfferFocus = useCallback(() => dispatch(TellersAction.setManualMode()), [dispatch]);

  const onOfferChange = useCallback(
    (e: any) => {
      dispatch(TellersAction.setOffer(e.target.value));
    },
    [dispatch]
  );

  const onsekOfferChange = useCallback(
    (e: any) => {
      dispatch(TellersAction.setsekOffer(e.target.value));
    },
    [dispatch]
  );

  const ongbpOfferChange = useCallback(
    (e: any) => {
      dispatch(TellersAction.setgbpOffer(e.target.value));
    },
    [dispatch]
  );

  return (
    <div className="form-group">
      <label>Offer</label>
      <input
        type="text"
        placeholder="Offer"
        value={offer}
        onFocus={onOfferFocus}
        onChange={onOfferChange}
        className="form-control"
      />
      {order.country === Country.SWEDEN && (
        <>
          <label>SEK offer</label>
          <input
            type="text"
            placeholder="SEK offer"
            value={sekOffer}
            onChange={onsekOfferChange}
            className="form-control"
          />
        </>
      )}
      {order.country === Country.UK && (
        <>
          <label>GBP offer</label>
          <input
            type="text"
            placeholder="GBP offer"
            value={gbpOffer}
            onChange={ongbpOfferChange}
            className="form-control"
          />
        </>
      )}
      <p className="text-muted">This value will be paid to the customer</p>
      <Input name="tellerBreakdown" label="Teller breakdown" />
    </div>
  );
};
