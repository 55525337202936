import { Order } from '../../../store/order/Order';
import ValuatedReportUser from './ValuatedReportUser';
import ValuatedReportUserContainer from './ValuatedReportUserContainer';

const getValuatedOffersReport = (
  inOrders: Order[],
  outOrders: Order[],
  out2wOrders: Order[],
  cancelledOrders: Order[]
) => {
  const userContainer = new ValuatedReportUserContainer();
  let auCount = 0;
  let totalCount = 0;
  let outCount = 0;
  let out2WCount = 0;
  let cancelledCount = 0;

  inOrders.forEach((o) => {
    if (!userContainer.hasUser(o.callerId)) {
      userContainer.addUser(o.callerId, new ValuatedReportUser(o.caller?.name as string));
    }

    const user = userContainer.getUser(o.callerId);

    user.incrementTotalCount();
    totalCount += 1;

    if (o.goldWeight !== undefined && o.goldWeight > 0) {
      user.incrementGoldCount();
      auCount += 1;
    }
  });

  outOrders.forEach((o) => {
    if (!userContainer.hasUser(o.callerId)) {
      userContainer.addUser(o.callerId, new ValuatedReportUser(o.caller?.name as string));
    }

    const user = userContainer.getUser(o.callerId);
    user.incrementOutCount();

    outCount += 1;
  });

  out2wOrders.forEach((o) => {
    if (!userContainer.hasUser(o.callerId)) {
      userContainer.addUser(o.callerId, new ValuatedReportUser(o.caller?.name as string));
    }

    const user = userContainer.getUser(o.callerId);
    user.incrementOut2WCount();

    out2WCount += 1;
  });

  cancelledOrders.forEach((o) => {
    if (!userContainer.hasUser(o.callerId)) {
      userContainer.addUser(o.callerId, new ValuatedReportUser(o.caller?.name as string));
    }

    const user = userContainer.getUser(o.callerId);
    user.incrementCancelledCount();

    cancelledCount += 1;
  });

  return {
    users: userContainer.getReport(),
    auCount,
    outCount,
    out2WCount,
    totalCount,
    cancelledCount,
  };
};

export default getValuatedOffersReport;

export type ValuatedOrdersReport = ReturnType<typeof getValuatedOffersReport>;
