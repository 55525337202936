import * as _ from 'lodash';
import React, { useMemo } from 'react';
import { Card } from 'releox-react';
import { AcceptedOffersReportResponse } from '../requests/report.request';
import { Material } from '../store/material/Material';

type Props = {
  data: AcceptedOffersReportResponse;
  materials: Material[];
};

const builtIntFields = [
  {
    count: 'counts.customGold',
    key: 'grossWeights.customGold',
    name: 'Custom Gold gross',
  },
  {
    count: 'counts.customGold',
    key: 'netWeights.customGold',
    name: 'Custom Gold net',
  },
  {
    count: 'counts.gold',
    key: 'grossWeights.gold',
    name: 'Gold gross',
  },
  {
    count: 'counts.gold',
    key: 'netWeights.gold',
    name: 'Gold net',
  },
  {
    key: 'totalOffer',
    name: 'Total',
  },
];

const getOrderValue = (material: Material) => {
  switch (material.category) {
    case 'gold':
      return 1;
    case '':
      return 2;
    case 'silver':
      return 3;
    default:
      return 0;
  }
};

const getCustomField = (record: Props['data'][0]) => (b: typeof builtIntFields[0]) => {
  if (!_.has(record, b.key)) return <td key={b.name}>-</td>;
  let content = _.get(record, b.key);
  if (b.count) content += ` (${_.get(record, b.count)})`;
  return <td key={b.name}>{content}</td>;
};

export default ({ data, materials }: Props) => {
  const sortedMaterials = useMemo(
    () => _.orderBy(materials, 'price', 'desc').sort((a, b) => getOrderValue(a) - getOrderValue(b)),
    [materials]
  );
  return (
    <div>
      <Card>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              {sortedMaterials.map((material) => (
                <th>{material.name}</th>
              ))}
              {builtIntFields.map((b) => (
                <th key={b.name}>{b.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data
              .filter((record) => record.id !== 'total')
              .map((record) => (
                <tr key={record.name}>
                  <td>{record.name}</td>
                  {sortedMaterials.map((material) => {
                    if (!_.has(record, `grossWeights.${material.id}`)) return <td>-</td>;
                    return (
                      <td>
                        {_.get(record, `grossWeights.${material.id}`, '-')} (
                        {_.get(record, `counts.${material.id}`, '-')})
                      </td>
                    );
                  })}
                  {builtIntFields.map(getCustomField(record))}
                </tr>
              ))}
            {data
              .filter((record) => record.id === 'total')
              .map((record) => (
                <tr key={record.name} style={{ borderTop: '3px solid #887' }}>
                  <td>{record.name} Gross</td>
                  {sortedMaterials.map((material) => {
                    if (!_.has(record, `grossWeights.${material.id}`)) return <td>-</td>;
                    return (
                      <td>
                        {_.get(record, `grossWeights.${material.id}`, '-')} (
                        {_.get(record, `counts.${material.id}`, '-')})
                      </td>
                    );
                  })}
                  {builtIntFields.map(getCustomField(record))}
                </tr>
              ))}
            {/* Net total */}
            {data
              .filter((record) => record.id === 'total')
              .map((record) => (
                <tr key={record.name}>
                  <td>{record.name} Net</td>
                  {sortedMaterials.map((material) => {
                    if (material.category !== 'gold') return <td>-</td>;
                    if (!_.has(record, `netWeights.${material.id}`)) return <td>-</td>;

                    return (
                      <td>
                        {_.get(record, `netWeights.${material.id}`, '-')} (
                        {_.get(record, `counts.${material.id}`, '-')})
                      </td>
                    );
                  })}
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  {/* {builtIntFields.filter((b) => b.name.includes('net')).map(getCustomField(record))} */}
                </tr>
              ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
