import React from 'react';
import { useSelector } from 'react-redux';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../../columns/OrderColumn';
import routes from '../../../configs/routes';
import OrdersAction from '../../../store/order/OrdersAction';
import OrderQuery from '../../../store/order/OrdersQuery';
import OrdersSelector from '../../../store/order/OrdersSelector';
import UsersSelector from '../../../store/user/UsersSelector';

export default () => {
  const columns: DataTableColumn[] = [
    OrderColumn.orderNumber,
    OrderColumn.clientName,
    OrderColumn.phone,
    OrderColumn.welcomeCall,
    OrderColumn.afterCallLatest,
    OrderColumn.afterCallTries,
    OrderColumn.callerName,
    // OrderColumn.afterCallLatestLegacy,
  ];

  const sort = {
    dataField: 'dueDate',
    order: 'ASC',
  };
  const user = useSelector(UsersSelector.selectUser);
  const query = OrderQuery.getBuyerAfterCallIndexQuery(user);

  return (
    <GenericIndexScene
      tNamespace="AfterCallIndexScene"
      loadingSelector={OrdersSelector.selectIsListLoading}
      dataSelector={OrdersSelector.selectOrders}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={columns}
      defaultSorted={sort}
      query={query}
      redirectUrl={routes.BUYER.AFTER_CALL}
    />
  );
};
