import { TellerSmsActionObject } from './TellerSmsAction';

export default class TellerSmsReducer {
  private static initState = {
    message: '',
  };

  public static reducer(
    state = TellerSmsReducer.initState,
    action: TellerSmsActionObject
  ): typeof state {
    switch (action.type) {
      default:
        return state;
    }
  }
}
