import React from 'react';
import { useSelector } from 'react-redux';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../../columns/OrderColumn';
import routes from '../../../configs/routes';
import OrdersAction from '../../../store/order/OrdersAction';
import OrderQuery from '../../../store/order/OrdersQuery';
import OrdersSelector from '../../../store/order/OrdersSelector';
import UsersSelector from '../../../store/user/UsersSelector';

export default () => {
  const columns: DataTableColumn[] = [
    OrderColumn.orderNumber,
    OrderColumn.phone,
    OrderColumn.clientName,
    OrderColumn.welcomeCall,
    OrderColumn.welcomeCallLatest,
    OrderColumn.welcomeCallTries,
    OrderColumn.callerName,
    // OrderColumn.welcomeCallLatestLegacy,
    // OrderColumn.welcomeCallTriesLegacy,
  ];

  const sort = {
    dataField: 'welcomeCall',
    order: 'ASC',
  };

  const user = useSelector(UsersSelector.selectUser);
  const query = OrderQuery.getBuyerWelcomeCallIndex(user);

  return (
    <GenericIndexScene
      tNamespace="WelcomeCallIndexScene"
      loadingSelector={OrdersSelector.selectIsListLoading}
      dataSelector={OrdersSelector.selectOrders}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={columns}
      defaultSorted={sort}
      query={query}
      redirectUrl={routes.BUYER.WELCOME_CALL}
    />
  );
};
