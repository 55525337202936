import { Language } from '../classes/Language';

export default {
  HOME: '/',
  LOGOUT: '/logout',
  ADMIN_POLICE_REPORTS: '/admin/police-reports',
  ADMIN_PACKAGE_CODE_REGISTRY: '/admin/package-code-registry',
  ADMIN_GREETINGS: '/admin/greetings',
  ADMIN_ORDER: {
    INDEX: '/admin/orders',
    EDIT: '/admin/orders/:id',
  },
  ADMIN_USERS: {
    CREATE: '/admin/users/new',
    INDEX: '/admin/users',
    EDIT: '/admin/users/:id',
    PASSWORD: '/admin/users/:id/password',
  },
  ADMIN_MATERIAL: {
    CREATE: '/admin/materials/new',
    INDEX: '/admin/materials',
    EDIT: '/admin/materials/:id',
  },
  ADMIN_CLIENT: {
    INDEX: '/admin/clients',
    EDIT: '/admin/clients/:id',
  },
  REPORTS: {
    INDEX: '/reports',
    VALUATED_ORDERS: '/reports/valuated-orders',
    ACCEPTED_OFFERS: '/reports/accepted-offers',
  },
  CUSTOMER_SERVICE: {
    ORDER_INDEX: '/customer-service/order',
    ORDER_SHOW: '/customer-service/order/:id',
    CLIENT_INDEX: '/customer-service/client',
    CLIENT_SHOW: '/customer-service/client/:id',
    CLOSE: '/customer-service/order/:id/close',
    CLOSED_INDEX: '/customer-service/closed',
    MASS_BOOMERANG: '/customer-service/mass-boomerang',
  },
  CC_MANAGER: {
    TRANSFER: '/cc-manager/transfer',
    CANCEL: '/cc-manager/cancel-transfer',
    LISTS: '/cc-manager/lists',
    REPORT: '/cc-manager/report',
  },
  BUYER: {
    ADD_ORDER: '/buyer/add-order',
    STATS: '/buyer/stats',
    WELCOME_CALLS: '/buyer/welcome-calls',
    WELCOME_CALL: '/buyer/welcome-calls/:id',
    AFTER_CALLS: '/buyer/after-calls',
    AFTER_CALL: '/buyer/after-calls/:id',
    MODIFY_ORDERS_INDEX: '/buyer/modify-orders',
    MODIFY_ORDERS_EDIT: '/buyer/modify-orders/:id',

    COLD_CASES_INDEX_ROOT: '/cold-cases/:language',
    COLD_CASES_INDEX_FI: `/cold-cases/${Language.FINNISH}`,
    COLD_CASES_INDEX_SE: `/cold-cases/${Language.SWEDISH}`,
    COLD_CASES_INDEX_FISE: `/cold-cases/${Language.FINLAND_SWEDISH}`,
    COLD_CASES_INDEX_UK: `/cold-cases/${Language.UK_ENGLISH}`,
    COLD_CASES_SHOW: '/cold-cases/:language/:id',
  },
  PACKAGER: {
    PACKAGER_LIST: '/packager/list',
    PACKAGER_LANGUAGE_SELECT: '/packager/pack',
    PACKAGER_SYSTEM_UK: '/packager/packager-system/uk',
    PACKAGER_SYSTEM: '/packager/packager-system/:language',
    PACKAGER_HISTORY: '/packager/history',
  },
  RECEIVER: '/receiver',
  VALUATION: {
    BAR_CODE_PROMPT: '/valuation/valuate',
    VALUATE: '/valuation/valuate/:id',
    RE_VALUATION_FINNISH: '/valuation/re-valuation/finnish',
    RE_VALUATION_SWEDISH: '/valuation/re-valuation/swedish',
    RE_VALUATION_UK: '/valuation/re-valuation/uk',
    WAITING_LIST: '/valuation/waiting-list',
    STATS: '/valuation/stats',
  },
  BURSAR: {
    BAR_CODE_PROMPT: '/bursar/:country/barcode',
    BURSAR_PAGE: '/bursar/:country/:id',
    BURSAR_HANDLED: '/bursar/handled',
    BURSAR_UNHANDLED: '/bursar/unhandled',
  },
  PAYER: {
    DONATION: '/payer/donation',
    COUNTRY: '/payer/:country',
  },
  TELLER: {
    TODAY_ORDERS: '/teller/today',
    FI: '/teller/finnish/orders',
    FI_ZERO: '/teller/finnish/zero',
    FI_SILVER: '/teller/finnish/silver',
    FI_OTHER: '/teller/finnish/others',
    SWE: '/teller/swedish/orders',
    SWE_ZERO: '/teller/swedish/zero',
    SWE_SILVER: '/teller/swedish/silver',
    SWE_OTHER: '/teller/swedish/others',
    FISWE: '/teller/finland-swedish/orders',
    FISWE_ZERO: '/teller/finland-swedish/zero',
    FISWE_SILVER: '/teller/finland-swedish/silver',
    FISWE_OTHER: '/teller/finland-swedish/others',
    UK: `/teller/${Language.UK_ENGLISH}/orders`,
    UK_ZERO: `/teller/${Language.UK_ENGLISH}/zero`,
    UK_SILVER: `/teller/${Language.UK_ENGLISH}/silver`,
    UK_OTHER: `/teller/${Language.UK_ENGLISH}/others`,
    TELLER_LANG_INDEX: '/teller/:lang',
    TELLER_LANG_ZERO: '/teller/:lang/zero',
    TELLER_LANG_SILVER: '/teller/:lang/silver',
    TELLER_SHOW: '/teller/:id',
    // TELLER_CALL_REQUEST: '/teller/call-requests',
    TELLER_SMS_LOG: '/teller/sms/log',
    TELLER_SMS: '/teller/sms/:language',
    TELLER_FINNISH_SMS: '/teller/sms/finnish',
    TELLER_SWEDISH_SMS: '/teller/sms/swedish',
    TELLER_FINLAND_SWEDISH_SMS: '/teller/sms/finland-swedish',
  },
  TIME_RECORDING: {
    INDEX: '/time-recording',
    CREATE: '/time-recording/new',
    EDIT: '/time-recording/:id/edit',
  },
};
