import { CancelTransferFormValues } from './CancelCallTransfer';
import {
  CancelCallTransfersActionObject,
  CancelCallTransfersActionTypes,
} from './CancelCallTransfersAction';

export default class CancelCallTransfersReducer {
  private static initState = {
    cancelTransferField: [] as CancelTransferFormValues[],
  };

  public static reducer(
    state = CancelCallTransfersReducer.initState,
    action: CancelCallTransfersActionObject
  ): typeof state {
    switch (action.type) {
      case CancelCallTransfersActionTypes.SEARCH_SUCCESS:
        return {
          ...state,
          cancelTransferField: action.payload.cancelTransferField,
        };

      case CancelCallTransfersActionTypes.RESET:
        return CancelCallTransfersReducer.initState;

      default:
        return state;
    }
  }
}
