import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle } from 'releox-react';
import CcmanagerReportSearchForm from '../../forms/ccmanager-report-search/CcmanagerReportSearchForm';
import CcmanagerReportSearchInitialValues, {
  CCManagerReportBody,
} from '../../forms/ccmanager-report-search/CcmanagerReportSearchInitialValues';
import CcmanagerReportSearchValidationSchema from '../../forms/ccmanager-report-search/CcmanagerReportSearchValidationSchema';
import CcManagerReportsAction from '../../store/cc-manager-report/CcManagerReportsAction';
import CcManagerReportsSelector from '../../store/cc-manager-report/CcManagerReportsSelector';

export default () => {
  const dispatch = useDispatch();
  const data = useSelector(CcManagerReportsSelector.selectMessage);

  const handleSubmit = useCallback(
    (body: CCManagerReportBody) => {
      dispatch(CcManagerReportsAction.fetch(body));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(CcManagerReportsAction.clean());
    };
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-md-6">
        <Card>
          <Formik
            initialValues={CcmanagerReportSearchInitialValues}
            onSubmit={handleSubmit}
            validationSchema={CcmanagerReportSearchValidationSchema}
          >
            <Form>
              <CcmanagerReportSearchForm />
              <Button id="search" type="submit">
                Search
              </Button>
            </Form>
          </Formik>
          <table className="table">
            <thead>
              <tr>
                <td />
                <th>User</th>
                <th>Team</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Total</th>
                <td>{data.totalCountUser}</td>
                <td>{data.totalCountTeam}</td>
              </tr>
              <tr>
                <td colSpan={4} className="p-4" />
              </tr>
              <tr>
                <th>Total in</th>
                <td>{data.totalInCountUser}</td>
                <td>{data.totalInCountTeam}</td>
              </tr>
              <tr>
                <th>Total Cancel</th>
                <td>{data.totalCancelCountUser}</td>
                <td>{data.totalCancelCountTeam}</td>
              </tr>
              <tr>
                <th>Total in AU</th>
                <td>{data.auInCountUser}</td>
                <td>{data.auInCountTeam}</td>
              </tr>
              <tr>
                <th>Total in AG/AU</th>
                <td>{data.returnAgAuUser}</td>
                <td>{data.returnAgAuTeam}</td>
              </tr>
              <tr>
                <td colSpan={4} className="p-4" />
              </tr>
              <tr>
                <th>Cancel silver only</th>
                <td>{data.cancelSilverOnlyCountUser}</td>
                <td>{data.cancelSilverOnlyCountTeam}</td>
              </tr>
              <tr>
                <th>Cancel no value</th>
                <td>{data.cancelNoValueCountUser}</td>
                <td>{data.cancelNoValueCountTeam}</td>
              </tr>
              <tr>
                <th>Cancel invoice paid</th>
                <td>{data.cancelPaidInvoiceCountUser}</td>
                <td>{data.cancelPaidInvoiceCountTeam}</td>
              </tr>
              <tr>
                <td colSpan={4} className="p-4" />
              </tr>
              <tr>
                <th>Warm cases</th>
                <td>{data.activeWarmCountUser}</td>
                <td>{data.activeWarmCountTeam}</td>
              </tr>
              <tr>
                <th>Cold cases</th>
                <td>{data.activeColdCountUser}</td>
                <td>{data.activeColdCountTeam}</td>
              </tr>
              <tr>
                <td colSpan={4} className="p-4" />
              </tr>
              <tr>
                <th>Gold avg</th>
                <td>{data.goldAvgUser}</td>
                <td>{data.goldAvgTeam}</td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
      <div className="col-md-6">
        <Card>
          <>
            <CardTitle>Info</CardTitle>
            <b>Total cancel:</b> closed: true, createdAt:lt:(To date), createdAt:gt:(From date)
          </>
        </Card>
      </div>
    </div>
  );
};
