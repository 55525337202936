export default {
  totalCountUser: 0,
  totalCountTeam: 0,
  totalCountLocation: 0,
  activeWarmCountUser: 0,
  activeWarmCountTeam: 0,
  activeWarmCountLocation: 0,
  activeColdCountUser: 0,
  activeColdCountTeam: 0,
  activeColdCountLocation: 0,
  totalInCountUser: 0,
  totalInCountTeam: 0,
  totalInCountLocation: 0,
  auInCountUser: 0,
  auInCountTeam: 0,
  auInCountLocation: 0,
  cancelPaidInvoiceCountUser: 0,
  cancelPaidInvoiceCountTeam: 0,
  cancelPaidInvoiceCountLocation: 0,
  cancelSilverOnlyCountUser: 0,
  cancelSilverOnlyCountTeam: 0,
  cancelSilverOnlyCountLocation: 0,
  cancelNoValueCountUser: 0,
  cancelNoValueCountTeam: 0,
  cancelNoValueCountLocation: 0,
  returnAgAuUser: 0,
  returnAgAuTeam: 0,
  returnAgAuLocation: 0,
  goldAvgUser: 0,
  goldAvgTeam: 0,
  goldAvgLocation: 0,
  totalCancelCountUser: 0,
  totalCancelCountTeam: 0,
  totalCancelCountLocation: 0,
};
