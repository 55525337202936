import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'releox-react';
import MassBoomerangForm from '../../forms/mass-boomerang/MassBoomerangForm';
import MassBoomerangInitialValues, {
  MassBoomerangSearchBody,
} from '../../forms/mass-boomerang/MassBoomerangInitialValues';
import MassBoomerangValidationSchema from '../../forms/mass-boomerang/MassBoomerangValidationSchema';
import MassBoomerangsAction from '../../store/mass-boomerang/MassBoomerangsAction';
import MassBoomerangsSelector from '../../store/mass-boomerang/MassBoomerangsSelector';

export default () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(MassBoomerangsSelector.selectIsLoading);
  const orders = useSelector(MassBoomerangsSelector.selectOrders);

  useEffect(
    () => () => {
      dispatch(MassBoomerangsAction.clean());
    },
    [dispatch]
  );

  const focusInput = useCallback(() => {
    const el = document.getElementById('focus-input');
    if (el) el.focus();
  }, []);

  const handleSubmit = useCallback(
    (body: MassBoomerangSearchBody, formikHelpers: FormikHelpers<MassBoomerangSearchBody>) => {
      dispatch(MassBoomerangsAction.searchOrder(body));
      formikHelpers.resetForm();
      focusInput();
    },
    [dispatch, focusInput]
  );

  const handleOnClick = useCallback(() => {
    dispatch(MassBoomerangsAction.massMarkAsBoomerang());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-4">
        <Card>
          <Formik
            initialValues={MassBoomerangInitialValues}
            validationSchema={MassBoomerangValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <MassBoomerangForm />
              <Button
                id="search-button"
                type="submit"
                disabled={isLoading}
                disabledText="Loading..."
              >
                Search
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      <div className="col-8">
        <Card>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Bar code</th>
                <th>Client</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((o) => (
                <tr>
                  <td>{o.orderNumber}</td>
                  <td>{o.barCode}</td>
                  <td>{`${o.client?.firstName} ${o.client?.lastName}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            id="boomerang-button"
            disabled={isLoading}
            disabledText="Loading..."
            onClick={handleOnClick}
          >
            Mark as boomerang
          </Button>
        </Card>
      </div>
    </div>
  );
};
