import React from 'react';
import { WeightReportWeight } from '../utils/report-methods/accepted-offers-report/get-accepted-offers-report';

interface Props {
  materialId: string;
  weights: WeightReportWeight;
  productCounts: WeightReportWeight;
}

export default ({ weights, materialId, productCounts }: Props) => {
  if (!weights[materialId]) return <td key={materialId}>-</td>;
  return (
    <td key={materialId}>
      {weights[materialId]} ({productCounts[materialId]})
    </td>
  );
};
