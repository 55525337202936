import moment from 'moment';

export enum CallTransferSearchType {
  WELCOME_CALL = 'welcomeCall',
  AFTER_CALL = 'dueDate',
}

const getCallTransferSearchInitialValues = () => ({
  type: CallTransferSearchType.WELCOME_CALL,
  startDate: moment()
    .startOf('day')
    .toDate(),
  endDate: moment()
    .endOf('week')
    .toDate(),
  callerId: '',
});

export type CallTransferSearchBody = ReturnType<typeof getCallTransferSearchInitialValues>;

export default getCallTransferSearchInitialValues;
