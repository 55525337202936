import { getErrorMessage } from 'releox-react';
import { Action, ReduxDispatch } from '../Action';
import RednotAction from '../rednot/RednotAction';

type ErrorAction = (arg?: any) => Action<string>;

export default class ErrorsAction {
  public static onCatch(action?: ErrorAction, payload?: any) {
    return (dispatch: ReduxDispatch) => {
      return (error: Error) => {
        dispatch(RednotAction.error(getErrorMessage(error)));
        if (action) dispatch(action(payload));
      };
    };
  }
}
