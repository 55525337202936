import { fixFloat } from '../config';
import { Material } from '../store/material/Material';

interface ProductField {
  factor: number | string;
  weight: number | string;
  density: string;
}

export default (p: ProductField, materials: Material[]) => {
  let factory = parseFloat(p.factor as any);
  const convertedWeight = parseFloat(p.weight as any);

  if (p.density !== 'other') {
    const material = materials.find((m) => m.id === p.density);
    if (material) factory = material.price;
  }

  if (Number.isNaN(convertedWeight) || Number.isNaN(factory)) return 'Error';
  return fixFloat(factory * convertedWeight);
};
