import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import { Client } from './Client';

export default class ClientsEffect {
  public static cleanBody(order: Partial<Client>) {
    /* eslint-disable no-param-reassign */
    delete order.id;
    delete order.orders;
    /* eslint-enable no-param-reassign */
    return order;
  }

  public static restRequests = loopbackRequestCreator<Client>(apis.CLIENT, ClientsEffect.cleanBody);

  public static findById = ClientsEffect.restRequests.getById;

  public static find = ClientsEffect.restRequests.getAll;

  public static create = ClientsEffect.restRequests.create;

  public static update = ClientsEffect.restRequests.update;

  public static delete = ClientsEffect.restRequests.delete;

  public static count = ClientsEffect.restRequests.count;
}
