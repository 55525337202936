import React from 'react';
import { useSelector } from 'react-redux';
import TransferTable from '../../components/TransferTable';
import CancelCallTransfersSelector from '../../store/cancel-call-transfer/CancelCallTransfersSelector';

export default () => {
  const cancelTransferField = useSelector(CancelCallTransfersSelector.selectCancelTransferField);

  return <TransferTable fields={cancelTransferField} />;
};
