import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import RednotAction from '../rednot/RednotAction';
import ClientsEffect from './ClientsEffect';

export default class ClientsAction {
  static ENTRY = 'CLIENT';

  static actionCreator = actionCreator(
    ClientsAction.ENTRY,
    ClientsEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = ClientsAction.actionCreator.getCreateAction();

  static update = ClientsAction.actionCreator.getUpdateAction();

  static fetch = ClientsAction.actionCreator.getFindByIdAction();

  static delete = ClientsAction.actionCreator.getDeleteAction();

  static list = ClientsAction.actionCreator.getFindAndCountAction();

  static clean = ClientsAction.actionCreator.getCleanAction();
}
