import { MassBoomerangSearchBody } from '../../forms/mass-boomerang/MassBoomerangInitialValues';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import ErrorsAction from '../error/ErrorsAction';
import { Order } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

export enum MassBoomerangsActionTypes {
  CLEAN = '@MASS_BOOMERANG:CLEAN',
  ADD_TO_LIST = '@MASS_BOOMERANG:ADD_TO_LIST',
  SET_IS_LOADING = '@MASS_BOOMERANG:SET_IS_LOADING',
}

type CleanReturn = Action<MassBoomerangsActionTypes.CLEAN>;
type AddToListReturn = ActionWPayload<MassBoomerangsActionTypes.ADD_TO_LIST, { order: Order }>;
type SetIsLoadingReturn = ActionWPayload<
  MassBoomerangsActionTypes.SET_IS_LOADING,
  { isLoading: boolean }
>;

export type MassBoomerangsActionObject = CleanReturn | AddToListReturn | SetIsLoadingReturn;

export default class MassBoomerangsAction {
  public static clean(): MassBoomerangsActionObject {
    return {
      type: MassBoomerangsActionTypes.CLEAN,
    };
  }

  private static addToList(order: Order): MassBoomerangsActionObject {
    return {
      type: MassBoomerangsActionTypes.ADD_TO_LIST,
      payload: { order },
    };
  }

  private static setIsLoading(isLoading: boolean): MassBoomerangsActionObject {
    return {
      type: MassBoomerangsActionTypes.SET_IS_LOADING,
      payload: { isLoading },
    };
  }

  public static searchOrder(body: MassBoomerangSearchBody) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const stateOrders = state.massBoomerang.orders;
      const existOrder = stateOrders.find((o) => o.barCode === body.barCode);
      if (existOrder) throw dispatch(RednotAction.error('Order is already added to list'));
      dispatch(MassBoomerangsAction.setIsLoading(true));
      OrdersEffect.find({
        include: OrderQuery.include,
        where: { ...body, closed: false },
        order: 'createdAt desc',
      })
        .then((orders) => {
          if (!orders.length) throw new Error('Cannot find order');
          const order = orders[0];
          if (order.closed) throw new Error('Order is already closed');
          dispatch(MassBoomerangsAction.addToList(order));
          dispatch(MassBoomerangsAction.setIsLoading(false));
        })
        .catch(dispatch(ErrorsAction.onCatch(MassBoomerangsAction.setIsLoading, false)));
    };
  }

  public static massMarkAsBoomerang() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const state = getState();
      const { orders } = state.massBoomerang;
      const userId = state.user.data.id;
      if (!orders.length) throw dispatch(RednotAction.error('Require at least 1 order'));
      dispatch(MassBoomerangsAction.setIsLoading(true));

      const requests = orders.map((order) => {
        return dispatch(OrdersAction.markAsBoomerang(order.id, userId));
      });

      Promise.all(requests)
        .then(() => {
          dispatch(RednotAction.success('Complete!'));
          dispatch(MassBoomerangsAction.clean());
        })
        .catch(dispatch(ErrorsAction.onCatch(MassBoomerangsAction.setIsLoading, false)));
    };
  }
}
