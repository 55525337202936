import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'releox-react';
import TellersAction from '../../../store/teller/TellersAction';

export default () => {
  const dispatch = useDispatch();

  const didNotAnswer = useCallback(() => dispatch(TellersAction.didNotAnswer()), [dispatch]);

  const noAnswerSendSMS = useCallback(() => dispatch(TellersAction.didNotAnswerAndSendSms()), [
    dispatch,
  ]);

  const returnToValuation = useCallback(() => dispatch(TellersAction.returnToValuation()), [
    dispatch,
  ]);

  const showPreview = useCallback(() => dispatch(TellersAction.showPreview(true)), [dispatch]);

  const redirectBack = useCallback(() => dispatch(TellersAction.goBack()), [dispatch]);

  return (
    <>
      <Button type="button" className="btn-block" onClick={showPreview} id="preview-button">
        To preview
      </Button>
      <Button
        type="button"
        className="btn-block"
        onClick={returnToValuation}
        id="return-valuation-button"
      >
        Return to valuation
      </Button>
      <Button type="button" onClick={redirectBack} className="btn-block" id="return-list-button">
        Return to list
      </Button>
      <hr />
      <Button
        type="button"
        onClick={noAnswerSendSMS}
        className="btn-block"
        id="no-answer-sms-button"
      >
        Did not answer and send SMS
      </Button>
      <Button type="button" onClick={didNotAnswer} className="btn-block" id="no-answer-button">
        Did not answer
      </Button>
    </>
  );
};
