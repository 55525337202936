import React from 'react';
import { useSelector } from 'react-redux';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import routes from '../../configs/routes';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';
import UsersSelector from '../../store/user/UsersSelector';

const columns: DataTableColumn[] = [
  OrderColumn.orderNumber,
  OrderColumn.clientName,
  OrderColumn.phone,
  OrderColumn.address,
  OrderColumn.isPackaged,
  OrderColumn.createdAt,
];

export default () => {
  const user = useSelector(UsersSelector.selectUser);
  return (
    <GenericIndexScene
      tNamespace="ModifyIndexScene"
      loadingSelector={OrdersSelector.selectIsListLoading}
      dataSelector={OrdersSelector.selectOrders}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={columns}
      defaultSorted={{ order: 'DESC', dataField: 'createdAt' }}
      query={OrderQuery.getBuyerModifyIndex(user)}
      redirectUrl={routes.BUYER.MODIFY_ORDERS_EDIT}
    />
  );
};
