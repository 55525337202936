import cleanDeep from 'clean-deep';
import moment from 'moment';
import getAcceptedOffersReport, {
  AcceptedOffersReport,
} from '../../utils/report-methods/accepted-offers-report/get-accepted-offers-report';
import getValuatedOffersReport, {
  ValuatedOrdersReport,
} from '../../utils/report-methods/valuated-offers-report/get-valuated-offers-report';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import ErrorsAction from '../error/ErrorsAction';
import { Order } from '../order/Order';
import OrdersEffect from '../order/OrdersEffect';

export enum ReportsActionTypes {
  MATERIAL_WEIGHT_SUCCESS = '@REPORT:MATERIAL_WEIGHT_SUCCESS',
  VALUATED_OFFERS_SUCCESS = '@REPORT:VALUATED_OFFERS_SUCCESS',
  RESET = '@REPORT:RESET',
  SEARCH_STARTED = '@REPORT:SEARCH_STARTED',
}

export enum ReportTypes {
  ACCEPTED_WEIGHTS = 'acceptedWeights',
  VALUATED_ORDERS = 'valuatedOrders',
}

export const reportTypeOptions = [];

export interface ReportSearchBody {
  date: {
    gt: Date;
    lt: Date;
  };
  params: {
    team: string;
  };
  reportType: ReportTypes;
}

interface MaterialWeight {
  totalWeight: number;
  count: number;
  materialName: string;
}

export interface MaterialWeightObject {
  [key: string]: MaterialWeight;
}

type Reset = Action<ReportsActionTypes.RESET>;
type SearchStarted = Action<ReportsActionTypes.SEARCH_STARTED>;
type MaterialWeightAction = ActionWPayload<
  ReportsActionTypes.MATERIAL_WEIGHT_SUCCESS,
  { acceptedOffersReport: AcceptedOffersReport }
>;
type ValuatedOffersReportAction = ActionWPayload<
  ReportsActionTypes.VALUATED_OFFERS_SUCCESS,
  { valuatedOffersReport: ValuatedOrdersReport }
>;

export type ReportsActionObject =
  | MaterialWeightAction
  | Reset
  | SearchStarted
  | ValuatedOffersReportAction;

export default class ReportsAction {
  private static fields = {
    id: true,
    team: true,
    offerAccepted: true,
    offer: true,
    createdAt: true,
    goldWeight: true,
    orderNumber: true,
    callerId: true,
    products: true,
    tellerDate: true,
    valuationDate: true,
  };

  private static acceptedOffersSuccess(
    acceptedOffersReport: AcceptedOffersReport
  ): ReportsActionObject {
    return {
      type: ReportsActionTypes.MATERIAL_WEIGHT_SUCCESS,
      payload: { acceptedOffersReport },
    };
  }

  private static valuatedOffersReportSuccess(
    valuatedOffersReport: ValuatedOrdersReport
  ): ReportsActionObject {
    return {
      type: ReportsActionTypes.VALUATED_OFFERS_SUCCESS,
      payload: { valuatedOffersReport },
    };
  }

  private static calculateMaterialWeights(orders: Order[], body: ReportSearchBody) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const materials = getState().materialEntry.list.data;

      if (body.reportType === ReportTypes.ACCEPTED_WEIGHTS) {
        const result = getAcceptedOffersReport(orders, materials);
        dispatch(this.acceptedOffersSuccess(result));
      }

      if (body.reportType === ReportTypes.VALUATED_ORDERS) {
        const outQuery = this.getOutOrdersWhereQuery(body);
        const out2WQuery = this.getOut2WOrdersWhereQuery(body);
        const cancelledQuery = this.getCancelledOrdersWhereQuery(body);
        const requests = [
          OrdersEffect.find(outQuery as any),
          OrdersEffect.find(out2WQuery as any),
          OrdersEffect.find(cancelledQuery as any),
        ];

        Promise.all(requests).then((res) => {
          const result = getValuatedOffersReport(orders, res[0], res[1], res[2]);
          dispatch(this.valuatedOffersReportSuccess(result));
        });
      }
    };
  }

  private static reset(): ReportsActionObject {
    return {
      type: ReportsActionTypes.RESET,
    };
  }

  private static searchStarted(): ReportsActionObject {
    return {
      type: ReportsActionTypes.SEARCH_STARTED,
    };
  }

  private static getAcceptedOffersWhereQuery(body: ReportSearchBody) {
    return {
      order: 'tellerDate ASC',
      include: ['caller'],
      fields: this.fields,
      where: {
        and: [
          { ...cleanDeep(body.params), offerAccepted: true },
          { tellerDate: { gt: body.date.gt } },
          { tellerDate: { lt: body.date.lt } },
        ],
      },
    };
  }

  private static getOut2WOrdersWhereQuery(body: ReportSearchBody) {
    return {
      order: 'createdAt ASC',
      include: ['caller'],
      fields: this.fields,
      where: {
        and: [
          { ...cleanDeep(body.params) },
          {
            createdAt: {
              gt: moment(body.date.gt)
                .subtract(2, 'week')
                .toString(),
            },
          },
          {
            createdAt: {
              lt: moment(body.date.lt)
                .subtract(2, 'week')
                .toString(),
            },
          },
        ],
      },
    };
  }

  private static getCancelledOrdersWhereQuery(body: ReportSearchBody) {
    return {
      order: 'createdAt ASC',
      include: ['caller'],
      fields: this.fields,
      where: {
        and: [
          { ...cleanDeep(body.params), closed: true },
          { closeDate: { gt: body.date.gt } },
          { closeDate: { lt: body.date.lt } },
        ],
      },
    };
  }

  private static getOutOrdersWhereQuery(body: ReportSearchBody) {
    return {
      order: 'createdAt ASC',
      include: ['caller'],
      fields: this.fields,
      where: {
        and: [
          { ...cleanDeep(body.params) },
          { createdAt: { gt: body.date.gt } },
          { createdAt: { lt: body.date.lt } },
        ],
      },
    };
  }

  private static getValuatedOrdersWhereQuery(body: ReportSearchBody) {
    return {
      order: 'valuationDate ASC',
      include: ['caller'],
      fields: this.fields,
      where: {
        and: [
          { ...cleanDeep(body.params), isValued: true },
          { valuationDate: { gt: body.date.gt } },
          { valuationDate: { lt: body.date.lt } },
        ],
      },
    };
  }

  public static onSubmit(body: ReportSearchBody) {
    return (dispatch: ReduxDispatch) => {
      let query = this.getAcceptedOffersWhereQuery(body);

      if (body.reportType === ReportTypes.VALUATED_ORDERS) {
        query = this.getValuatedOrdersWhereQuery(body);
      }

      dispatch(this.reset());
      dispatch(this.searchStarted());
      OrdersEffect.find(query as any)
        .then((orders) => {
          dispatch(this.calculateMaterialWeights(orders, body));
        })
        .catch(ErrorsAction.onCatch());
    };
  }
}
