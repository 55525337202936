import { orderFormInitValue } from '../../forms/OrderForm';
import { PackagerSystemsActionObject, PackagerSystemsActionTypes } from './PackagerSystemsAction';

export default class PackagerSystemsReducer {
  private static initState = {
    isSending: false,
    isLoading: false,
    isValidPackage: false,
    hasNoMoreOrders: false,
    order: orderFormInitValue,
    messages: [] as string[],
  };

  public static reducer(
    state = PackagerSystemsReducer.initState,
    action: PackagerSystemsActionObject
  ): typeof state {
    switch (action.type) {
      case PackagerSystemsActionTypes.RESET:
        return PackagerSystemsReducer.initState;

      case PackagerSystemsActionTypes.NO_MORE_ORDERS:
        return {
          ...state,
          hasNoMoreOrders: true,
        };

      case PackagerSystemsActionTypes.FOUND_ORDER:
        return {
          ...state,
          order: action.payload.order,
          isValidPackage: action.payload.isValid,
        };

      case PackagerSystemsActionTypes.SEND_REQUEST:
        return {
          ...state,
          isSending: true,
        };

      case PackagerSystemsActionTypes.SEND_FAIL:
      case PackagerSystemsActionTypes.SEND_SUCCESS:
        return {
          ...state,
          isSending: false,
        };

      case PackagerSystemsActionTypes.FIND_ORDER_REQUEST:
        return {
          ...state,
          isLoading: true,
          isValidPackage: false,
        };

      case PackagerSystemsActionTypes.FIND_ORDER_FAIL:
      case PackagerSystemsActionTypes.FIND_ORDER_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case PackagerSystemsActionTypes.RESET_MESSAGES:
        return {
          ...state,
          messages: [],
        };

      case PackagerSystemsActionTypes.ADD_MESSAGE:
        return {
          ...state,
          messages: [...state.messages, action.payload.message],
        };

      default:
        return state;
    }
  }
}
