import { SearchActions, SearchActionType } from './SearchAction';

export default class SearchReducer {
  public static initialState = {
    clientQ: '',
    orderQ: '',
    orderSearch: {} as any,
    clientSearch: {} as any,
  };

  public static reducer(state = SearchReducer.initialState, action: SearchActions): typeof state {
    switch (action.type) {
      case SearchActionType.SET_ORDER_QUERY:
        return {
          ...state,
          orderQ: action.payload.q,
          orderSearch: action.payload.orderQuery,
        };
      case SearchActionType.SET_CLIENT_QUERY:
        return {
          ...state,
          clientQ: action.payload.q,
          clientSearch: action.payload.clientQuery,
        };
      default:
        return state;
    }
  }
}
