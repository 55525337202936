import Axios from 'axios';
import { getErrorMessage } from 'releox-react';
import apis from '../configs/apis';
import { TransactionBody } from '../store/payer/Payer';
import PaymentXmlsEffect from '../store/payment-xml/PaymentXmlsEffect';
import AbstractPaymentGenerator from './AbstractPaymentGenerator';

export default class PaymentXMLGenerator extends AbstractPaymentGenerator {
  protected MIME_TYPE = 'text/xml';

  protected DIRECTORY = `payment-xmls`;

  protected FILE_EXTENSION = 'xml';

  /**
   * Get file content from Integration API
   *
   * @protected
   * @returns {Promise<string>}
   * @memberof PaymentXMLGenerator
   */
  protected getBody(): Promise<string> {
    const { orders } = this;

    return new Promise((resolve, reject) => {
      const body: TransactionBody[] = orders.map((order) => {
        if (!order.client || !order.client.iban || !order.offer || !order.orderNumber) {
          throw new Error(
            'Missing key information. client, client.iban, order.orderNumber or offer'
          );
        }

        if (order.country === 'sweden') {
          return {
            name: `${order.client?.firstName} ${order.client?.lastName}`,
            message: order.products
              .map((p) => `${p.weight}g/${this.materialObject[p.density] || p.density}`)
              .join(' ')
              .substring(0, 139),
            sekOffer: `${order.sekOffer?.toFixed(2)}`,
            address: order.client.address,
            zip: order.client.zip,
            city: order.client.city,
            iban: order.client.iban.toUpperCase(),
            country: order.client.iban.substring(0, 2).toUpperCase(),
            orderNumber: order.orderNumber,
          };
        }

        return {
          name: `${order.client?.firstName} ${order.client?.lastName}`,
          message: order.products
            .map((p) => `${p.weight}g/${this.materialObject[p.density] || p.density}`)
            .join(' ')
            .substring(0, 139),
          price: `${order.offer.toFixed(2)}`,
          iban: order.client.iban.toUpperCase(),
          country: order.client.iban.substring(0, 2).toUpperCase(),
          orderNumber: order.orderNumber,
        };
      });

      let url = `${apis.PAYMENTXML}/generate/finnish`;

      if (orders[0].country === 'sweden') url = `${apis.PAYMENTXML}/generate/sweden`;

      Axios.post<string>(url, body)
        .then((r) => resolve(r.data))
        .catch((e) => getErrorMessage(reject(e)));
    });
  }

  /**
   * Create database record
   *
   * @protected
   * @param {string} location
   * @returns
   * @memberof PaymentXMLGenerator
   */
  protected createRecord(location: string) {
    const body = {
      id: '',
      country: this.category,
      location,
      name: this.getFileName(),
      total: this.countTotal(),
      isDonation: this.isDonation,
    };
    return PaymentXmlsEffect.create(body);
  }
}
