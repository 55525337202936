import { Order } from '../order/Order';

export default class MassBoomerangsSelector {
  public static selectOrders(state: ReduxState): Order[] {
    return state.massBoomerang.orders;
  }

  public static selectIsLoading(state: ReduxState): boolean {
    return state.massBoomerang.isLoading;
  }
}
