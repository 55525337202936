import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withOrder from '../../HOC/withOrder';
import withQZ, { QZTrayProps } from '../../HOC/withQZ';
import { PrintURL } from '../../classes/PrintURL';
import Printer from '../../classes/Printer';
import ValuationForm from '../../forms/valuation/ValuationForm';
import MaterialsAction from '../../store/material/MaterialsAction';
import OrdersSelector from '../../store/order/OrdersSelector';
import ValuatesAction, { ValuateScenes } from '../../store/valuate/ValuatesAction';
import ValuatesSelector from '../../store/valuate/ValuatesSelector';
import ValuationMessages from './components/ValuationMessages';
import ValuationPreview from './components/ValuationPreview';

const ValuationValuate2 = ({ print }: QZTrayProps) => {
  const dispatch = useDispatch();
  const order = useSelector(OrdersSelector.selectOrder);
  const view = useSelector(ValuatesSelector.selectView);

  useEffect(() => {
    const url = PrintURL.getValuationLabelUrl(order.id);
    if (!order.returnToValuation) print(url, Printer.ZEBRA);
  }, [order, print]);

  useEffect(() => {
    dispatch(MaterialsAction.list());
    return () => {
      dispatch(ValuatesAction.reset());
    };
  }, [dispatch]);

  if (view === ValuateScenes.PREVIEW) return <ValuationPreview />;
  if (view === ValuateScenes.VALUATE) return <ValuationForm />;
  return <ValuationMessages />;
};

export default withQZ(withOrder(ValuationValuate2), Printer.ZEBRA);
