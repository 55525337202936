import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Card, CardTitle, Input } from 'releox-react';

interface BarCodePrompt {
  title: string;
  size?: number;
  errorMessage?: string;
  onSubmit(e: BarCodePromptBody): any;
}

export interface BarCodePromptBody {
  barCode: string;
}

export default (props: BarCodePrompt) => {
  const { onSubmit, title, size, errorMessage } = props;

  const onEventSubmit = useCallback(
    (body: BarCodePromptBody, { resetForm }: FormikHelpers<BarCodePromptBody>) => {
      onSubmit(body);
      resetForm();
    },
    [onSubmit]
  );

  const focusInput = useCallback(() => {
    const el = document.getElementById('focus-input');
    if (el) el.focus();
  }, []);

  useEffect(focusInput, []);

  return (
    <div className="row justify-content-lg-center">
      <div className={`col-lg-${size || 12} `}>
        <Card>
          <CardTitle>{`${title}: Scan bar code`}</CardTitle>
          <div className="form-group">
            <Formik initialValues={{ barCode: '' }} onSubmit={onEventSubmit}>
              <Form>
                <Input label="Bar Code" name="barCode" id="focus-input" />
                {errorMessage ? (
                  <span className="text-danger">
                    <b>{errorMessage}</b>
                  </span>
                ) : (
                  undefined
                )}
              </Form>
            </Formik>
          </div>
        </Card>
      </div>
    </div>
  );
};
