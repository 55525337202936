import { Material } from '../../../store/material/Material';
import { Order } from '../../../store/order/Order';
import sortMaterials from '../../sort-materials';
import AcceptedReportOrder from './AcceptedReportOrder';
import AcceptedReportTotal from './AcceptedReportTotal';
import AcceptedReportUser from './AcceptedReportUser';
import AcceptedReportUserContainer from './AcceptedReportUserContainer';

export interface WeightReportWeight {
  [key: string]: number;
}

export interface WeightReportUser {
  name: string;
  gross: WeightReportWeight;
  net: WeightReportWeight;
  counts: WeightReportWeight;
  offer: number;
}

const getAcceptedOffersReport = (orders: Order[], materials: Material[]) => {
  const materialList = materials.sort(sortMaterials);

  const reportOrders = orders.map((order) => new AcceptedReportOrder(order));
  const users = new AcceptedReportUserContainer();
  const total = new AcceptedReportTotal();

  reportOrders.forEach((order) => {
    if (!users.hasUser(order.getCallerId())) {
      users.addUser(order.getCallerId(), new AcceptedReportUser(order.getCallerName()));
    }

    const user = users.getUser(order.getCallerId());

    total.calculateTotalOffer(order.getOffer());
    user.calculateOffer(order.getOffer());

    if (order.hasGold()) {
      user.incrementGoldCount();
      total.incrementGoldCount();
    }

    order.getProducts().forEach((product) => {
      const density = product.getDensity();
      const weight = product.getWeight();

      user.calculateGrossWeight(density, weight);
      total.calculateGrossWeight(density, weight);

      user.calculateCount(density);
      total.calculateCount(density);

      if (product.isGold() || product.isCustomGold()) {
        user.calculateNetWeight(product.getProduct());
        total.calculateNetWeight(product.getProduct());
      }

      if (product.isGold()) {
        user.calculateGrossWeight('gold', weight);
        total.calculateGrossWeight('gold', weight);
      }
    });
  });

  return {
    materials: materialList,
    gross: total.getGrosses(),
    net: total.getNet(),
    users: users.getReport(),
    productCounts: total.getCounts(),
    totalOffer: total.getTotalOffer(),
  };
};

export default getAcceptedOffersReport;

export type AcceptedOffersReport = ReturnType<typeof getAcceptedOffersReport>;
