import { Language } from './classes/Language';

export const getReceivedSMSMessage = (language: Language, firstName: string) => {
  const translsate = {
    [Language.FINNISH]: `Hei ${firstName}. Kultapussinne on vastaanotettu. Soitamme Teille ostotarjouksen 1-3pv /  9-17 välisenä aikana. Viestiin ei voi vastata. T:SJMK`,
    [Language.SWEDISH]: `Hej ${firstName}. Er värdepåse har mottagits. Vi kontaktar dig per telefon med ett köp erbjudande inom 1-3 dagar. Man kan ej svara på meddelandet. H. SJMK`,
    [Language.FINLAND_SWEDISH]: `Hej ${firstName}. Er värdepåse har mottagits. Vi kontaktar dig per telefon med ett köp erbjudande inom 1-3 dagar. Man kan ej svara på meddelandet. H. SJMK`,
    [Language.UK_ENGLISH]: `Hello ${firstName}. Your package has been received. We will contact you by phone with a purchase offer today or tomorrow. You cannot reply to the message. H. SJMK`,
  };
  return translsate[language];
};

export const getNotAnswerSMSMessage = (language: Language) => {
  const translsate = {
    [Language.FINNISH]: `Lähetyksenne on arvioitu, mutta emme ole tavoittaneet teitä. Voitte olla yhteydessä nroon 02-2317834 klo 9-12 ja 13-16. T. SJMK`,
    [Language.SWEDISH]:
      'Er försändelse har blivit värderad men vi kunde inte nå er per telefon. Ni kan kontakta oss på 08- 628 20400   Mellan 10:00-16:00. H. SJMK',
    [Language.FINLAND_SWEDISH]:
      'Er försändelse har blivit värderad men vi kunde inte nå er per telefon. Ni kan kontakta oss på 08- 628 20400   Mellan 10:00-16:00. H. SJMK',
    [Language.UK_ENGLISH]:
      'Your package has been evaluated, but we have not been able to reach you by phone. You can contact us at 08- 628 20400 between 10:00-16:00. H. SJMK',
  };
  return translsate[language];
};
