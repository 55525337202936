import { getErrorMessage } from 'releox-react';
import { sendArrivedSMS } from '../../send-sms';
import { getReceivedSMSMessage } from '../../sms-messages';
import { Action, GetState, ReduxDispatch } from '../Action';
import OrdersAction from '../order/OrdersAction';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

export enum TellerSmsActionTypes {
  EXAMPLE = '@TELLER_SMS:EXAMPLE',
  EXAMPLE_MESSAGE = '@TELLER_SMS:EXAMPLE_MESSAGE',
}

export type TellerSmsActionObject = Action<any>;

export default class TellerSmsAction {
  public static sendSMS(language: string) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const orders = getState().orderEntry.list.data;
      const requests = orders.map((o) => {
        if (!o.client) throw new Error('Missing phone number');
        const message = getReceivedSMSMessage(o.language, o.client?.firstName);
        return sendArrivedSMS(o.id, o.client?.phone, message);
      });

      Promise.all(requests)
        .then(() => {
          dispatch(OrdersAction.list(OrderQuery.getTellerSMSQuery(language)));
        })
        .catch((e) => {
          dispatch(RednotAction.error(getErrorMessage(e)));
        });
    };
  }
}
