import OrderColumn from '../../columns/OrderColumn';
import OrderColumnInitialValues from '../../forms/order-columns/OrderColumnInitialValues';
import { ColumnsActions, ColumnsActionType } from './ColumnsAction';

export default class ColumnsReducer {
  private static initState = {
    adminOrderCheckboxes: OrderColumnInitialValues,
    adminOrderColumns: [
      OrderColumn.orderNumberAdminLink,
      OrderColumn.status,
      OrderColumn.clientName,
      OrderColumn.barCode,
      OrderColumn.country,
      OrderColumn.language,
      OrderColumn.createdAt,
      OrderColumn.updatedAt,
    ],
    customerServiceOrderColumns: [
      OrderColumn.orderNumber,
      OrderColumn.clientName,
      OrderColumn.barCode,
      OrderColumn.country,
      OrderColumn.language,
      OrderColumn.createdAt,
      OrderColumn.updatedAt,
    ],
  };

  public static reducer(state = ColumnsReducer.initState, action: ColumnsActions): typeof state {
    switch (action.type) {
      case ColumnsActionType.SET_ADMIN_ORDER_COLUMNS:
        return {
          ...state,
          adminOrderColumns: action.payload.columns,
          adminOrderCheckboxes: action.payload.checkboxValues,
        };

      default:
        return state;
    }
  }
}
