import React from 'react';
import { GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

const handledColumns = [
  OrderColumn.orderNumber,
  OrderColumn.bursarName,
  OrderColumn.country,
  OrderColumn.bursarHandleDate,
  OrderColumn.clientName,
];

const unhandledColumns = [OrderColumn.orderNumber, OrderColumn.country, OrderColumn.clientName];

export const BursarHandledScene = () => (
  <GenericIndexScene
    tNamespace="BursarHandledScene"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={handledColumns}
    defaultSorted={{ dataField: 'createdAt', order: 'desc' }}
    query={OrderQuery.getBursarHandledQuery()}
  />
);

export const BursarUnhandledScene = () => (
  <GenericIndexScene
    tNamespace="BursarUnhandledScene"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={unhandledColumns}
    defaultSorted={{ dataField: 'createdAt', order: 'desc' }}
    query={OrderQuery.getBursarUnhandledQuery()}
  />
);
