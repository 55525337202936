import randomString from 'randomstring';
import { Material } from '../../store/material/Material';
import { Product } from '../../store/order/Order';

export const ProductInitialValue = (silver: Material) => ({
  key: randomString.generate(),
  name: '',
  category: silver.category,
  weight: '',
  factor: silver.price,
  density: silver.id,
  densityName: silver.name,
  price: '',
  inDeal: true,
});

const ValuateInitialValues = {
  materialWeight: '',
  packageWeight: '',
  valuationImage: '',
  tellerMessageFromValuation: '',
  products: [] as Product[],
  extraImages: [] as string[],
};

export default ValuateInitialValues;

export type ValuationFormBody = typeof ValuateInitialValues;
