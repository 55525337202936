import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'releox-react';
import BarCodePrompt, { BarCodePromptBody } from '../components/BarCodePrompt';
import ReceiverAction from '../store/receiver/ReceiverAction';
import ReceiverSelector from '../store/receiver/ReceiverSelector';

export default () => {
  const dispatch = useDispatch();
  const receivedCount = useSelector(ReceiverSelector.selectReceivedCount);
  const receivedByTeamCount = useSelector(ReceiverSelector.selectReceivedByTeamCount);

  const fetchReceived = useCallback(() => {
    dispatch(ReceiverAction.fetchReceivedCount());
  }, [dispatch]);

  const onSubmit = useCallback(
    async (body: BarCodePromptBody) => {
      dispatch(ReceiverAction.onSubmit(body));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchReceived();
  }, [fetchReceived]);

  return (
    <div className="row">
      <div className="col-sm-6">
        <BarCodePrompt title="Receiver" onSubmit={onSubmit} />
      </div>
      <div className="col-sm-6">
        <Card>
          <h4>Received today: {receivedCount}</h4>
          <h5>Turku: {receivedByTeamCount.turku}</h5>
          <h5>Helsinki: {receivedByTeamCount.helsinki}</h5>
          <h5>Gothenburg: {receivedByTeamCount.gothenburg}</h5>
          <h5>Malaga Finland: {receivedByTeamCount.malagaFinland}</h5>
          <h5>Malaga Sweden: {receivedByTeamCount.malagaSweden}</h5>
        </Card>
      </div>
    </div>
  );
};
