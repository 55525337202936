import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardTitle } from 'releox-react';
import routes from '../../configs/routes';
import PackagerStatsAction from '../../store/packager-stats/PackagerStatsAction';
import PackagerStatsSelector from '../../store/packager-stats/PackagerStatsSelector';

export default () => {
  const dispatch = useDispatch();
  const waitingFI = useSelector(PackagerStatsSelector.selectWaitingFinnishPackageCount);
  const waitingFinSE = useSelector(PackagerStatsSelector.selectWaitingFinlandSwedishPackageCount);
  const waitingSE = useSelector(PackagerStatsSelector.selectWaitingSwedishPackageCount);
  const waitingUK = useSelector(PackagerStatsSelector.selectWaitingUKPackageCount);
  const doneFI = useSelector(PackagerStatsSelector.selectDoneFinnishPackageCount);
  const doneFinSE = useSelector(PackagerStatsSelector.selectDoneFinlandSwedishPackageCount);
  const doneSE = useSelector(PackagerStatsSelector.selectDoneSwedishPackageCount);
  const doneUK = useSelector(PackagerStatsSelector.selectDoneUKPackageCount);

  useEffect(() => {
    dispatch(PackagerStatsAction.fetchStats());

    const interval = setInterval(() => {
      dispatch(PackagerStatsAction.fetchStats());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);
  return (
    <div className="row">
      <div className="col-md-4">
        <Card>
          <Link
            to={routes.PACKAGER.PACKAGER_SYSTEM.replace(':language', 'finnish')}
            className="btn btn-primary btn-block"
          >
            Finnish
          </Link>
          <Link
            to={routes.PACKAGER.PACKAGER_SYSTEM.replace(':language', 'finland-swedish')}
            className="btn btn-primary btn-block"
          >
            Finland swedish
          </Link>
          <Link
            to={routes.PACKAGER.PACKAGER_SYSTEM.replace(':language', 'swedish')}
            className="btn btn-primary btn-block"
          >
            Swedish
          </Link>
          <Link
            to={routes.PACKAGER.PACKAGER_SYSTEM.replace(':language', 'uk')}
            className="btn btn-primary btn-block"
          >
            UK
          </Link>
        </Card>
      </div>
      <div className="col-md-4 offset-md-4">
        <Card>
          <CardTitle>{`Finnish unpackaged: ${waitingFI}`}</CardTitle>
          <CardTitle>{`Finland swedish unpackaged: ${waitingFinSE}`}</CardTitle>
          <CardTitle>{`Swedish unpackaged: ${waitingSE}`}</CardTitle>
          <CardTitle>{`UK unpackaged: ${waitingUK}`}</CardTitle>
          <hr />
          <CardTitle>{`Finnish packaged: ${doneFI}`}</CardTitle>
          <CardTitle>{`Finland swedish packaged: ${doneFinSE}`}</CardTitle>
          <CardTitle>{`Swedish packaged: ${doneSE}`}</CardTitle>
          <CardTitle>{`UK packaged: ${doneUK}`}</CardTitle>
        </Card>
      </div>
    </div>
  );
};
