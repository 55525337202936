import merge from 'deepmerge';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { goBack } from 'react-router-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Card, CardTitle, CheckBox, Select, TextArea } from 'releox-react';
import { closeMethodOptions } from '../../options';
import OrdersAction from '../../store/order/OrdersAction';
import UsersSelector from '../../store/user/UsersSelector';

const fastOptions = [
  'Age',
  '3rd party',
  'Nothing to send',
  "Don't trust us",
  "Didn't know about invoice",
  'Invoice paid',
  'No information',
];

export type ReduxDispatch = ThunkDispatch<any, any, Action>;

export default () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { id } = useParams<{ id: string }>();
  const user = useSelector(UsersSelector.selectUser);

  const onSubmit = useCallback(
    (body: any) => {
      const isBoomerang = body.closeMethod === 'boomerang';
      const fReasons = Object.keys(body.fastOptions)
        .map((key) => {
          if (body.fastOptions[key]) return key;
          return '';
        })
        .filter((x) => x)
        .join(', ');

      dispatch(
        OrdersAction.closeOrder(
          id,
          user.id,
          body.closeMethod,
          [fReasons, body.closeReason].join(', '),
          isBoomerang
        )
      ).then(() => dispatch(goBack()));
    },
    [dispatch, user, id]
  );

  const onBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-sm-6 offset-sm-3">
        <Card>
          <CardTitle>Close order</CardTitle>
          <Formik
            initialValues={{
              closeReason: '',
              fastOptions: merge.all(fastOptions.map((o) => ({ [o]: false }))),
              closeMethod: 'phone',
            }}
            onSubmit={onSubmit}
          >
            <Form>
              <Select name="closeMethod" label="Close method" options={closeMethodOptions} />
              {fastOptions.map((o) => (
                <CheckBox key={o} name={`fastOptions.${o}`} label={o} />
              ))}
              <TextArea name="closeReason" label="Reason" />
              <Button id="back" onClick={onBack}>
                Back
              </Button>
              <Button type="submit" className="float-right" id="close">
                Close
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  );
};
