import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

/* eslint-disable react/jsx-props-no-spreading */
const SuggestionItem = ({ suggestion, getSuggestionItemProps }: any) => {
  const className = suggestion.active
    ? 'PlacesAutocomplete-suggestion-item--active'
    : 'PlacesAutocomplete-suggestion-item';
  return (
    <div key={suggestion.id} {...getSuggestionItemProps(suggestion, { className })}>
      <span>{suggestion.description}</span>
    </div>
  );
};

interface GoogleAutocompleteProps {
  label: string;
  value: string;
  placeholder?: string;
  id?: string;
  onChange(value: string): void;
  onSelect(address: string): void;
}

const GoogleAutocomplete = (props: GoogleAutocompleteProps) => {
  const { label, value, placeholder, id, onChange, onSelect } = props;
  return (
    <div className="form-group row">
      <label htmlFor={id} className="col-md-2 col-form-label">
        {label}
      </label>
      <div className="col-md-10">
        <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect} debounce={1000}>
          {(childprops) => {
            const { getInputProps, suggestions, getSuggestionItemProps, loading } = childprops;
            return (
              <div>
                <input
                  id={id}
                  {...getInputProps({
                    placeholder: placeholder || label,
                    className: 'form-control',
                  })}
                />
                <div className="PlacesAutocomplete-wrapper">
                  <div className="PlacesAutocomplete-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => (
                      <SuggestionItem
                        key={suggestion.id}
                        suggestion={suggestion}
                        getSuggestionItemProps={getSuggestionItemProps}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          }}
        </PlacesAutocomplete>
      </div>
    </div>
  );
};

GoogleAutocomplete.defaultProps = {
  id: '',
  placeholder: '',
};

export default GoogleAutocomplete;
