import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle, CheckBox } from 'releox-react';
import TellersSelector from '../../../store/teller/TellersSelector';
import sortProducts from '../../../utils/sort-products';

export default ({ isEditable = false }: { isEditable?: boolean }) => {
  const order = useSelector(TellersSelector.selectOrder);
  const inDealTotal = useSelector(TellersSelector.selectInDealTotal);

  return (
    <Card>
      <CardTitle>Offer</CardTitle>
      <table className="table table-striped">
        <tbody>
          <tr>
            <th>Product</th>
            <th>Weight</th>
            <th>Price</th>
            <th>In deal</th>
          </tr>
          {order.products.sort(sortProducts).map((p, i) => (
            <tr key={p.key}>
              <td>{`${p.densityName}${p.name ? ` (${p.name})` : ''}`}</td>
              <td>{p.weight}</td>
              <td>{p.price}</td>
              <td>
                {isEditable ? (
                  <CheckBox name={`products.${i}.inDeal`} label="in deal" />
                ) : (
                  <>{order.isTellered ? <>{p.inDeal ? 'Yes' : 'No'}</> : 'Waiting teller'}</>
                )}
              </td>
            </tr>
          ))}
          {order.tellerBreakdown ? (
            <tr>
              <td colSpan={4}>{order.tellerBreakdown}</td>
            </tr>
          ) : (
            undefined
          )}

          {isEditable ? (
            <>
              <tr>
                <td colSpan={2} className="text-right">
                  Total
                </td>
                <td colSpan={2}>{order.price}</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-right">
                  In-deal total
                </td>
                <td colSpan={2}>{inDealTotal}</td>
              </tr>
            </>
          ) : (
            undefined
          )}
        </tbody>
      </table>
    </Card>
  );
};
