import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Loading } from 'releox-react';
import ClientOrders from '../../components/ClientOrders';
import ClientForm from '../../forms/ClientForm';
import { Client } from '../../store/client/Client';
import ClientsAction from '../../store/client/ClientsAction';
import ClientFilter from '../../store/client/ClientsQuery';
import ClientsSelector from '../../store/client/ClientsSelector';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const isLoading = useSelector(ClientsSelector.selectClientIsLoading);
  const client = useSelector(ClientsSelector.selectClient);

  const onSubmit = useCallback(
    (body: Client) => {
      dispatch(ClientsAction.update(id, body));
    },
    [dispatch, id]
  );

  useEffect(() => {
    dispatch(ClientsAction.fetch(id, { include: ClientFilter.include }));
  }, [dispatch, id]);

  if (isLoading) return <Loading />;
  return (
    <div className="row">
      <div className="col-lg-6">
        <Card>
          <CardTitle>
            <>Client #{client.clientNumber}</>
          </CardTitle>
          <Formik onSubmit={onSubmit} initialValues={client}>
            <Form>
              <ClientForm />
              <Button type="submit" id="submit">
                Save
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
      <div className="col-lg-6">
        <Card>
          <CardTitle>
            <>Orders</>
          </CardTitle>
          <ClientOrders />
        </Card>
      </div>
    </div>
  );
};
