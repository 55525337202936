import merge from 'deepmerge';
import { Resource } from 'i18next';
import { releoxTranslations } from 'releox-react';
import ClientIndexTranslation from '../components/client-index/ClientIndexTranslation';
import OrderIndexTranslation from '../components/order-index/OrderIndexTranslation';
import ClientEditSceneTranslation from '../scenes/admin/client/ClientEditSceneTranslation';
import MaterialCreateSceneTranslation from '../scenes/admin/material/MaterialCreateSceneTranslation';
import MaterialEditSceneTranslation from '../scenes/admin/material/MaterialEditSceneTranslation';
import MaterialIndexSceneTranslation from '../scenes/admin/material/MaterialIndexSceneTranslation';
import TeamCreateSceneTranslation from '../scenes/admin/team/TeamCreateSceneTranslation';
import TeamEditSceneTranslation from '../scenes/admin/team/TeamEditSceneTranslation';
import TeamIndexSceneTranslation from '../scenes/admin/team/TeamIndexSceneTranslation';
import TeamPasswordSceneTranslation from '../scenes/admin/team/TeamPasswordSceneTranslation';
import bursarIndexesScenesTranslation from '../scenes/bursar/bursar.indexes.scenes.translation';
import AfterCallIndexSceneTranslation from '../scenes/buyer/after-call/AfterCallIndexSceneTranslation';
import ColdCasesIndexSceneTranslation from '../scenes/buyer/cold-cases/ColdCasesIndexSceneTranslation';
import ModifyIndexSceneTranslation from '../scenes/buyer/ModifyIndexSceneTranslation';
import WelcomeCallIndexSceneTranslation from '../scenes/buyer/welcome-call/WelcomeCallIndexSceneTranslation';
import CCManagerCallListTranslation from '../scenes/cc-manager/CCManagerCallListTranslation';
import CustomerClosedTranslation from '../scenes/customer-service/CustomerClosedTranslation';
import PackageCodeRegistryIndexSceneTranslation from '../scenes/package-code-registry/PackageCodeRegistryIndexSceneTranslation';
import PackagerHistoryTranslation from '../scenes/packager/PackagerHistoryTranslation';
import tellerScenesTranslation from '../scenes/teller/teller-scenes-translation';
import TellerSMSLogTranslation from '../scenes/teller/TellerSMSLogTranslation';
import TimeRecordingIndexSceneTranslation from '../scenes/time-recording/TimeRecordingIndexSceneTranslation';
import ReValuationTranslation from '../scenes/valuation/ReValuationTranslation';
import ValuationWaitingIndexSceneTranslation from '../scenes/valuation/ValuationWaitingIndexSceneTranslation';

const translation = merge.all<Resource>([
  releoxTranslations,
  ClientIndexTranslation,
  OrderIndexTranslation,
  MaterialCreateSceneTranslation,
  MaterialEditSceneTranslation,
  MaterialIndexSceneTranslation,
  TeamPasswordSceneTranslation,
  TeamCreateSceneTranslation,
  TeamEditSceneTranslation,
  TeamIndexSceneTranslation,
  ClientEditSceneTranslation,
  bursarIndexesScenesTranslation,
  AfterCallIndexSceneTranslation,
  ModifyIndexSceneTranslation,
  WelcomeCallIndexSceneTranslation,
  CCManagerCallListTranslation,
  CustomerClosedTranslation,
  PackagerHistoryTranslation,
  tellerScenesTranslation,
  TellerSMSLogTranslation,
  ReValuationTranslation,
  ValuationWaitingIndexSceneTranslation,
  TimeRecordingIndexSceneTranslation,
  PackageCodeRegistryIndexSceneTranslation,
  ColdCasesIndexSceneTranslation,
]);

export default translation;
