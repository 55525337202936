import getValue from 'get-value';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Card, CardTitle, Loading } from 'releox-react';
import GenericForm from '../../forms/GenericForm';
import GreetingsForm from '../../forms/greetings/GreetingsForm';
import GreetingsInitialValues, {
  GreetingsFormBody,
} from '../../forms/greetings/GreetingsInitialValues';
import { greetingsRequest } from '../../requests/greetings.request';
import RednotAction from '../../store/rednot/RednotAction';

export default () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const query = useQuery('greetings', async () => {
    const data = await greetingsRequest.getAll();
    return data;
  });

  const mutation = useMutation(
    async (body: GreetingsFormBody) => {
      const id = getValue(query.data || {}, '0.id');
      if (!id) throw new Error('Missing greetings id');
      const data = await greetingsRequest.update(id, body);
      return data;
    },
    {
      onSuccess: () => {
        dispatch(RednotAction.success('Saved'));
        queryClient.invalidateQueries('greetings');
      },
    }
  );

  if (query.error) return <span>Missing base greetings</span>;

  if (query.isLoading || query.data === undefined) return <Loading />;

  return (
    <div className="col-md-6 offset-3">
      <Card>
        <CardTitle>Invoice Greetings</CardTitle>
        <GenericForm
          onSubmit={mutation.mutate}
          initialValues={{
            ...GreetingsInitialValues,
            ...query.data[0],
          }}
        >
          <GreetingsForm />
        </GenericForm>
      </Card>
    </div>
  );
};
