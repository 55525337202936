import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, DetailField } from 'releox-react';
import { SignedMagnifyImg } from '../../../components/SignedComponents';
import ValuationOrderInfo from '../../../components/ValuationOrderInfo';
import ValuatesAction, { ValuateScenes } from '../../../store/valuate/ValuatesAction';
import ValuatesSelector from '../../../store/valuate/ValuatesSelector';

const ValuationPreview = () => {
  const dispatch = useDispatch();
  const form = useSelector(ValuatesSelector.selectFormValues);
  const goldWeight = useSelector(ValuatesSelector.selectGoldWeight);
  const silverWeight = useSelector(ValuatesSelector.selectSilverWeight);
  const customGoldWeight = useSelector(ValuatesSelector.selectCustomGoldWeight);
  const otherWeight = useSelector(ValuatesSelector.selectOtherWeight);
  const totalPrice = useSelector(ValuatesSelector.selectPrice);

  const goPreviousState = useCallback(() => {
    dispatch(ValuatesAction.nextStep(ValuateScenes.VALUATE));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(ValuatesAction.saveOrder());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-lg-6">
        <Card>
          <div>
            <CardTitle>Preview</CardTitle>
            <ValuationOrderInfo />
            <hr />
            <CardTitle>Weight</CardTitle>
            <div className="row">
              <div className="col-6">
                <table>
                  <tbody>
                    <DetailField label="Gold">{goldWeight}</DetailField>
                    <DetailField label="Silver">{silverWeight}</DetailField>
                    <DetailField label="Custom gold">{customGoldWeight}</DetailField>
                    <DetailField label="Other">{otherWeight}</DetailField>
                    <DetailField label="Package">{form.packageWeight}</DetailField>
                    <DetailField label="Material">{form.materialWeight}</DetailField>
                    <DetailField label="Total price">{totalPrice}</DetailField>
                  </tbody>
                </table>
              </div>
              <div className="col-6">
                <strong>Message to teller: </strong>
                {form.tellerMessageFromValuation}
              </div>
            </div>
            <hr />
            <table className="table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Density</th>
                  <th>Weight</th>
                  <th>Factor</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {form.products.map((p) => (
                  <tr key={p.key}>
                    <td>{p.name}</td>
                    <td>{p.densityName}</td>
                    <td>{p.weight}</td>
                    <td>{p.factor}</td>
                    <td>{p.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button id="back-button" onClick={goPreviousState}>
              Back
            </Button>
            <Button id="save-button" className="float-right" onClick={onSubmit}>
              Save
            </Button>
          </div>
        </Card>
      </div>
      <div className="col-lg-6">
        {form.valuationImage ? <SignedMagnifyImg src={form.valuationImage} /> : ''}
        {form.extraImages && form.extraImages.length
          ? form.extraImages.map((img) => (
              <div className="mt-4">
                <SignedMagnifyImg src={img} />
              </div>
            ))
          : ''}
      </div>
    </div>
  );
};

export default ValuationPreview;
