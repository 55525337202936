export default class NewOrdersSelector {
  public static selectIsLoading(state: ReduxState) {
    return state.newOrder.isLoading;
  }

  public static selectIsPhoneSearched(state: ReduxState) {
    return state.newOrder.isPhoneSearched;
  }

  public static selectClient(state: ReduxState) {
    return state.newOrder.client;
  }

  public static selectIsWebOrder(state: ReduxState) {
    return state.newOrder.isWebOrder;
  }
}
