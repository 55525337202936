import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import RednotAction from '../rednot/RednotAction';
import PaymentXmlsEffect from './PaymentXmlsEffect';

export default class PaymentXmlsAction {
  static ENTRY = 'PAYMENT_XML';

  static actionCreator = actionCreator(
    PaymentXmlsAction.ENTRY,
    PaymentXmlsEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = PaymentXmlsAction.actionCreator.getCreateAction();

  static fetch = PaymentXmlsAction.actionCreator.getFindByIdAction();

  static list = PaymentXmlsAction.actionCreator.getFindAndCountAction();

  static clean = PaymentXmlsAction.actionCreator.getCleanAction();
}
