import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardTitle, Details } from 'releox-react';
import withOrder from '../../HOC/withOrder';
import withQZ, { QZTrayProps } from '../../HOC/withQZ';
import { PrintURL } from '../../classes/PrintURL';
import Printer from '../../classes/Printer';
import OrderColumn from '../../columns/OrderColumn';
import { SignedImg } from '../../components/SignedComponents';
import BursarForm from '../../forms/bursar/BursarForm';
import BursarInitialValues, { BursarFormBody } from '../../forms/bursar/BursarInitialValues';
import BursarsAction from '../../store/bursar/BursarsAction';
import MaterialsAction from '../../store/material/MaterialsAction';
import MaterialsSelector from '../../store/material/MaterialsSelector';
import { Product } from '../../store/order/Order';
import OrdersSelector from '../../store/order/OrdersSelector';
import getProductName from '../../utils/get-product-name';

interface ProductLineProp {
  title: string;
  weight: number;
  lineKey?: string;
}

const ProductLine = ({ title, weight, lineKey }: ProductLineProp) => (
  <li key={lineKey}>
    <b>{title}:</b> {weight}g
  </li>
);

const BursarPage = ({ print }: QZTrayProps) => {
  const dispatch = useDispatch();
  const order = useSelector(OrdersSelector.selectOrder);
  const materials = useSelector(MaterialsSelector.selectMaterials);

  useEffect(() => {
    dispatch(MaterialsAction.list());
    dispatch(BursarsAction.viewDidMount());
  }, [dispatch]);

  useEffect(() => {
    const noDealProducts = order.products.filter((product) => !product.inDeal);
    if (order.ignobleReturn || noDealProducts.length) {
      print(PrintURL.getAddressLabelUrl(order.id));
    }
  }, [order, print]);

  const printAddressLabel = useCallback(() => {
    print(PrintURL.getAddressLabelUrl(order.id));
  }, [print, order]);

  const onSubmit = useCallback(
    (body: BursarFormBody) => {
      if (order.products.filter((product) => !product.inDeal).length || !order.offerAccepted) {
        // eslint-disable-next-line no-restricted-globals,no-alert
        if (!confirm('Please make sure you have made returns correctly!')) return;
      }
      dispatch(BursarsAction.onSubmit(body));
    },
    [dispatch, order]
  );

  const productMapper = useCallback(
    (product: Product) => {
      return (
        <ProductLine
          key={product.key}
          title={getProductName(product, materials)}
          weight={product.weight}
          lineKey={product.key}
        />
      );
    },
    [materials]
  );

  return (
    <Formik onSubmit={onSubmit} initialValues={BursarInitialValues}>
      <Form>
        <div className="row">
          <div className="col-md-6">
            <Card>
              <CardTitle>{`Confirmed Order # ${order.orderNumber}`}</CardTitle>
              <div>
                <Details
                  object={order}
                  properties={[
                    OrderColumn.clientName,
                    OrderColumn.phone,
                    OrderColumn.address,
                    OrderColumn.tellerDate,
                    OrderColumn.tellerName,
                  ]}
                />
                <hr />
                <div>
                  <p>In deal products:</p>
                  <ul>{order.products.filter((product) => product.inDeal).map(productMapper)}</ul>
                  <div className="bursar-highlight-bg p-2">
                    <p className="bursar-highlight-title">NOT in deal products:</p>
                    <ul>
                      {order.products.filter((product) => !product.inDeal).map(productMapper)}
                      {order.insuranceReturn ? (
                        <li>
                          <b>Insurance return</b>
                        </li>
                      ) : (
                        ''
                      )}
                      {order.ignobleReturn ? (
                        <li>
                          <b>ignoble return</b>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                <b>Message from teller</b>
                <br />
                {order.revaluationMessageFromTeller || '-'}
              </p>
            </Card>
            <Card>
              <>
                <CardTitle>Follow-Up</CardTitle>
                <div>
                  <ul>
                    {order.termsAccepted ? <li>Terms and conditions accepted</li> : ''}
                    {order.offerAccepted ? <li>Offer accepted</li> : ''}
                    {order.offeredDeclined ? <li>Offer declined</li> : ''}
                    {order.ignobleReturn ? <li>Ignoble return</li> : ''}
                    {order.offeredDeclined ? <li>Offer declined</li> : ''}
                    {order.insuranceReturn ? <li>Insurance return</li> : ''}
                    {order.isDonation ? <li>Donation</li> : ''}
                    <li>Payment status: {order.isPaid ? 'Paid' : 'Not Paid'}</li>
                  </ul>
                </div>
                {!order.offerAccepted ? (
                  <>
                    <hr />
                    <div className="alert alert-danger" role="alert">
                      The client has not accept offer. By default, all materials are returned.
                    </div>
                  </>
                ) : (
                  ''
                )}
                {!order.isComplete ? (
                  <>
                    <hr />
                    <BursarForm />
                    <Button type="submit" id="submit-button" className="mr-3">
                      Order Processed
                    </Button>
                    <Button type="button" id="print-button" onClick={printAddressLabel}>
                      Print address label
                    </Button>
                  </>
                ) : (
                  <p>
                    <b>Bursar comment:</b> {order.bursarComment}
                  </p>
                )}
              </>
            </Card>
          </div>
          <div className="col-md-6">
            <SignedImg src={order.valuationImage ?? ''} className="valuation-img" />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default withQZ(withOrder(BursarPage), Printer.ZEBRA);
