import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Input } from 'releox-react';
import SearchAction, { SearchableModels, SearchBody } from '../store/search/SearchAction';
import OrderSearchGuide from './OrderSearchGuide';

interface Props {
  type: SearchableModels;
  qSelector(state: ReduxState): string;
  searchFields: string[];
  SearchGuide: typeof OrderSearchGuide;
}

export default ({ type, searchFields, SearchGuide, qSelector }: Props) => {
  const dispatch = useDispatch();
  const q = useSelector(qSelector);

  const onSubmit = useCallback(
    (body: SearchBody) => {
      dispatch(SearchAction.onSubmit(body, type, searchFields));
    },
    [dispatch, type, searchFields]
  );

  return (
    <Card>
      <div className="row">
        <div className="col-6">
          <Formik initialValues={{ q }} onSubmit={onSubmit}>
            <Form>
              <Input name="q" label="Search" />
            </Form>
          </Formik>
        </div>
        <div className="col-6">
          <SearchGuide />
        </div>
      </div>
    </Card>
  );
};
