import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardTitle, Input } from 'releox-react';
import GenericForm from '../../../forms/GenericForm';
import NewOrdersAction from '../../../store/new-order/NewOrdersActions';

interface PhoneQuery {
  phone: string;
}

export default () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (body: PhoneQuery) => {
      dispatch(NewOrdersAction.searchByPhoneNumber(body.phone));
    },
    [dispatch]
  );

  return (
    <div className="col-md-6 offset-3">
      <Card>
        <CardTitle>Find user</CardTitle>
        <GenericForm<PhoneQuery>
          initialValues={{ phone: '' }}
          showBackButton={false}
          onSubmit={onSubmit}
        >
          <Input label="Phone number" name="phone" />
        </GenericForm>
      </Card>
    </div>
  );
};
