import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PrintURL } from '../classes/PrintURL';
import { formatTimeAndFrom } from '../config';
import routes from '../configs/routes';
import packageStatus from '../package-status';
import { Order } from '../store/order/Order';

const formatTime = (time?: string | Date) => {
  if (!time) return '-';
  return formatTimeAndFrom(time);
};

const booleanFormatter = (bool: boolean) => (bool ? 'Yes' : 'No');

/* eslint-disable react/jsx-no-target-blank */
export default class OrderColumn {
  static barCode = { text: 'Bar code', dataField: 'barCode' };

  static country = { text: 'Country', dataField: 'country', sort: true };

  static language = { text: 'Language', dataField: 'language', sort: true };

  static birthYear = { text: 'Birth year', dataField: 'client.birthYear' };

  static packagerMessageFromBuyer = {
    text: 'Buyer to packager',
    dataField: 'packagerMessageFromBuyer',
  };

  static valuationMessageFromBuyer = {
    text: 'Buyer to valuation',
    dataField: 'valuationMessageFromBuyer',
  };

  static tellerMessageFromBuyer = {
    text: 'Buyer to teller',
    dataField: 'tellerMessageFromBuyer',
  };

  static tellerMessageFromValuation = {
    text: 'Valuation to teller',
    dataField: 'tellerMessageFromValuation',
  };

  static revaluationMessageFromTeller = {
    text: 'Teller to revaluation',
    dataField: 'revaluationMessageFromTeller',
  };

  static bursarComment = {
    text: 'Bursar comment',
    dataField: 'bursarComment',
  };

  static tellerComment = {
    text: 'Teller comment',
    dataField: 'tellerComment',
  };

  static birthYearAndAge = {
    text: 'Birth year (age)',
    dataField: 'client.birthYear',
    formatter: (year: number) => (year ? `${year} (${new Date().getFullYear() - year})` : '-'),
  };

  static callTransfer = {
    text: 'Call transfer',
    dataField: 'isPermanentlyTransferred',
    formatter: (_: boolean, order: Order) => {
      if (order.isPermanentlyTransferred) return `Permanently (${order.originalCaller?.name})`;
      if (order.isTemporarilyTransferred) return `Temporarily (${order.caller?.name})`;
      return 'No';
    },
  };

  static generalMessage = { text: 'General message', dataField: 'generalMessage' };

  static createdAt = {
    text: 'Created',
    dataField: 'createdAt',
    sort: true,
    formatter: formatTime,
  };

  static updatedAt = {
    text: 'Updated',
    dataField: 'updatedAt',
    sort: true,
    formatter: formatTime,
  };

  static clientName = {
    text: 'Client',
    dataField: 'client.firstName',
    formatter: (_: string, r: Order) =>
      `${r.client?.firstName} ${r.client?.lastName} (# ${r.client?.clientNumber})`,
  };

  static status = {
    text: 'Status',
    dataField: 'isPackaged',
    formatter: (_: boolean, r: Order) => packageStatus(r),
  };

  static orderNumberAspaLink = {
    text: '#',
    dataField: 'orderNumber',
    sort: true,
    formatter: (orderNumber: string, o: Order) => (
      <Link to={routes.CUSTOMER_SERVICE.ORDER_SHOW.replace(':id', o.id)}>{orderNumber}</Link>
    ),
  };

  static orderNumber = { text: '#', dataField: 'orderNumber', sort: true };

  static orderNumberAdminLink = {
    text: '#',
    dataField: 'orderNumber',
    sort: true,
    formatter: (orderNumber: string, o: Order) => (
      <Link to={routes.ADMIN_ORDER.EDIT.replace(':id', o.id)}>{orderNumber}</Link>
    ),
  };

  static payerName = { dataField: 'payer.name', text: 'Payer' };

  static payedDate = {
    dataField: 'payed',
    text: 'Payday',
    sort: true,
    formatter: formatTime,
  };

  static dueDate = {
    dataField: 'dueDate',
    text: 'Due date',
    sort: true,
    formatter: formatTime,
  };

  static afterCall = {
    ...OrderColumn.dueDate,
    text: 'After call',
  };

  static bursarName = { dataField: 'bursar.name', text: 'Bursar' };

  static bursarHandleDate = {
    dataField: 'bursarHandleDate',
    text: 'Handled',
    sort: true,
    formatter: formatTime,
  };

  static tellerName = { dataField: 'teller.name', text: 'Teller' };

  static tellerDate = {
    dataField: 'tellerDate',
    text: 'Teller date',
    sort: true,
    formatter: formatTime,
  };

  static phone = { dataField: 'client.phone', text: 'Phone' };

  static afterCallLatest = {
    dataField: 'id',
    text: 'Latest after call try',
    formatter: (c: string, r: Order) => {
      if (!r.latestAfterCall) return '-';
      return formatTime(r.latestAfterCall);
    },
  };

  static afterCallLatestLegacy = {
    dataField: 'id',
    text: 'Latest after call try (Legacy)',
    formatter: (c: string, r: Order) => {
      if (!r.afterCallTries) return '-';
      return r.afterCallTries.reverse()[0]
        ? formatTime(r.afterCallTries.reverse()[r.afterCallTries.length - 1])
        : '-';
    },
  };

  static welcomeCall = {
    dataField: 'welcomeCall',
    text: 'Welcome call',
    formatter: formatTime,
    sort: true,
  };

  static valuationDate = {
    dataField: 'valuationDate',
    text: 'Valuation date',
    formatter: formatTime,
    sort: true,
  };

  static afterCallTries = {
    dataField: 'afterCallLogs.length',
    text: 'After call tries',
  };

  static coldCallTries = {
    dataField: 'coldCallLogs.length',
    text: 'Cold call tries',
  };

  static afterCallTriesLegacy = {
    dataField: 'afterCallTries.length',
    text: 'After call tries (Legacy)',
  };

  static address = {
    dataField: 'client.zip',
    text: 'Address',
    formatter: (c: string, o: Order) => {
      return `${o.client?.address}, ${o.client?.zip}, ${o.client?.city}, ${o.client?.country}`;
    },
  };

  static valuationLabel = {
    dataField: 'id',
    text: 'Valuation label',
    formatter: (id: string) => {
      return (
        <a href={PrintURL.getValuationLabelUrl(id)} target="_blank">
          Valuation label
        </a>
      );
    },
  };

  static addressLabel = {
    dataField: 'id',
    text: 'Address label',
    formatter: (id: string) => {
      return (
        <a href={PrintURL.getAddressLabelUrl(id)} target="_blank">
          Address label
        </a>
      );
    },
  };

  static isPackaged = {
    dataField: 'isPackaged',
    text: 'Is packaged',
    formatter: (isPackaged: boolean) => (isPackaged ? 'Yes' : 'No'),
  };

  static welcomeCallLatest = {
    dataField: 'id',
    text: 'Latest welcome call try',
    formatter: (c: string, r: Order) => {
      if (!r.latestWelcomeCall) return '-';
      return formatTime(r.latestWelcomeCall);
    },
  };

  static coldCallLatest = {
    dataField: 'id',
    text: 'Latest cold call',
    formatter: (c: string, r: Order) => {
      if (!r.latestColdCall) return '-';
      return formatTime(r.latestColdCall);
    },
  };

  static welcomeCallLatestLegacy = {
    dataField: 'id',
    text: 'Latest welcome call try (Legacy)',
    formatter: (c: string, r: Order) => {
      if (!r.welcomeCallTried) return '-';
      return r.welcomeCallTried.reverse()[0]
        ? formatTime(r.welcomeCallTried.reverse()[r.welcomeCallTried.length - 1])
        : '-';
    },
  };

  static welcomeCallTries = {
    dataField: 'welcomeCallLogs.length',
    text: 'Welcome call tries',
  };

  static welcomeCallTriesLegacy = {
    dataField: 'welcomeCallTried.length',
    text: 'Tries (Legacy)',
  };

  static callerName = {
    dataField: 'caller.name',
    text: 'Buyer',
    formatter: (c: string, r: Order) => {
      const callerName = r.caller?.name;
      if (r.assignedCallerId) {
        return (
          <>
            {r.assignedCaller?.name} <br /> <small>from: {callerName} (T)</small>
          </>
        );
      }
      if (r.originalCallerId) {
        return (
          <>
            {callerName} <br /> <small>from: {r.originalCaller?.name} (P)</small>
          </>
        );
      }
      return callerName || '';
    },
  };

  static isTransferred = {
    dataField: 'isPermanentlyTransferred',
    text: 'Call transfer',
    formatter: (c: string, r: Order) => {
      if (r.isPermanentlyTransferred) return 'Transferred permanently';
      if (r.isTemporarilyTransferred) return 'Transferred temporarily';
      return '-';
    },
  };

  static closerName = { dataField: 'closer.name', text: 'Closer' };

  static closeMethod = { dataField: 'closeMethod', text: 'Method' };

  static closeReason = { dataField: 'closeReason', text: 'Reason' };

  static closeDate = { dataField: 'closeDate', text: 'Closed', formatter: formatTime };

  static invoice = {
    dataField: 'invoice',
    text: 'Invoice',
    formatter: (c: string, r: Order) => (
      <a href={PrintURL.getInvoiceUrl(r.language, r.id)} target="_blank">
        Invoice
      </a>
    ),
  };

  static letter = {
    dataField: 'letter',
    text: 'Letter',
    formatter: (c: string, r: Order) => (
      <a href={PrintURL.getLetterUrl(r.language, r.id)} target="_blank">
        Letter
      </a>
    ),
  };

  static barCodePrintForDetails = {
    dataField: 'foo',
    text: 'Shipment barcode',
    formatter: (c: string, order: Order) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [url, setUrl] = useState('');

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        PrintURL.getCarrierLabelUrl(order).then((u) => setUrl(u));
      }, [order]);

      if (!order.barCode) return '-';
      return (
        <a href={url} target="_blank">
          Shipment barcode
        </a>
      );
    },
  };

  static barCodePrintForTable = (onClick: (order: Order) => void) => ({
    dataField: 'foo',
    text: 'Shipment barcode',
    formatter: (c: string, order: Order) => {
      if (!order.barCode) return '-';
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="link" onClick={() => onClick(order)}>
          Shipment barcode
        </div>
      );
    },
  });

  static packagedDate = {
    dataField: 'packagedDate',
    text: 'Packaged date',
    sort: true,
    formatter: formatTime,
  };

  static shipmentRegisterError = {
    dataField: 'shipmentRegisterError',
    text: 'Shipment error',
  };

  static isShipmentRegistered = {
    dataField: 'isShipmentRegistered',
    text: 'Is register',
    formatter: booleanFormatter,
  };

  static hasShipmentError = {
    dataField: 'hasShipmentError',
    text: 'Has shipment error',
    formatter: booleanFormatter,
  };

  static packagedName = {
    dataField: 'packager.name',
    text: 'Packager',
  };

  static arrivedSMSDate = {
    dataField: 'arrivedSmsDate',
    text: 'Arrived SMS date',
    formatter: formatTime,
  };

  static arrivedSMSLog = {
    dataField: 'arrivedSmsLog',
    text: 'Arrived SMS log',
  };

  static receivedDate = {
    dataField: 'receivedDate',
    text: 'Received date',
    sort: true,
    formatter: formatTime,
  };

  static receiverName = {
    dataField: 'receiver.name',
    text: 'Receiver',
  };

  static tellerCallLatest = {
    dataField: 'latestTellerCall',
    sort: true,
    text: 'Latest try',
    formatter: (latestTellerCall: string) => {
      if (!latestTellerCall) return '-';
      return formatTime(latestTellerCall);
    },
  };

  static offer = {
    dataField: 'offer',
    text: 'Offer',
    sort: true,
  };

  static valuatorName = {
    dataField: 'valuator.name',
    text: 'Valuator',
  };

  static goldWeight = {
    dataField: 'goldWeight',
    text: 'Gold',
  };

  static silverWeight = {
    dataField: 'silverWeight',
    text: 'Silver',
  };

  static packageWeight = {
    dataField: 'packageWeight',
    text: 'Package',
  };

  static materialWeight = {
    dataField: 'materialWeight',
    text: 'Material',
  };

  static team = {
    dataField: 'team',
    text: 'Team',
  };

  static isPaid = {
    dataField: 'isPaid',
    text: 'Is paid',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static termsAccepted = {
    dataField: 'termsAccepted',
    text: 'Terms and conditions accepted',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static offerAccepted = {
    dataField: 'offerAccepted',
    text: 'Offer accepted',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static insuranceReturn = {
    dataField: 'insuranceReturn',
    text: 'Insurance return',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static ignobleReturn = {
    dataField: 'ignobleReturn',
    text: 'Ignoble return',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static offeredDeclined = {
    dataField: 'offeredDeclined',
    text: 'Offer declined',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };

  static isDonation = {
    dataField: 'isDonation',
    text: 'Donation',
    formatter: (b: boolean) => (b ? 'Yes' : 'No'),
  };
}
