import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from 'releox-react';
import { PaymentMode, SelectedOrder } from '../../classes/AbstractPaymentGenerator';
import PayerPaymentTable from '../../components/PayerPaymentTable';
import PayerPreviousPaymentsTable from '../../components/PayerPreviousPaymentsTable';
import MaterialsSelector from '../../store/material/MaterialsSelector';
import { Order } from '../../store/order/Order';
import OrdersSelector from '../../store/order/OrdersSelector';
import PayersAction from '../../store/payer/PayersAction';

const addIsSelectedField = (orders: Order[]): SelectedOrder[] => {
  return orders.map((order) => {
    return {
      ...order,
      isSelected: true,
    };
  });
};

const Payer = () => {
  const dispatch = useDispatch();
  const params = useParams() as { country: PaymentMode };
  const orders = useSelector(OrdersSelector.selectOrders);
  const isLoading = useSelector(OrdersSelector.selectIsListLoading);
  const materials = useSelector(MaterialsSelector.selectMaterials);

  useEffect(() => {
    dispatch(PayersAction.fetchOrdersByCountry(params.country));
  }, [dispatch, params.country]);

  useEffect(() => {
    dispatch(PayersAction.fetchMaterial());
  }, [dispatch]);

  useEffect(() => {
    dispatch(PayersAction.remapMaterials(materials));
  }, [dispatch, materials]);

  const downloadXML = useCallback(
    (formBody) => {
      dispatch(PayersAction.downloadDocument(formBody, params.country));
    },
    [dispatch, params.country]
  );

  if (isLoading) return <Loading />;

  return (
    <Formik enableReinitialize initialValues={addIsSelectedField(orders)} onSubmit={downloadXML}>
      <Form>
        <div className="row">
          <div className="col-lg-7">
            <PayerPaymentTable />
          </div>
          <div className="col-lg-5">
            <PayerPreviousPaymentsTable />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default Payer;
