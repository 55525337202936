import axios from 'axios';
import { getErrorMessage } from 'loopback-error-parser';
import { useMutation, useQuery } from 'react-query';
import { Language } from '../../classes/Language';
import apis from '../../configs/apis';
import { Client } from '../../store/client/Client';
import { Order } from '../../store/order/Order';

export const usePackagerOrderQuery = (lang: Language) => {
  return useQuery<{
    isLast: boolean;
    order: Order & { client: Client };
  }>(`usePackagerOrderQuery:${lang}`, async () => {
    const url = apis.PACKAGER.FIND_NEXT_ORDER.replace(':country', lang);

    const { data } = await axios.get(url);
    return data;
  });
};

type PackagerUpdateClientMutation = {
  id: string;
  form: any;
};

type Options = {
  onComplete: () => void;
};

export const usePackagerUpdateClientMutation = ({ onComplete }: Options) => {
  return useMutation(
    `usePackagerUpdateClientMutation`,
    async ({ id, form }: PackagerUpdateClientMutation) => {
      const url = `${apis.CLIENT}/${id || 'UNKNOWN'}`;
      const { data } = await axios.patch(url, form);
      return data;
    },
    {
      onSuccess: onComplete,
    }
  );
};

type MarkAsPackagedMutation = {
  onSuccess: () => void;
  onSettled: () => void;
};

export const usePackagerMarkAsPackagedMutation = ({
  onSuccess,
  onSettled,
}: MarkAsPackagedMutation) => {
  return useMutation(
    `usePackagerUpdateClientMutation`,
    async (id: string) => {
      const url = `${apis.PACKAGER.MARK_AS_PACKAGED}`;
      const { data } = await axios.post(url, { orderId: id });
      return data;
    },
    {
      onSuccess,
      onSettled,
    }
  );
};

export const usePackagerAddBarcodeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: MarkAsPackagedMutation['onSuccess'];
  onError: (msg: string) => void;
}) => {
  return useMutation(
    `usePackagerUpdateClientMutation`,
    async ({ barCode, orderId }: { barCode: string; orderId: string }) => {
      const url = `${apis.PACKAGER.ADD_BARCODE}`;
      try {
        const { data } = await axios.post(url, { barCode, orderId });
        return data;
      } catch (e) {
        const msg = getErrorMessage(e);
        return onError(msg);
      }
    },
    {
      onSuccess,
    }
  );
};
