export default {
  en: {
    'ReValuation:sweden': {
      title: 'Re-valuations Sweden',
    },
    'ReValuation:finnish': {
      title: 'Re-valuations Finland',
    },
    'ReValuation:uk': {
      title: 'Re-valuations UK',
    },
  },
};
