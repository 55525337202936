enum CallCountsActionType {
  AFTER_CALL_REQUEST = '@CALL_COUNT:AFTER_CALL_REQUEST',
  AFTER_CALL_FAIL = '@CALL_COUNT:AFTER_CALL_FAIL',
  AFTER_CALL_SUCCESS = '@CALL_COUNT:AFTER_CALL_SUCCESS',

  WELCOME_CALL_REQUEST = '@CALL_COUNT:WELCOME_CALL_REQUEST',
  WELCOME_CALL_FAIL = '@CALL_COUNT:WELCOME_CALL_FAIL',
  WELCOME_CALL_SUCCESS = '@CALL_COUNT:WELCOME_CALL_SUCCESS',
}

export default CallCountsActionType;
