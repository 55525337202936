import { createReducer } from 'redux-rest-helper-for-loopback';
import { Action } from '../Action';
import { TellerRedux } from './Teller';
import {
  TellersActionType,
  TellerSetAccountNumber,
  TellerSetBBAN,
  TellerSetFormValues,
  TellerSetgbpOffer,
  TellerSetIBAN,
  TellerSetInDealTotal,
  TellerSetOffer,
  TellerSetsekOffer,
  TellerSetSMSLoading,
  TellerSetSortCode,
  TellerShowPreview,
} from './TellersAction';
import TellersInitState from './TellersInitState';

const setIBAN = (state: TellerRedux, action: TellerSetIBAN): TellerRedux => ({
  ...state,
  iban: action.payload.iban,
  isIBANValid: action.payload.isIBANValid,
});

const setUkAccountNumber = (state: TellerRedux, action: TellerSetAccountNumber): TellerRedux => ({
  ...state,
  ukAccountNumber: action.payload.ukAccountNumber,
});

const setUkSortCode = (state: TellerRedux, action: TellerSetSortCode): TellerRedux => ({
  ...state,
  ukSortCode: action.payload.ukSortCode,
});

const setBBAN = (state: TellerRedux, action: TellerSetBBAN): TellerRedux => ({
  ...state,
  bban: action.payload.bban,
  isBBANValid: action.payload.isBBANValid,
});

const setInDealTotal = (state: TellerRedux, action: TellerSetInDealTotal): TellerRedux => ({
  ...state,
  inDealTotal: action.payload.inDealTotal,
});

const setFormValues = (state: TellerRedux, action: TellerSetFormValues): TellerRedux => ({
  ...state,
  formValues: action.payload.formValues,
});

const setOffer = (state: TellerRedux, action: TellerSetOffer): TellerRedux => ({
  ...state,
  offer: action.payload.offer,
});

const setsekOffer = (state: TellerRedux, action: TellerSetsekOffer): TellerRedux => ({
  ...state,
  sekOffer: action.payload.sekOffer,
});

const setGbpOffer = (state: TellerRedux, action: TellerSetgbpOffer): TellerRedux => ({
  ...state,
  gbpOffer: action.payload.gbpOffer,
});

const setManualMode = (state: TellerRedux): TellerRedux => ({
  ...state,
  isManualCalculation: true,
});

const setSmsLoading = (state: TellerRedux, action: TellerSetSMSLoading): TellerRedux => ({
  ...state,
  isSMSLoading: action.payload.isSMSLoading,
});

const showPreview = (state: TellerRedux, action: TellerShowPreview): TellerRedux => ({
  ...state,
  showPreview: action.payload.showPreview,
});

const reset = (
  state: TellerRedux,
  action: Action<TellersActionType.RESET>,
  initState: TellerRedux
): TellerRedux => initState;

const handlers = {
  [TellersActionType.SET_IBAN]: setIBAN,
  [TellersActionType.SET_BBAN]: setBBAN,
  [TellersActionType.SET_IN_DEAL_TOTAL]: setInDealTotal,
  [TellersActionType.SET_FORM_VALUES]: setFormValues,
  [TellersActionType.SET_OFFER]: setOffer,
  [TellersActionType.SET_MANUAL_MODE]: setManualMode,
  [TellersActionType.SET_SMS_LOADING]: setSmsLoading,
  [TellersActionType.SHOW_PREVIEW]: showPreview,
  [TellersActionType.RESET]: reset,
  [TellersActionType.SET_SEK_OFFER]: setsekOffer,
  [TellersActionType.SET_GBP_OFFER]: setGbpOffer,
  [TellersActionType.SET_UK_ACCOUNT_NUMBER]: setUkAccountNumber,
  [TellersActionType.SET_UK_SORT_CODE]: setUkSortCode,
};

export default createReducer(handlers, TellersInitState);
