import React from 'react';
import { AsyncSelect } from 'releox-react';
import DatePicker from '../../components/DatePicker';
import apis from '../../configs/apis';

export default () => (
  <div className="row">
    <div className="col-md-3">
      <AsyncSelect getUrl={apis.MEMBER} searchFields={['name']} name="callerId" label="Caller" />
    </div>
    <div className="col-md-3">
      <DatePicker name="startDate" label="From" maxDate={null} minDate={null} />
    </div>
    <div className="col-md-3">
      <DatePicker name="endDate" label="To" maxDate={null} minDate={null} />
    </div>
  </div>
);
