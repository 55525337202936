import { push } from 'react-router-redux';
import { BarCodePromptBody } from '../../components/BarCodePrompt';
import routes from '../../configs/routes';
import { ActionWPayload, ReduxDispatch } from '../Action';
import ErrorsAction from '../error/ErrorsAction';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';

export enum ValuationBarCodePropmtActionTypes {
  VALUATED_COUNT_SUCCESS = '@VALUATED_COUNT:VALUATED_COUNT_SUCCESS',
  WAITING_VALUATION_COUNT_SUCCESS = '@VALUATED_COUNT:WAITING_VALUATION_COUNT_SUCCESS',
  WAITING_REVALUATION_COUNT_SUCCESS = '@VALUATED_COUNT:WAITING_REVALUATION_COUNT_SUCCESS',
}

enum PromptError {
  NOT_FOUND = 'Cannot find order',
  IS_RECEIVED = 'Package is not received',
  IS_CLOSED = 'Package is closed',
  IS_BOOMERANG = 'Package is marked as boomerang',
  IS_VALUED = 'Order is already valuated',
}

type Payload = { count: number };

type ValuatedCountSuccess = ActionWPayload<
  ValuationBarCodePropmtActionTypes.VALUATED_COUNT_SUCCESS,
  Payload
>;
type WaitingValuationCountSuccess = ActionWPayload<
  ValuationBarCodePropmtActionTypes.WAITING_VALUATION_COUNT_SUCCESS,
  Payload
>;
type WaitingRevaluationCountSuccess = ActionWPayload<
  ValuationBarCodePropmtActionTypes.WAITING_REVALUATION_COUNT_SUCCESS,
  Payload
>;

export type ValuationBarCodePromptActions =
  | ValuatedCountSuccess
  | WaitingValuationCountSuccess
  | WaitingRevaluationCountSuccess;

export default class ValuationBarCodePromptAction {
  private static valuatedCountSuccess(count: number): ValuationBarCodePromptActions {
    return {
      type: ValuationBarCodePropmtActionTypes.VALUATED_COUNT_SUCCESS,
      payload: {
        count,
      },
    };
  }

  private static waitingValuationCountSuccess(count: number): ValuationBarCodePromptActions {
    return {
      type: ValuationBarCodePropmtActionTypes.WAITING_VALUATION_COUNT_SUCCESS,
      payload: {
        count,
      },
    };
  }

  private static waitingRevaluationCountSuccess(count: number): ValuationBarCodePromptActions {
    return {
      type: ValuationBarCodePropmtActionTypes.WAITING_REVALUATION_COUNT_SUCCESS,
      payload: {
        count,
      },
    };
  }

  public static updateNumbers() {
    return (dispatch: ReduxDispatch) => {
      const valuatedQuery = OrderQuery.getValuationTodayValuatedQuery();
      const waitingValuationQuery = OrderQuery.getValuationWaitingValuationQuery();
      const waitingReValuationQuery = OrderQuery.getValuationWaitingReValuationQuery();

      OrdersEffect.count(valuatedQuery).then(({ count }) =>
        dispatch(this.valuatedCountSuccess(count))
      );
      OrdersEffect.count(waitingValuationQuery).then(({ count }) =>
        dispatch(this.waitingValuationCountSuccess(count))
      );
      OrdersEffect.restRequests
        .count(waitingReValuationQuery)
        .then(({ count }) => dispatch(this.waitingRevaluationCountSuccess(count)));
    };
  }

  public static onSubmit(body: BarCodePromptBody) {
    return (dispatch: ReduxDispatch) => {
      OrdersEffect.findByBarcode(body)
        .then((order) => {
          if (!order) return Promise.reject(new Error(PromptError.NOT_FOUND));
          if (!order.isReceived) return Promise.reject(new Error(PromptError.IS_RECEIVED));
          if (order.closed) return Promise.reject(new Error(PromptError.IS_CLOSED));
          if (order.isBoomerang) return Promise.reject(new Error(PromptError.IS_BOOMERANG));
          if (order.isValued && !order.returnToValuation)
            return Promise.reject(new Error(PromptError.IS_VALUED));
          return dispatch(push(routes.VALUATION.VALUATE.replace(':id', order.id)));
        })
        .catch(dispatch(ErrorsAction.onCatch()));
    };
  }
}
