import { Country } from './classes/Country';
import routes from './configs/routes';
import { User } from './store/user/User';

export default (user: User, counts: { afterCallCount: number; welcomeCallCount: number }) => {
  const sidebar: any = [
    {
      text: 'Home',
      exact: true,
      url: routes.HOME,
    },
  ];
  if (user.admin) {
    sidebar.push({
      text: '[Preview] Time tracking',
      exact: true,
      url: routes.TIME_RECORDING.INDEX,
    });
    sidebar.push({
      type: 'dropdown',
      text: 'Admin',
      url: '/admin',
      children: [
        {
          text: 'Users',
          url: routes.ADMIN_USERS.INDEX,
        },
        {
          text: 'Materials',
          url: routes.ADMIN_MATERIAL.INDEX,
        },
        {
          text: 'Clients',
          url: routes.ADMIN_CLIENT.INDEX,
        },
        {
          text: 'Orders',
          url: routes.ADMIN_ORDER.INDEX,
        },
        {
          text: 'Police reports',
          url: routes.ADMIN_POLICE_REPORTS,
        },
        {
          text: 'Package Code Registry',
          url: routes.ADMIN_PACKAGE_CODE_REGISTRY,
        },
        {
          text: 'Greetings',
          url: routes.ADMIN_GREETINGS,
        },
      ],
    });

    let hidden;
    if (user.id === '5df3c8c44cac0b0012404519') {
      hidden = {
        text: 'Old report (Hidden)',
        url: routes.REPORTS.INDEX,
      };
    }

    sidebar.push({
      type: 'dropdown',
      text: 'Reports',
      url: '/reports',
      children: [
        hidden,
        {
          text: 'Valuated report',
          url: routes.REPORTS.VALUATED_ORDERS,
        },
        {
          text: 'Accepted offers report',
          url: routes.REPORTS.ACCEPTED_OFFERS,
        },
      ].filter((x) => x),
    });
  }

  if (user.ccManager) {
    sidebar.push({
      type: 'dropdown',
      text: 'CC Manager',
      url: '/cc-manager',
      children: [
        {
          text: 'Call transfer',
          url: routes.CC_MANAGER.TRANSFER,
        },
        {
          text: 'Cancel transfer',
          url: routes.CC_MANAGER.CANCEL,
        },
        {
          text: 'Call lists',
          url: routes.CC_MANAGER.LISTS,
        },
        {
          text: 'Report',
          url: routes.CC_MANAGER.REPORT,
        },
      ],
    });
  }

  if (user.customer) {
    sidebar.push({
      type: 'dropdown',
      text: 'Customer service',
      url: '/customer-service',
      children: [
        {
          text: 'Orders',
          url: routes.CUSTOMER_SERVICE.ORDER_INDEX,
        },
        {
          text: 'Clients',
          url: routes.CUSTOMER_SERVICE.CLIENT_INDEX,
        },
        {
          text: 'Cancelled orders',
          url: routes.CUSTOMER_SERVICE.CLOSED_INDEX,
        },
        {
          text: 'Mass boomerang',
          url: routes.CUSTOMER_SERVICE.MASS_BOOMERANG,
        },
      ],
    });
  }

  if (user.buyer) {
    let welcomeCallText = 'Welcome calls';
    let afterCallText = 'After calls';
    if (counts.welcomeCallCount > -1) welcomeCallText += ` (${counts.welcomeCallCount})`;
    if (counts.afterCallCount > -1) afterCallText += ` (${counts.afterCallCount})`;
    sidebar.push({
      type: 'dropdown',
      text: 'Buyer',
      url: '/buyer',
      children: [
        {
          text: 'Add order',
          url: routes.BUYER.ADD_ORDER,
        },
        {
          text: 'Stats',
          url: routes.BUYER.STATS,
        },
        {
          text: welcomeCallText,
          url: routes.BUYER.WELCOME_CALLS,
        },
        {
          text: afterCallText,
          url: routes.BUYER.AFTER_CALLS,
        },
        {
          text: 'Modify orders',
          url: routes.BUYER.MODIFY_ORDERS_INDEX,
        },
      ],
    });
  }

  if (user.isColdCaller) {
    sidebar.push({
      type: 'dropdown',
      text: 'Cold cases',
      url: '/cold-cases',
      children: [
        {
          text: 'Finnish Cold Cases',
          url: routes.BUYER.COLD_CASES_INDEX_FI,
        },
        {
          text: 'Swedish Cold Cases',
          url: routes.BUYER.COLD_CASES_INDEX_SE,
        },
        {
          text: 'Fi-Swedish Cold Cases',
          url: routes.BUYER.COLD_CASES_INDEX_FISE,
        },
        {
          text: 'UK Cold Cases',
          url: routes.BUYER.COLD_CASES_INDEX_UK,
        },
      ],
    });
  }

  if (user.packager) {
    sidebar.push({
      type: 'dropdown',
      text: 'Packager',
      url: '/packager',
      children: [
        {
          text: 'Packager',
          url: routes.PACKAGER.PACKAGER_LANGUAGE_SELECT,
        },
        {
          text: 'Packager waiting list',
          url: routes.PACKAGER.PACKAGER_LIST,
        },
        {
          text: 'Packager history',
          url: routes.PACKAGER.PACKAGER_HISTORY,
        },
      ],
    });
  }

  if (user.receiver) {
    sidebar.push({
      text: 'Receiver',
      url: routes.RECEIVER,
    });
  }

  if (user.valuation) {
    sidebar.push({
      type: 'dropdown',
      text: 'Valuation',
      url: '/valuation',
      children: [
        {
          text: 'Valuation',
          url: routes.VALUATION.BAR_CODE_PROMPT,
        },
        {
          text: 'Re-Valuation finnish',
          url: routes.VALUATION.RE_VALUATION_FINNISH,
        },
        {
          text: 'Re-Valuation swedish',
          url: routes.VALUATION.RE_VALUATION_SWEDISH,
        },
        {
          text: 'Re-Valuation UK',
          url: routes.VALUATION.RE_VALUATION_UK,
        },
        {
          text: 'Waiting list',
          url: routes.VALUATION.WAITING_LIST,
        },
        // Valuation stats
        {
          text: 'Valuation stats',
          url: routes.VALUATION.STATS,
        },
      ],
    });
  }

  if (user.teller) {
    sidebar.push({
      type: 'dropdown',
      text: 'Teller',
      url: '/teller',
      children: [
        {
          text: "Today's orders",
          url: routes.TELLER.TODAY_ORDERS,
        },
        {
          text: 'Finnish Verification',
          url: routes.TELLER.FI,
        },
        {
          text: 'Finnish Silver',
          url: routes.TELLER.FI_SILVER,
        },
        {
          text: 'Finnish Zero',
          url: routes.TELLER.FI_ZERO,
        },
        {
          text: 'Finnish Others',
          url: routes.TELLER.FI_OTHER,
        },
        {
          text: 'Swedish Verification',
          url: routes.TELLER.SWE,
        },
        {
          text: 'Swedish Silver',
          url: routes.TELLER.SWE_SILVER,
        },
        {
          text: 'Swedish Zero',
          url: routes.TELLER.SWE_ZERO,
        },
        {
          text: 'Swedish Others',
          url: routes.TELLER.SWE_OTHER,
        },
        {
          text: 'Fi-Swedish Verification',
          url: routes.TELLER.FISWE,
        },
        {
          text: 'Fi-Swedish Silver',
          url: routes.TELLER.FISWE_SILVER,
        },
        {
          text: 'Fi-Swedish Zero',
          url: routes.TELLER.FISWE_ZERO,
        },
        {
          text: 'Fi-Swedish others',
          url: routes.TELLER.FISWE_OTHER,
        },
        {
          text: 'UK Verification',
          url: routes.TELLER.UK,
        },
        {
          text: 'UK Silver',
          url: routes.TELLER.UK_SILVER,
        },
        {
          text: 'UK Zero',
          url: routes.TELLER.UK_ZERO,
        },
        {
          text: 'UK Others',
          url: routes.TELLER.UK_OTHER,
        },
        {
          text: 'Finnish SMS',
          url: routes.TELLER.TELLER_FINNISH_SMS,
        },
        {
          text: 'Swedish SMS',
          url: routes.TELLER.TELLER_SWEDISH_SMS,
        },
        {
          text: 'Fi-Swedish SMS',
          url: routes.TELLER.TELLER_FINLAND_SWEDISH_SMS,
        },
        {
          text: 'SMS logs',
          url: routes.TELLER.TELLER_SMS_LOG,
        },
      ],
    });
  }

  if (user.payer) {
    sidebar.push({
      type: 'dropdown',
      text: 'Payer',
      url: '/payer',
      children: [
        {
          text: 'Payer Finland',
          url: routes.PAYER.COUNTRY.replace(':country', Country.FINLAND),
        },
        {
          text: 'Payer Sweden',
          url: routes.PAYER.COUNTRY.replace(':country', Country.SWEDEN),
        },
        {
          text: 'Payer UK',
          url: routes.PAYER.COUNTRY.replace(':country', Country.UK),
        },
        {
          text: 'Payer Donations',
          url: routes.PAYER.DONATION,
        },
      ],
    });
  }

  if (user.bursar) {
    sidebar.push({
      type: 'dropdown',
      text: 'Bursar',
      url: '/bursar',
      children: [
        {
          text: 'Finnish Bursar',
          url: routes.BURSAR.BAR_CODE_PROMPT.replace(':country', Country.FINLAND),
        },
        {
          text: 'Swedish Bursar',
          url: routes.BURSAR.BAR_CODE_PROMPT.replace(':country', Country.SWEDEN),
        },
        {
          text: 'UK Bursar',
          url: routes.BURSAR.BAR_CODE_PROMPT.replace(':country', Country.UK),
        },
        {
          text: 'Today handled',
          url: routes.BURSAR.BURSAR_HANDLED,
        },
        {
          text: 'Unhandled',
          url: routes.BURSAR.BURSAR_UNHANDLED,
        },
      ],
    });
  }
  sidebar.push({
    text: 'Logout',
    exact: true,
    url: routes.LOGOUT,
  });

  return sidebar;
};
