import { Language } from '../../classes/Language';
import { User } from './User';
import { UsersActionType } from './UsersAction';

export default class UsersReducer {
  private static userInit: User = {
    id: '',
    name: '',
    username: '',
    phone: '',
    preferLanguage: Language.FINNISH,
    buyerCommission: 0,
    team: '',
    preferCountry: '',
    admin: false,
    isColdCaller: false,
    isActive: true,
    buyer: false,
    buyerWebOrder: false,
    packager: false,
    receiver: false,
    valuation: false,
    teller: false,
    bursar: false,
    customer: false,
    payer: false,
    ccManager: false,
    teamLeader: false,
    messageCenter: false,
    officeAdmin: false,
  };

  private static initState = {
    data: UsersReducer.userInit,
    isLoading: false,
  };

  public static reducer(state = UsersReducer.initState, action: any): typeof state {
    switch (action.type) {
      case UsersActionType.SET_USER:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
