import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle, DetailField } from 'releox-react';
import { fixFloat } from '../../../config';
import TellersSelector from '../../../store/teller/TellersSelector';

export default () => {
  const order = useSelector(TellersSelector.selectOrder);

  const { materialWeight = 0 } = order;
  const { goldWeight = 0 } = order;
  const { silverWeight = 0 } = order;
  const { customGoldWeight = 0 } = order;
  const { otherWeight = 0 } = order;

  const noValueWeight = fixFloat(
    materialWeight - goldWeight - silverWeight - otherWeight - customGoldWeight
  );

  return (
    <Card>
      <CardTitle>Valuation weights</CardTitle>
      <table>
        <tbody>
          <DetailField label="Package weight">{order.packageWeight}</DetailField>
          <DetailField label="Material weight">{order.materialWeight}</DetailField>
          <DetailField label="Gold weight">{order.goldWeight}</DetailField>
          <DetailField label="Silver weight">{order.silverWeight}</DetailField>
          <DetailField label="Custom gold weight">{order.customGoldWeight}</DetailField>
          <DetailField label="Other weight">{order.otherWeight}</DetailField>
          <DetailField label="No value weight">{noValueWeight}</DetailField>
        </tbody>
      </table>
    </Card>
  );
};
