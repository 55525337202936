import { push } from 'react-router-redux';
import { actionCreator } from 'redux-rest-helper-for-loopback';
import routes from '../../configs/routes';
import RednotAction from '../rednot/RednotAction';
import MaterialsEffect from './MaterialsEffect';

export default class MaterialsAction {
  static ENTRY = 'MATERIAL';

  static actionCreator = actionCreator(
    MaterialsAction.ENTRY,
    MaterialsEffect.restRequests,
    push,
    RednotAction.onError
  );

  static create = MaterialsAction.actionCreator.getCreateAction(routes.ADMIN_MATERIAL.INDEX);

  static update = MaterialsAction.actionCreator.getUpdateAction(routes.ADMIN_MATERIAL.INDEX);

  static fetch = MaterialsAction.actionCreator.getFindByIdAction();

  static delete = MaterialsAction.actionCreator.getDeleteAction(routes.ADMIN_MATERIAL.INDEX);

  static list = MaterialsAction.actionCreator.getFindAndCountAction();

  static clean = MaterialsAction.actionCreator.getCleanAction();
}
