export default class TeamsSelector {
  public static selectMember(state: ReduxState) {
    return state.teamEntry.model.data;
  }

  public static selectMembers(state: ReduxState) {
    return state.teamEntry.list.data;
  }

  public static selectMemberIsLoading(state: ReduxState) {
    return state.teamEntry.model.isLoading;
  }

  public static selectMembersIsLoading(state: ReduxState) {
    return state.teamEntry.list.isFetchLoading;
  }

  public static selectMembersCount(state: ReduxState) {
    return state.teamEntry.list.count;
  }
}
