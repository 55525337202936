export default class CallTransfersSelector {
  public static selectCallTransferField(state: ReduxState) {
    return state.callTransfer.callTransferField;
  }

  public static selectType(state: ReduxState) {
    return state.callTransfer.type;
  }

  public static selectSearchUserId(state: ReduxState) {
    return state.callTransfer.searchUserId;
  }
}
