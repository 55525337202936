import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../configs/routes';
import packageStatus from '../package-status';
import ClientsSelector from '../store/client/ClientsSelector';

export default () => {
  const client = useSelector(ClientsSelector.selectClient);
  return (
    <ul>
      {client.orders?.map((order) => (
        <li>
          <Link to={routes.CUSTOMER_SERVICE.ORDER_SHOW.replace(':id', order.id)}>
            #{order.orderNumber} (Status: {packageStatus(order)})
          </Link>
        </li>
      ))}
    </ul>
  );
};
