import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import CCManagerCallListSearchForm, {
  CCManagerCallListSearchBy,
} from '../../forms/ccmanager-call-list-search/CCManagerCallListSearchForm';
import CCManagerCallListSearchInitialValues, {
  CCCManagerCallListSearch,
} from '../../forms/ccmanager-call-list-search/CCManagerCallListSearchInitialValues';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

export default () => {
  const [where, setWhere] = useState<any>({});

  const onSubmit = useCallback(
    (body: CCCManagerCallListSearch) => {
      let callerQuery;
      const baseQuery: any = {
        ...OrderQuery.closeFilter,
        isReceived: false,
      };

      const dateQuery = [
        { [body.by]: { gt: body.startDate } },
        { [body.by]: { lt: body.endDate } },
      ];

      const userlessQuery = { and: [...dateQuery, baseQuery] };

      if (body.team) {
        baseQuery.team = body.team;
      }

      if (body.by === CCManagerCallListSearchBy.AFTER_CALL) {
        // TODO: Change this to false after 15.4.2020
        baseQuery.afterCallDone = { neq: true };
      }

      if (body.by === CCManagerCallListSearchBy.WELCOME_CALL) {
        // TODO: Change this to false after 15.4.2020
        baseQuery.welcomeCallDone = { neq: true };
      }

      if (body.callerId) {
        callerQuery = {
          or: [
            { and: [...dateQuery, baseQuery, { callerId: body.callerId }] },
            { and: [...dateQuery, baseQuery, { assignedCallerId: body.callerId }] },
            { and: [...dateQuery, baseQuery, { originalCallerId: body.callerId }] },
          ],
        };
      }

      setWhere(callerQuery || userlessQuery);
    },
    [setWhere]
  );

  useEffect(() => {
    onSubmit(CCManagerCallListSearchInitialValues);
  }, [onSubmit]);

  if (!Object.keys(where).length) return <p>Load</p>;

  return (
    <>
      <Card>
        <Formik initialValues={CCManagerCallListSearchInitialValues} onSubmit={onSubmit}>
          <Form>
            <CCManagerCallListSearchForm />
            <Button id="search" type="submit">
              Search
            </Button>
          </Form>
        </Formik>
      </Card>
      <GenericIndexScene
        key={JSON.stringify(where)}
        tNamespace="CCManagerCallList"
        loadingSelector={OrdersSelector.selectIsListLoading}
        dataSelector={OrdersSelector.selectOrders}
        dataSizeSelector={OrdersSelector.selectOrdersCount}
        onChangeLoopback={OrdersAction.list}
        columns={[
          OrderColumn.orderNumber,
          OrderColumn.callerName,
          OrderColumn.isTransferred,
          OrderColumn.welcomeCall,
          OrderColumn.dueDate,
        ]}
        defaultSorted={{ dataField: 'welcomeCall', order: 'ASC' }}
        query={{ where, include: OrderQuery.include }}
      />
    </>
  );
};
