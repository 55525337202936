import * as Sentry from '@sentry/browser';
import { FieldProps } from 'formik';
import { fixFloat } from '../../config';
import routes from '../../configs/routes';
import { ValuationFormBody } from '../../forms/valuation/ValuateInitialValues';
import ValuateValidationSchema, {
  ReValuationRequestSchema,
} from '../../forms/valuation/ValuateValidationSchema';
import isProduction from '../../utils/is-production';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import { Order, Product } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import RednotAction from '../rednot/RednotAction';
import { ValuationRequest } from './Valuation';

export enum ValuatesActionTypes {
  RESET = '@VALUATE:RESET',
  NEXT_STEP = '@VALUATE:NEXT_STEP',
  CALCULATE = '@VALUATE:CALCULATE',
  SAVE_FORM = '@VALUATE:SAVE_FORM',
  SET_DEFAULTS = '@VALUATE:SET_DEFAULTS',
}

export enum ValuateScenes {
  PREVIEW = 'PREVIEW',
  VALUATE = 'VALUATE',
  MESSAGES = 'MESSAGES',
}

type ResetReturn = Action<ValuatesActionTypes.RESET>;
type NextStepReturn = ActionWPayload<ValuatesActionTypes.NEXT_STEP, { nextStep: ValuateScenes }>;
type SaveFormReturn = ActionWPayload<ValuatesActionTypes.SAVE_FORM, { form: ValuationFormBody }>;
type CalculateReturn = ActionWPayload<
  ValuatesActionTypes.CALCULATE,
  {
    goldWeight: number;
    silverWeight: number;
    price: number;
    customGoldWeight: number;
    otherWeight: number;
  }
>;
type SetDefaultsReturn = ActionWPayload<
  ValuatesActionTypes.SET_DEFAULTS,
  {
    valuationImage?: string;
    tellerMessageFromValuation?: string;
    goldWeight?: number;
    silverWeight?: number;
    packageWeight?: number;
    materialWeight?: number;
    products: Product[];
  }
>;

export type ValuatesActionObject =
  | ResetReturn
  | NextStepReturn
  | CalculateReturn
  | SaveFormReturn
  | SetDefaultsReturn;

export default class ValuatesAction {
  public static saveForm(form: ValuationFormBody): ValuatesActionObject {
    return {
      type: ValuatesActionTypes.SAVE_FORM,
      payload: { form },
    };
  }

  public static reset(): ValuatesActionObject {
    return {
      type: ValuatesActionTypes.RESET,
    };
  }

  public static nextStep(nextStep: ValuateScenes): ValuatesActionObject {
    return {
      type: ValuatesActionTypes.NEXT_STEP,
      payload: { nextStep },
    };
  }

  public static onSubmit(body: ValuationFormBody) {
    return (dispatch: ReduxDispatch) => {
      dispatch(this.saveForm(body));
      dispatch(this.nextStep(ValuateScenes.PREVIEW));
    };
  }

  public static setDefaults(body: Order): ValuatesActionObject {
    return {
      type: ValuatesActionTypes.SET_DEFAULTS,
      payload: {
        valuationImage: body.valuationImage,
        tellerMessageFromValuation: body.tellerMessageFromValuation,
        goldWeight: body.goldWeight,
        silverWeight: body.silverWeight,
        materialWeight: body.materialWeight,
        packageWeight: body.packageWeight,
        products: body.products,
      },
    };
  }

  public static calculate(
    fieldProps: FieldProps<ValuationFormBody, ValuationFormBody>
  ): ValuatesActionObject {
    const { values } = fieldProps.form;
    let goldWeight = 0;
    let silverWeight = 0;
    let customGoldWeight = 0;
    let otherWeight = 0;
    let price = 0;
    values.products.forEach((p) => {
      const localWeight = p.weight as any;
      const localPrice = p.price as any;
      if (!Number.isNaN(parseFloat(localWeight))) {
        if (p.category === 'gold') goldWeight += parseFloat(localWeight);
        if (p.category === 'silver') silverWeight += parseFloat(localWeight);
        if (p.density === 'customGold') customGoldWeight += parseFloat(localWeight);
        if (p.density === 'other') otherWeight += parseFloat(localWeight);
      }
      if (!Number.isNaN(parseFloat(localPrice))) {
        price += parseFloat(localPrice);
      }
    });
    return {
      type: ValuatesActionTypes.CALCULATE,
      payload: {
        silverWeight: fixFloat(silverWeight),
        goldWeight: fixFloat(goldWeight),
        otherWeight: fixFloat(otherWeight),
        customGoldWeight: fixFloat(customGoldWeight),
        price: fixFloat(price),
      },
    };
  }

  public static saveOrder() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const { valuate, orderEntry, user } = getState();
      const order = orderEntry.model.data;
      let validationSchema = ValuateValidationSchema;
      let body: ValuationRequest;

      const numericValues = {
        silverWeight: valuate.silverWeight,
        goldWeight: valuate.goldWeight,
        customGoldWeight: valuate.customGoldWeight,
        otherWeight: valuate.otherWeight,
        price: valuate.price,
      };

      if (order.returnToValuation) {
        validationSchema = ReValuationRequestSchema;
        body = {
          ...valuate.form,
          ...numericValues,
          returnToValuation: false,
          reValuationDate: new Date().toString(),
          reValuatorId: user.data.id,
        };
      } else {
        body = {
          ...valuate.form,
          ...numericValues,
          valuatorId: user.data.id,
          valuationDate: new Date().toString(),
          isValued: true,
        };
      }

      validationSchema
        .validate(body)
        .then((validatedBody) => {
          dispatch(
            OrdersAction.update(order.id, validatedBody, {
              redirect: routes.VALUATION.BAR_CODE_PROMPT,
            })
          );
        })
        .catch((e) => {
          if (isProduction()) {
            Sentry.withScope((scope) => {
              scope.setExtra('error-object', e);
              scope.setLevel(Sentry.Severity.Error);
              Sentry.captureException(new Error(e.errors[0]));
            });
          }
          dispatch(RednotAction.error(e.errors[0]));
        });
    };
  }
}
