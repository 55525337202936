import { MaterialObject } from './Payer';
import PayersActionType, { PayersActionObject } from './PayerActionType';

export default class PayersReducer {
  public static initialState = {
    materialsObject: {} as MaterialObject,
    team: '',
  };

  public static reducer(
    state = PayersReducer.initialState,
    action: PayersActionObject
  ): typeof state {
    switch (action.type) {
      case PayersActionType.SET_TEAM:
        return { ...state, team: action.payload.team };

      case PayersActionType.SET_MATERIAL:
        return { ...state, materialsObject: action.payload.materialsObject };

      default:
        return state;
    }
  }
}
