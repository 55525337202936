import { ActionWPayload } from '../Action';
import { MaterialObject } from './Payer';

interface SetMaterialPayload {
  materialsObject: MaterialObject;
}

enum PayersActionType {
  SET_MATERIAL = '@PAYER:SET_MATERIAL',
  SET_TEAM = '@PAYER:SET_TEAM',
}

type PayerSetMaterialAction = ActionWPayload<PayersActionType.SET_MATERIAL, SetMaterialPayload>;
type PayerSetTeamAction = ActionWPayload<PayersActionType.SET_TEAM, { team: string }>;

export type PayersActionObject = PayerSetMaterialAction | PayerSetTeamAction;

export default PayersActionType;
