import React, { useCallback, useMemo } from 'react';
import { GenericIndexScene } from 'releox-react';
import { PrintURL } from '../../classes/PrintURL';
import OrderColumn from '../../columns/OrderColumn';
import { Order } from '../../store/order/Order';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

const sort = {
  dataField: 'packagedDate',
  order: 'DESC',
};

export default () => {
  const query = OrderQuery.getPackagerHistoryQuery();

  const onClickBarCode = useCallback((order: Order) => {
    PrintURL.getCarrierLabelUrl(order).then((url) => {
      // Open url in new tab
      window.open(url, '_blank');
    });
  }, []);

  const cols = useMemo(() => {
    return [
      OrderColumn.orderNumber,
      OrderColumn.clientName,
      OrderColumn.packagedDate,
      OrderColumn.invoice,
      OrderColumn.letter,
      OrderColumn.barCodePrintForTable(onClickBarCode),
    ];
  }, [onClickBarCode]);

  return (
    <GenericIndexScene
      tNamespace="PackagerHistory"
      loadingSelector={OrdersSelector.selectIsListLoading}
      dataSelector={OrdersSelector.selectOrders}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={cols}
      defaultSorted={sort}
      query={query}
    />
  );
};
