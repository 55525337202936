import { FieldProps, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormikEffect from '../../../components/FormikEffect';
import { ClientValidationSchema } from '../../../forms/ClientForm';
import NewOrderForm from '../../../forms/NewOrderForm';
import { orderFormInitValue, OrderValidationSchema } from '../../../forms/OrderForm';
import NewOrdersAction, { BuyerOrderBody } from '../../../store/new-order/NewOrdersActions';
import NewOrdersSelector from '../../../store/new-order/NewOrdersSelector';
import TeamsAction from '../../../store/team/TeamsAction';
import UsersSelector from '../../../store/user/UsersSelector';
import NewOrderPhonePrompt from './NewOrderPhonePrompt';

const ValidationSchema = Yup.object().shape({
  client: ClientValidationSchema(),
  order: OrderValidationSchema(['language', 'country']),
});

const WebValidationSchema = Yup.object().shape({
  client: ClientValidationSchema(['birthYear']),
  order: OrderValidationSchema(['language', 'country']),
});

export default () => {
  const dispatch = useDispatch();
  const client = useSelector(NewOrdersSelector.selectClient);
  const user = useSelector(UsersSelector.selectUser);
  const isPhoneSearched = useSelector(NewOrdersSelector.selectIsPhoneSearched);
  const isWebOrder = useSelector(NewOrdersSelector.selectIsWebOrder);

  useEffect(() => {
    dispatch(TeamsAction.list());
    return () => {
      dispatch(NewOrdersAction.reset());
    };
  }, [dispatch]);

  const onFormikChange = useCallback(
    (values: FieldProps) => {
      if (isWebOrder !== values.form.values.order.isWebOrder) {
        dispatch(NewOrdersAction.setWebOrder(values.form.values.order.isWebOrder));
      }
    },
    [dispatch, isWebOrder]
  );

  const getInitValues = useCallback(
    (): BuyerOrderBody => ({
      client: {
        ...client,
        language: user.preferLanguage,
        country: user.preferCountry,
      },
      googleAddress: '',
      order: {
        ...orderFormInitValue,
        team: user.team,
        callerId: user.id,
        welcomeCall: moment()
          .add(4, 'day')
          .toDate(),
        dueDate: moment()
          .add(16, 'day')
          .toDate(),
      },
    }),
    [client, user]
  );

  const onSubmit = useCallback(
    (body: BuyerOrderBody) => {
      dispatch(NewOrdersAction.createOrder(body));
    },
    [dispatch]
  );

  if (!isPhoneSearched) return <NewOrderPhonePrompt />;
  return (
    <Formik
      initialValues={getInitValues()}
      onSubmit={onSubmit}
      validationSchema={isWebOrder ? WebValidationSchema : ValidationSchema}
    >
      <Form>
        <FormikEffect onChange={onFormikChange} />
        <NewOrderForm />
      </Form>
    </Formik>
  );
};
