import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { CoreuiLayout, Loading, authMiddleware } from 'releox-react';
import routes from './configs/routes';
import HomeScene from './scenes/HomeScene';
import Receiver from './scenes/Receiver';
import AdminClientIndex from './scenes/admin/AdminClientIndex';
import AdminGreetingsEdit from './scenes/admin/AdminGreetingsEdit';
import AdminOrderEdit from './scenes/admin/AdminOrderEdit';
import AdminOrderIndex from './scenes/admin/AdminOrderIndex';
import ClientEditScene from './scenes/admin/client/ClientEditScene';
import MaterialCreateScene from './scenes/admin/material/MaterialCreateScene';
import MaterialEditScene from './scenes/admin/material/MaterialEditScene';
import MaterialIndexScene from './scenes/admin/material/MaterialIndexScene';
import TeamCreateScene from './scenes/admin/team/TeamCreateScene';
import TeamEditScene from './scenes/admin/team/TeamEditScene';
import TeamIndexScene from './scenes/admin/team/TeamIndexScene';
import TeamPasswordScene from './scenes/admin/team/TeamPasswordScene';
import BursarBarCodeScene from './scenes/bursar/BursarBarCodeScene';
import BursarPage from './scenes/bursar/BursarPage';
import { BursarHandledScene, BursarUnhandledScene } from './scenes/bursar/bursar.indexes.scenes';
import BuyerModifyEditScene from './scenes/buyer/BuyerModifyEditScene';
import BuyerStatsScene from './scenes/buyer/BuyerStatsScene';
import ModifyIndexScene from './scenes/buyer/ModifyIndexScene';
import AfterCallIndexScene from './scenes/buyer/after-call/AfterCallIndexScene';
import AfterCallShowScene from './scenes/buyer/after-call/AfterCallShowScene';
import ColdCasesIndexScene from './scenes/buyer/cold-cases/ColdCasesIndexScene';
import ColdCasesShowScene from './scenes/buyer/cold-cases/ColdCasesShowScene';
import AddOrder from './scenes/buyer/new-order/NewOrderScene';
import WelcomeCallIncexScene from './scenes/buyer/welcome-call/WelcomeCallIndexScene';
import WelcomeCallShowScene from './scenes/buyer/welcome-call/WelcomeCallShowScene';
import CCManagerCallList from './scenes/cc-manager/CCManagerCallList';
import CCCallTransferScene from './scenes/cc-manager/CCManagerCallTransferScene';
import CCManagerCancelTransferScene from './scenes/cc-manager/CCManagerCancelTransferScene';
import CCManagerReport from './scenes/cc-manager/CCManagerReport';
import CustomerClientIndex from './scenes/customer-service/CustomerClientIndex';
import CustomerClientShow from './scenes/customer-service/CustomerClientShow';
import CustomerClose from './scenes/customer-service/CustomerClose';
import CustomerClosed from './scenes/customer-service/CustomerClosed';
import CustomerMassBoomerang from './scenes/customer-service/CustomerMassBoomerang';
import CustomerSearch from './scenes/customer-service/CustomerOrderIndex';
import CustomerOrder from './scenes/customer-service/CustomerOrderShow';
import PackageCodeRegistryIndexScene from './scenes/package-code-registry/PackageCodeRegistryIndexScene';
import PackagerHistory from './scenes/packager/PackagerHistory';
import PackagerLanguageSelect from './scenes/packager/PackagerLanguageSelect';
import PackagerSystem from './scenes/packager/PackagerSystem';
import PackagerSystemUK from './scenes/packager/PackagerSystemUK';
import PackagerWaitingScene from './scenes/packager/PackagerWaitingScene';
import Payer from './scenes/payer/Payer';
import PayerDonations from './scenes/payer/PayerDonations';
import PoliceReportShowScene from './scenes/police-report/PoliceReportShowScene';
import AcceptedOffersReportScene from './scenes/report/AcceptedOffersReportScene';
import ReportIndexScene from './scenes/report/ReportIndexScene';
import ValuatedReportScene from './scenes/report/ValuatedReportScene';
import TellerSMS from './scenes/teller/TellerSMS';
import TellerSMSLog from './scenes/teller/TellerSMSLog';
import TellerShow from './scenes/teller/TellerShow';
import {
  TellerFi,
  TellerFiOther,
  TellerFiSilver,
  TellerFiSwe,
  TellerFiSweOther,
  TellerFiSweSilver,
  TellerFiSweZero,
  TellerFiZero,
  TellerSwe,
  TellerSweOther,
  TellerSweSilver,
  TellerSweZero,
  TellerTodayHandled,
  TellerUK,
  TellerUKOther,
  TellerUKSilver,
  TellerUKZero,
} from './scenes/teller/teller-scenes';
import TimeRecordCreateScene from './scenes/time-recording/TimeRecordCreateScene';
import {
  ReValuationFinnish,
  ReValuationSwedish,
  ReValuationUK,
} from './scenes/valuation/ReValuation';
import ValuationBarCodePrompt from './scenes/valuation/ValuationBarCodePrompt';
import { ValuationStatsScene } from './scenes/valuation/ValuationStatsScene';
import ValuationValuate from './scenes/valuation/ValuationValuate';
import ValuationWaitingIndexScene from './scenes/valuation/ValuationWaitingIndexScene';
import sidebarMenu from './sidebar.menu';
import CallCountsAction from './store/call-count/CallCountsAction';
import CallCountsSelector from './store/call-count/CallCountsSelector';
import { User } from './store/user/User';
import UsersAction from './store/user/UsersAction';
import UsersSelector from './store/user/UsersSelector';
import isProduction from './utils/is-production';

interface Props {
  authenticatedUser: User;
}

const DashboardRouter = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(UsersSelector.selectUser);
  const { authenticatedUser } = props;
  const afterCallCount = useSelector(CallCountsSelector.selectAfterCallCount);
  const welcomeCallCount = useSelector(CallCountsSelector.selectWelcomeCallCount);

  useEffect(() => {
    dispatch(UsersAction.setUserAction(authenticatedUser));
    if (isProduction()) localStorage.clear();
  }, [authenticatedUser, dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user.id && user.buyer) {
      dispatch(CallCountsAction.fetchCounts());

      const interval = setInterval(() => {
        dispatch(CallCountsAction.fetchCounts());
      }, 120000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, user]);

  if (!user.name) return <Loading centeredVertical />;
  return (
    <CoreuiLayout
      brand={<img src="/sjmk.png" alt="logo" />}
      headerRightText={user.name}
      sidebarMenu={sidebarMenu(user, { afterCallCount, welcomeCallCount })}
    >
      <Switch>
        <Route exact path={routes.HOME} component={HomeScene} />

        <Route exact path={routes.ADMIN_USERS.INDEX} component={TeamIndexScene} />
        <Route exact path={routes.ADMIN_USERS.CREATE} component={TeamCreateScene} />
        <Route exact path={routes.ADMIN_USERS.EDIT} component={TeamEditScene} />
        <Route exact path={routes.ADMIN_USERS.PASSWORD} component={TeamPasswordScene} />
        <Route exact path={routes.ADMIN_MATERIAL.INDEX} component={MaterialIndexScene} />
        <Route exact path={routes.ADMIN_MATERIAL.CREATE} component={MaterialCreateScene} />
        <Route exact path={routes.ADMIN_MATERIAL.EDIT} component={MaterialEditScene} />
        <Route exact path={routes.ADMIN_CLIENT.INDEX} component={AdminClientIndex} />
        <Route exact path={routes.ADMIN_CLIENT.EDIT} component={ClientEditScene} />
        <Route exact path={routes.ADMIN_ORDER.INDEX} component={AdminOrderIndex} />
        <Route exact path={routes.ADMIN_ORDER.EDIT} component={AdminOrderEdit} />
        <Route exact path={routes.ADMIN_POLICE_REPORTS} component={PoliceReportShowScene} />
        <Route exact path={routes.ADMIN_GREETINGS} component={AdminGreetingsEdit} />

        <Route
          exact
          path={routes.ADMIN_PACKAGE_CODE_REGISTRY}
          component={PackageCodeRegistryIndexScene}
        />

        <Route exact path={routes.REPORTS.INDEX} component={ReportIndexScene} />
        <Route exact path={routes.REPORTS.VALUATED_ORDERS} component={ValuatedReportScene} />
        <Route exact path={routes.REPORTS.ACCEPTED_OFFERS} component={AcceptedOffersReportScene} />

        <Route exact path={routes.CUSTOMER_SERVICE.ORDER_INDEX} component={CustomerSearch} />
        <Route exact path={routes.CUSTOMER_SERVICE.ORDER_SHOW} component={CustomerOrder} />
        <Route exact path={routes.CUSTOMER_SERVICE.CLOSED_INDEX} component={CustomerClosed} />
        <Route exact path={routes.CUSTOMER_SERVICE.CLIENT_INDEX} component={CustomerClientIndex} />
        <Route exact path={routes.CUSTOMER_SERVICE.CLIENT_SHOW} component={CustomerClientShow} />
        <Route exact path={routes.CUSTOMER_SERVICE.CLOSE} component={CustomerClose} />
        <Route
          exact
          path={routes.CUSTOMER_SERVICE.MASS_BOOMERANG}
          component={CustomerMassBoomerang}
        />

        <Route exact path={routes.CC_MANAGER.TRANSFER} component={CCCallTransferScene} />
        <Route exact path={routes.CC_MANAGER.REPORT} component={CCManagerReport} />
        <Route exact path={routes.CC_MANAGER.LISTS} component={CCManagerCallList} />
        <Route exact path={routes.CC_MANAGER.CANCEL} component={CCManagerCancelTransferScene} />

        <Route exact path={routes.BUYER.ADD_ORDER} component={AddOrder} />
        <Route exact path={routes.BUYER.WELCOME_CALLS} component={WelcomeCallIncexScene} />
        <Route exact path={routes.BUYER.WELCOME_CALL} component={WelcomeCallShowScene} />
        <Route exact path={routes.BUYER.AFTER_CALLS} component={AfterCallIndexScene} />
        <Route exact path={routes.BUYER.AFTER_CALL} component={AfterCallShowScene} />
        <Route exact path={routes.BUYER.MODIFY_ORDERS_INDEX} component={ModifyIndexScene} />
        <Route exact path={routes.BUYER.MODIFY_ORDERS_EDIT} component={BuyerModifyEditScene} />
        <Route exact path={routes.BUYER.STATS} component={BuyerStatsScene} />
        <Route exact path={routes.BUYER.COLD_CASES_INDEX_ROOT} component={ColdCasesIndexScene} />
        <Route exact path={routes.BUYER.COLD_CASES_SHOW} component={ColdCasesShowScene} />

        <Route
          exact
          path={routes.PACKAGER.PACKAGER_LANGUAGE_SELECT}
          component={PackagerLanguageSelect}
        />
        <Route exact path={routes.PACKAGER.PACKAGER_LIST} component={PackagerWaitingScene} />
        <Route exact path={routes.PACKAGER.PACKAGER_SYSTEM_UK} component={PackagerSystemUK} />
        <Route exact path={routes.PACKAGER.PACKAGER_SYSTEM} component={PackagerSystem} />
        <Route exact path={routes.PACKAGER.PACKAGER_HISTORY} component={PackagerHistory} />

        <Route exact path={routes.RECEIVER} component={Receiver} />

        <Route exact path={routes.VALUATION.BAR_CODE_PROMPT} component={ValuationBarCodePrompt} />
        <Route exact path={routes.VALUATION.STATS} component={ValuationStatsScene} />
        <Route exact path={routes.VALUATION.VALUATE} component={ValuationValuate} />
        <Route exact path={routes.VALUATION.RE_VALUATION_FINNISH} component={ReValuationFinnish} />
        <Route exact path={routes.VALUATION.RE_VALUATION_SWEDISH} component={ReValuationSwedish} />
        <Route exact path={routes.VALUATION.RE_VALUATION_UK} component={ReValuationUK} />
        <Route exact path={routes.VALUATION.WAITING_LIST} component={ValuationWaitingIndexScene} />

        <Route exact path={routes.TELLER.TELLER_SMS_LOG} component={TellerSMSLog} />
        <Route exact path={routes.TELLER.TELLER_SMS} component={TellerSMS} />
        <Route exact path={routes.TELLER.TODAY_ORDERS} component={TellerTodayHandled} />
        <Route exact path={routes.TELLER.FI} component={TellerFi} />
        <Route exact path={routes.TELLER.UK} component={TellerUK} />
        <Route exact path={routes.TELLER.SWE} component={TellerSwe} />
        <Route exact path={routes.TELLER.FISWE} component={TellerFiSwe} />
        <Route exact path={routes.TELLER.FI_ZERO} component={TellerFiZero} />
        <Route exact path={routes.TELLER.UK_ZERO} component={TellerUKZero} />
        <Route exact path={routes.TELLER.SWE_ZERO} component={TellerSweZero} />
        <Route exact path={routes.TELLER.FISWE_ZERO} component={TellerFiSweZero} />
        <Route exact path={routes.TELLER.FI_SILVER} component={TellerFiSilver} />
        <Route exact path={routes.TELLER.UK_SILVER} component={TellerUKSilver} />
        <Route exact path={routes.TELLER.SWE_SILVER} component={TellerSweSilver} />
        <Route exact path={routes.TELLER.FISWE_SILVER} component={TellerFiSweSilver} />
        <Route exact path={routes.TELLER.FI_OTHER} component={TellerFiOther} />
        <Route exact path={routes.TELLER.UK_OTHER} component={TellerUKOther} />
        <Route exact path={routes.TELLER.SWE_OTHER} component={TellerSweOther} />
        <Route exact path={routes.TELLER.FISWE_OTHER} component={TellerFiSweOther} />
        <Route exact path={routes.TELLER.TELLER_SHOW} component={TellerShow} />

        <Route exact path={routes.PAYER.DONATION} component={PayerDonations} />
        <Route exact path={routes.PAYER.COUNTRY} component={Payer} />

        <Route exact path={routes.BURSAR.BAR_CODE_PROMPT} component={BursarBarCodeScene} />
        <Route exact path={routes.BURSAR.BURSAR_PAGE} component={BursarPage} />
        <Route exact path={routes.BURSAR.BURSAR_HANDLED} component={BursarHandledScene} />
        <Route exact path={routes.BURSAR.BURSAR_UNHANDLED} component={BursarUnhandledScene} />

        {/* <Route exact path={routes.TIME_RECORDING.INDEX} component={TimeRecordingIndexScene} /> */}
        <Route exact path={routes.TIME_RECORDING.INDEX} component={TimeRecordCreateScene} />
        {/* <Route exact path={routes.TIME_RECORDING.EDIT} component={TimeRecordingEditScene} /> */}
      </Switch>
    </CoreuiLayout>
  );
};

export default authMiddleware<User>(DashboardRouter);
