import enGB from 'date-fns/locale/en-GB';
import { Field, FieldProps } from 'formik';
import moment from 'moment';
import React from 'react';
import DateTime, { registerLocale } from 'react-datepicker';
import {
  AbstractBaseInputProps,
  BaseInput,
} from 'releox-react/lib/components/form/BaseInput/BaseInput';

registerLocale('en-gb', enGB);

interface DatePickProps extends AbstractBaseInputProps {
  minDate?: Date | null;
  maxDate?: Date | null;
}

export default (props: DatePickProps) => {
  const { name, maxDate, minDate } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseInput {...props}>
      {({ getInputClass, getErrorMessageField, getId }) => (
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            return (
              <>
                <Field
                  name={name}
                  className={getInputClass(fieldProps)}
                  component={({ field, form }: any) => {
                    let { value } = field;
                    if (typeof field.value === 'string') value = moment(field.value).toDate();
                    return (
                      <DateTime
                        id={getId()}
                        className="form-control"
                        showTimeSelect
                        locale="en-gb"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd.MM.yyyy H:mm"
                        timeCaption="time"
                        onChange={(date: Date) => form.setFieldValue(field.name, date)}
                        minDate={
                          minDate !== undefined
                            ? minDate
                            : moment()
                                .subtract(1, 'days')
                                .toDate()
                        }
                        maxDate={
                          maxDate !== undefined
                            ? maxDate
                            : moment()
                                .add(60, 'days')
                                .toDate()
                        }
                        withPortal
                        selected={value}
                      />
                    );
                  }}
                />
                {getErrorMessageField()}
              </>
            );
          }}
        </Field>
      )}
    </BaseInput>
  );
};
