import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'releox-react';
import { formatTimeAndFrom } from '../config';
import OrdersSelector from '../store/order/OrdersSelector';
import { User } from '../store/user/User';

const StepStamp = ({
  member,
  title,
  timestamp,
}: {
  member: User | undefined;
  title: string;
  timestamp: string | Date | undefined | null;
}) => (
  <div className="mb-3">
    <b>{title}: </b>
    <div>{member ? member.name : '-'} </div>
    <div>{timestamp ? formatTimeAndFrom(timestamp) : '-'}</div>
  </div>
);

export default () => {
  const order = useSelector(OrdersSelector.selectOrder);

  return (
    <Card>
      <StepStamp title="Caller" member={order.caller} timestamp={order.createdAt} />
      <StepStamp title="Packager" member={order.packager} timestamp={order.packagedDate} />
      <StepStamp title="Receiver" member={order.receiver} timestamp={order.receivedDate} />
      <StepStamp title="Valuator" member={order.valuator} timestamp={order.valuationDate} />
      <StepStamp title="Teller" member={order.teller} timestamp={order.tellerDate} />
      <StepStamp title="Payer" member={order.payer} timestamp={order.payed} />
      <StepStamp title="Bursar" member={order.bursar} timestamp={order.bursarHandleDate} />
      <StepStamp title="Closer" member={order.closer} timestamp={order.closeDate} />
      <StepStamp
        title="ColdCaller"
        member={order.coldCaseCaller}
        timestamp={order.latestColdCall}
      />
      <StepStamp
        title="Original caller"
        member={order.originalCaller}
        timestamp={order.createdAt}
      />
    </Card>
  );
};
