import { Field, FieldProps } from 'formik';
import getValue from 'get-value';
import React, { useCallback } from 'react';
import {
  AbstractBaseInputProps,
  BaseInput,
} from 'releox-react/lib/components/form/BaseInput/BaseInput';
import { Material } from '../../store/material/Material';
import calculateProductPrice from '../../utils/calculate-product-price';
import { getFieldPrefix } from './ProductDensitySelect';

interface Props extends AbstractBaseInputProps {
  materials: Material[];
  calculatePrice?: boolean;
}

export default (props: Props) => {
  const { materials, name, calculatePrice = true } = props;

  const onChange = useCallback(
    (fieldProps: FieldProps) => (e: React.FormEvent<HTMLInputElement>) => {
      const fieldNamePrefix = getFieldPrefix(fieldProps);
      const fieldName = fieldProps.field.name;
      const targetValue = e.currentTarget.value;

      const weightKey = `${fieldNamePrefix}.weight`;
      const factorKey = `${fieldNamePrefix}.factor`;
      const densityKey = `${fieldNamePrefix}.weight`;

      let weight = getValue(fieldProps.form.values, weightKey);
      let factor = getValue(fieldProps.form.values, factorKey);
      const density = getValue(fieldProps.form.values, densityKey);

      if (fieldName === weightKey) {
        weight = targetValue;
        fieldProps.form.setFieldValue(fieldName, targetValue);
      }

      if (fieldName === factorKey) {
        factor = targetValue;
        fieldProps.form.setFieldValue(fieldName, targetValue);
      }

      if (calculatePrice) {
        fieldProps.form.setFieldValue(
          `${fieldNamePrefix}.price`,
          calculateProductPrice(getValue({ density, factor, weight }), materials)
        );
      }
    },
    [materials, calculatePrice]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseInput {...props}>
      {({ getInputClass, getErrorMessageField, getId, getPlaceholder }) => (
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            return (
              <>
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...fieldProps.field}
                  onChange={onChange(fieldProps)}
                  type="text"
                  id={getId()}
                  placeholder={getPlaceholder()}
                  className={getInputClass(fieldProps)}
                />
                {getErrorMessageField()}
              </>
            );
          }}
        </Field>
      )}
    </BaseInput>
  );
};
