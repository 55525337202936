import React from 'react';
import { useSelector } from 'react-redux';
import QzsSelector from '../store/qz/QzsSelector';

export default () => {
  const connectionMessages = useSelector(QzsSelector.selectConnectionMessages);
  return (
    <>
      {connectionMessages.map((message) => (
        <p key={message}>{message}</p>
      ))}
    </>
  );
};
