import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OrdersSelector from '../store/order/OrdersSelector';

interface Props {
  url: string;
}

export default ({ url }: Props) => {
  const order = useSelector(OrdersSelector.selectOrder);
  if (!order.client) return <p>Missing: client relation</p>;
  return (
    <p>
      Client:{' '}
      <Link
        to={url.replace(':id', order.client.id)}
      >{`${order.client?.firstName} ${order.client?.lastName} (#${order.client?.clientNumber})`}</Link>
    </p>
  );
};
