import React from 'react';
import { CheckBox } from 'releox-react';
import { formatTimeAndFrom } from '../config';
import { CallTransferFormValues } from '../store/call-transfer/CallTransferModel';
import { CancelTransferFormValues } from '../store/cancel-call-transfer/CancelCallTransfer';

interface Props {
  fields: CancelTransferFormValues[] | CallTransferFormValues[];
}

export default ({ fields }: Props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Select</th>
          <th>#</th>
          <th>Client</th>
          <th>Caller</th>
          <th>Original caller</th>
          <th>Due date</th>
          <th>Welcome call</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((ctf, i) => (
          <tr key={ctf.id}>
            <td>
              <CheckBox name={`callTransferField.${i}.form.isSelected`} label="Transfer" />
            </td>
            <td>{ctf.orderNumber}</td>
            <td>{`${ctf.client?.firstName} ${ctf.client?.lastName}`}</td>
            <td>{ctf.assignedCaller?.name || ctf.caller?.name}</td>
            <td>{ctf.originalCaller?.name || ctf.caller?.name}</td>
            <td>{formatTimeAndFrom(ctf.dueDate)}</td>
            <td>{formatTimeAndFrom(ctf.welcomeCall)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
