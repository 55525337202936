import React from 'react';
import { GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

export default () => {
  return (
    <GenericIndexScene
      title="Waiting list"
      dataSelector={OrdersSelector.selectOrders}
      loadingSelector={OrdersSelector.selectIsModelLoading}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={[
        OrderColumn.orderNumber,
        OrderColumn.clientName,
        OrderColumn.address,
        OrderColumn.barCode,
        OrderColumn.packagedName,
        OrderColumn.createdAt,
        OrderColumn.isShipmentRegistered,
        OrderColumn.hasShipmentError,
        OrderColumn.shipmentRegisterError,
      ]}
      query={OrderQuery.getPackagerWaitingList()}
    />
  );
};
