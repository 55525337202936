export default class BuyerStatsSelector {
  public static selectOutCount(state: ReduxState) {
    return {
      yourMonth: state.buyerStats.yourMonthOutCount,
      yourToday: state.buyerStats.yourTodayOutCount,
      teamMonth: state.buyerStats.teamMonthOutCount,
      teamToday: state.buyerStats.teamTodayOutCount,
    };
  }

  public static selectInCount(state: ReduxState) {
    return {
      yourMonth: state.buyerStats.yourMonthAuInCount,
      yourToday: state.buyerStats.yourTodayAuInCount,
      teamMonth: state.buyerStats.teamMonthAuInCount,
      teamToday: state.buyerStats.teamTodayAuInCount,
    };
  }

  public static selectCancelCount(state: ReduxState) {
    return {
      yourMonth: state.buyerStats.yourMonthCancelCount,
      yourToday: state.buyerStats.yourTodayCancelCount,
      teamMonth: state.buyerStats.teamMonthCancelCount,
      teamToday: state.buyerStats.teamTodayCancelCount,
    };
  }
}
