import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'releox-react';
import BarCodePrompt, { BarCodePromptBody } from '../../components/BarCodePrompt';
import OrdersEffect, { ValuatedPerDay } from '../../store/order/OrdersEffect';
import UsersSelector from '../../store/user/UsersSelector';
import ValuationBarCodePromptAction from '../../store/valuation-bar-code-prompt/ValuationBarCodePromptAction';
import ValuationBarCodePromptSelector from '../../store/valuation-bar-code-prompt/ValuationBarCodePromptSelector';

export default () => {
  const user = useSelector(UsersSelector.selectUser);
  const [valuatedToday, setValuatedToday] = useState<ValuatedPerDay | undefined>();

  const valuatedCount = useSelector(ValuationBarCodePromptSelector.selectValuatedCount);

  const waitingValuationCount = useSelector(
    ValuationBarCodePromptSelector.selectWaitingValuationCount
  );

  const waitingRevaluationCount = useSelector(
    ValuationBarCodePromptSelector.selectWaitingRevaluationCount
  );

  const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timeout | undefined>();
  const dispatch = useDispatch();

  const updateNumbers = useCallback(() => {
    dispatch(ValuationBarCodePromptAction.updateNumbers());
  }, [dispatch]);

  useEffect(() => {
    OrdersEffect.valuatedTodayReport(user.id).then((report) => {
      setValuatedToday(report);
    });
  }, [user.id]);

  const onEventSubmit = useCallback(
    (body: BarCodePromptBody) => {
      dispatch(ValuationBarCodePromptAction.onSubmit(body));
    },
    [dispatch]
  );

  useEffect(() => {
    updateNumbers();
    setIntervalInstance(
      setInterval(() => {
        updateNumbers();
      }, 20000)
    );
  }, [updateNumbers]);

  useEffect(() => {
    return () => {
      if (intervalInstance) clearInterval(intervalInstance);
    };
  }, [intervalInstance]);

  return (
    <div className="row">
      <div className="col-md-6">
        <BarCodePrompt title="Valuation" onSubmit={onEventSubmit} />
      </div>
      <div className="col-md-6">
        <Card>
          <h4>Waiting valuation: {waitingValuationCount}</h4>
          <h4>Waiting revaluation: {waitingRevaluationCount}</h4>
          <h4>Valuated today: {valuatedCount}</h4>
          <h4>Your valuated today: {valuatedToday?.user}</h4>
        </Card>
      </div>
    </div>
  );
};
