import { Team } from '../../classes/Team';
import { BarCodePromptBody } from '../../components/BarCodePrompt';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import ErrorsAction from '../error/ErrorsAction';
import OrdersEffect from '../order/OrdersEffect';
import OrderQuery from '../order/OrdersQuery';
import RednotAction from '../rednot/RednotAction';

export enum ReceiverActionTypes {
  FETCH_RECEIVED_COUNT_REQUEST = '@RECEIVER:FETCH_RECEIVED_COUNT_REQUEST',
  FETCH_RECEIVED_COUNT_SUCCESS = '@RECEIVER:FETCH_RECEIVED_COUNT_SUCCESS',
  FETCH_RECEIVED_COUNT_FAIL = '@RECEIVER:FETCH_RECEIVED_COUNT_FAIL',

  FETCH_RECEIVED_BY_TEAM_COUNT_REQUEST = '@RECEIVER:FETCH_RECEIVED_BY_TEAM_COUNT_REQUEST',
  FETCH_RECEIVED_BY_TEAM_COUNT_SUCCESS = '@RECEIVER:FETCH_RECEIVED_BY_TEAM_COUNT_SUCCESS',
  FETCH_RECEIVED_BY_TEAM_COUNT_FAIL = '@RECEIVER:FETCH_RECEIVED_BY_TEAM_COUNT_FAIL',

  SUBMIT_REQUEST = '@RECEIVER:SUBMIT_REQUEST',
  SUBMIT_SUCCESS = '@RECEIVER:SUBMIT_SUCCESS',
  SUBMIT_FAIL = '@RECEIVER:SUBMIT_FAIL',
}
type SubmitRequest = Action<ReceiverActionTypes.SUBMIT_REQUEST>;
type SubmitFail = Action<ReceiverActionTypes.SUBMIT_FAIL>;
type SubmitSuccess = Action<ReceiverActionTypes.SUBMIT_SUCCESS>;

type FetchRequest = Action<ReceiverActionTypes.FETCH_RECEIVED_COUNT_REQUEST>;
type FetchFail = Action<ReceiverActionTypes.FETCH_RECEIVED_COUNT_FAIL>;
type FetchSuccess = ActionWPayload<
  ReceiverActionTypes.FETCH_RECEIVED_COUNT_SUCCESS,
  { count: number }
>;

type ByTeamBody = {
  turku: number;
  helsinki: number;
  gothenburg: number;
  malagaFinland: number;
  malagaSweden: number;
};

type FetchByTeamRequest = Action<ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_REQUEST>;
type FetchByTeamFail = Action<ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_FAIL>;
type FetchByTeamSuccess = ActionWPayload<
  ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_SUCCESS,
  ByTeamBody
>;

export type ReceiverActions =
  | FetchFail
  | FetchRequest
  | FetchSuccess
  | SubmitRequest
  | SubmitFail
  | SubmitSuccess
  | FetchByTeamRequest
  | FetchByTeamFail
  | FetchByTeamSuccess;

export default class ReceiverAction {
  private static fetchReceivedCountRequest(): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_COUNT_REQUEST,
    };
  }

  private static fetchReceivedCountSuccess(count: number): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_COUNT_SUCCESS,
      payload: { count },
    };
  }

  private static fetchReceivedCountFail(): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_COUNT_FAIL,
    };
  }

  private static fetchReceivedByTeamCountRequest(): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_REQUEST,
    };
  }

  private static fetchReceivedByTeamCountSuccess(counts: ByTeamBody): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_SUCCESS,
      payload: counts,
    };
  }

  private static fetchReceivedByTeamCountFail(): ReceiverActions {
    return {
      type: ReceiverActionTypes.FETCH_RECEIVED_BY_TEAM_COUNT_FAIL,
    };
  }

  private static onSubmitRequest(): ReceiverActions {
    return {
      type: ReceiverActionTypes.SUBMIT_REQUEST,
    };
  }

  private static onSubmitSuccess(): ReceiverActions {
    return {
      type: ReceiverActionTypes.SUBMIT_SUCCESS,
    };
  }

  private static onSubmitFail(): ReceiverActions {
    return {
      type: ReceiverActionTypes.SUBMIT_FAIL,
    };
  }

  public static fetchReceivedCount() {
    return (dispatch: ReduxDispatch) => {
      dispatch(this.fetchReceivedCountRequest());
      dispatch(this.fetchReceivedByTeamCountRequest());

      // Total
      OrdersEffect.count(OrderQuery.getReceiverTodayReceivedQuery())
        .then((res) => {
          dispatch(this.fetchReceivedCountSuccess(res.count));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.fetchReceivedCountFail)));

      // ByTeam
      const requests = [
        Team.TURKU,
        Team.HELSINKI,
        Team.GOTHENBURG,
        Team.MALAGA_FINLAND,
        Team.MALAGA_SWEDEN,
      ].map((team) => OrdersEffect.count(OrderQuery.getReceiverTodayReceivedByTeamQuery(team)));

      Promise.all(requests)
        .then(([turku, helsinki, gothenburg, malagaFinland, malagaSweden]) => {
          dispatch(
            this.fetchReceivedByTeamCountSuccess({
              turku: turku.count,
              helsinki: helsinki.count,
              gothenburg: gothenburg.count,
              malagaFinland: malagaFinland.count,
              malagaSweden: malagaSweden.count,
            })
          );
        })
        .catch(dispatch(ErrorsAction.onCatch(this.fetchReceivedByTeamCountFail)));
    };
  }

  public static onSubmit(body: BarCodePromptBody) {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const user = getState().user.data;
      dispatch(this.onSubmitRequest());
      OrdersEffect.find({
        where: { barCode: body.barCode },
        order: 'createdAt desc',
        include: ['client'],
      })
        .then(async (orders) => {
          if (!orders.length) return Promise.reject(new Error('Cannot find order'));
          const order = orders[0];
          dispatch(this.onSubmitSuccess());
          if (order.isReceived) return Promise.reject(new Error('Package is already received'));
          if (order.isColdCase) {
            if (!order.coldCaseCallerId) {
              return Promise.reject(new Error('Missing coldCaseCAllerId'));
            }
            if (order.coldCaseCallerId !== order.callerId) {
              await OrdersEffect.update(order.id, {
                callerId: order.coldCaseCallerId,
                originalCallerId: order.callerId,
              });
              dispatch(RednotAction.success('Cold case'));
            }
          }
          if (order.closed) {
            if (
              // eslint-disable-next-line no-restricted-globals,no-alert
              confirm(`Order is closed. Reason: ${order.closeReason}. Do you want to reopen case?`)
            ) {
              return OrdersEffect.update(order.id, {
                closed: false,
                closerId: null,
                closeDate: null,
                closeReason: '',
              });
            }
          }
          return Promise.resolve(order);
        })
        .then((order) => {
          let addressUpdate = {};

          if (!order.address || !order.zip || !order.city) {
            if (!order.client || !order.client.address || !order.client.zip || !order.client.city) {
              return Promise.reject(new Error('Address update missing information'));
            }
            addressUpdate = {
              address: order.client.address,
              zip: order.client.zip,
              city: order.client.city,
            };
          }

          return OrdersEffect.update(order.id, {
            ...addressUpdate,
            receiverId: user.id,
            isReceived: true,
            receivedDate: new Date(),
          });
        })
        .then(() => {
          dispatch(this.fetchReceivedCount());
          dispatch(RednotAction.success('Received'));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.onSubmitFail)));
    };
  }
}
