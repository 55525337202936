import { Form, Formik } from 'formik';
import { getErrorMessage } from 'loopback-error-parser';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card } from 'releox-react';
import ValuatedReportTable2 from '../../components/ValuatedReportTable2';
import ReportForm2 from '../../forms/report/ReportForm2';
import { validatedReportRequest, ValuatedReportResponse } from '../../requests/report.request';
import RednotAction from '../../store/rednot/RednotAction';

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<ValuatedReportResponse | null>(null);
  const initValues = useMemo(
    () => ({
      start: moment()
        .subtract(1, 'week')
        .startOf('day')
        .toDate(),
      end: moment()
        .endOf('day')
        .toDate(),
      team: '',
    }),
    []
  );

  const onSubmit = useCallback(
    async (body) => {
      try {
        setIsLoading(true);
        const localData = await validatedReportRequest(body);
        setData(localData);
        setIsLoading(false);
      } catch (e) {
        dispatch(RednotAction.error(getErrorMessage(e)));
      }
    },
    [dispatch]
  );

  return (
    <div id="ReportIndexScene">
      <div>
        <Card>
          <Formik onSubmit={onSubmit} initialValues={initValues}>
            <Form>
              <div className="row">
                <ReportForm2 />
                <div className="col-md-2">
                  <Button
                    id="submit"
                    type="submit"
                    className="btn-block mt-4"
                    disabled={isLoading}
                    disabledText="Loading..."
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
      {data ? <ValuatedReportTable2 data={data} /> : null}
    </div>
  );
};
