export default {
  MEMBER: `${window.API_ENDPOINT}/Members`,
  MATERIAL: `${window.API_ENDPOINT}/Materials`,
  CLIENT: `${window.API_ENDPOINT}/Clients`,
  ORDER: `${window.API_ENDPOINT}/Orders`,

  PACKAGER: {
    FIND_NEXT_ORDER: `${window.API_ENDPOINT}/Packagers/next/:country`,
    MARK_AS_PACKAGED: `${window.API_ENDPOINT}/Packagers/mark-as-packaged`,
    ADD_BARCODE: `${window.API_ENDPOINT}/Packagers/add-barcode`,
  },

  S3: {
    READ_SIGN_URL: `${window.API_ENDPOINT}/Packagers/read-pre-signed-url`,
    UPLOAD_SIGN_URL: `${window.API_ENDPOINT}/Packagers/upload-pre-signed-url`,
  },

  CALL_LOG: `${window.API_ENDPOINT}/CallLogs`,
  GREETINGS: `${window.API_ENDPOINT}/Greetings`,
  PAYMENTXML: `${window.API_ENDPOINT}/PaymentXMLs`,
  PACKAGE_CODE_REGISTRY: `${window.API_ENDPOINT}/PackageCodeRegistries`,
  CC_MANAGER_REPORT: `${window.API_ENDPOINT}/cc-manager-reports/cc-manager-report/:start/:end/:userId`,
  TELLER_STATS: `${window.API_ENDPOINT}/teller-stats`,
  VALUATION_STATS: `${window.API_ENDPOINT}/valuation-stats`,
};
