import React from 'react';
import { useSelector } from 'react-redux';
import { DataTableColumn, GenericIndexScene } from 'releox-react';
import { formatDateAndFrom } from '../../config';
import ClientsAction from '../../store/client/ClientsAction';
import ClientsSelector from '../../store/client/ClientsSelector';
import SearchSelector from '../../store/search/SearchSelector';
import ClientSearchGuide from '../ClientSearchGuide';
import Search from '../Search';

const columns: DataTableColumn[] = [
  { text: '#', dataField: 'clientNumber', sort: true },
  { text: 'Last name', dataField: 'lastName', sort: true },
  { text: 'First name', dataField: 'firstName', sort: true },
  { text: 'Country', dataField: 'country', sort: true },
  { text: 'Zip', dataField: 'zip', sort: true },
  { text: 'City', dataField: 'city', sort: true },
  { text: 'Phone', dataField: 'phone' },
  { text: 'Created', dataField: 'createdAt', sort: true, formatter: formatDateAndFrom },
  { text: 'Updated', dataField: 'updatedAt', sort: true, formatter: formatDateAndFrom },
];

interface Props {
  redirect: string;
}

export default ({ redirect }: Props) => {
  const where = useSelector(SearchSelector.selectClientQuery);
  return (
    <>
      <div className="row">
        <div className="col-7">
          <Search
            qSelector={SearchSelector.selectClientQ}
            type="client"
            searchFields={['firstName', 'lastName', 'phone', 'clientNumber']}
            SearchGuide={ClientSearchGuide}
          />
        </div>
      </div>
      <GenericIndexScene
        key={JSON.stringify(where)}
        tNamespace="ClientIndex"
        loadingSelector={ClientsSelector.selectClientsIsLoading}
        dataSelector={ClientsSelector.selectClients}
        dataSizeSelector={ClientsSelector.selectClientsCount}
        onChangeLoopback={ClientsAction.list}
        columns={columns}
        defaultSorted={{ dataField: 'createdAt', order: 'desc' }}
        query={{ where }}
        redirectUrl={redirect}
      />
    </>
  );
};
