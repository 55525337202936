import Axios from 'axios';
import apis from '../configs/apis';

type Body = {
  start: string;
  end: string;
  team?: string;
};

export type ValuatedReportResponse = {
  id: string;
  name: string;
  auIn: number;
  inTotal: number;
  outTotal: number;
  outTotal2w: number;
  auInPercent: string;
  auInPercent2Weeks: string;
  cancelled: number;
}[];

export type AcceptedOffersReportResponse = {
  id: string;
  name: string;
  totalOffer: number;
  counts: {
    [key: string]: number;
  };
  grossWeights: {
    [key: string]: number;
  };
  netWeights: {
    [key: string]: number;
  };
}[];

export const validatedReportRequest = async (body: Body) => {
  const { data } = await Axios.post<ValuatedReportResponse>(
    `${apis.ORDER}/reports/valuated-orders`,
    body
  );
  return data;
};

export const acceptedOffersReportRequest = async (body: Body) => {
  const { data } = await Axios.post<AcceptedOffersReportResponse>(
    `${apis.ORDER}/reports/accepted-offers`,
    body
  );
  return data;
};
