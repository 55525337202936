import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import apis from '../configs/apis';

type SignedImgProps = {
  src: string;
  className?: string;
};

export const SignedImg = ({ src, className }: SignedImgProps) => {
  const [_src, setSrc] = useState('');

  useEffect(() => {
    // update the src attribute of the img only if the src prop changes
    if (_src !== src) {
      (async () => {
        const { data } = await Axios.post(apis.S3.READ_SIGN_URL, {
          fileName: src,
        });
        setSrc(data);
      })();
    }
  }, [_src, src]);

  return <img src={_src} className={className} alt="-" />;
};

type SignedMagnifyImgProps = {
  src: string;
};

export const SignedMagnifyImg = ({ src }: SignedMagnifyImgProps) => {
  const [_src, setSrc] = useState('');

  useEffect(() => {
    // update the src attribute of the img only if the src prop changes
    if (_src !== src) {
      (async () => {
        const { data } = await Axios.post(apis.S3.READ_SIGN_URL, {
          fileName: src,
        });
        setSrc(data);
      })();
    }
  }, [_src, src]);

  return (
    <ReactImageMagnify
      enlargedImagePosition="over"
      smallImage={{
        alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,
        src: _src as string,
      }}
      largeImage={{
        src: _src as string,
        width: 805 * 3,
        height: 537 * 3,
      }}
    />
  );
};

type SignedLinkProps = {
  href: string;
  className?: string;
  children: React.ReactNode;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  download?: React.AnchorHTMLAttributes<HTMLAnchorElement>['download'];
  rel?: React.AnchorHTMLAttributes<HTMLAnchorElement>['rel'];
};

export const SignedLink = ({
  href,
  className,
  children,
  target,
  download,
  rel,
}: SignedLinkProps) => {
  const [_src, setSrc] = useState('');

  useEffect(() => {
    // update the src attribute of the img only if the src prop changes
    if (_src !== href) {
      (async () => {
        const { data } = await Axios.post(apis.S3.READ_SIGN_URL, {
          fileName: href,
        });
        setSrc(data);
      })();
    }
  }, [_src, href]);

  return (
    <a href={_src} className={className} target={target} download={download} rel={rel}>
      {children}
    </a>
  );
};

type LazySignedLinkProps = {
  href: string;
  className?: string;
  children: React.ReactNode;
};

export const LazySignedLink = ({ href, className, children }: LazySignedLinkProps) => {
  const handleOnClick = async () => {
    const { data } = await Axios.post(apis.S3.READ_SIGN_URL, {
      fileName: href,
    });

    // Download file from signed URL
    const link = document.createElement('a');
    link.href = data;
    link.download = href;
    link.click();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span onClick={handleOnClick} className={`link ${className}`}>
      {children}
    </span>
  );
};
