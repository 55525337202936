import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardTitle } from 'releox-react';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';
import TellerSmsAction from '../../store/teller-sms/TellerSmsAction';

const TellerSMS = () => {
  const dispatch = useDispatch();
  const params = useParams<{ language: string }>();
  const orders = useSelector(OrdersSelector.selectOrders);

  useEffect(() => {
    dispatch(OrdersAction.list(OrderQuery.getTellerSMSQuery(params.language)));
  }, [dispatch, params]);

  const sendMessages = useCallback(() => {
    dispatch(TellerSmsAction.sendSMS(params.language));
  }, [dispatch, params]);

  return (
    <div className="row">
      <div className="col">
        <Card>
          <CardTitle>Send SMS</CardTitle>
          {/* Button to send SMS */}
          <Button id="send-sms" onClick={sendMessages}>
            Send SMS
          </Button>
        </Card>
      </div>
      <div className="col">
        <Card>
          <div>
            <CardTitle>Clients</CardTitle>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* List of clients information */}
                {orders.map((order) => {
                  if (order.client) {
                    return (
                      <tr key={order.id}>
                        <td>{order.orderNumber}</td>
                        <td>{`${order.client.firstName} ${order.client.lastName}`}</td>
                        <td>{order.client.phone}</td>
                        <td>{order.arrivedSmsLog}</td>
                      </tr>
                    );
                  }
                  return '';
                })}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TellerSMS;
