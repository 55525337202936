import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, DataTableColumn, GenericIndexScene, Select } from 'releox-react';
import OrderColumn from '../../../columns/OrderColumn';
import routes from '../../../configs/routes';
import { teamOptions } from '../../../options';
import OrdersAction from '../../../store/order/OrdersAction';
import OrderQuery from '../../../store/order/OrdersQuery';
import OrdersSelector from '../../../store/order/OrdersSelector';

export default () => {
  const params = useParams<{ language: string }>();
  const [team, setTeam] = useState('');

  const columns: DataTableColumn[] = [
    OrderColumn.orderNumber,
    OrderColumn.phone,
    OrderColumn.clientName,
    OrderColumn.coldCallTries,
    OrderColumn.coldCallLatest,
    OrderColumn.createdAt,
    OrderColumn.callerName,
  ];

  const sort = {
    dataField: 'createdAt',
    order: 'DESC',
  };

  const handleSubmit = (values: any) => {
    setTeam(values.team);
  };

  return (
    <div key={params.language}>
      <Card>
        <Formik initialValues={{ team: '' }} onSubmit={handleSubmit}>
          <Form>
            {/* <CCManagerCallListSearchForm /> */}
            <Select name="team" label="Team" options={teamOptions} />
            <Button id="search" type="submit">
              Search
            </Button>
          </Form>
        </Formik>
      </Card>
      <div key={`${team}:${params.language}`}>
        <GenericIndexScene
          tNamespace="ColdCasesIndexScene"
          loadingSelector={OrdersSelector.selectIsListLoading}
          dataSelector={OrdersSelector.selectOrders}
          dataSizeSelector={OrdersSelector.selectOrdersCount}
          onChangeLoopback={OrdersAction.list}
          columns={columns}
          defaultSorted={sort}
          query={OrderQuery.getColdCaseIndex(params.language, team)}
          redirectUrl={routes.BUYER.COLD_CASES_SHOW.replace(':language', params.language)}
        />
      </div>
    </div>
  );
};
