import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardTitle } from 'releox-react';
import { PrintURL } from '../../classes/PrintURL';

interface Report {
  name: string;
  url: string;
}

interface RegionReport {
  key: string;
  reports: Report[];
}

interface Region {
  region: string;
  name: string;
}

const regions: Region[] = [
  { region: 'syd', name: 'Syd' },
  { region: 'vast', name: 'Vast' },
  { region: 'bergslagen', name: 'Bergslagen' },
  { region: 'ost', name: 'Öst' },
  { region: 'mitt', name: 'Mitt' },
  { region: 'nord', name: 'Nord' },
  { region: 'stockholm', name: 'Stockholm' },
];

const REVERSE_DATE_FORMAT = 'YYYY-MM-DD';

const getFileName = (start: string, end: string, region?: string) =>
  `SJMK Sverige AB - Police report ${!region ? 'ALL' : region} ${moment(start).format(
    REVERSE_DATE_FORMAT
  )} to ${moment(end).format(REVERSE_DATE_FORMAT)}`;

const weekDef = 'isoWeek';

const getStart = (sub: number) =>
  moment()
    .subtract(sub, 'week')
    .startOf(weekDef)
    .toISOString();

const getEnd = (sub: number) =>
  moment()
    .subtract(sub, 'week')
    .endOf(weekDef)
    .toISOString();

export default (): JSX.Element => {
  const [reports, setReports] = useState<Report[]>();
  const [regionList, setRegionList] = useState<RegionReport[]>([]);

  useEffect(() => {
    const week1 = {
      start: getStart(1),
      end: getEnd(1),
    };

    const week2 = {
      start: getStart(2),
      end: getEnd(2),
    };

    const week3 = {
      start: getStart(3),
      end: getEnd(3),
    };

    const r = [week1, week2, week3].map((w) => ({
      name: getFileName(w.start, w.end),
      url: PrintURL.getPoliceReportUrl(w.start, w.end),
    }));

    const regionReportList = [week1, week2, week3].map((w) => ({
      key: w.start,
      reports: regions.map((region) => ({
        name: getFileName(w.start, w.end, region.name),
        url: PrintURL.getPoliceRegionReportUrl(w.start, w.end, region.region),
      })),
    }));

    setRegionList(regionReportList);
    setReports(r);
  }, [setReports, setRegionList]);

  const listElements: JSX.Element[] = [];

  regionList.forEach((week) => {
    week.reports.forEach((item) => {
      listElements.push(
        <li key={item.name}>
          <a target="__blank" href={item.url}>
            {item.name}
          </a>
        </li>
      );
    });
    listElements.push(<br key={`key-${week.key}`} />);
  });

  return (
    <div className="row">
      <div className="col-sm-6">
        <Card>
          <CardTitle>Police reports by week</CardTitle>
          <ul>
            {reports?.map((r) => (
              <li key={r.name}>
                <a target="__blank" href={r.url}>
                  {r.name}
                </a>
              </li>
            ))}
          </ul>
        </Card>
      </div>
      <div className="col-sm-6">
        <Card>
          <CardTitle>Police reports by week by region</CardTitle>
          <>{listElements}</>
        </Card>
      </div>
    </div>
  );
};
