import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import { User } from '../user/User';

export default class TeamsEffect {
  public static restRequests = loopbackRequestCreator<User>(apis.MEMBER);

  public static findById = TeamsEffect.restRequests.getById;

  public static find = TeamsEffect.restRequests.getAll;

  public static create = TeamsEffect.restRequests.create;

  public static update = TeamsEffect.restRequests.update;

  public static delete = TeamsEffect.restRequests.delete;

  public static count = TeamsEffect.restRequests.count;
}
