import _ from 'lodash';
import { Print } from '../HOC/withQZ';
import { Order } from '../store/order/Order';
import { PrintURL } from './PrintURL';
import Printer from './Printer';

export default class PackagerPipe {
  private order: Order;

  private logMessage: (msg: string) => any;

  private print: Print;

  constructor(order: Order, addMessage: (msg: string) => any, print: Print) {
    this.order = order;
    this.logMessage = addMessage;
    this.print = print;
    addMessage('Packager print system initialized');
  }

  private getInvoiceOrLetterUrl(): string {
    if (this.order.isWebOrder) {
      return PrintURL.getLetterUrl(this.order.language, this.order.id);
    }
    return PrintURL.getInvoiceUrl(this.order.language, this.order.id);
  }

  async printCarrierLabel(): Promise<void> {
    try {
      this.logMessage('Printing carrier label...');

      const labelUrl = await PrintURL.getCarrierLabelUrl(this.order);

      this.logMessage(`Carrier label url: ${labelUrl}`);

      await this.print(labelUrl, Printer.ZEBRA, {
        units: 'cm',
        scaleContent: false,
        size: { height: 13, width: 10 },
      });

      this.logMessage('Print carrier label: OK!');
    } catch (e) {
      this.logMessage(`Print carrier label failed: ${_.get(e, 'message', 'Unknown error')}`);
      throw e;
    }
  }

  async printInvoice(): Promise<void> {
    try {
      this.logMessage('Waiting letter/invoice print...');
      const printUrl = this.getInvoiceOrLetterUrl();

      await this.print(printUrl, Printer.PACKAGER);

      this.logMessage('Print letter/invoice: OK!');
    } catch (e) {
      this.logMessage(`Print invoice failed: ${_.get(e, 'message', 'Unknown error')}`);
      throw e;
    }
  }

  async printAll(): Promise<void> {
    await this.printCarrierLabel();
    await this.printInvoice();
  }
}
