import {
  RegisterPackagesActionObject,
  RegisterPackagesActionTypes,
} from './RegisterPackagesAction';

export default class RegisterPackagesReducer {
  private static initState = {
    isLoading: false,
  };

  public static reducer(
    state = RegisterPackagesReducer.initState,
    action: RegisterPackagesActionObject
  ): typeof state {
    switch (action.type) {
      case RegisterPackagesActionTypes.REQUEST:
        return {
          ...state,
          isLoading: true,
        };

      case RegisterPackagesActionTypes.REQUEST_SUCCESS:
      case RegisterPackagesActionTypes.REQUEST_FAIL:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  }
}
