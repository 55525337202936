import React from 'react';
import { useSelector } from 'react-redux';
import { AsyncSelect, CoreuiCheckBox } from 'releox-react';
import TransferTable from '../../components/TransferTable';
import apis from '../../configs/apis';
import CallTransfersSelector from '../../store/call-transfer/CallTransfersSelector';

export default () => {
  const callTransferField = useSelector(CallTransfersSelector.selectCallTransferField);
  const searchUserId = useSelector(CallTransfersSelector.selectSearchUserId);

  return (
    <>
      <TransferTable fields={callTransferField} />
      <div className="row">
        <div className="col-lg-6">
          <AsyncSelect
            name="newCallerId"
            label="Transfer to"
            searchFields={['name']}
            query={{ id: { neq: searchUserId } }}
            getUrl={apis.MEMBER}
          />
        </div>
        <div className="col-lg-6 pt-4">
          <CoreuiCheckBox label="Transfer permanently" name="isPermanent" />
        </div>
      </div>
    </>
  );
};
