import { PackagerStatsActionObject, PackagerStatsActionTypes } from './PackagerStatsAction';

export default class PackagerStatsReducer {
  private static initState = {
    waitingFinnishPackageCount: 0,
    waitingFinlandSwedishPackageCount: 0,
    waitingSwedishPackageCount: 0,
    waitingUKPackageCount: 0,

    doneFinnishPackageCount: 0,
    doneFinlandSwedishPackageCount: 0,
    doneSwedishPackageCount: 0,
    doneUKPackageCount: 0,
  };

  public static reducer(
    state = PackagerStatsReducer.initState,
    action: PackagerStatsActionObject
  ): typeof state {
    switch (action.type) {
      case PackagerStatsActionTypes.WAITING_FI:
        return {
          ...state,
          waitingFinnishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.WAITING_FIN_SE:
        return {
          ...state,
          waitingFinlandSwedishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.WAITING_SE:
        return {
          ...state,
          waitingSwedishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.WAITING_UK:
        return {
          ...state,
          waitingUKPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.DONE_FI:
        return {
          ...state,
          doneFinnishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.DONE_FIN_SE:
        return {
          ...state,
          doneFinlandSwedishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.DONE_SE:
        return {
          ...state,
          doneSwedishPackageCount: action.payload.count,
        };

      case PackagerStatsActionTypes.DONE_UK:
        return {
          ...state,
          doneUKPackageCount: action.payload.count,
        };

      default:
        return state;
    }
  }
}
