import React from 'react';
import { Input, Select } from 'releox-react';
import * as Yup from 'yup';
import { Material } from '../store/material/Material';

export const MaterialValidationSchema: any = (ignore: string[] = []) => {
  const rules: any = {
    name: Yup.string().required(),
    category: Yup.string(),
    price: Yup.number().required(),
  };
  ignore.forEach((key) => {
    delete rules[key];
  });
  return Yup.object().shape(rules);
};

export const materialFormInitValue: Material = {
  id: '',
  name: '',
  category: '',
  price: 0,
};

const materialOptions = [
  { label: 'Gold', value: 'gold' },
  { label: 'Silver', value: 'silver' },
  { label: 'No category', value: '' },
];

export default () => (
  <div>
    <Input label="Name" name="name" />
    <Input label="Price" type="number" name="price" />
    <Select label="Category" name="category" options={materialOptions} />
  </div>
);
