import { CallCountsActionReturnType } from './CallCountsActionReturnType';
import CallCountsActionType from './CallCountsActionType';

export default class CallCountsReducer {
  private static initState = {
    afterCallCount: -1,
    welcomeCallCount: -1,
    isAfterCallCountLoading: false,
    isWelcomeCallCountLoading: false,
  };

  public static reducer(
    state = CallCountsReducer.initState,
    action: CallCountsActionReturnType
  ): typeof state {
    switch (action.type) {
      case CallCountsActionType.AFTER_CALL_FAIL:
        return {
          ...state,
          isAfterCallCountLoading: false,
        };

      case CallCountsActionType.WELCOME_CALL_FAIL:
        return {
          ...state,
          isWelcomeCallCountLoading: false,
        };

      case CallCountsActionType.WELCOME_CALL_REQUEST:
        return {
          ...state,
          isWelcomeCallCountLoading: true,
        };

      case CallCountsActionType.AFTER_CALL_REQUEST:
        return {
          ...state,
          isAfterCallCountLoading: true,
        };

      case CallCountsActionType.AFTER_CALL_SUCCESS:
        return {
          ...state,
          afterCallCount: action.payload.count,
          isAfterCallCountLoading: false,
        };

      case CallCountsActionType.WELCOME_CALL_SUCCESS:
        return {
          ...state,
          welcomeCallCount: action.payload.count,
          isWelcomeCallCountLoading: false,
        };

      default:
        return state;
    }
  }
}
