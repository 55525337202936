import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'react-router-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import BarCodePrompt, { BarCodePromptBody } from '../../components/BarCodePrompt';
import routes from '../../configs/routes';
import OrdersAction from '../../store/order/OrdersAction';
import OrdersEffect from '../../store/order/OrdersEffect';
import RednotAction from '../../store/rednot/RednotAction';

export type ReduxDispatch = ThunkDispatch<any, any, Action>;

const BursarBarCodeScene = () => {
  const params = useParams<{ country: string }>();
  const dispatch = useDispatch<ReduxDispatch>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(OrdersAction.clean());
  }, [dispatch]);

  const onSubmit = useCallback(
    (body: BarCodePromptBody) => {
      OrdersEffect.findByBarcode(body).then((order) => {
        let errMessage;

        if (!order) {
          setErrorMessage('Cannot find order');
          return dispatch(RednotAction.error('Cannot find order'));
        }

        if (!order.offeredDeclined) {
          if (!order.isDonation && !order.isPaid) errMessage = 'Order is not paid';
        }

        if (!order.isTellered) errMessage = 'Order is not handled by teller';

        if (order.country !== params.country) errMessage = `Order's country is: ${order.country}.`;

        if (errMessage) {
          setErrorMessage(errMessage);
          return dispatch(RednotAction.error(errMessage));
        }
        let url = routes.BURSAR.BURSAR_PAGE.replace(':country', params.country);
        url = url.replace(':id', order.id);
        return dispatch(push(url));
      });
    },
    [dispatch, params.country, setErrorMessage]
  );

  return (
    <BarCodePrompt
      key={params.country}
      title="Bursar"
      onSubmit={onSubmit}
      size={6}
      errorMessage={errorMessage}
    />
  );
};

export default BursarBarCodeScene;
