import React from 'react';
import { Button } from 'releox-react';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Formik, Form } from 'formik';

export interface GenericFormAbstractProps<R> {
  onSubmit(data: R): void;
  initialValues: R;
}

interface GenericFormBaseProps<R> extends GenericFormAbstractProps<R> {
  children: JSX.Element | JSX.Element[];
  showBackButton?: boolean;
}

interface BackButtonProps {
  toBack(): void;
}

const BackButton = ({ toBack }: BackButtonProps) => (
  <Button id="back-button" color="light" onClick={toBack}>
    Takaisin
  </Button>
);

const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  toBack: () => dispatch(goBack()),
});

const ConnectedBackButton = connect(undefined, mapDispatchToProps)(BackButton);

const GenericForm = <R extends {}>(props: GenericFormBaseProps<R>): JSX.Element => {
  const { onSubmit, initialValues, children, showBackButton } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <div id="form">
            <div id="form-container">{children}</div>
            {showBackButton ? <ConnectedBackButton /> : ''}
            <Button type="submit" id="submit-button" className="float-right">
              Send
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

GenericForm.defaultProps = {
  showBackButton: true,
};

export default GenericForm;
