import cleanDeep from 'clean-deep';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Button, Card, GenericIndexScene, Select } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import DatePicker from '../../components/DatePicker';
import routes from '../../configs/routes';
import { closeMethodOptions, teamOptions } from '../../options';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

export default () => {
  const timeKey = 'closeDate';
  const initWhere = {
    and: [
      { closed: true },
      {
        [timeKey]: {
          gt: moment()
            .subtract(7, 'days')
            .startOf('d')
            .toDate(),
        },
      },
      {
        [timeKey]: {
          lt: moment()
            .endOf('d')
            .toDate(),
        },
      },
      { team: '' },
      { closeMethod: '' },
    ],
  };
  const [where, setWhere] = useState(initWhere);
  const [reRenderCount, setReRenderCount] = useState(0);

  const onSubmit = useCallback(
    (body: any) => {
      setWhere(body);
      setReRenderCount(reRenderCount + 1);
    },
    [reRenderCount, setReRenderCount, setWhere]
  );

  return (
    <>
      <Card>
        <Formik initialValues={where} onSubmit={onSubmit}>
          <Form>
            <div className="row">
              <div className="col-sm-3">
                <DatePicker
                  name={`and.1.${timeKey}.gt`}
                  label="Close start"
                  maxDate={null}
                  minDate={null}
                />
              </div>
              <div className="col-sm-3">
                <DatePicker
                  name={`and.2.${timeKey}.lt`}
                  label="Close end"
                  maxDate={null}
                  minDate={null}
                />
              </div>
              <div className="col-sm-3">
                <Select name="and.3.team" label="Team" options={teamOptions} />
              </div>
              <div className="col-sm-3">
                <Select name="and.4.closeMethod" label="Method" options={closeMethodOptions} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button type="submit" id="submit">
                  Search
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </Card>
      <GenericIndexScene
        key={reRenderCount}
        tNamespace="CustomerClosed"
        loadingSelector={OrdersSelector.selectIsListLoading}
        dataSelector={OrdersSelector.selectOrders}
        dataSizeSelector={OrdersSelector.selectOrdersCount}
        onChangeLoopback={OrdersAction.list}
        columns={[
          OrderColumn.orderNumber,
          OrderColumn.clientName,
          OrderColumn.callerName,
          OrderColumn.closerName,
          OrderColumn.closeMethod,
          OrderColumn.closeReason,
          OrderColumn.closeDate,
          OrderColumn.createdAt,
        ]}
        defaultSorted={{ dataField: 'closeDate', order: 'DESC' }}
        query={{ where: cleanDeep(where), include: OrderQuery.include }}
        redirectUrl={routes.CUSTOMER_SERVICE.ORDER_SHOW}
      />
    </>
  );
};
