import React from 'react';
import { Card } from 'releox-react';
import { ValuatedReportResponse } from '../requests/report.request';

type Props = {
  data: ValuatedReportResponse;
};

export default ({ data }: Props) => {
  return (
    <div>
      <Card>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>AU in count</th>
              <th>Total in count</th>
              <th>AU in %</th>
              <th>Total out count</th>
              <th>Total out count (2w)</th>
              <th>AU in % (2w)</th>
              <th>Cancelled</th>
            </tr>
          </thead>
          <tbody>
            {data.map((record) => (
              <tr key={record.name}>
                <td>{record.name}</td>
                <td>{record.auIn}</td>
                <td>{record.inTotal}</td>
                <td>{record.auInPercent}</td>
                <td>{record.outTotal}</td>
                <td>{record.outTotal2w}</td>
                <td>{record.auInPercent2Weeks}</td>
                <td>{record.cancelled}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
