import Axios from 'axios';
import qz from 'qz-tray';
import createHash from 'sha.js';

declare global {
  interface Window {
    KJUR: any;
    stob64: any;
    hextorstr: any;
  }
}

class QZPrint {
  certURL: string;

  privateKeyURL: string;

  constructor(certURL: string, privateKeyURL: string) {
    this.certURL = certURL;
    this.privateKeyURL = privateKeyURL;
  }

  getConnectionInstance() {
    return new Promise((proresolve, proreject) => {
      qz.api.setPromiseType((resolver: any) => new Promise(resolver));
      qz.api.setSha256Type((data: any) =>
        createHash('sha256')
          .update(data)
          .digest('hex')
      );
      qz.security.setCertificatePromise((resolve: any) =>
        Axios.get(this.certURL).then((cert: any) => resolve(cert.data))
      );
      qz.security.setSignaturePromise((toSign: any) => (resolve: any, reject: any) => {
        Axios.get(this.privateKeyURL).then((privateKey) => {
          try {
            const pk = privateKey.data;
            const sig = new window.KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
            sig.init(pk);
            sig.updateString(toSign);
            const hex = sig.sign();
            resolve(window.stob64(window.hextorstr(hex)));
          } catch (err) {
            reject(err);
          }
        });
      });
      qz.websocket
        .connect()
        .then(() => {
          proresolve(qz);
        })
        .catch(proreject);
    });
  }
}

export default QZPrint;
