import { Form, Formik } from 'formik';
import React from 'react';
import { Button, Card, CardTitle } from 'releox-react';
import TimeRecordingForm from '../../forms/TimeRecordingForm';

export default () => {
  return (
    <div className="row">
      <div className="col-lg-6 offset-3">
        <Card>
          <CardTitle>Create Time Tracking</CardTitle>
          <Formik initialValues={{}} onSubmit={console.log}>
            <Form>
              <TimeRecordingForm />
              <Button id="back" type="button">
                Back
              </Button>
              <Button id="" type="button" className="float-right">
                Save
              </Button>
            </Form>
          </Formik>
        </Card>
      </div>
    </div>
  );
};
