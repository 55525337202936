import { fixFloat } from '../../../config';
import { Product } from '../../../store/order/Order';
import addOrCalculate from '../../add-or-calculate';
import getNetWeight from '../../get-net-weight';

interface WeightReportWeight {
  [key: string]: number;
}

interface ReportTotalData {
  gross: WeightReportWeight;
  net: WeightReportWeight;
  counts: WeightReportWeight;
  offer: number;
}

export default class AcceptedReportTotal {
  private total: ReportTotalData;

  constructor() {
    this.total = {
      gross: {},
      net: {},
      counts: {},
      offer: 0,
    };
  }

  public incrementGoldCount(): void {
    this.calculateCount('gold');
  }

  public calculateCount(density: string): void {
    this.total.counts[density] = addOrCalculate(this.total.counts, density, 1);
  }

  public calculateGrossWeight(density: string, weight: number): void {
    this.total.gross[density] = fixFloat(addOrCalculate(this.total.gross, density, weight));
  }

  public calculateNetWeight(product: Product): void {
    const { density } = product;
    const netWeight = getNetWeight(product);
    this.total.net[density] = fixFloat(addOrCalculate(this.total.net, density, netWeight));
    if (density !== 'customGold') {
      this.total.net.gold = fixFloat(addOrCalculate(this.total.net, 'gold', netWeight));
    }
  }

  public calculateTotalOffer(offer: number): void {
    const totalOffer = this.total.offer + offer;
    this.total.offer = fixFloat(totalOffer);
  }

  public getGrosses(): WeightReportWeight {
    return this.total.gross;
  }

  public getNet(): WeightReportWeight {
    return this.total.net;
  }

  public getCounts(): WeightReportWeight {
    return this.total.counts;
  }

  public getTotalOffer(): number {
    return this.total.offer;
  }
}
