import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardTitle, Details } from 'releox-react';
import withQZ, { Print } from '../../HOC/withQZ';
import Printer from '../../classes/Printer';
import OrderColumn from '../../columns/OrderColumn';
import routes from '../../configs/routes';
import ClientForm, { ClientValidationSchema } from '../../forms/ClientForm';
import PackagerSystemsAction from '../../store/PackagerSystem/PackagerSystemsAction';
import PackagerSystemsSelector from '../../store/PackagerSystem/PackagerSystemsSelector';
import { Client } from '../../store/client/Client';
import UsersSelector from '../../store/user/UsersSelector';

const NoMore = () => (
  <Card>
    <CardTitle>No more orders</CardTitle>
    <Link to={routes.PACKAGER.PACKAGER_LANGUAGE_SELECT}>Back to package page</Link>
  </Card>
);

interface Props {
  print: Print;
}

const PackagerSystem = ({ print }: Props) => {
  const dispatch = useDispatch();
  const hasNoMoreOrders = useSelector(PackagerSystemsSelector.selectHasNoMoreOrders);
  const params = useParams<{ language: string }>();
  const user = useSelector(UsersSelector.selectUser);
  const order = useSelector(PackagerSystemsSelector.selectOrder);
  const isSending = useSelector(PackagerSystemsSelector.selectIsSending);
  const isLoading = useSelector(PackagerSystemsSelector.selectIsLoading);
  const logMessages = useSelector(PackagerSystemsSelector.selectMessages);
  const isValidPackage = useSelector(PackagerSystemsSelector.selectIsValidPackage);

  useEffect(() => {
    dispatch(PackagerSystemsAction.findPackage(user, params.language));
    return () => {
      dispatch(PackagerSystemsAction.reset());
    };
  }, [dispatch, user, params.language]);

  const handleUpdate = useCallback(
    (body: Client) => {
      dispatch(PackagerSystemsAction.updateClient(body));
    },
    [dispatch]
  );

  const handleSend = useCallback(() => {
    dispatch(PackagerSystemsAction.send(print, user, params.language));
  }, [dispatch, print, user, params.language]);

  let buttonDisableText = 'Loading...';
  if (isSending) buttonDisableText = 'Sending...';
  if (!isValidPackage) buttonDisableText = 'Invalid package number.';

  if (hasNoMoreOrders) return <NoMore />;
  return (
    <div className="row">
      <div className="col-sm-5 offset-sm-2">
        <Card>
          <>
            <CardTitle>Package order</CardTitle>
            <Details
              properties={[
                OrderColumn.orderNumber,
                OrderColumn.dueDate,
                OrderColumn.welcomeCall,
                OrderColumn.phone,
                OrderColumn.language,
                OrderColumn.clientName,
                OrderColumn.address,
                OrderColumn.generalMessage,
              ]}
              object={order}
            />

            {order.packagerMessageFromBuyer ? (
              <div className="alert alert-danger my-3">
                <b>{order.packagerMessageFromBuyer}</b>
              </div>
            ) : (
              ''
            )}

            <hr />
            <Link to={routes.PACKAGER.PACKAGER_LANGUAGE_SELECT} className="btn btn-primary">
              Back
            </Link>
            <Button
              id="button"
              className="float-right"
              onClick={handleSend}
              disabledText={buttonDisableText}
              disabled={isSending || isLoading || !isValidPackage}
            >
              Send
            </Button>
          </>
        </Card>
        <Card key={JSON.stringify(order.client)}>
          <details>
            <summary>Edit client information</summary>
            {order.client ? (
              <Formik
                initialValues={order.client}
                validateSchema={ClientValidationSchema}
                onSubmit={handleUpdate}
              >
                <Form>
                  <ClientForm />
                  <Button id="update-button" className="float-right" type="submit">
                    Update
                  </Button>
                </Form>
              </Formik>
            ) : (
              'Missing client population'
            )}
          </details>
        </Card>
      </div>
      <div className="col-sm-3">
        <Card>
          <>
            <CardTitle>Log</CardTitle>
            {logMessages.map((m) => (
              <span key={m} style={{ display: 'block', marginBottom: '5px' }}>
                {m}
              </span>
            ))}
          </>
        </Card>
      </div>
    </div>
  );
};

export default withQZ(PackagerSystem, Printer.PACKAGER);
