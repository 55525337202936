import React from 'react';
import { GenericIndexScene } from 'releox-react';
import OrderColumn from '../../columns/OrderColumn';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

export default () => {
  const columns = [
    OrderColumn.orderNumber,
    OrderColumn.clientName,
    OrderColumn.phone,
    OrderColumn.arrivedSMSDate,
    OrderColumn.arrivedSMSLog,
  ];
  const query = OrderQuery.getTellerSMSLogQuery();
  return (
    <GenericIndexScene
      tNamespace="TellerSMSLog"
      loadingSelector={OrdersSelector.selectIsListLoading}
      dataSelector={OrdersSelector.selectOrders}
      dataSizeSelector={OrdersSelector.selectOrdersCount}
      onChangeLoopback={OrdersAction.list}
      columns={columns}
      defaultSorted={{ dataField: 'arrivedSmsDate', order: 'DESC' }}
      query={query}
    />
  );
};
