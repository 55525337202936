import { sentenceCase } from 'change-case';
import { Field, FieldProps } from 'formik';
import getValue from 'get-value';
import React, { useCallback } from 'react';
import {
  AbstractBaseInputProps,
  BaseInput,
} from 'releox-react/lib/components/form/BaseInput/BaseInput';
import { Material } from '../../store/material/Material';
import calculateProductPrice from '../../utils/calculate-product-price';

export interface SelectOption {
  value: string | number;
  label: string | number;
}

interface Props extends AbstractBaseInputProps {
  options: SelectOption[];
  materials: Material[];
}

export const getFieldPrefix = (fieldProps: FieldProps) => {
  const fieldName = fieldProps.field.name;
  const index = fieldName.lastIndexOf('.');
  const fieldNamePrefix = fieldName.slice(0, index);
  return fieldNamePrefix;
};

export default (props: Props) => {
  const { name, options, materials } = props;

  const onChange = useCallback(
    (fieldProps: FieldProps) => (e: React.FormEvent<HTMLSelectElement>) => {
      const fieldNamePrefix = getFieldPrefix(fieldProps);

      const weight = getValue(fieldProps.form.values, `${fieldNamePrefix}.weight`);
      let densityName = '';
      let category = '';
      let factor: number | string = '';
      const density = e.currentTarget.value;

      if (density) {
        if (['other', 'customGold'].includes(density)) {
          densityName = sentenceCase(density);
        } else {
          const material = materials.find((m) => m.id === density);
          if (!material) throw new Error('ProductDensitySelect.onChange missing material');
          densityName = material.name;
          factor = material.price;
          category = material.category;
        }
      }

      fieldProps.form.setFieldValue(`${fieldNamePrefix}.category`, category);
      fieldProps.form.setFieldValue(`${fieldNamePrefix}.factor`, '');

      fieldProps.form.setFieldValue(`${fieldNamePrefix}.factor`, factor);
      fieldProps.form.setFieldValue(`${fieldNamePrefix}.density`, density);
      fieldProps.form.setFieldValue(`${fieldNamePrefix}.densityName`, densityName);
      fieldProps.form.setFieldValue(
        `${fieldNamePrefix}.price`,
        calculateProductPrice(getValue({ density, factor, weight }), materials)
      );
    },
    [materials]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseInput {...props}>
      {({ getInputClass, getErrorMessageField, getId }) => (
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            return (
              <>
                <select
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...fieldProps.field}
                  onChange={onChange(fieldProps)}
                  id={getId()}
                  className={getInputClass(fieldProps)}
                >
                  {options.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
                {getErrorMessageField()}
              </>
            );
          }}
        </Field>
      )}
    </BaseInput>
  );
};
