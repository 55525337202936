import ValuateInitialValues from '../../forms/valuation/ValuateInitialValues';
import { ValuatesActionObject, ValuatesActionTypes, ValuateScenes } from './ValuatesAction';

export default class ValuatesReducer {
  public static initState = {
    view: ValuateScenes.MESSAGES,
    form: ValuateInitialValues,
    goldWeight: 0,
    silverWeight: 0,
    price: 0,
    customGoldWeight: 0,
    otherWeight: 0,
  };

  public static reducer(
    state = ValuatesReducer.initState,
    action: ValuatesActionObject
  ): typeof state {
    switch (action.type) {
      case ValuatesActionTypes.RESET:
        return ValuatesReducer.initState;

      case ValuatesActionTypes.NEXT_STEP:
        return {
          ...state,
          view: action.payload.nextStep,
        };

      case ValuatesActionTypes.SAVE_FORM:
        return {
          ...state,
          form: action.payload.form,
        };

      case ValuatesActionTypes.SET_DEFAULTS:
        return {
          ...state,
          form: {
            ...state.form,
            valuationImage: action.payload.valuationImage || '',
            tellerMessageFromValuation: action.payload.tellerMessageFromValuation || '',
            materialWeight: action.payload.materialWeight ? `${action.payload.materialWeight}` : '',
            packageWeight: action.payload.packageWeight ? `${action.payload.packageWeight}` : '',
            products: action.payload.products,
          },
          silverWeight: action.payload.silverWeight || 0,
          goldWeight: action.payload.goldWeight || 0,
        };

      case ValuatesActionTypes.CALCULATE:
        return {
          ...state,
          goldWeight: action.payload.goldWeight,
          silverWeight: action.payload.silverWeight,
          price: action.payload.price,
          customGoldWeight: action.payload.customGoldWeight,
          otherWeight: action.payload.otherWeight,
        };

      default:
        return state;
    }
  }
}
