import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import CallLog from './CallLog';

export default class CallLogsEffect {
  public static restRequests = loopbackRequestCreator<CallLog>(apis.CALL_LOG);

  public static findById = CallLogsEffect.restRequests.getById;

  public static find = CallLogsEffect.restRequests.getAll;

  public static create = CallLogsEffect.restRequests.create;

  public static update = CallLogsEffect.restRequests.update;

  public static delete = CallLogsEffect.restRequests.delete;

  public static count = CallLogsEffect.restRequests.count;
}
