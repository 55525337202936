import { Order } from './store/order/Order';

export default (order: Order) => {
  if (order.closed) return 'Closed';
  if (order.isComplete) return 'Complete';
  if (order.isPaid) return 'Is paid';
  if (order.isRevaluated) return 'Is revaluated';
  if (order.isTellered) return 'Is tellered';
  if (order.returnToValuation) return 'In revaluation';
  if (order.isValued) return 'Is valuated';
  if (order.isReceived) return 'Received';
  if (order.welcomeCallDone) return 'Welcome call done';
  if (order.isPackaged) return 'Is packaged';
  if (order.inPackager) return 'In packager';
  return 'Waiting packager';
};
