import { loopbackRequestCreator } from 'redux-rest-helper-for-loopback';
import apis from '../../configs/apis';
import PaymentXml from './PaymentXml';

export default class PaymentXmlsEffect {
  static restRequests = loopbackRequestCreator<PaymentXml>(apis.PAYMENTXML);

  static find = PaymentXmlsEffect.restRequests.getById;

  static findAll = PaymentXmlsEffect.restRequests.getAll;

  static create = PaymentXmlsEffect.restRequests.create;

  static update = PaymentXmlsEffect.restRequests.update;

  static delete = PaymentXmlsEffect.restRequests.delete;

  static count = PaymentXmlsEffect.restRequests.count;
}
