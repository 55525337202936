/* eslint-disable class-methods-use-this */
import Axios from 'axios';
import apis from '../configs/apis';
import { Order } from '../store/order/Order';
import { Country } from './Country';
import { Language } from './Language';

export class PrintURL {
  private BASE = window.API_ENDPOINT;

  private url: string = this.BASE;

  private accessToken() {
    this.url += `?access_token=${Axios.defaults.headers.common.Authorization}`;
    return this;
  }

  private s3() {
    this.url = 'https://sjmk-work.s3.eu-central-1.amazonaws.com';
    return this;
  }

  private add(url: string) {
    this.url += url;
    return this;
  }

  private getUrl(encode: boolean = false) {
    if (encode) encodeURI(this.url);
    return this.url;
  }

  private getUrlLanguage(lang: string) {
    if (lang === Language.UK_ENGLISH) return Language.UK_ENGLISH;
    if (lang === Language.SWEDISH) return Language.SWEDISH;
    if (lang === Language.FINLAND_SWEDISH) return Language.FINLAND_SWEDISH;
    if (lang === Language.FINNISH) return Language.FINNISH;
    throw new Error('Unknown language');
  }

  private async signUrl(): Promise<string> {
    const { data } = await Axios.post(apis.S3.READ_SIGN_URL, {
      fileName: this.url,
    });

    return data;
  }

  static async getCarrierLabelUrl(order: Order): Promise<string> {
    const prints = PrintURL.createInstace();

    switch (order.country) {
      case Country.SWEDEN:
        return prints
          .s3()
          .add(`/postnord/${order.barCode}.pdf`)
          .signUrl();
      case Country.FINLAND:
        return prints
          .add(`/Packagers/${order.id}/fi/carrier-label`)
          .accessToken()
          .getUrl();
      case Country.UK:
        return prints
          .add(`/Packagers/${order.id}/uk/carrier-label`)
          .accessToken()
          .getUrl();
      default:
        throw new Error('Unknown country');
    }
  }

  static getLetterUrl(lang: string, id: string) {
    const prints = PrintURL.createInstace();

    return prints
      .add(`/Orders/${prints.getUrlLanguage(lang)}/${id}/letter`)
      .accessToken()
      .getUrl();
  }

  static getInvoiceUrl(lang: string, id: string) {
    const prints = PrintURL.createInstace();

    if (lang === Language.UK_ENGLISH) {
      return PrintURL.getLetterUrl(lang, id);
    }

    return prints
      .add(`/Orders/${prints.getUrlLanguage(lang)}/${id}/invoice`)
      .accessToken()
      .getUrl();
  }

  static getPoliceRegionReportUrl(start: string, end: string, region: string) {
    const prints = PrintURL.createInstace();

    return prints
      .add(`/PoliceReports/sweden/${start}/${end}/${region}`)
      .accessToken()
      .getUrl(true);
  }

  static getPoliceReportUrl(start: string, end: string) {
    const prints = PrintURL.createInstace();

    return prints
      .add(`/PoliceReports/sweden/${start}/${end}`)
      .accessToken()
      .getUrl(true);
  }

  static getValuationLabelUrl(orderId: string) {
    const prints = PrintURL.createInstace();

    return prints
      .add(`/Orders/${orderId}/valuation-label`)
      .accessToken()
      .getUrl();
  }

  static getAddressLabelUrl(orderId: string) {
    const prints = PrintURL.createInstace();

    return prints
      .add(`/Orders/${orderId}/address-label`)
      .accessToken()
      .getUrl();
  }

  static createInstace() {
    return new PrintURL();
  }
}
