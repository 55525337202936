import moment from 'moment';
import { push } from 'react-router-redux';
import { getErrorMessage } from 'releox-react';
import routes from '../../configs/routes';
import { orderFormInitValue } from '../../forms/OrderForm';
import { sendSingleSMS } from '../../send-sms';
import { Action, ActionWPayload, GetState, ReduxDispatch } from '../Action';
import CallLog from '../call-log/CallLog';
import CallLogsAction from '../call-log/CallLogsAction';
import ErrorsAction from '../error/ErrorsAction';
import { Order } from '../order/Order';
import OrdersAction from '../order/OrdersAction';
import OrdersEffect from '../order/OrdersEffect';
import RednotAction from '../rednot/RednotAction';
import { User } from '../user/User';

export enum AfterCallEventOption {
  // WILL_RETURN = 'willReturn',
  // WILL_NOT_RETURN = 'willNotReturn',
  NEW_DUE_DATE = 'newDueDate',
  INVOICE_PAID = 'invoicePaid',
  HAS_NOT_ARRIVED = 'hasNotArrived',
  WANTS_A_NEW_SECURITY_BAG = 'wantsANewSecurityBag',
  DOES_NOT_ANSWER = 'doesNotAnswer',
  EMPTY_EVENT = '',
}

export enum AfterCallsActionTypes {
  SMS_REQUEST = '@AFTER_CALL:SMS_REQUEST',
  SMS_FAIL = '@AFTER_CALL:SMS_FAIL',
  SMS_SUCCESS = '@AFTER_CALL:SMS_SUCCESS',
  SET_LOADING = '@AFTER_CALL:SET_LOADING',
}

type SMSRequest = Action<AfterCallsActionTypes.SMS_REQUEST>;
type SMSFail = Action<AfterCallsActionTypes.SMS_FAIL>;
type SMSSuccess = Action<AfterCallsActionTypes.SMS_SUCCESS>;
type SetLoading = ActionWPayload<AfterCallsActionTypes.SET_LOADING, { isLoading: boolean }>;

export type AfterCallsActionObject = SMSRequest | SMSFail | SMSSuccess | SetLoading;

export default class AfterCallsAction {
  public static MHSMSMessage =
    'Palautuskoodi matkahuoltoa varten on 9516710. Ystävällisin terveisin SJMK Service Ltd';

  public static getInitialValues(order: Order) {
    return {
      client: order.client,
      dueDate: moment(order.dueDate).toDate(),
      generalMessage: order.generalMessage,
      valuationMessageFromBuyer: order.valuationMessageFromBuyer,
      tellerMessageFromBuyer: order.tellerMessageFromBuyer,
      event: AfterCallEventOption.EMPTY_EVENT,
      logMessage: '',
    };
  }

  private static sendSMSRequest(): AfterCallsActionObject {
    return {
      type: AfterCallsActionTypes.SMS_REQUEST,
    };
  }

  private static sendSMSSuccess(): AfterCallsActionObject {
    return {
      type: AfterCallsActionTypes.SMS_SUCCESS,
    };
  }

  private static sendSMSFail(): AfterCallsActionObject {
    return {
      type: AfterCallsActionTypes.SMS_FAIL,
    };
  }

  public static sendMHSMS() {
    return (dispatch: ReduxDispatch, getState: GetState) => {
      const phone = getState().orderEntry.model.data.client?.phone;
      if (!phone) return dispatch(RednotAction.error('Missing phone number'));
      dispatch(this.sendSMSRequest());
      return sendSingleSMS(phone, this.MHSMSMessage)
        .then(() => {
          dispatch(this.sendSMSSuccess());
          dispatch(RednotAction.success('Message sent'));
        })
        .catch(dispatch(ErrorsAction.onCatch(this.sendSMSFail)));
    };
  }

  public static setLoading(bool: boolean) {
    return {
      type: AfterCallsActionTypes.SET_LOADING,
      payload: {
        isLoading: bool,
      },
    };
  }

  private static createOrder(requestBody: Order, redirect: string) {
    return (dispatch: ReduxDispatch) => {
      OrdersEffect.create(requestBody)
        .then(() => dispatch(push(redirect)))
        .catch((e) => dispatch(RednotAction.error(getErrorMessage(e))));
    };
  }

  public static onSubmit(order: Order, user: User, body: AfterCallBody) {
    return (dispatch: ReduxDispatch) => {
      if (!body.event) return dispatch(RednotAction.error('Please choose event'));

      const callLogBody: CallLog = {
        id: '',
        type: 'AFTER_CALL',
        event: body.event,
        message: body.logMessage,
        memberId: user.id,
        orderId: order.id,
      };

      const updateRequestBody: Partial<Order> = {
        dueDate: body.dueDate,
        latestAfterCall: new Date().toString(),
      };

      const messagesRequestBody: Partial<Order> = {
        generalMessage: body.generalMessage,
        valuationMessageFromBuyer: body.valuationMessageFromBuyer,
        tellerMessageFromBuyer: body.tellerMessageFromBuyer,
      };

      const newOrder = {
        ...orderFormInitValue,
        clientId: order.clientId,
        clientBody: body.client,
        language: order.language,
        country: order.country,
        team: user.team,
        callerId: user.id,
        welcomeCall: moment()
          .add(4, 'day')
          .toDate(),
        dueDate: moment()
          .add(16, 'day')
          .toDate(),
      };

      const redirect = routes.BUYER.AFTER_CALLS;
      const actionOptions = { redirect };

      dispatch(OrdersAction.update(order.id, messagesRequestBody));

      switch (body.event) {
        case AfterCallEventOption.HAS_NOT_ARRIVED:
        case AfterCallEventOption.INVOICE_PAID:
          dispatch(CallLogsAction.create(callLogBody));
          dispatch(OrdersAction.closeOrder(order.id, user.id, 'phone', body.event));
          return dispatch(push(routes.BUYER.AFTER_CALLS));

        case AfterCallEventOption.WANTS_A_NEW_SECURITY_BAG:
          dispatch(CallLogsAction.create(callLogBody));
          dispatch(OrdersAction.closeOrder(order.id, user.id, 'phone', body.event));
          return dispatch(AfterCallsAction.createOrder(newOrder, redirect));

        case AfterCallEventOption.DOES_NOT_ANSWER:
          // TODO: Remove this line after 1.3.2020
          updateRequestBody.afterCallTries = order.afterCallTries || [];
          updateRequestBody.afterCallTries.push(new Date());
          // END-OF-REMOVE
          dispatch(CallLogsAction.create(callLogBody));
          return dispatch(OrdersAction.update(order.id, updateRequestBody, actionOptions));

        case AfterCallEventOption.NEW_DUE_DATE:
          dispatch(CallLogsAction.create(callLogBody));
          updateRequestBody.dueDate = moment(updateRequestBody.dueDate);
          return dispatch(OrdersAction.update(order.id, updateRequestBody, actionOptions));

        default:
          return dispatch(RednotAction.error('Please choose event'));
      }
    };
  }
}

export type AfterCallBody = ReturnType<typeof AfterCallsAction.getInitialValues>;
