import { ActionWPayload, ReduxDispatch } from '../Action';

export interface SearchBody {
  q: string;
}

export enum SearchActionType {
  SET_ORDER_QUERY = '@SEARCH_ORDER:SET_ORDER_QUERY',
  SET_CLIENT_QUERY = '@SEARCH_ORDER:SET_CLIENT_QUERY',
}

export type SearchableModels = 'order' | 'client';

type SetOrderQuery = ActionWPayload<
  SearchActionType.SET_ORDER_QUERY,
  { orderQuery: any; q: string }
>;
type SetClientQuery = ActionWPayload<
  SearchActionType.SET_CLIENT_QUERY,
  { clientQuery: any; q: string }
>;

export type SearchActions = SetOrderQuery | SetClientQuery;

export default class SearchAction {
  private static getBasicSearchQuery(value: string, searchFields: string[]) {
    return searchFields.map((s) => ({ [s]: value }));
  }

  private static setOrderQuery(orderQuery: any, q: string): SearchActions {
    return {
      type: SearchActionType.SET_ORDER_QUERY,
      payload: { orderQuery, q },
    };
  }

  private static setClientQuery(clientQuery: any, q: string): SearchActions {
    return {
      type: SearchActionType.SET_CLIENT_QUERY,
      payload: { clientQuery, q },
    };
  }

  private static getDateSearchQuery(params: string[]) {
    const date = `${params[2]}:${params[3]}:${params[4]}`;
    return { [params[0]]: { [params[1]]: date } };
  }

  public static onSubmit({ q }: SearchBody, type: SearchableModels, searchFields: string[]) {
    return (dispatch: ReduxDispatch) => {
      let query: any = {};
      if (!q) {
        query = {};
      } else if (!q.includes(':')) {
        query.or = this.getBasicSearchQuery(q, searchFields);
      } else {
        const statements = q.split(' ');
        query.and = statements.map((stmnt) => {
          const params = stmnt.split(':');
          if (params.length < 2) return {};
          if (params.length > 3) {
            return this.getDateSearchQuery(params);
          }
          if (params.length === 2) return { [params[0]]: params[1] };
          return { [params[0]]: { [params[1]]: params[2] } };
        });
      }
      let setter = this.setOrderQuery;
      if (type === 'client') setter = this.setClientQuery;
      dispatch(setter(query, q));
    };
  }
}
