const getFirstValueOfObject = (object: any) => {
  return object[Object.keys(object)[0]];
};

export default (errors: any) => {
  const firstValue = getFirstValueOfObject(errors);
  if (Array.isArray(firstValue)) {
    if (typeof firstValue[0] === 'string') return firstValue[0];
    const filtered = firstValue.filter((x) => x);
    if (filtered.length) return getFirstValueOfObject(filtered[0]);
  }
  return firstValue;
};
