import CCManagerReportReducerInit from './CCManagerReportReducerInit';
import { CcManagerReportsActionReturnType } from './CcManagerReportsActionReturnType';
import CcManagerReportsActionType from './CcManagerReportsActionType';

export default class CcManagerReportsReducer {
  public static initState = {
    isLoading: false,
    data: CCManagerReportReducerInit,
  };

  public static reducer(
    state = CcManagerReportsReducer.initState,
    action: CcManagerReportsActionReturnType
  ): typeof state {
    switch (action.type) {
      case CcManagerReportsActionType.SUCCESS:
        return {
          ...state,
          data: action.payload.data,
        };

      case CcManagerReportsActionType.CLEAN:
        return {
          ...state,
          data: CCManagerReportReducerInit,
        };

      default:
        return state;
    }
  }
}
