import { BuyerStatsActions, BuyerStatsActionTypes } from './BuyerStatsAction';

export default class BuyerStatsReducer {
  private static initState = {
    yourTodayOutCount: 0,
    teamTodayOutCount: 0,
    yourMonthOutCount: 0,
    teamMonthOutCount: 0,

    yourTodayAuInCount: 0,
    teamTodayAuInCount: 0,
    yourMonthAuInCount: 0,
    teamMonthAuInCount: 0,

    yourTodayCancelCount: 0,
    teamTodayCancelCount: 0,
    yourMonthCancelCount: 0,
    teamMonthCancelCount: 0,
  };

  public static reducer(
    state = BuyerStatsReducer.initState,
    action: BuyerStatsActions
  ): typeof state {
    switch (action.type) {
      case BuyerStatsActionTypes.FETCH_OUT_SUCCESS:
        return {
          ...state,
          yourTodayOutCount: action.payload.yourToday,
          teamTodayOutCount: action.payload.teamToday,
          yourMonthOutCount: action.payload.yourMonth,
          teamMonthOutCount: action.payload.teamMonth,
        };
      case BuyerStatsActionTypes.FETCH_IN_SUCCESS:
        return {
          ...state,
          yourTodayAuInCount: action.payload.yourToday,
          teamTodayAuInCount: action.payload.teamToday,
          yourMonthAuInCount: action.payload.yourMonth,
          teamMonthAuInCount: action.payload.teamMonth,
        };
      case BuyerStatsActionTypes.FETCH_CANCEL_SUCCESS:
        return {
          ...state,
          yourTodayCancelCount: action.payload.yourToday,
          teamTodayCancelCount: action.payload.teamToday,
          yourMonthCancelCount: action.payload.yourMonth,
          teamMonthCancelCount: action.payload.teamMonth,
        };
      default:
        return state;
    }
  }
}
