import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { LoopbackFilter } from 'redux-rest-helper-for-loopback';
import {
  Card,
  CardTitle,
  DataTableColumn,
  DataTableDefaultSort,
  GenericIndexScene,
} from 'releox-react';
import { Country } from '../../classes/Country';
import { Language } from '../../classes/Language';
import OrderColumn from '../../columns/OrderColumn';
import { fixFloat } from '../../config';
import apis from '../../configs/apis';
import routes from '../../configs/routes';
import { useUnvaluatedCount } from '../../react-query-hooks/teller/teller-query-hooks';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

const cols: DataTableColumn[] = [
  OrderColumn.offer,
  OrderColumn.tellerCallLatest,
  OrderColumn.clientName,
  OrderColumn.phone,
];

const columns: DataTableColumn[] = [OrderColumn.orderNumber, ...cols];

const todayColumns: DataTableColumn[] = [OrderColumn.orderNumberAspaLink, ...cols];

const defaultSorted: DataTableDefaultSort = { dataField: 'orderNumber', order: 'ASC' };
const baseWhere = {
  ...OrderQuery.closeFilter,
  isTellered: false,
  isValued: true,
  returnToValuation: false,
};
const generalWhere = { goldWeight: { neq: 0 } };
const zeroWhere = { price: 0 };
const silverWhere = { goldWeight: 0, silverWeight: { neq: 0 } };
const otherWhere = { goldWeight: 0, silverWeight: 0, price: { neq: 0 } };

const buildQuery = (language: Language, custom: any) => ({
  where: { ...baseWhere, ...custom, language },
  include: OrderQuery.include,
});

const parseRequest = (res: UseQueryResult<{ count: number }>) => {
  if (res.error) return 'Error';
  if (res.isLoading || !res.data) return 'Loading...';
  return res.data.count;
};

type TellerStats = {
  doneGoldWeight: number;
  undoneGoldWeight: number;
};

const index = (title: string, query: LoopbackFilter) => () => {
  const finRes = useUnvaluatedCount(Country.FINLAND);
  const sweRes = useUnvaluatedCount(Country.SWEDEN);
  // Get valuation stats from the api, by using react-query
  const { data: stats } = useQuery<TellerStats>(
    'valuationStats',
    async (): Promise<TellerStats> => {
      // Fetch the data from the api by using axios
      const { data } = await Axios.get(apis.TELLER_STATS);
      return data;
    }
  );

  return (
    <div className="row">
      <div className="col-md-10">
        <GenericIndexScene
          title={title}
          loadingSelector={OrdersSelector.selectIsListLoading}
          dataSelector={OrdersSelector.selectOrders}
          dataSizeSelector={OrdersSelector.selectOrdersCount}
          onChangeLoopback={OrdersAction.list}
          columns={columns}
          defaultSorted={defaultSorted}
          query={query}
          redirectUrl={routes.TELLER.TELLER_SHOW.replace(':lang', query.where.language)}
        />
      </div>
      <div className="col-md-2">
        <Card>
          <CardTitle>
            <b>Unvaluated</b>
          </CardTitle>
          <p>
            Finland: {parseRequest(finRes)}
            <br />
            Sweden: {parseRequest(sweRes)}
          </p>
          <CardTitle>
            <b>Stats</b>
          </CardTitle>
          <p className="card-text">
            Approved g/Au: {fixFloat(stats?.doneGoldWeight || 0)}
            <br />
            Open g/Au: {fixFloat(stats?.undoneGoldWeight || 0)}
          </p>
        </Card>
      </div>
    </div>
  );
};

export const TellerTodayHandled = () => (
  <GenericIndexScene
    tNamespace="TellerTodayHandled"
    loadingSelector={OrdersSelector.selectIsListLoading}
    dataSelector={OrdersSelector.selectOrders}
    dataSizeSelector={OrdersSelector.selectOrdersCount}
    onChangeLoopback={OrdersAction.list}
    columns={todayColumns}
    defaultSorted={{ dataField: 'tellerDate', order: 'DESC' }}
    query={{ where: { tellerDate: { gt: moment().startOf('day') } }, include: OrderQuery.include }}
  />
);

export const TellerFi = index('Finnish', buildQuery(Language.FINNISH, generalWhere));
export const TellerSwe = index('Swedish', buildQuery(Language.SWEDISH, generalWhere));
export const TellerFiSwe = index(
  'Finland swedish',
  buildQuery(Language.FINLAND_SWEDISH, generalWhere)
);
export const TellerUK = index('UK', buildQuery(Language.UK_ENGLISH, generalWhere));

export const TellerFiZero = index('Finnish zero', buildQuery(Language.FINNISH, zeroWhere));
export const TellerSweZero = index('Swedish zero', buildQuery(Language.SWEDISH, zeroWhere));
export const TellerFiSweZero = index(
  'Finland swedish zero',
  buildQuery(Language.FINLAND_SWEDISH, zeroWhere)
);
export const TellerUKZero = index('UK zero', buildQuery(Language.UK_ENGLISH, zeroWhere));

export const TellerFiSilver = index('Finnish silver', buildQuery(Language.FINNISH, silverWhere));
export const TellerSweSilver = index('Swedish silver', buildQuery(Language.SWEDISH, silverWhere));
export const TellerFiSweSilver = index(
  'Finland swedish silver',
  buildQuery(Language.FINLAND_SWEDISH, silverWhere)
);
export const TellerUKSilver = index('UK silver', buildQuery(Language.UK_ENGLISH, silverWhere));

export const TellerFiOther = index('Finnish other', buildQuery(Language.FINNISH, otherWhere));
export const TellerSweOther = index('Swedish other', buildQuery(Language.SWEDISH, otherWhere));
export const TellerFiSweOther = index(
  'Finland swedish other',
  buildQuery(Language.FINLAND_SWEDISH, otherWhere)
);
export const TellerUKOther = index('UK other', buildQuery(Language.UK_ENGLISH, otherWhere));
