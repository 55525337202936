import { Form, Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { goBack } from 'react-router-redux';
import { Button, Card, CardTitle, Loading, TextArea } from 'releox-react';
import * as Yup from 'yup';
import BuyerOrderInfo from '../../components/BuyerOrderInfo';
import DatePicker from '../../components/DatePicker';
import ClientForm, { ClientValidationSchema } from '../../forms/ClientForm';
import BuyerModifyAction from '../../store/buyer-modify/BuyerModifyAction';
import { Order } from '../../store/order/Order';
import OrdersAction from '../../store/order/OrdersAction';
import OrderQuery from '../../store/order/OrdersQuery';
import OrdersSelector from '../../store/order/OrdersSelector';

export default () => {
  const params = useParams<{ id: string }>();
  const order = useSelector(OrdersSelector.selectOrder);
  const isLoading = useSelector(OrdersSelector.selectIsModelLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(OrdersAction.fetch(params.id, { include: OrderQuery.include }));
  }, [dispatch, params.id]);

  const ValidationSchema = Yup.object().shape({
    client: ClientValidationSchema(),
  });

  const onSubmit = useCallback(
    (body: Order) => {
      dispatch(BuyerModifyAction.onSubmit(body));
    },
    [dispatch]
  );

  const onGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  if (isLoading || !order) return <Loading />;

  let content = (
    <>
      <ClientForm prefix="client" hideLanguage hideCountry hideIban />
      <DatePicker inline inlineLabelWidth={2} label="Due date" name="dueDate" />
    </>
  );

  if (order.isPackaged || order.inPackager) {
    content = (
      <>
        <CardTitle>Order is locked</CardTitle>
        <BuyerOrderInfo order={order} />
      </>
    );
  }

  return (
    <div className="col-sm-6 offset-sm-3">
      <Card>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={order}
          validationSchema={ValidationSchema}
        >
          <Form>
            <>
              {content}
              <hr />
              <DatePicker inline inlineLabelWidth={2} label="Welcome call" name="welcomeCall" />
              <TextArea label="General message" name="generalMessage" />
              <TextArea label="Message to valuation" name="valuationMessageFromBuyer" />
              <TextArea label="Message to teller" name="tellerMessageFromBuyer" />
              <Button id="goBack" onClick={onGoBack}>
                Back
              </Button>
              <Button type="submit" id="submit" className="float-right">
                Save
              </Button>
            </>
          </Form>
        </Formik>
      </Card>
    </div>
  );
};
